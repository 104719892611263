import React from 'react';
import firebase from 'firebase/compat/app';
import { unionBy } from 'lodash';

import { useAppContext, useProfileContext } from '@context';

type TMessageRole = 'manager' | 'creator' | 'system';
type TMessageType = 'text' | 'event' | 'image' | 'video';
export interface IMessage {
  id: string;
  type?: TMessageType;
  role: TMessageRole;
  userId: string;
  name: string;
  email: string;
  profileImage: string;
  timestamp: firebase.firestore.Timestamp;
  payload: {
    data: string;
  };
}

const { useContext, useCallback, useEffect, useState } = React;

interface ICollabMessagingContext {
  loading: boolean;
  messages: IMessage[];
  sendMessge(text: string): void;
}

const CollabMessagingContext = React.createContext<ICollabMessagingContext>(null);
export const useCollabMessagingContext = () => useContext(CollabMessagingContext);
export const CollabMessagingContextProvider: React.FC<
  React.PropsWithChildren<React.PropsWithChildren<{ collabId: string }>>
> = ({ children, collabId }) => {
  const { firebaseApp } = useAppContext();
  const { profile } = useProfileContext();
  const [messages, setMessages] = useState<IMessage[]>([]);
  const [loading, setLoading] = useState(false);

  const sendMessge = useCallback(
    async (text: string) => {
      const db = firebaseApp.firestore();
      const messagesRef = db.collection(collabId);

      try {
        const message = {
          role: 'manager',
          type: 'text',
          userId: profile.id,
          name: profile.name,
          email: profile.email,
          profileImage: profile.profileImage,
          timestamp: firebase.firestore.FieldValue.serverTimestamp(),
          payload: {
            data: text,
          },
        };
        await messagesRef.doc().set(message);
      } catch (err) {
        console.log('err', err);
      }
    },
    [firebaseApp, profile, collabId],
  );

  // update chat users
  useEffect(() => {
    let mounted = true;
    console.log('updating collab messaging', collabId);

    const db = firebaseApp.firestore();
    const messagesQuery = db.collection(collabId).orderBy('timestamp', 'desc');

    // fetch previous messages
    setLoading(true);
    messagesQuery.get().then((snapshot) => {
      const results = snapshot.docs.map((doc) => ({
        id: doc.id,
        ...doc.data(),
      }));
      console.log('messages', results);

      if (mounted) {
        setMessages(results as IMessage[]);
      }

      setLoading(false);
    });

    const unsub = messagesQuery.onSnapshot((snapshot) => {
      const newMessages = snapshot.docChanges().map((d) => {
        const data = d.doc.data();

        return {
          id: d.doc.id,
          ...data,
          // server timestamp is null at the time when the message is sent
          timestamp: data.timestamp || firebase.firestore.Timestamp.now(),
        };
      });

      setMessages((messages) => unionBy(newMessages as IMessage[], messages, 'id'));
    });

    return () => {
      unsub();
      mounted = false;
    };
  }, [collabId, setMessages, firebaseApp]);

  return (
    <CollabMessagingContext.Provider
      value={{
        loading,
        messages,
        sendMessge,
      }}
    >
      {children}
    </CollabMessagingContext.Provider>
  );
};
