import React from 'react';
import cx from 'classnames';

import { Collapse, Typography } from 'antd';

import styles from './CollapsiblePanel.module.scss';

interface IProps {
  title: React.ReactNode;
  children: React.ReactNode;

  ghost?: boolean;
  icon?: React.ReactNode;
  className?: string;
}

const { Title } = Typography;
const { Panel } = Collapse;

/**
 * @type {React.FC}
 */
export const CollapsiblePanel: React.FC<React.PropsWithChildren<IProps>> = React.memo(
  ({ title, children, ghost = true, icon, className }) => {
    return (
      <Collapse
        className={cx(styles.CollapsiblePanel, className, {
          [styles.ghost]: ghost,
        })}
        defaultActiveKey={['1']}
        expandIconPosition="end"
        ghost={ghost}
      >
        <Panel
          key="1"
          header={
            <Title className={styles.title} level={5}>
              {icon}
              {title}
            </Title>
          }
        >
          <div className={styles.content}>{children}</div>
        </Panel>
      </Collapse>
    );
  },
);
