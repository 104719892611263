import { gql } from '@apollo/client';

import {
  MANAGER_FRAGMENT,
  MEMBER_FRAGMENT,
  PROGRAM_COLLAB_ACTIVITY_FRAGMENT,
  PROGRAM_COLLAB_FRAGMENT,
} from './fragments';

//========================================================
//= Fragments
//========================================================
const PROGRAM_COLLAB_ACTIVITY_DETAIL_FRAGMENT = gql`
  fragment ProgramCollabActivityDetail on ProgramCollabActivity {
    ...ProgramCollabActivity
    collab {
      ...ProgramCollab
      member {
        ...Member
      }
    }
    manager {
      ...Manager
    }
  }
  ${PROGRAM_COLLAB_FRAGMENT}
  ${PROGRAM_COLLAB_ACTIVITY_FRAGMENT}
  ${MANAGER_FRAGMENT}
  ${MEMBER_FRAGMENT}
`;

//========================================================
//= Queries
//========================================================
export const GET_PROGRAM_COLLAB_ACTIVITIES_QUERY = gql`
  query GetProgramCollabActivities($collabId: String!) {
    activities: creatorGetProgramCollabActivities(collabId: $collabId) {
      ...ProgramCollabActivityDetail
    }
  }
  ${PROGRAM_COLLAB_ACTIVITY_DETAIL_FRAGMENT}
`;
