import React from 'react';
import cx from 'classnames';
import numeral from 'numeral';
import { isEmpty } from 'lodash';

import { Skeleton, Empty } from 'antd';
import { Line } from '@ant-design/charts';

import { IChartData, useSalesTrackingMetricsContext } from './SalesTrackingMetricsContext';

import styles from './Chart.module.scss';

interface IProps {
  className?: string;
}

const { useMemo } = React;

/**
 * @type {React.FC}
 */
export const Chart: React.FC<React.PropsWithChildren<IProps>> = React.memo(({ className }) => {
  const { chart, loading, sales, conversions, clicks, costs } = useSalesTrackingMetricsContext();

  const isDollar = useMemo(() => {
    if (['sales', 'costs'].includes(chart)) {
      return true;
    }

    return false;
  }, [chart]);
  const data = useMemo(() => {
    if (chart === 'clicks') {
      return clicks;
    } else if (chart === 'conversions') {
      return conversions;
    } else if (chart === 'sales') {
      return sales;
    }

    return costs;
  }, [chart, sales, conversions, clicks, costs]);

  return (
    <div className={cx(styles.Chart, className)}>
      {loading && <Skeleton.Input className={styles.skeleton} active />}
      {!loading && isEmpty(data) && (
        <Empty
          className={styles.empty}
          description="No tracking data available for selected period."
        />
      )}
      {!loading && !isEmpty(data) && (
        <Line
          data={data}
          theme="custom"
          xField="label"
          yField="value"
          seriesField="name"
          legend={{
            position: 'top-right',
          }}
          lineStyle={{
            lineWidth: 3,
          }}
          yAxis={{
            label: {
              formatter: function formatter(v) {
                return numeral(v).format(`${isDollar ? '$' : ''}0 a`);
              },
            },
          }}
          tooltip={
            {
              formatter: (d: IChartData) => {
                return {
                  name: d.name,
                  value: numeral(d.value).format(`${isDollar ? '$' : ''}0,0.[00]`),
                };
              },
            } as any
          }
          point={{
            shape: 'circle',
            style: {
              r: 3,
            },
          }}
          smooth={true}
          animation={{
            appear: {
              animation: 'wave-in', // 'path-in' does not work with point
              duration: 1000,
            },
          }}
        />
      )}
    </div>
  );
});
