import React from 'react';
import cx from 'classnames';
import { find, noop } from 'lodash';

import { Alert, Button, Divider, Drawer } from 'antd';
import { SocialAccountAvatar } from '@components';
import { SelectPostList } from './SelectPostList';

import {
  AuthorizationResourceStatus,
  IPostTrackingDetail,
  IProgramCollab,
  WorkflowEvent,
} from '@types';
import { useWorkflowCheckIn } from '@hooks';

import styles from './SubmitPostPanel.module.scss';

interface IProps {
  collab: IProgramCollab;
  tracking: IPostTrackingDetail;
  show: boolean;
  onRequestClose(): void;

  onComplete?(): void;
  className?: string;
}

const { useMemo, useCallback, useState } = React;

/**
 * @type {React.FC}
 */
export const SubmitPostPanel: React.FC<React.PropsWithChildren<IProps>> = React.memo(
  ({ collab, tracking, show, onRequestClose, onComplete = noop, className }) => {
    const { loading: checkingIn, workflowCheckIn } = useWorkflowCheckIn();
    const [postId, setPostId] = useState<string>(null);
    const [errorMessage, setErrorMessage] = useState('');

    const resource = useMemo(() => {
      return find(
        collab.member.accountResources,
        (r) => r.id === tracking.requirement.accountResourceId,
      );
    }, [collab, tracking]);
    const isResourceActive = useMemo(
      () => resource?.authorizationResource.status === AuthorizationResourceStatus.Active,
      [resource],
    );
    const handlePostClick = useCallback((id: string) => setPostId(id), []);
    const onClose = useCallback(() => {
      setPostId(null);
      onRequestClose();
    }, [onRequestClose]);
    const handleSubmit = useCallback(async () => {
      if (!postId) {
        return;
      }

      setErrorMessage('');

      try {
        await workflowCheckIn({
          variables: {
            collabId: collab.id,
            event: WorkflowEvent.ContentReviewStageSubmitPost,
            params: {
              contentReview: {
                trackingId: tracking.id,
                postId,
              },
            },
          },
        });

        onComplete();
        onRequestClose();
      } catch (err) {
        console.log(err);
        setErrorMessage((err as Error)?.message);
      }
    }, [collab, tracking, postId, workflowCheckIn, onComplete, onRequestClose]);

    return (
      <Drawer
        className={cx(styles.SubmitPostPanel, className)}
        open={show}
        onClose={onClose}
        title={
          <div className={styles.title}>
            <span>Choose post from account</span>
            <SocialAccountAvatar account={resource?.account} />
          </div>
        }
        footer={null}
        width={900}
      >
        {!isResourceActive && (
          <Alert
            type="error"
            message="The account is not active"
            description="Please contact the member to reconnect their account."
          />
        )}
        {isResourceActive && (
          <div className={styles.content}>
            <SelectPostList
              className={styles.list}
              resource={resource}
              selectedPostId={postId}
              onPostClick={handlePostClick}
            />
            {errorMessage && (
              <Alert
                className={styles.alert}
                type="error"
                message="Failed to submit post"
                description={errorMessage}
                closable
              />
            )}
            <Divider />
            <div className={styles.submit}>
              <Button onClick={onClose}>Cancel</Button>
              <Button type="primary" disabled={!postId} loading={checkingIn} onClick={handleSubmit}>
                Submit Post
              </Button>
            </div>
          </div>
        )}
      </Drawer>
    );
  },
);
