import { gql } from '@apollo/client';

import {
  MEMBER_FRAGMENT,
  PROGRAM_FRAGMENT,
  PROGRAM_COLLAB_FRAGMENT,
  PROGRAM_COLLAB_USER_STATUS_FRAGMENT,
  PROGRAM_COLLAB_CONTRACT_FRAGMENT,
  CONTENT_FRAGMENT,
  WORKFLOW_ITEM_FRAGMENT,
  PROGRAM_COLLAB_ORDER_FRAGMENT,
  SHOPIFY_ORDER_FRAGMENT,
  AFTERSHIP_TRACKING_FRAGMENT,
  COLLAB_SALES_TRACKING_FRAGMENT,
  COLLAB_TERM_FRAGMENT,
  COLLAB_TERM_ITERATION_FRAGMENT,
} from './fragments';
import { CREATOR_ACCOUNT_RESOURCE_DETAIL_FRAGMENT } from './creatorAccountResources';
import { CONTENT_REVIEW_DETAIL_FRAGMENT } from './contentReviews';
import { POST_TRACKING_DETAIL_FRAGMENT, POST_TRACKING_OVERVIEW_FRAGMENT } from './postTrackings';
import { BRAND_OVERVIEW_FRAGMENT } from './brands';

//========================================================
//= Fragments
//========================================================
const PROGRAM_COLLAB_OVERVIEW_FRAGMENT = gql`
  fragment ProgramCollabOverview on ProgramCollab {
    ...ProgramCollab
    program {
      ...Program
      brand {
        ...BrandOverview
      }
      cover {
        ...Content
      }
    }
    member {
      ...Member
      accountResources {
        ...CreatorAccountResourceDetail
      }
    }
    userStatus {
      ...ProgramCollabUserStatus
    }
    workflowItem {
      ...WorkflowItem
    }
    term {
      ...CollabTerm
      iterations {
        ...CollabTermIteration
      }
    }
    contract {
      ...ProgramCollabContract
      contractContent {
        ...Content
      }
      signedContractContent {
        ...Content
      }
    }
    contentReviews {
      ...ContentReviewDetail
    }
    postTrackings {
      ...PostTrackingOverview
    }
    salesTracking {
      ...CollabSalesTracking
    }
  }
  ${BRAND_OVERVIEW_FRAGMENT}
  ${CREATOR_ACCOUNT_RESOURCE_DETAIL_FRAGMENT}
  ${CONTENT_FRAGMENT}
  ${CONTENT_REVIEW_DETAIL_FRAGMENT}
  ${POST_TRACKING_OVERVIEW_FRAGMENT}
  ${MEMBER_FRAGMENT}
  ${PROGRAM_COLLAB_FRAGMENT}
  ${PROGRAM_FRAGMENT}
  ${PROGRAM_COLLAB_USER_STATUS_FRAGMENT}
  ${COLLAB_TERM_FRAGMENT}
  ${COLLAB_TERM_ITERATION_FRAGMENT}
  ${PROGRAM_COLLAB_CONTRACT_FRAGMENT}
  ${WORKFLOW_ITEM_FRAGMENT}
  ${COLLAB_SALES_TRACKING_FRAGMENT}
`;

const PROGRAM_COLLAB_DETAIL_FRAGMENT = gql`
  fragment ProgramCollabDetail on ProgramCollab {
    ...ProgramCollab
    program {
      ...Program
      brand {
        ...BrandOverview
      }
      cover {
        ...Content
      }
    }
    member {
      ...Member
      accountResources {
        ...CreatorAccountResourceDetail
      }
    }
    userStatus {
      ...ProgramCollabUserStatus
    }
    workflowItem {
      ...WorkflowItem
    }
    term {
      ...CollabTerm
      iterations {
        ...CollabTermIteration
      }
    }
    contract {
      ...ProgramCollabContract
      contractContent {
        ...Content
      }
      signedContractContent {
        ...Content
      }
    }
    order {
      ...ProgramCollabOrder
      order {
        ...ShopifyOrder
      }
      tracking {
        ...AftershipTracking
      }
    }
    contentReviews {
      ...ContentReviewDetail
    }
    postTrackings {
      ...PostTrackingDetail
    }
    salesTracking {
      ...CollabSalesTracking
    }
  }
  ${BRAND_OVERVIEW_FRAGMENT}
  ${CREATOR_ACCOUNT_RESOURCE_DETAIL_FRAGMENT}
  ${CONTENT_FRAGMENT}
  ${CONTENT_REVIEW_DETAIL_FRAGMENT}
  ${POST_TRACKING_DETAIL_FRAGMENT}
  ${MEMBER_FRAGMENT}
  ${PROGRAM_COLLAB_FRAGMENT}
  ${PROGRAM_FRAGMENT}
  ${PROGRAM_COLLAB_USER_STATUS_FRAGMENT}
  ${COLLAB_TERM_FRAGMENT}
  ${COLLAB_TERM_ITERATION_FRAGMENT}
  ${PROGRAM_COLLAB_CONTRACT_FRAGMENT}
  ${PROGRAM_COLLAB_ORDER_FRAGMENT}
  ${SHOPIFY_ORDER_FRAGMENT}
  ${AFTERSHIP_TRACKING_FRAGMENT}
  ${WORKFLOW_ITEM_FRAGMENT}
  ${COLLAB_SALES_TRACKING_FRAGMENT}
`;

//========================================================
//= Queries
//========================================================
export const GET_PROGRAM_COLLAB_QUERY = gql`
  query GetProgramCollab($id: String!) {
    collab: creatorGetProgramCollabById(id: $id) {
      ...ProgramCollabDetail
    }
  }
  ${PROGRAM_COLLAB_DETAIL_FRAGMENT}
`;

export const GET_PROGRAM_COLLABS_QUERY = gql`
  query GetProgramCollabs(
    $group: CreatorProgramCollabGroup!
    $limit: Int
    $offset: Int
    $sortBy: ProgramCollabSortBy
    $filters: ProgramCollabFilters
  ) {
    collabs: creatorGetProgramCollabs(
      group: $group
      limit: $limit
      offset: $offset
      sortBy: $sortBy
      filters: $filters
    ) {
      ...ProgramCollabOverview
    }
  }
  ${PROGRAM_COLLAB_OVERVIEW_FRAGMENT}
`;

export const GET_PROGRAM_COLLAB_COUNT_QUERY = gql`
  query GetProgramCollabCount($group: CreatorProgramCollabGroup!, $filters: ProgramCollabFilters) {
    count: creatorGetProgramCollabCount(group: $group, filters: $filters)
  }
`;

export const GET_ALL_PROGRAM_COLLAB_COUNT_QUERY = gql`
  query GetAllProgramCollabCount($params: JSONObject!) {
    result: creatorGetAllProgramCollabCount(params: $params)
  }
`;

export const GET_UNREAD_PROGRAM_COLLAB_COUNT_QUERY = gql`
  query GetUnreadProgramCollabCount {
    count: creatorGetUnreadProgramCollabCount
  }
`;

//========================================================
//= Mutations
//========================================================
export const MARK_PROGRAM_COLLAB_AS_READ_MUTATION = gql`
  mutation MarkProgramCollabAsRead($id: String!) {
    collab: creatorMarkProgramCollabAsRead(id: $id) {
      ...ProgramCollabDetail
    }
  }
  ${PROGRAM_COLLAB_DETAIL_FRAGMENT}
`;

export const MARK_PROGRAM_COLLAB_AS_UNREAD_MUTATION = gql`
  mutation MarkProgramCollabAsUnread($id: String!) {
    collab: creatorMarkProgramCollabAsUnread(id: $id) {
      ...ProgramCollabDetail
    }
  }
  ${PROGRAM_COLLAB_DETAIL_FRAGMENT}
`;

export const BULK_MARK_PROGRAM_COLLAB_AS_READ_MUTATION = gql`
  mutation BulkMarkProgramCollabAsRead($ids: [String!]!) {
    succeeded: creatorBulkMarkProgramCollabAsRead(ids: $ids)
  }
`;

export const BULK_MARK_PROGRAM_COLLAB_AS_UNREAD_MUTATION = gql`
  mutation BulkMarkProgramCollabAsUnread($ids: [String!]!) {
    succeeded: creatorBulkMarkProgramCollabAsUnread(ids: $ids)
  }
`;

export const WORKFLOW_CHECK_IN_MUTATION = gql`
  mutation WorkflowCheckIn(
    $collabId: String!
    $event: WorkflowEvent!
    $params: WorkflowCheckInParams
  ) {
    collab: creatorWorkflowCheckIn(collabId: $collabId, event: $event, params: $params) {
      ...ProgramCollabDetail
    }
  }
  ${PROGRAM_COLLAB_DETAIL_FRAGMENT}
`;

export const WORKFLOW_PRE_CHECK_IN_MUTATION = gql`
  mutation WorkflowPreCheckIn($collabId: String!, $params: WorkflowCheckInParams) {
    collab: creatorWorkflowPreCheckIn(collabId: $collabId, params: $params) {
      ...ProgramCollabDetail
    }
  }
  ${PROGRAM_COLLAB_DETAIL_FRAGMENT}
`;
