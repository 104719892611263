import { useQuery, QueryHookOptions } from '@apollo/client';

import { GET_PROFILE_QUERY } from '@queries';
import { GetProfileQuery } from '@types';

type IOptions = QueryHookOptions<GetProfileQuery>;

export function useGetProfile(options: IOptions = {}) {
  const {
    loading,
    data: { profile } = {},
    error,
    refetch,
  } = useQuery<GetProfileQuery>(GET_PROFILE_QUERY, {
    ...options,
    fetchPolicy: 'cache-and-network',
    nextFetchPolicy: 'cache-first',
    notifyOnNetworkStatusChange: true,
  });

  return {
    loading,
    profile,
    error,
    refetch,
  };
}
