import React from 'react';
import { useQuery, QueryHookOptions } from '@apollo/client';

import { message } from 'antd';

import { GET_PROGRAM_COLLABS_QUERY } from '@queries';
import { GetProgramCollabsQuery, GetProgramCollabsQueryVariables } from '@types';

type IOptions = QueryHookOptions<GetProgramCollabsQuery, GetProgramCollabsQueryVariables>;

const { useEffect, useMemo } = React;

export function useGetProgramCollabs(options: IOptions = {}) {
  const {
    loading,
    data: { collabs = [] } = {},
    error,
    refetch,
  } = useQuery<GetProgramCollabsQuery, GetProgramCollabsQueryVariables>(GET_PROGRAM_COLLABS_QUERY, {
    ...options,
    // in order to correctly update read/unread status
    // we need to remove the other two options
    // TODO: figure out how to handle this elegantly
    fetchPolicy: 'cache-and-network',
  });

  const errorMessage = useMemo(() => error?.message, [error]);
  useEffect(() => {
    if (!loading && errorMessage) {
      message.error(errorMessage);
    }
  }, [loading, errorMessage]);

  return {
    loading,
    collabs,
    error,
    refetch,
  };
}
