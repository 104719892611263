import { gql } from '@apollo/client';

export const MEMBER_FRAGMENT = gql`
  fragment Member on Member {
    id
    email
    info {
      firstName
      lastName
      fullName
    }
    important
    createdDate
    updatedDate
  }
`;
