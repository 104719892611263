import React from 'react';
import { filter, findIndex, get, slice } from 'lodash';

import { message } from 'antd';

import { CollabOrderItem, EntitySortBy, IShopifyProduct, ShopifyProductFilters } from '@types';
import { useGetShopifyProductCount, useGetShopifyProducts } from '@hooks';

export interface IPickProductsContextProps {
  brandResourceId: string;
}
interface IPickProductsContext {
  loading: boolean;
  count: number;
  products: IShopifyProduct[];
  refetch(): void;

  limit: number;
  setLimit(limit: number): void;
  offset: number;
  setOffset(offset: number): void;

  sortBy: EntitySortBy;
  setSortBy(sortBy: EntitySortBy): void;

  filters: ShopifyProductFilters;
  setFilters(filters: ShopifyProductFilters): void;

  items: CollabOrderItem[];
  addItem(item: CollabOrderItem): void;
  updateItemQuantity(variantId: number, quantity: number): void;
  deleteItem(variantId: number): void;
}

const { useState, useMemo, useCallback, useContext } = React;
const DEFAULT_OFFSET = 0;
const DEFAULT_LIMIT = 20;

const PickProductsContext = React.createContext<IPickProductsContext>(null);
export const usePickProductsContext = () => useContext(PickProductsContext);
export const PickProductsContextProvider: React.FC<
  React.PropsWithChildren<IPickProductsContextProps>
> = React.memo(({ children, brandResourceId }) => {
  const [offset, setOffset] = useState(DEFAULT_OFFSET);
  const [limit, setLimit] = useState(DEFAULT_LIMIT);
  const [filters, setFilters] = useState<ShopifyProductFilters>({ brandResourceId });
  const [sortBy, setSortBy] = useState<EntitySortBy>(EntitySortBy.Newest);
  const [items, setItems] = useState<CollabOrderItem[]>([]);

  const combinedFilters: ShopifyProductFilters = useMemo(
    () => ({
      ...filters,
      brandResourceId,
    }),
    [brandResourceId, filters],
  );

  const {
    count,
    loading: loadingCount,
    refetch: refetchCount,
  } = useGetShopifyProductCount({
    variables: {
      filters: combinedFilters,
    },
  });
  const {
    products,
    loading: loadingProducts,
    refetch: refetchProducts,
  } = useGetShopifyProducts({
    variables: {
      limit,
      offset,
      sortBy,
      filters: combinedFilters,
    },
  });

  const loading = useMemo(() => loadingCount || loadingProducts, [loadingCount, loadingProducts]);
  const refetch = useCallback(() => {
    refetchCount();
    refetchProducts();
  }, [refetchCount, refetchProducts]);
  const addItem = useCallback((item: CollabOrderItem) => {
    setItems((items) => {
      const itemIndex = findIndex(items, (i) => i.variantId === item.variantId);
      if (itemIndex === -1) {
        return [...items, item];
      }

      const existingItem = get(items, itemIndex);

      return [
        ...slice(items, 0, itemIndex),
        {
          ...existingItem,
          quantity: existingItem.quantity + item.quantity,
        },
        ...slice(items, itemIndex + 1),
      ];
    });

    message.info('Item added to the cart.');
  }, []);
  const updateItemQuantity = useCallback((variantId: number, quantity: number) => {
    setItems((items) => {
      const itemIndex = findIndex(items, (i) => i.variantId === variantId);
      const item = get(items, itemIndex);

      return [
        ...slice(items, 0, itemIndex),
        {
          ...item,
          quantity,
        },
        ...slice(items, itemIndex + 1),
      ];
    });
  }, []);
  const deleteItem = useCallback((variantId: number) => {
    setItems((items) => filter(items, (i) => i.variantId !== variantId));

    message.info('Item removed from the cart.');
  }, []);

  return (
    <PickProductsContext.Provider
      value={{
        loading,
        count,
        products,
        refetch,

        limit,
        setLimit,
        offset,
        setOffset,

        sortBy,
        setSortBy,

        filters,
        setFilters,

        items,
        addItem,
        updateItemQuantity,
        deleteItem,
      }}
    >
      {children}
    </PickProductsContext.Provider>
  );
});
