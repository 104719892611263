import React from 'react';
import cx from 'classnames';
import { useTranslation } from 'react-i18next';
import { formatDistanceToNow } from 'date-fns';
import { map, flatMap, filter, groupBy, size, isEmpty, chain } from 'lodash';

import { Popover, Badge, Empty, Button } from 'antd';
import { SettingOutlined } from '@ant-design/icons';
import { Image, LoadSpinner } from '@components';
import { BellIcon } from '@customIcons';
import { NotificationContent } from './NotificationContent';

import { INotification } from '@types';
import { useMarkNotificationRead, useUpdateLastNotificationOpened } from '@hooks';
import { useNotificationContext, useProfileContext } from '@context';

import styles from './NotificationPopover.module.scss';

interface IProps {
  className?: string;
}

const { useState, useMemo } = React;

/**
 * @type {React.FC}
 */
export const NotificationPopover: React.FC<React.PropsWithChildren<IProps>> = React.memo(
  (props) => {
    const { t } = useTranslation();
    const { profile } = useProfileContext();
    const { loading, notifications } = useNotificationContext();
    const { markNotificationRead } = useMarkNotificationRead();
    const { updateLastNotificationOpened } = useUpdateLastNotificationOpened();
    const [open, setOpen] = useState(false);

    const relevantNotifications = useMemo(() => {
      // first group by notification type: COLLAB, CAMPAIGN, etc
      // then group by notification entity id: collab.id, campaign.id, etc
      // and pick latest one notification for each entity

      // TODO: instead of showing the last one, should show something meaningful for program notification
      // for example: you received x applications for program x.
      return flatMap(groupBy(notifications, 'type'), (values) =>
        map(
          groupBy(values, (n) => n.payload.data.id),
          (values) =>
            chain(values)
              .sortBy((n) => -new Date(n.updatedDate))
              .first()
              .value(),
        ),
      );
    }, [notifications]);
    const [unreadCount, unOpenedCount] = useMemo(() => {
      const unreadCount = size(filter(relevantNotifications, (n) => !n.read));
      const unOpenedCount = size(
        filter(
          relevantNotifications,
          (n) =>
            !n.read &&
            new Date(n.updatedDate) >
              new Date(profile?.metadata?.lastNotificationOpened || Date.now()),
        ),
      );

      return [unreadCount, unOpenedCount];
    }, [relevantNotifications, profile]);

    const onNotificationClick = (notification: INotification) => {
      switch (notification.type) {
        default: {
          break;
        }
      }

      markNotificationRead({
        variables: {
          notificationId: notification.id,
        },
      });
      setOpen(false);
    };

    const Content = (
      <div className={styles.PopoverContent}>
        <div className={styles.header}>
          {t('NOTIFICATION_PLURAL')}
          <SettingOutlined className={styles.settingsIcon} />
        </div>
        {loading && <LoadSpinner className={styles.loading} />}
        {!loading && isEmpty(notifications) && (
          <Empty description="Your notifications live here" className={styles.empty} />
        )}
        {!loading && !isEmpty(notifications) && (
          <div className={styles.notifications}>
            {chain(relevantNotifications)
              .sortBy((n) => -new Date(n.updatedDate))
              .map((notification) => (
                <div
                  key={notification.id}
                  className={styles.item}
                  onClick={() => onNotificationClick(notification)}
                >
                  <Badge color={notification.read ? 'white' : 'blue'} className={styles.newDot} />
                  <Image
                    className={styles.image}
                    src={`https://picsum.photos/100?id=${notification.id}`}
                  />
                  <div className={styles.snippetAndDate}>
                    <NotificationContent notification={notification} />
                    <div className={styles.date}>
                      {formatDistanceToNow(new Date(notification.createdDate))} ago
                    </div>
                  </div>
                </div>
              ))
              .value()}
          </div>
        )}
      </div>
    );

    return (
      <Popover
        placement="bottomRight"
        content={Content}
        trigger="click"
        open={open}
        onOpenChange={(open) => {
          if (open) {
            updateLastNotificationOpened();
          }

          setOpen(open);
        }}
      >
        <Button
          className={cx(styles.NotificationPopover, props.className)}
          type="text"
          shape="circle"
          size="large"
          icon={
            <Badge
              size="small"
              count={unOpenedCount > 0 ? unOpenedCount : undefined}
              dot={unOpenedCount === 0 && unreadCount > 0}
              overflowCount={9}
            >
              <BellIcon
                className={cx(styles.icon, {
                  [styles.active]: open,
                })}
              />
            </Badge>
          }
        />
      </Popover>
    );
  },
);
