import { gql } from '@apollo/client';

import { COLLAB_TERM_FRAGMENT, COLLAB_TERM_ITERATION_FRAGMENT } from './fragments';

//========================================================
//= Fragments
//========================================================
const COLLAB_TERM_DETAIL_FRAGMENT = gql`
  fragment CollabTermDetail on CollabTerm {
    ...CollabTerm
    iterations {
      ...CollabTermIteration
    }
  }
  ${COLLAB_TERM_FRAGMENT}
  ${COLLAB_TERM_ITERATION_FRAGMENT}
`;

//========================================================
//= Queries
//========================================================
export const GET_COLLAB_TERM_QUERY = gql`
  query GetCollabTerm($id: String!) {
    term: creatorGetCollabTermById(id: $id) {
      ...CollabTermDetail
    }
  }
  ${COLLAB_TERM_DETAIL_FRAGMENT}
`;
