import React from 'react';
import cx from 'classnames';

import { AvatarProps } from 'antd';
import { FileIcon, ImageIcon, VideoIcon } from '@customIcons';

import { ContentType } from '@types';

import styles from './ContentIcon.module.scss';

interface IProps extends AvatarProps {
  type: ContentType;

  className?: string;
}

const { useMemo } = React;

export const ContentIcon: React.FC<React.PropsWithChildren<IProps>> = React.memo(
  ({ type, className, size = 'default', ...rest }) => {
    const IconClass = useMemo(() => {
      switch (type) {
        case ContentType.Image: {
          return ImageIcon;
        }
        case ContentType.Video: {
          return VideoIcon;
        }
        case ContentType.Application: {
          return FileIcon;
        }
        default: {
          break;
        }
      }

      return ImageIcon;
    }, [type]);

    return <IconClass className={cx(styles.ContentIcon, className)} {...rest} />;
  },
);
