import React from 'react';

import { ErrorNotice } from '@components';

interface IProps {
  message?: string | Element;
  children: React.ReactNode;
}

interface IState {
  hasError: boolean;
}

/**
 * @class
 * @extends {React.Component}
 */
export class ErrorBoundary extends React.Component<IProps, IState> {
  /**
   * @inheritDoc
   */
  constructor(props: IProps) {
    super(props);

    this.state = {
      hasError: false,
    };
  }

  /**
   * @inheritDoc
   */
  public componentDidCatch() {
    this.setState({
      hasError: true,
    });
  }

  /**
   * @inheritdoc
   */
  public render() {
    const { children } = this.props;
    const { hasError } = this.state;

    if (hasError) {
      return <ErrorNotice />;
    }

    return children;
  }
}
