import { gql } from '@apollo/client';

export const CREATOR_ADDRESS_FRAGMENT = gql`
  fragment CreatorAddress on CreatorAddress {
    id
    creatorId
    primary
    address {
      firstName
      lastName
      unit
      instruction
      place
    }
    createdDate
    updatedDate
  }
`;
