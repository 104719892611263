import React from 'react';
import { format } from 'date-fns';
import cx from 'classnames';

import { IProgramCollabDetail, WorkflowProspectSource } from '@types';

import styles from './ProspectPanelContent.module.scss';

interface IProps {
  collab: IProgramCollabDetail;

  className?: string;
}

const { useMemo } = React;

/**
 * @type {React.FC}
 */
export const ProspectPanelContent: React.FC<React.PropsWithChildren<IProps>> = React.memo(
  ({ collab, className }) => {
    // placeholder
    const description = useMemo(() => {
      const { prospect } = collab.workflowItem.data;

      // this shouldn't happen
      if (!prospect) {
        return '-';
      }

      const { source, createdTs, acceptedTs, rejectedTs } = prospect;
      if (acceptedTs) {
        if (source === WorkflowProspectSource.Application) {
          return `Application accepted on ${format(new Date(acceptedTs), 'PPpp')}.`;
        } else if (source === WorkflowProspectSource.Invitation) {
          return `Invitation accepted on ${format(new Date(acceptedTs), 'PPpp')}.`;
        }
      } else if (rejectedTs) {
        if (source === WorkflowProspectSource.Application) {
          return `Application rejected on ${format(new Date(acceptedTs), 'PPpp')}.`;
        } else if (source === WorkflowProspectSource.Invitation) {
          return `Invitation rejected on ${format(new Date(acceptedTs), 'PPpp')}.`;
        }
      }

      if (source === WorkflowProspectSource.Application) {
        return `Application received on ${format(new Date(createdTs), 'PPpp')}.`;
      } else if (source === WorkflowProspectSource.Invitation) {
        return `Invitation received on ${format(new Date(createdTs), 'PPpp')}.`;
      } else {
        return `Added to program on ${format(new Date(createdTs), 'PPpp')}.`;
      }
    }, [collab]);

    return <div className={cx(styles.ProspectPanelContent, className)}>{description}</div>;
  },
);
