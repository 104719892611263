import { indexOf, map } from 'lodash';

import { PickProductsButton, SendTermButton, UpdateInfoButton } from '@components';

import { IProgramCollabDetail, WorkflowStage } from '@types';

export function getPanelFutureActions(
  stage: WorkflowStage,
  collab: IProgramCollabDetail,
): JSX.Element | JSX.Element[] {
  const { stage: currentStage } = collab.workflowItem;

  const stages = map(collab.program.workflowConfig.stages, (s) => s.type);
  const currentStageIndex = indexOf(stages, currentStage);
  const targetStageIndex = indexOf(stages, stage);

  const isFutureStage = currentStageIndex < targetStageIndex;
  if (!isFutureStage) {
    return null;
  }

  switch (stage) {
    case WorkflowStage.Term: {
      return <SendTermButton collab={collab} isPreCheckIn />;
    }
    case WorkflowStage.InfoUpdate: {
      return <UpdateInfoButton collab={collab} isPreCheckIn />;
    }
    case WorkflowStage.ProductFulfillment: {
      return <PickProductsButton collab={collab} isPreCheckIn />;
    }
    default: {
      break;
    }
  }

  return null;
}
