import React from 'react';
import cx from 'classnames';

import { Avatar, AvatarProps } from 'antd';

import stripeImage from '@assets/stripe_icon.png';

import styles from './StripeIcon.module.scss';

interface IProps extends AvatarProps {}

/**
 * @type {React.FC}
 */
export const StripeIcon: React.FC<React.PropsWithChildren<IProps>> = React.memo((props) => {
  return <Avatar {...props} className={cx(styles.StripeIcon, props.className)} src={stripeImage} />;
});

StripeIcon.defaultProps = {
  size: 18,
};
