import React from 'react';
import { format } from 'date-fns';
import numeral from 'numeral';
import cx from 'classnames';
import { noop } from 'lodash';

import { Card, Tooltip, Typography } from 'antd';
import { HeartOutlined } from '@ant-design/icons';
import { Image, SocialAccountAvatar, SocialPostPanel } from '@components';

import { formatDate } from '@utils';
import type { CardProps } from 'antd';
import { ISocialPost } from '@types';

import styles from './SocialPostCard.module.scss';

interface IProps extends CardProps {
  post: ISocialPost;

  showDetail?: boolean;
  className?: string;
}

const { useState, useCallback } = React;
const { Meta } = Card;
const { Text, Paragraph } = Typography;

/**
 * @type {React.FC}
 */
export const SocialPostCard: React.FC<IProps> = React.memo(
  ({ post, showDetail = false, className, hoverable, onClick = noop, ...rest }) => {
    const [showModal, setShowModal] = useState(false);

    const onShowModal = useCallback(() => setShowModal(true), []);
    const onCloseModal = useCallback(() => setShowModal(false), []);
    const onCardClick = useCallback(
      (e: React.MouseEvent<HTMLDivElement>) => {
        onClick(e);

        if (showDetail) {
          onShowModal();
        }
      },
      [showDetail, onClick, onShowModal],
    );

    return (
      <>
        <Card
          {...rest}
          className={cx(styles.SocialPostCard, className)}
          cover={<Image className={styles.image} src={post.info.cover} />}
          hoverable={hoverable || showDetail}
          onClick={onCardClick}
        >
          <Meta
            title={
              <div className={styles.title}>
                <Text className={styles.text} ellipsis>
                  {post.info.title || '-'}
                </Text>
                <SocialAccountAvatar
                  className={styles.avatar}
                  account={post.account}
                  size="small"
                  showDetail
                  showUsername={false}
                />
              </div>
            }
            description={
              <>
                <Paragraph className={styles.description} ellipsis={{ rows: 2 }}>
                  {post.info.description}
                </Paragraph>
                <div className={styles.info}>
                  {/* switch to combined engagement later on */}
                  <Tooltip title={`${numeral(post.stats.likes).format('0,0')} likes`}>
                    <div className={styles.item}>
                      <HeartOutlined />
                      <span className={styles.count}>
                        {numeral(post.stats.likes).format('0 a')}
                      </span>
                    </div>
                  </Tooltip>
                  <div className={styles.dot} />
                  <Tooltip
                    title={
                      <>
                        <div>Posted on</div>
                        <div>{format(new Date(post.info.postDate), 'PPpp')}</div>
                      </>
                    }
                  >
                    {formatDate(new Date(post.info.postDate), true)}
                  </Tooltip>
                </div>
              </>
            }
          />
        </Card>
        <SocialPostPanel postId={post.id} open={showModal} onRequestClose={onCloseModal} />
      </>
    );
  },
);
