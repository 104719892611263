import React from 'react';
import { useMutation, MutationHookOptions, MutationFunctionOptions } from '@apollo/client';

import { message } from 'antd';

import { DELETE_ADDRESS_MUTATION } from '@queries';
import { DeleteAddressMutation, DeleteAddressMutationVariables } from '@types';

type IOptions = MutationHookOptions<DeleteAddressMutation, DeleteAddressMutationVariables>;
type IFunctionOptions = MutationFunctionOptions<
  DeleteAddressMutation,
  DeleteAddressMutationVariables
>;

const { useCallback } = React;

export const useDeleteAddressById = (options: IOptions = {}) => {
  const [_deleteAddressById, { loading, error }] = useMutation<
    DeleteAddressMutation,
    DeleteAddressMutationVariables
  >(DELETE_ADDRESS_MUTATION, {
    ...options,
  });

  const deleteAddressById = useCallback(
    async (options: IFunctionOptions = {}) => {
      try {
        const result = await _deleteAddressById(options);

        return result;
      } catch (err) {
        message.error((err as Error).message);
      }
    },
    [_deleteAddressById],
  );

  return {
    deleteAddressById,
    loading,
    error,
  };
};
