import React from 'react';
import cx from 'classnames';

import { Button, ButtonProps, Dropdown, MenuProps } from 'antd';
import { EllipsisVIcon } from '@customIcons';

import styles from './MoreActionsButton.module.scss';

interface IProps extends ButtonProps {
  menuProps: MenuProps;

  loading?: boolean;
  className?: string;
}

/**
 * @type {React.FC}
 */
export const MoreActionsButton: React.FC<React.PropsWithChildren<IProps>> = React.memo(
  ({ menuProps, className, loading, ...rest }) => {
    return (
      <Dropdown trigger={['click']} menu={menuProps} disabled={loading}>
        <Button
          {...rest}
          className={cx(styles.MoreActionsButton, className)}
          shape="circle"
          type="text"
          icon={<EllipsisVIcon />}
          loading={loading}
        />
      </Dropdown>
    );
  },
);
