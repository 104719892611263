import React from 'react';
import cx from 'classnames';
import { formatDistanceToNow } from 'date-fns';
import { find, noop, startCase } from 'lodash';

import { Tooltip } from 'antd';
import {
  UserAvatar,
  SocialPostIcon,
  PostTrackingStatusTag,
  SocialAccountAvatar,
} from '@components';
import { PostTrackingPanel } from './PostTrackingPanel';
import { SubmitPostButton } from './SubmitPostButton';

import { formatDate } from '@utils';
import { IPostTrackingDetail, IProgramCollabDetail, PostTrackingStatus } from '@types';

import styles from './PostTrackingItem.module.scss';

interface IProps {
  collab: IProgramCollabDetail;
  tracking: IPostTrackingDetail;

  onComplete?(): void;
  className?: string;
}

const { useState, useMemo, useCallback } = React;

/**
 * @type {React.FC}
 */
export const PostTrackingItem: React.FC<React.PropsWithChildren<IProps>> = React.memo(
  ({ collab, tracking, onComplete = noop, className }) => {
    const [showModal, setShowModal] = useState(false);

    const resource = useMemo(() => {
      return find(
        collab.member.accountResources,
        (r) => r.id === tracking.requirement.accountResourceId,
      );
    }, [collab, tracking]);
    const onShowModal = useCallback(() => setShowModal(true), []);
    const onCloseModal = useCallback(() => setShowModal(false), []);

    return (
      <>
        <div
          className={cx(styles.PostTrackingItem, className, {
            [styles.active]: tracking.status === PostTrackingStatus.PendingSubmit,
            [styles.disabled]: !tracking.post,
          })}
          onClick={onShowModal}
        >
          <div className={styles.left}>
            <div className={styles.title}>
              <UserAvatar
                className={styles.name}
                name={startCase(tracking.requirement.type)}
                icon={<SocialPostIcon type={tracking.requirement.type} size="small" />}
                showBoth
              />
              {tracking.requirement.dueDateTs && (
                <div className={styles.dueDate}>
                  due in <b>{formatDistanceToNow(tracking.requirement.dueDateTs)}</b>
                </div>
              )}
            </div>
            <div className={styles.info}>
              <span className={styles.entry}>
                {resource && (
                  <SocialAccountAvatar
                    className={styles.avatar}
                    account={resource.account}
                    size="small"
                    showDetail
                    showUsername={false}
                  />
                )}
                {!resource && '-'}
              </span>
              <span className={styles.dot} />
              <span className={styles.entry}>
                <Tooltip
                  title={`Last updated ${formatDistanceToNow(new Date(tracking.updatedDate))} ago`}
                >
                  {formatDate(new Date(tracking.updatedDate), true)}
                </Tooltip>
              </span>
              <span className={styles.dot} />
              <span className={styles.entry}>
                <PostTrackingStatusTag className={styles.status} status={tracking.status} />
              </span>
            </div>
          </div>
          <SubmitPostButton className={styles.submitPost} collab={collab} tracking={tracking} />
        </div>
        <PostTrackingPanel
          show={showModal}
          onRequestClose={onCloseModal}
          tracking={tracking}
          onComplete={onComplete}
        />
      </>
    );
  },
);
