import React from 'react';
import cx from 'classnames';

import { Descriptions } from 'antd';
import { ContractStatusTag } from '@components';

import { IProgramCollabDetail } from '@types';

import styles from './ContractPanelContent.module.scss';

interface IProps {
  collab: IProgramCollabDetail;

  className?: string;
}

const { useMemo } = React;

/**
 * @type {React.FC}
 */
export const ContractPanelContent: React.FC<React.PropsWithChildren<IProps>> = React.memo(
  ({ collab, className }) => {
    const contract = useMemo(() => {
      if (!!collab.contract?.signedContractContent) {
        return collab.contract.signedContractContent;
      } else if (!!collab.contract?.contractContent) {
        return collab.contract.contractContent;
      }

      return null;
    }, [collab]);

    if (!contract) {
      return null;
    }

    return (
      <div className={cx(styles.ContractPanelContent, className)}>
        <Descriptions column={1} layout="vertical">
          <Descriptions.Item label="Status">
            <ContractStatusTag status={collab.contract?.status} />
          </Descriptions.Item>
          <Descriptions.Item label="Contract">
            <embed
              className={styles.embed}
              title={contract.originalName}
              src={contract.url}
              type="application/pdf"
            />
          </Descriptions.Item>
        </Descriptions>
      </div>
    );
  },
);
