import React from 'react';
import { useMutation, MutationHookOptions, MutationFunctionOptions } from '@apollo/client';

import { CREATE_CONTENT_MUTATION } from '@queries';
import { CreateContentMutation, CreateContentMutationVariables } from '@types';

type IOptions = MutationHookOptions<CreateContentMutation, CreateContentMutationVariables>;
type IFunctionOptions = MutationFunctionOptions<
  CreateContentMutation,
  CreateContentMutationVariables
>;

const { useCallback } = React;

export const useCreateContent = (options: IOptions = {}) => {
  const [_createContent, { loading, error }] = useMutation<
    CreateContentMutation,
    CreateContentMutationVariables
  >(CREATE_CONTENT_MUTATION, {
    ...options,
  });

  const createContent = useCallback(
    async (options: IFunctionOptions = {}) => {
      try {
        const result = await _createContent(options);

        return result;
      } catch (err) {
        throw err;
      }
    },
    [_createContent],
  );

  return {
    createContent,
    loading,
    error,
  };
};
