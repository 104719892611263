import { gql } from '@apollo/client';

import {
  CONTENT_FRAGMENT,
  MANAGER_FRAGMENT,
  MESSAGE_FRAGMENT,
  MESSAGE_THREAD_FRAGMENT,
  PROGRAM_COLLAB_FRAGMENT,
  PROGRAM_FRAGMENT,
} from './fragments';

//========================================================
//= Fragments
//========================================================
const MESSAGE_THREAD_DETAIL_FRAGMENT = gql`
  fragment MessageThreadDetail on MessageThread {
    ...MessageThread
    messageCount
    managers {
      ...Manager
    }
    collab {
      ...ProgramCollab
      program {
        ...Program
        cover {
          ...Content
        }
      }
    }
    messages {
      ...Message
      managers {
        ...Manager
      }
      attachments {
        ...Content
      }
    }
  }
  ${MESSAGE_FRAGMENT}
  ${MESSAGE_THREAD_FRAGMENT}
  ${MANAGER_FRAGMENT}
  ${PROGRAM_FRAGMENT}
  ${PROGRAM_COLLAB_FRAGMENT}
  ${CONTENT_FRAGMENT}
`;

//========================================================
//= Queries
//========================================================
export const GET_MESSAGE_THREADS_QUERY = gql`
  query GetMessageThreads($limit: Int, $offset: Int, $filters: MessageThreadFilters) {
    threads: creatorGetMessageThreads(limit: $limit, offset: $offset, filters: $filters) {
      ...MessageThreadDetail
    }
  }
  ${MESSAGE_THREAD_DETAIL_FRAGMENT}
`;

export const GET_MESSAGE_THREAD_COUNT_QUERY = gql`
  query GetMessageThreadCount($filters: MessageThreadFilters) {
    count: creatorGetMessageThreadCount(filters: $filters)
  }
`;
