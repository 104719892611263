import React from 'react';
import { useMutation, MutationHookOptions, MutationFunctionOptions } from '@apollo/client';

import { message } from 'antd';

import { DELETE_CONTENT_MUTATION } from '@queries';
import { DeleteContentMutation, DeleteContentMutationVariables } from '@types';

type IOptions = MutationHookOptions<DeleteContentMutation, DeleteContentMutationVariables>;
type IFunctionOptions = MutationFunctionOptions<
  DeleteContentMutation,
  DeleteContentMutationVariables
>;

const { useCallback } = React;

export const useDeleteContentById = (options: IOptions = {}) => {
  const [_deleteContentById, { loading, error }] = useMutation<
    DeleteContentMutation,
    DeleteContentMutationVariables
  >(DELETE_CONTENT_MUTATION, {
    ...options,
  });

  const deleteContentById = useCallback(
    async (options: IFunctionOptions = {}) => {
      try {
        const result = await _deleteContentById(options);

        return result;
      } catch (err) {
        message.error((err as Error).message);
      }
    },
    [_deleteContentById],
  );

  return {
    deleteContentById,
    loading,
    error,
  };
};
