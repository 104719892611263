import React from 'react';
import cx from 'classnames';
import { noop } from 'lodash';

import { Button } from 'antd';
import { ReviewTermPanel } from './ReviewTermPanel';

import { IProgramCollab } from '@types';

import styles from './ReviewTermButton.module.scss';

interface IProps {
  collab: IProgramCollab;

  onComplete?(): void;
  label?: string;
  className?: string;
}

const { useState, useCallback } = React;

/**
 * @type {React.FC}
 */
export const ReviewTermButton: React.FC<React.PropsWithChildren<IProps>> = React.memo(
  ({ collab, label = 'Review Terms', onComplete = noop, className }) => {
    const [showModal, setShowModal] = useState(false);

    const stopPropagation = useCallback((event: React.MouseEvent) => {
      // NOTE: do not preventDefault(), otherwise form submission wont work
      // do not open detail panel
      event.stopPropagation();
    }, []);
    const openModal = useCallback(() => setShowModal(true), []);
    const closeModal = useCallback(() => setShowModal(false), []);

    return (
      <div onClick={stopPropagation}>
        <Button
          className={cx(styles.ReviewTermButton, className)}
          shape="round"
          type="primary"
          onClick={openModal}
        >
          {label}
        </Button>
        <ReviewTermPanel
          collab={collab}
          show={showModal}
          onRequestClose={closeModal}
          onComplete={onComplete}
        />
      </div>
    );
  },
);
