import { gql } from '@apollo/client';

import { CONTENT_REVIEW_FRAGMENT, CONTENT_FRAGMENT } from './fragments';

//========================================================
//= Fragments
//========================================================
export const CONTENT_REVIEW_DETAIL_FRAGMENT = gql`
  fragment ContentReviewDetail on ContentReview {
    ...ContentReview
    contents {
      ...Content
    }
  }
  ${CONTENT_FRAGMENT}
  ${CONTENT_REVIEW_FRAGMENT}
`;

//========================================================
//= Queries
//========================================================
export const GET_CONTENT_REVIEW_QUERY = gql`
  query GetContentReview($id: String!) {
    review: creatorGetContentReviewById(id: $id) {
      ...ContentReviewDetail
    }
  }
  ${CONTENT_REVIEW_DETAIL_FRAGMENT}
`;
