import React from 'react';
import { useMutation, MutationHookOptions, MutationFunctionOptions } from '@apollo/client';

import { message } from 'antd';

import { APPLY_TO_PROGRAM_MUTATION } from '@queries';
import { ApplyToProgramMutation, ApplyToProgramMutationVariables } from '@types';

type IOptions = MutationHookOptions<ApplyToProgramMutation, ApplyToProgramMutationVariables>;
type IFunctionOptions = MutationFunctionOptions<
  ApplyToProgramMutation,
  ApplyToProgramMutationVariables
>;

const { useCallback } = React;

export const useApplyToProgram = (options: IOptions = {}) => {
  const [_applyToProgram, { loading, error }] = useMutation<
    ApplyToProgramMutation,
    ApplyToProgramMutationVariables
  >(APPLY_TO_PROGRAM_MUTATION, options);

  const applyToProgram = useCallback(
    async (options: IFunctionOptions = {}) => {
      try {
        const result = await _applyToProgram(options);

        return result;
      } catch (err) {
        message.error((err as Error).message);
      }
    },
    [_applyToProgram],
  );

  return {
    applyToProgram,
    loading,
    error,
  };
};
