import React from 'react';
import { useMutation, MutationHookOptions, MutationFunctionOptions } from '@apollo/client';

import { message } from 'antd';

import { AUTHORIZE_RESOURCE_MUTATION } from '@queries';
import { AuthorizeResourceMutation, AuthorizeResourceMutationVariables } from '@types';

type IOptions = MutationHookOptions<AuthorizeResourceMutation, AuthorizeResourceMutationVariables>;
type IFunctionOptions = MutationFunctionOptions<
  AuthorizeResourceMutation,
  AuthorizeResourceMutationVariables
>;

const { useCallback } = React;

export const useAuthorizeResource = (options: IOptions = {}) => {
  const [_authorizeResource, { loading, error }] = useMutation<
    AuthorizeResourceMutation,
    AuthorizeResourceMutationVariables
  >(AUTHORIZE_RESOURCE_MUTATION, {
    ...options,
  });

  const authorizeResource = useCallback(
    async (options: IFunctionOptions = {}) => {
      try {
        const result = await _authorizeResource(options);

        return result;
      } catch (err) {
        message.error((err as Error).message);
      }
    },
    [_authorizeResource],
  );

  return {
    authorizeResource,
    loading,
    error,
  };
};
