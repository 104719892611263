import React from 'react';
import cx from 'classnames';
import { startCase } from 'lodash';

import { Tag } from 'antd';
import {
  CheckOutlined,
  CloseOutlined,
  FieldTimeOutlined,
  InfoCircleFilled,
} from '@ant-design/icons';

import { ContentReviewStatus } from '@types';

import styles from './ContentReviewStatusTag.module.scss';

interface IProps {
  status: ContentReviewStatus;

  className?: string;
}

const { useMemo } = React;

/**
 * @type {React.FC}
 */
export const ContentReviewStatusTag: React.FC<React.PropsWithChildren<IProps>> = React.memo(
  ({ status, className }) => {
    const color = useMemo(() => {
      if (status === ContentReviewStatus.PendingUpload) {
        return 'gray';
      } else if (status === ContentReviewStatus.PendingReview) {
        return 'blue';
      } else if (status === ContentReviewStatus.ApprovedWithComments) {
        return 'gold';
      } else if (status === ContentReviewStatus.Rejected) {
        return 'red';
      }

      // approved
      return 'green';
    }, [status]);
    const icon = useMemo(() => {
      if (status === ContentReviewStatus.PendingUpload) {
        return <FieldTimeOutlined />;
      } else if (status === ContentReviewStatus.PendingReview) {
        return <InfoCircleFilled />;
      } else if (status === ContentReviewStatus.Rejected) {
        return <CloseOutlined />;
      }

      // approved and approved with comments
      return <CheckOutlined />;
    }, [status]);

    return (
      <Tag className={cx(styles.ContentReviewStatusTag, className)} color={color}>
        {icon}
        {startCase(status)}
      </Tag>
    );
  },
);
