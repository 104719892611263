import React from 'react';
import cx from 'classnames';
import { formatDistance, isBefore } from 'date-fns';
import { filter, find, first, isArray, isEmpty, map, startCase } from 'lodash';

import { Input, Button, Form, Collapse, Select, Badge, FormItemProps, Tag } from 'antd';
import { DeleteFilled, PlusOutlined } from '@ant-design/icons';
import {
  DatePicker,
  SocialAccountIcon,
  CreatorAccountResourceSelect,
  UserAvatar,
  SocialPostIcon,
} from '@components';

import type { DefaultOptionType } from 'antd/lib/select';
import { ContentGuidelineSections, isCustomPostType, SocialAccountTypeForPostType } from '@utils';
import {
  ContentRequirementParams,
  ICreatorAccountResource,
  IProgram,
  SocialAccountType,
  SocialPostType,
} from '@types';

import styles from './ContentRequirementsFormItem.module.scss';

interface IShowPostType {
  youtube: boolean;
  instagram: boolean;
}
interface IProps extends FormItemProps {
  program: IProgram;
  accountResources: ICreatorAccountResource[];

  className?: string;
}

const { useMemo, useCallback } = React;
const { Panel } = Collapse;
const { TextArea } = Input;

export const ContentRequirementsFormItem: React.FC<React.PropsWithChildren<IProps>> = React.memo(
  ({
    program,
    accountResources,
    className,
    required = false,
    name = 'requirements',
    ...formItemProps
  }) => {
    const enablePostTracking = useMemo(() => {
      return program.workflowConfig.data.contentReview.enablePostTracking;
    }, [program]);
    const getAccountResourcesForType = useCallback(
      (type: SocialPostType) => {
        if (isEmpty(accountResources)) {
          return [];
        } else if (isCustomPostType(type)) {
          return [];
        }

        const accountType = SocialAccountTypeForPostType[type];
        const resources = filter(accountResources, (r) => r.account.type === accountType);

        return resources;
      },
      [accountResources],
    );
    const showPostType: IShowPostType = useMemo(() => {
      if (isEmpty(accountResources)) {
        return {
          instagram: false,
          youtube: false,
        };
      }

      return {
        instagram: !!find(
          accountResources,
          (resource) => resource.account.type === SocialAccountType.Instagram,
        ),
        youtube: !!find(
          accountResources,
          (resource) => resource.account.type === SocialAccountType.Youtube,
        ),
      };
    }, [accountResources]);
    const availablePostTypes: SocialPostType[] = useMemo(() => {
      const types = [SocialPostType.CustomImage, SocialPostType.CustomVideo];

      const { instagram, youtube } = showPostType;
      if (instagram) {
        types.unshift(...[SocialPostType.InstagramPost, SocialPostType.InstagramStory]);
      }
      if (youtube) {
        types.unshift(...[SocialPostType.YoutubeVideo]);
      }

      return types;
    }, [showPostType]);
    const defaultParams: ContentRequirementParams = useMemo(() => {
      const type = first(availablePostTypes);
      const params = {
        type,
        guidelines: {
          conceptAndFormat: [''],
          talkingPoints: [''],
          caption: [''],
          postInstructions: [''],
          other: [''],
        },
      };

      if (isCustomPostType(type)) {
        return params;
      }

      return {
        ...params,
        accountResourceId: first(getAccountResourcesForType(type))?.id,
      };
    }, [availablePostTypes, getAccountResourcesForType]);
    const socialPostTypeOptions: DefaultOptionType[] = useMemo(() => {
      return map(availablePostTypes, (type) => ({
        value: type,
        label: (
          <span className={styles.SelectOption}>
            <SocialPostIcon className={styles.icon} type={type} />
            {startCase(type)}
          </span>
        ),
      }));
    }, [availablePostTypes]);
    const guidelineSections = useMemo(() => {
      if (!enablePostTracking) {
        // caption and post instructions are for post tracking only
        return filter(
          ContentGuidelineSections,
          (s) => !['caption', 'postInstructions'].includes(s.key),
        );
      }

      return ContentGuidelineSections;
    }, [enablePostTracking]);
    const onSelectClick = useCallback((e: React.MouseEvent<HTMLDivElement>) => {
      e.stopPropagation();
    }, []);

    return (
      <Form.Item
        {...formItemProps}
        className={cx(styles.ContentRequirementsFormItem, className)}
        label="Requirements"
        required={required}
      >
        <Form.List
          name={name}
          rules={[
            {
              validator: (_, requirements: ContentRequirementParams[]) => {
                if (required && isEmpty(requirements)) {
                  return Promise.reject(new Error(`Please add at least one content requirement.`));
                }

                return Promise.resolve();
              },
            },
          ]}
        >
          {(fields, { add, remove }, { errors }) => (
            <div className={styles.requirements}>
              {fields.map(({ name: fieldName, key, ...rest }) => {
                return (
                  <Collapse key={key} className={styles.collapse}>
                    <Panel
                      key="1"
                      className={styles.panel}
                      header={
                        <div className={styles.header}>
                          <Form.Item {...rest} name={[fieldName, 'type']} noStyle>
                            <Select
                              className={styles.select}
                              popupClassName={styles.SelectPopover}
                              bordered={false}
                              size="small"
                              options={socialPostTypeOptions}
                              onClick={onSelectClick}
                            />
                          </Form.Item>
                          <Form.Item shouldUpdate noStyle>
                            {({ getFieldValue }) => {
                              const namePath = isArray(name) ? name : [name];
                              const dueDateTs = getFieldValue([
                                ...namePath,
                                fieldName,
                                'dueDateTs',
                              ]);

                              if (!dueDateTs) {
                                return null;
                              }

                              return (
                                <div className={styles.dueDate}>
                                  <span className={styles.text}>due in</span>
                                  <Tag className={styles.tag} color="blue">
                                    {formatDistance(Date.now(), dueDateTs)}
                                  </Tag>
                                </div>
                              );
                            }}
                          </Form.Item>
                          <Form.Item shouldUpdate noStyle>
                            {({ getFieldValue }) => {
                              const namePath = isArray(name) ? name : [name];
                              const type = getFieldValue([...namePath, fieldName, 'type']);
                              const accountResourceId = getFieldValue([
                                ...namePath,
                                fieldName,
                                'accountResourceId',
                              ]);
                              const resource = find(
                                accountResources,
                                (r) => r.id === accountResourceId,
                              );

                              if (isCustomPostType(type)) {
                                return null;
                              } else if (!resource) {
                                // this should not happen
                                return null;
                              }

                              return (
                                <span className={styles.account}>
                                  <span className={styles.text}>with account</span>
                                  <UserAvatar
                                    className={styles.accountAvatar}
                                    size="small"
                                    showBoth
                                    name={resource.account.info.displayName}
                                    icon={<SocialAccountIcon type={resource.account.type} />}
                                  />
                                </span>
                              );
                            }}
                          </Form.Item>
                        </div>
                      }
                      extra={
                        <Button
                          className={styles.deleteButton}
                          icon={<DeleteFilled />}
                          type="text"
                          shape="circle"
                          size="small"
                          onClick={() => remove(fieldName)}
                        />
                      }
                    >
                      <Form.Item shouldUpdate noStyle>
                        {({ getFieldValue }) => {
                          const namePath = isArray(name) ? name : [name];
                          const type = getFieldValue([...namePath, fieldName, 'type']);
                          const resources = getAccountResourcesForType(type);

                          // skip account selector for custom video/image
                          if (isCustomPostType(type)) {
                            return null;
                          } else if (isEmpty(resources)) {
                            return null;
                          }

                          return (
                            <Form.Item
                              {...rest}
                              name={[fieldName, 'accountResourceId']}
                              label="Account"
                              rules={[
                                {
                                  required: true,
                                  message: 'Please select which account the member should use.',
                                },
                              ]}
                            >
                              <CreatorAccountResourceSelect resources={resources} />
                            </Form.Item>
                          );
                        }}
                      </Form.Item>
                      <Form.Item
                        {...rest}
                        name={[fieldName, 'dueDateTs']}
                        label="Due Date"
                        getValueFromEvent={(value) => value?.getTime()}
                      >
                        <DatePicker disabledDate={(d) => isBefore(d, new Date())} showTime />
                      </Form.Item>
                      {map(guidelineSections, (section) => {
                        return (
                          <Form.Item key={section.key} label={section.label}>
                            <Form.List name={[fieldName, 'guidelines', section.key]}>
                              {(fields, { add, remove }, { errors }) => (
                                <>
                                  {fields.map((field) => (
                                    <Form.Item
                                      className={styles.optionWrapper}
                                      key={field.key}
                                      required={true}
                                    >
                                      <Badge className={styles.badge} color="gray" />
                                      <Form.Item {...field} className={styles.inputWrapper} noStyle>
                                        <TextArea
                                          className={styles.textarea}
                                          bordered={false}
                                          autoSize
                                          placeholder={section.placeholder}
                                        />
                                      </Form.Item>
                                      <Button
                                        className={styles.deleteButton}
                                        icon={<DeleteFilled />}
                                        type="text"
                                        shape="circle"
                                        size="small"
                                        onClick={() => remove(field.name)}
                                      />
                                    </Form.Item>
                                  ))}
                                  <Form.Item noStyle>
                                    <Button
                                      className={styles.addButton}
                                      type="dashed"
                                      onClick={() => add()}
                                      icon={<PlusOutlined />}
                                    >
                                      Add Guideline
                                    </Button>
                                  </Form.Item>
                                  <Form.ErrorList errors={errors} />
                                </>
                              )}
                            </Form.List>
                          </Form.Item>
                        );
                      })}
                    </Panel>
                  </Collapse>
                );
              })}
              <Form.Item noStyle>
                <Button
                  className={styles.addButton}
                  type="dashed"
                  onClick={() => add(defaultParams)}
                  icon={<PlusOutlined />}
                >
                  Add Requirement
                </Button>
              </Form.Item>
              <Form.ErrorList errors={errors} />
            </div>
          )}
        </Form.List>
      </Form.Item>
    );
  },
);
