import React from 'react';
import cx from 'classnames';

import { CommentDollarIcon } from '@customIcons';

import styles from './PaymentStageIcon.module.scss';

interface IProps {
  className?: string;
}

/**
 * @type {React.FC}
 */
export const PaymentStageIcon: React.FC<React.PropsWithChildren<IProps>> = React.memo(
  ({ className }) => {
    return (
      <div className={cx(styles.PaymentStageIcon, className)}>
        <CommentDollarIcon className={styles.icon} />
      </div>
    );
  },
);
