import { gql } from '@apollo/client';

//========================================================
//= Mutations
//========================================================
export const CONNECT_STRIPE_ACCOUNT_MUTATION = gql`
  mutation ConnectStripeAccount {
    url: creatorConnectStripeAccount
  }
`;

export const DELETE_STRIPE_ACCOUNT_MUTATION = gql`
  mutation DeleteStripeAccount($id: String!) {
    succeeded: creatorDeleteStripeAccountById(id: $id)
  }
`;
