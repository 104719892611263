import React from 'react';
import cx from 'classnames';
import { isEmpty } from 'lodash';

import { message } from 'antd';

import styles from './CopyLinkButton.module.scss';

interface IProps {
  link: string;

  label?: string;
  className?: string;
}

const { useCallback } = React;

/**
 * @type {React.FC}
 */
export const CopyLinkButton: React.FC<React.PropsWithChildren<IProps>> = React.memo(
  ({ link, label = 'Copy Link', className }) => {
    const onCopyLink = useCallback(
      async (e: React.MouseEvent<HTMLDivElement>) => {
        e.stopPropagation();

        await navigator.clipboard.writeText(link);

        message.info('Link copied to clipboard.');
      },
      [link],
    );

    if (isEmpty(link)) {
      return null;
    }

    return (
      <div className={cx(styles.CopyLinkButton, className)} onClick={onCopyLink}>
        {label}
      </div>
    );
  },
);
