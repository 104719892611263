import React from 'react';
import cx from 'classnames';
import { isEmpty, sumBy } from 'lodash';

import { Alert, Button, Divider, Empty, Tooltip } from 'antd';
import { ArrowLeftOutlined } from '@ant-design/icons';
import { LoadSpinner } from '@components';
import { ListHeader, ProductsGrid, SelectedItems } from './components';

import { CollabOrderItem, IWorkflowProductFulfillmentStageConfig } from '@types';
import {
  IPickProductsContextProps,
  PickProductsContextProvider,
  usePickProductsContext,
} from './context';

import styles from './PickProducts.module.scss';

interface IProps {
  loading: boolean;
  pfaConfig: IWorkflowProductFulfillmentStageConfig;
  onPickItems(items: CollabOrderItem[]): void;

  className?: string;
}

const { useState, useMemo, useCallback } = React;

/**
 * @type {React.FC}
 */
const PickProductsInternal: React.FC<IProps> = React.memo(
  ({ loading: checkingIn, pfaConfig, onPickItems, className }) => {
    const { loading, products, filters, items } = usePickProductsContext();
    const [confirming, setConfirming] = useState(false);

    const productLimitMessage = useMemo(() => {
      const { maxProductCount, maxProductValue } = pfaConfig;

      if (maxProductCount > 0 && maxProductValue > 0) {
        return (
          <div>
            Select up to <b>{maxProductCount}</b> item(s), totalling up to <b>${maxProductValue}</b>
            .
          </div>
        );
      } else if (maxProductCount > 0) {
        return (
          <div>
            Select up to <b>{maxProductCount}</b> item(s).
          </div>
        );
      } else if (maxProductValue > 0) {
        return (
          <div>
            Select item(s) totalling up to <b>${maxProductValue}</b>.
          </div>
        );
      }

      return null;
    }, [pfaConfig]);
    const exceedingLimitMessage = useMemo(() => {
      const { maxProductCount, maxProductValue } = pfaConfig;
      const currentProductCount = sumBy(items, (i) => i.quantity);
      const currentProductValue = sumBy(items, (i) => parseFloat(i.price) * i.quantity);

      if (maxProductCount > 0 && currentProductCount > maxProductCount) {
        return `You can only select up to ${maxProductCount} item(s).`;
      } else if (maxProductValue > 0 && currentProductValue > maxProductValue) {
        return `You can only select item(s) totalling up to $${maxProductValue}.`;
      }

      return null;
    }, [pfaConfig, items]);
    const goToProductSelect = useCallback(() => setConfirming(false), []);
    const goToConfirming = useCallback(() => setConfirming(true), []);
    const handlePickItems = useCallback(() => onPickItems(items), [onPickItems, items]);

    return (
      <div className={cx(styles.PickProducts, className)}>
        {!confirming && (
          <>
            {productLimitMessage && (
              <>
                <Alert
                  className={styles.alert}
                  showIcon
                  type="info"
                  message="Gift Order Items"
                  description={productLimitMessage}
                />
                <Divider />
              </>
            )}
            <ListHeader className={styles.header} />
            <div className={styles.content}>
              {loading && <LoadSpinner className={styles.loading} />}
              {!loading && isEmpty(products) && (
                <Empty
                  className={styles.empty}
                  description={
                    !isEmpty(filters.value)
                      ? 'No products match your search criteria.'
                      : 'Looks like this store does not have any products.'
                  }
                />
              )}
              {!loading && !isEmpty(products) && <ProductsGrid className={styles.grid} />}
            </div>
          </>
        )}
        {confirming && (
          <div className={styles.confirmation}>
            <SelectedItems />
          </div>
        )}
        <Divider />
        <div className={styles.footer}>
          {!confirming && (
            <Tooltip
              overlayStyle={{
                display: !!exceedingLimitMessage ? undefined : 'none',
              }}
              title={exceedingLimitMessage}
            >
              <Button
                className={styles.button}
                type="primary"
                disabled={isEmpty(items) || !!exceedingLimitMessage}
                onClick={goToConfirming}
              >
                Continue
              </Button>
            </Tooltip>
          )}
          {confirming && (
            <>
              <Button
                className={styles.button}
                type="text"
                onClick={goToProductSelect}
                icon={<ArrowLeftOutlined />}
              >
                Go Back
              </Button>
              <Tooltip
                overlayStyle={{
                  display: !!exceedingLimitMessage ? undefined : 'none',
                }}
                title={exceedingLimitMessage}
              >
                <Button
                  className={styles.button}
                  type="primary"
                  onClick={handlePickItems}
                  disabled={isEmpty(items) || !!exceedingLimitMessage}
                  loading={checkingIn}
                >
                  Confirm Selection
                </Button>
              </Tooltip>
            </>
          )}
        </div>
      </div>
    );
  },
);

export const PickProducts = React.memo<IProps & IPickProductsContextProps>(
  ({ brandResourceId, ...rest }) => {
    return (
      <PickProductsContextProvider brandResourceId={brandResourceId}>
        <PickProductsInternal {...rest} />
      </PickProductsContextProvider>
    );
  },
);
