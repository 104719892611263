import React from 'react';
import cx from 'classnames';
import { compact, find, flatMap, isEmpty, map, size } from 'lodash';

import { Tabs } from 'antd';
import { Carousel, ContentAgreement, SocialPostGrid } from '@components';

import { IProgramCollabDetail } from '@types';

import styles from './ContentReviewPanelContent.module.scss';

interface IProps {
  collab: IProgramCollabDetail;

  className?: string;
}

const { useMemo } = React;

/**
 * @type {React.FC}
 */
export const ContentReviewPanelContent: React.FC<React.PropsWithChildren<IProps>> = React.memo(
  ({ collab, className }) => {
    const iteration = useMemo(() => {
      return find(collab.term.iterations, (i) => i.id === collab.term.latestIterationId);
    }, [collab]);
    const contents = useMemo(
      () => compact(flatMap(collab.contentReviews, (c) => c.contents)),
      [collab],
    );
    const posts = useMemo(() => map(collab.postTrackings, (t) => t.post), [collab]);
    const tabItems = useMemo(() => {
      return [
        {
          key: 'agreement',
          label: 'Agreement',
          children: <ContentAgreement data={iteration.data.contentReview} collab={collab} />,
        },
        {
          key: 'contents',
          label: `Contents (${size(contents)})`,
          disabled: isEmpty(contents),
          children: <Carousel className={styles.carousel} contents={contents} />,
        },
        {
          key: 'posts',
          label: `Posts (${size(posts)})`,
          disabled: isEmpty(posts),
          children: <SocialPostGrid posts={posts} showDetail />,
        },
      ];
    }, [collab, iteration, contents, posts]);

    return (
      <div className={cx(styles.ContentReviewPanelContent, className)}>
        <Tabs items={tabItems} />
      </div>
    );
  },
);
