import React from 'react';
import cx from 'classnames';
import { map, noop } from 'lodash';

import { SocialPostCard } from '@components';

import { useSelectPostListContext } from '../SelectPostListContext';

import styles from './PostList.module.scss';

interface IProps {
  selectedPostId?: string;
  onPostClick?(id: string): void;
  className?: string;
}

/**
 * @type {React.FC}
 */
export const PostList: React.FC<IProps> = React.memo(
  ({ selectedPostId, onPostClick = noop, className }) => {
    const { posts } = useSelectPostListContext();

    return (
      <div className={cx(styles.PostList, className)}>
        {map(posts, (post) => (
          <SocialPostCard
            className={cx(styles.card, {
              [styles.active]: post.id === selectedPostId,
            })}
            key={post.id}
            post={post}
            hoverable
            onClick={() => onPostClick(post.id)}
          />
        ))}
      </div>
    );
  },
);
