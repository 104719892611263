import React from 'react';
import cx from 'classnames';

import { Drawer } from 'antd';
import { PickProducts } from './PickProducts';

import { CollabOrderItem, IProgram, IWorkflowProductFulfillmentStageConfig } from '@types';

import styles from './PickProductsPanel.module.scss';

interface IProps {
  program: IProgram;
  show: boolean;
  onRequestClose(): void;

  loading?: boolean;
  onSubmit(items: CollabOrderItem[]): void;

  className?: string;
}

const { useMemo } = React;

/**
 * @type {React.FC}
 */
export const PickProductsPanel: React.FC<React.PropsWithChildren<IProps>> = React.memo(
  ({ program, show, onRequestClose, loading = false, onSubmit, className }) => {
    const pfaConfig: IWorkflowProductFulfillmentStageConfig = useMemo(
      () => program.workflowConfig.data.productFulfillment,
      [program],
    );

    return (
      <Drawer
        className={cx(styles.PickProductsPanel, className)}
        open={show}
        onClose={onRequestClose}
        title="Pick Products"
        footer={null}
        width={1000}
        destroyOnClose
      >
        <PickProducts
          className={styles.form}
          brandResourceId={pfaConfig.productBrandResourceId}
          pfaConfig={pfaConfig}
          loading={loading}
          onPickItems={onSubmit}
        />
      </Drawer>
    );
  },
);
