import { gql } from '@apollo/client';

export const AFTERSHIP_TRACKING_FRAGMENT = gql`
  fragment AftershipTracking on AftershipTracking {
    id
    shipmentStatus
    slug
    trackingNumber
    trackingUrl
    createdDate
    updatedDate
  }
`;
