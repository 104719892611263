import React from 'react';
import cx from 'classnames';
import { cloneDeep, compact, find, map } from 'lodash';

import { Drawer } from 'antd';
import { SendTermForm } from './SendTermForm';

import {
  isCustomPostType,
  SocialAccountTypeForPostType,
  SocialPostTypesForAccountType,
} from '@utils';
import {
  CollabTermContentReviewDataParams,
  CollabTermPaymentDataParams,
  CollabTermSalesTrackingDataParams,
  ICollabTermData,
  IContentRequirement,
  ICreatorAccountResource,
  IProgram,
  SocialAccountType,
  SocialPostType,
  WorkflowStage,
} from '@types';

import styles from './SendTermPanel.module.scss';

interface IProps {
  program: IProgram;
  accountResources: ICreatorAccountResource[];

  show: boolean;
  onRequestClose(): void;

  onSubmit(params: ICollabTermData): void;
  loading?: boolean;
  errorMessage?: string;
  submitLabel?: string;

  className?: string;
}

const { useMemo } = React;

/**
 * @type {React.FC}
 */
export const SendTermPanel: React.FC<React.PropsWithChildren<IProps>> = React.memo(
  ({
    program,
    accountResources,
    show,
    onRequestClose,
    errorMessage,
    loading = false,
    onSubmit,
    submitLabel,
    className,
  }) => {
    const contentReviewData: CollabTermContentReviewDataParams = useMemo(() => {
      const hasContentReview = !!find(
        program.workflowConfig.stages,
        (s) => s.type === WorkflowStage.ContentReview,
      );
      if (!hasContentReview) {
        return null;
      }

      // contentReview data is read only
      const data = cloneDeep(program.workflowConfig.data.contentReview.agreementData);

      const hasInstagram = !!find(
        accountResources,
        (resource) => resource.account.type === SocialAccountType.Instagram,
      );
      const hasYoutube = !!find(
        accountResources,
        (resource) => resource.account.type === SocialAccountType.Youtube,
      );
      const invalidTypes: SocialPostType[] = [];
      if (!hasInstagram) {
        invalidTypes.push(...SocialPostTypesForAccountType[SocialAccountType.Instagram]);
      }
      if (!hasYoutube) {
        invalidTypes.push(...SocialPostTypesForAccountType[SocialAccountType.Youtube]);
      }

      data.requirements = compact(
        map(data.requirements, (requirement): IContentRequirement => {
          if (invalidTypes.includes(requirement.type)) {
            return null;
          } else if (isCustomPostType(requirement.type)) {
            return requirement;
          }

          const accountType = SocialAccountTypeForPostType[requirement.type];
          const resource = find(
            accountResources,
            (resource) => resource.account.type === accountType,
          );

          return {
            ...requirement,
            accountResourceId: resource?.id,
          };
        }),
      );

      return data;
    }, [program, accountResources]);
    const paymentData: CollabTermPaymentDataParams = useMemo(() => {
      const hasPayment = !!find(
        program.workflowConfig.stages,
        (s) => s.type === WorkflowStage.Payment,
      );
      if (!hasPayment) {
        return null;
      }

      return {
        amount: program.workflowConfig.data.payment.paymentRange.low,
      };
    }, [program]);
    const salesTrackingData: CollabTermSalesTrackingDataParams = useMemo(() => {
      const hasSalesTracking = !!find(
        program.workflowConfig.stages,
        (s) => s.type === WorkflowStage.SalesTracking,
      );
      if (!hasSalesTracking) {
        return null;
      }

      return {
        incentive: program.workflowConfig.data.salesTracking.incentive,
      };
    }, [program]);
    const initialValues: ICollabTermData = useMemo(() => {
      return {
        contentReview: contentReviewData,
        payment: paymentData,
        salesTracking: salesTrackingData,
      };
    }, [contentReviewData, paymentData, salesTrackingData]);

    return (
      <Drawer
        className={cx(styles.SendTermPanel, className)}
        open={show}
        onClose={onRequestClose}
        title="Collab Terms"
        footer={null}
        width={700}
        destroyOnClose
      >
        <SendTermForm
          program={program}
          accountResources={accountResources}
          initialValues={initialValues}
          onSubmit={onSubmit}
          isSubmitting={loading}
          errorMessage={errorMessage}
          onCancel={onRequestClose}
          submitLabel={submitLabel}
        />
      </Drawer>
    );
  },
);
