import React from 'react';
import cx from 'classnames';
import { words, size, first, toUpper, nth, isFunction } from 'lodash';

import { Typography, Avatar, AvatarProps } from 'antd';
import { Image } from '@components';

import styles from './UserAvatar.module.scss';

interface IProps extends AvatarProps {
  name: string;
  icon?: JSX.Element;
  picture?: string;
  showBoth?: boolean;
  onClick?(event: React.MouseEvent<HTMLElement>): void;

  className?: string;
}

const { useMemo, useCallback } = React;
const { Text } = Typography;
function stringToHslColor(str: string, saturation: number, lightness: number): string {
  let hash = 0;
  for (let i = 0; i < size(str); i++) {
    hash = str.charCodeAt(i) + ((hash << 5) - hash);
  }

  const h = hash % 360;

  return `hsl(${h}, ${saturation}%, ${lightness}%)`;
}

export const UserAvatar: React.FC<React.PropsWithChildren<IProps>> = React.memo(
  ({ name, picture, icon, showBoth, onClick, className, ...rest }) => {
    const [firstName, lastName] = useMemo(() => {
      const names = words(name);
      const firstName = nth(names, 0);
      const lastName = names.length > 1 ? nth(names, 1) : null;

      return [firstName, lastName];
    }, [name]);
    const hsl = useMemo(() => stringToHslColor(name, 60, 60), [name]);
    const clickable = useMemo(() => isFunction(onClick), [onClick]);
    const avatar = useMemo(() => {
      if (icon) {
        return icon;
      }

      return (
        <Avatar
          {...rest}
          className={styles.avatar}
          src={picture && <Image src={picture} />}
          style={{ backgroundColor: picture ? undefined : hsl }}
          alt={name}
        >
          {toUpper(first(firstName))}
          {toUpper(first(lastName))}
        </Avatar>
      );
    }, [rest, hsl, icon, picture, name, firstName, lastName]);
    const handleClick = useCallback(
      (event: React.MouseEvent<HTMLDivElement>) => {
        if (clickable) {
          onClick(event);
        }
      },
      [clickable, onClick],
    );

    return (
      <div
        className={cx(styles.UserAvatar, className, {
          [styles.clickable]: clickable,
        })}
        onClick={handleClick}
      >
        {avatar}
        {showBoth && (
          <Text ellipsis className={styles.name}>
            {name}
          </Text>
        )}
      </div>
    );
  },
);
