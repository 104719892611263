import React from 'react';
import cx from 'classnames';
import { isNull } from 'lodash';

import { Button, Result } from 'antd';

import type { ResultStatusType } from 'antd/lib/result';

import styles from './ErrorNotice.module.scss';

interface IProps {
  status?: ResultStatusType;
  title?: React.ReactNode;
  description?: React.ReactNode;
  extraDescription?: boolean;
  action?: JSX.Element;

  className?: string;
}

const { useMemo, useCallback } = React;

/**
 * @type {React.FC}
 */
export const ErrorNotice: React.FC<React.PropsWithChildren<IProps>> = React.memo(
  ({
    status = '500',
    title = 'Oops',
    description = `Looks like there's an error when trying to load the page.`,
    extraDescription = true,
    action,
    className,
  }) => {
    const onReloadApp = useCallback(() => window.location.assign('/'), []);
    const extra = useMemo(() => {
      // allow omitting action button
      if (isNull(action)) {
        return null;
      } else if (!!action) {
        return action;
      }

      return <Button onClick={onReloadApp}>Reload App</Button>;
    }, [action, onReloadApp]);

    return (
      <Result
        className={cx(styles.ErrorNotice, className)}
        status={status}
        title={title}
        subTitle={
          <div>
            <div>{description}</div>
            {extraDescription && (
              <div>
                If this is unexpected and the error persists, please contact support at
                support@superbloom.one.
              </div>
            )}
          </div>
        }
        extra={extra}
      />
    );
  },
);
