import React from 'react';
import { format } from 'date-fns';
import { first, flatMap, isEmpty, last, map, slice, sortBy, uniqBy } from 'lodash';

import {
  IProgramSalesTrackingSummary,
  ISalesTrackingSummary,
  SalesTrackingLastDate,
  SalesTrackingSummaryFilters,
} from '@types';
import { useGetProgramSalesTrackingSummary, useGetSalesTrackingSummary } from '@hooks';

export interface IContextProps {
  filters?: SalesTrackingSummaryFilters;
}
export interface IChartData {
  value: number;
  label: string;

  name?: React.ReactNode; // program/member avatar
}
export type TDisplayMode = 'overall' | 'programs';
type TChart = 'sales' | 'clicks' | 'conversions' | 'costs';
interface ISalesTrackingTotal {
  clicks: number;
  conversions: number;
  sales: number;
  costs: number;
}
interface ISalesTrackingMetrics {
  displayModeOptions: Array<{
    label: React.ReactNode;
    value: TDisplayMode;
  }>;

  loading: boolean;
  total: ISalesTrackingTotal;
  clicks: IChartData[];
  conversions: IChartData[];
  sales: IChartData[];
  costs: IChartData[];

  chart: TChart;
  setChart(chart: TChart): void;

  displayMode: TDisplayMode;
  setDisplayMode: React.Dispatch<React.SetStateAction<TDisplayMode>>;

  lastDays: SalesTrackingLastDate;
  setLastDays(lastDate: SalesTrackingLastDate): void;
}

const { useContext, useState, useMemo } = React;
const topResultCount = 6;
function getChartData({
  displayMode,
  chart,
  overallSummary,
  programSummary,
}: {
  displayMode: TDisplayMode;
  chart: TChart;
  overallSummary: ISalesTrackingSummary;
  programSummary: IProgramSalesTrackingSummary[];
}): IChartData[] {
  // NOTE: need to de-dup with uniqBy and sortBy date (label) to properly show multiple lines with the same xAxis
  if (displayMode === 'programs') {
    const sortedProgramSummary = sortBy(programSummary, (s) => -last(s.data[chart])?.value);

    return sortBy(
      flatMap(slice(sortedProgramSummary, 0, topResultCount), (summary) =>
        uniqBy(
          map(
            sortBy(summary.data[chart], (t) => -t.ts),
            ({ value, ts }) => {
              return {
                label: format(ts, 'yyyy-MM-dd'),
                value,
                name: summary.program.info.name,
              };
            },
          ),
          'label',
        ),
      ),
      'label',
    );
  }

  // overall summary
  return sortBy(
    uniqBy(
      map(
        sortBy(overallSummary?.[chart], (t) => -t.ts),
        ({ value, ts }) => {
          return {
            label: format(ts, 'yyyy-MM-dd'),
            value,
            name: chart,
          };
        },
      ),
      'label',
    ),
    'label',
  );
}

const SalesTrackingMetricsContext = React.createContext<ISalesTrackingMetrics>(null);
export const useSalesTrackingMetricsContext = () => useContext(SalesTrackingMetricsContext);
export const SalesTrackingMetricsProvider: React.FC<React.PropsWithChildren<IContextProps>> = ({
  filters = {},
  children,
}) => {
  const [lastDays, setLastDays] = useState<SalesTrackingLastDate>(SalesTrackingLastDate.ThisWeek);
  const displayModeOptions: Array<{
    label: React.ReactNode;
    value: TDisplayMode;
  }> = useMemo(() => {
    const options: Array<{
      label: React.ReactNode;
      value: TDisplayMode;
    }> = [
      {
        label: 'Overall',
        value: 'overall',
      },
    ];

    if (!filters.programId) {
      options.push({
        label: 'Top Programs',
        value: 'programs',
      });
    }

    return options;
  }, [filters]);
  const [displayMode, setDisplayMode] = useState<TDisplayMode>(first(displayModeOptions).value);
  const [chart, setChart] = useState<TChart>('sales');
  const combinedFilters: SalesTrackingSummaryFilters = useMemo(
    () => ({
      ...filters,
      lastDays: isEmpty(lastDays) ? undefined : lastDays,
    }),
    [lastDays, filters],
  );

  const { loading: loadingOverallSummary, summary: overallSummary } = useGetSalesTrackingSummary({
    variables: {
      filters: combinedFilters,
    },
  });
  const { loading: loadingProgramSummary, summary: programSummary } =
    useGetProgramSalesTrackingSummary({
      variables: {
        filters: combinedFilters,
      },
    });

  const loading = useMemo(
    () => loadingOverallSummary || loadingProgramSummary,
    [loadingOverallSummary, loadingProgramSummary],
  );
  const total: ISalesTrackingTotal = useMemo(() => {
    const totalClicks = last(overallSummary?.clicks)?.value || 0;
    const totalConversions = last(overallSummary?.conversions)?.value || 0;
    const totalSales = last(overallSummary?.sales)?.value || 0;
    const totalCosts = last(overallSummary?.costs)?.value || 0;

    return {
      clicks: totalClicks,
      conversions: totalConversions,
      sales: totalSales,
      costs: totalCosts,
    };
  }, [overallSummary]);
  const clicks = useMemo(
    () =>
      getChartData({
        displayMode,
        chart: 'clicks',
        overallSummary,
        programSummary,
      }),
    [displayMode, overallSummary, programSummary],
  );
  const conversions = useMemo(
    () =>
      getChartData({
        displayMode,
        chart: 'conversions',
        overallSummary,
        programSummary,
      }),
    [displayMode, overallSummary, programSummary],
  );
  const sales = useMemo(
    () =>
      getChartData({
        displayMode,
        chart: 'sales',
        overallSummary,
        programSummary,
      }),
    [displayMode, overallSummary, programSummary],
  );
  const costs = useMemo(
    () =>
      getChartData({
        displayMode,
        chart: 'costs',
        overallSummary,
        programSummary,
      }),
    [displayMode, overallSummary, programSummary],
  );

  return (
    <SalesTrackingMetricsContext.Provider
      value={{
        displayModeOptions,

        loading,
        total,
        clicks,
        conversions,
        sales,
        costs,

        chart,
        setChart,

        displayMode,
        setDisplayMode,

        lastDays,
        setLastDays,
      }}
    >
      {children}
    </SalesTrackingMetricsContext.Provider>
  );
};
