import { gql } from '@apollo/client';

export const SOCIAL_POST_FRAGMENT = gql`
  fragment SocialPost on SocialPost {
    id
    accountId
    info {
      title
      description
      sponsored
      postDate
      url
      cover
    }
    stats {
      views
      likes
      comments
    }
    media {
      type
      url
    }
    createdDate
    updatedDate
  }
`;
