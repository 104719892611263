import React from 'react';
import cx from 'classnames';

import { Avatar, AvatarProps } from 'antd';

import tiktokImage from '@assets/tiktok.svg';

import styles from './TiktokIcon.module.scss';

interface IProps extends AvatarProps {}

/**
 * @type {React.FC}
 */
export const TiktokIcon: React.FC<React.PropsWithChildren<IProps>> = React.memo((props) => {
  return <Avatar {...props} className={cx(styles.TiktokIcon, props.className)} src={tiktokImage} />;
});
