import React from 'react';
import cx from 'classnames';
import { map, startCase, toLower } from 'lodash';

import { Select, Pagination } from 'antd';
import { RefreshButton } from '@components';

import { EntitySortBy } from '@types';
import { useSyncCreatorAccountResource } from '@hooks';
import { useSelectPostListContext } from '../SelectPostListContext';

import styles from './PostListHeader.module.scss';

interface IProps {
  className?: string;
}

const { useMemo, useCallback } = React;
const { Option } = Select;

/**
 * @type {React.FC}
 */
export const PostListHeader: React.FC<React.PropsWithChildren<IProps>> = React.memo(
  ({ className }) => {
    const {
      resource,
      loading: loadingPosts,
      refetch,
      count,
      limit,
      offset,
      setLimit,
      setOffset,
      sortBy,
      setSortBy,
    } = useSelectPostListContext();
    const { syncCreatorAccountResource, loading: syncing } = useSyncCreatorAccountResource();

    const loading = useMemo(() => loadingPosts || syncing, [loadingPosts, syncing]);
    const page = useMemo(() => Math.floor(offset / limit) + 1, [offset, limit]);
    const onPaginationChange = useCallback(
      (page: number, pageSize: number) => {
        setLimit(pageSize);
        setOffset((page - 1) * pageSize);
      },
      [setLimit, setOffset],
    );
    const onRefetch = useCallback(async () => {
      await syncCreatorAccountResource({
        variables: {
          id: resource.id,
        },
      });

      refetch();
    }, [resource, syncCreatorAccountResource, refetch]);

    return (
      <div className={cx(styles.PostListHeader, className)}>
        <Pagination
          className={styles.pagination}
          current={page}
          total={count}
          pageSize={limit}
          showSizeChanger={true}
          showTotal={(total) => `${total} post(s)`}
          onChange={onPaginationChange}
        />
        <Select
          className={styles.sortBy}
          value={sortBy}
          onChange={(sortBy) => setSortBy(sortBy)}
          optionLabelProp="label"
          bordered={false}
        >
          {map(EntitySortBy, (sortBy) => (
            <Option value={sortBy} label={`Sort by ${startCase(toLower(sortBy))}`} key={sortBy}>
              {startCase(toLower(sortBy))}
            </Option>
          ))}
        </Select>
        <RefreshButton className={styles.refresh} loading={loading} onClick={onRefetch} />
      </div>
    );
  },
);
