import React from 'react';
import { useNavigate } from 'react-router-dom';
import numeral from 'numeral';
import cx from 'classnames';
import { toLower } from 'lodash';

import { Descriptions, MenuProps, PageHeader, Tabs, Tag, Tooltip, message } from 'antd';
import { QuestionCircleOutlined } from '@ant-design/icons';
import {
  ErrorNotice,
  LoadSpinner,
  MoreActionsButton,
  ProgramIncentiveTag,
  RefreshButton,
  SalesTrackingMetrics,
  TrackingStatsDisplay,
  UserAvatar,
} from '@components';

import { formatDate } from '@utils';
import { AffiliateTrackingStatus } from '@types';
import { useGetAffiliateTrackingById } from '@hooks';

import styles from './AffiliateTrackingDetail.module.scss';

interface IProps {
  trackingId: string;

  className?: string;
}
type TActiveTab = 'metrics';

const { useMemo, useCallback, useState } = React;
const tabItems = [
  {
    label: 'Metrics',
    key: 'metrics',
  },
];

/**
 * @type {React.FC}
 */
export const AffiliateTrackingDetail: React.FC<React.PropsWithChildren<IProps>> = React.memo(
  ({ trackingId, className }) => {
    const navigate = useNavigate();
    const [activeTab, setActiveTab] = useState<TActiveTab>('metrics');

    const { loading, tracking, error, refetch } = useGetAffiliateTrackingById({
      variables: {
        id: trackingId,
      },
    });

    const menuProps: MenuProps = useMemo(() => {
      return {
        items: [
          {
            key: 'tracking',
            label: 'Copy tracking link',
            onClick: async () => {
              await navigator.clipboard.writeText(tracking?.info.trackingUrl);

              message.info('Link copied to clipboard.');
            },
          },
          {
            key: 'recruit',
            label: 'Copy recruit link',
            onClick: async () => {
              await navigator.clipboard.writeText(tracking?.info.recruitUrl);

              message.info('Link copied to clipboard.');
            },
          },
        ],
      };
    }, [tracking]);
    const onActiveTabChange = useCallback(
      (activeKey: string) => setActiveTab(activeKey as TActiveTab),
      [],
    );
    const onBack = useCallback(() => navigate(-1), [navigate]);

    return (
      <div className={cx(styles.AffiliateTrackingDetail, className)}>
        {loading && <LoadSpinner className={styles.loading} />}
        {!loading && error && <ErrorNotice />}
        {!loading && tracking && (
          <>
            <PageHeader
              className={styles.header}
              title={<UserAvatar className={styles.name} name={tracking.info.name} showBoth />}
              subTitle={tracking.email}
              tags={
                <Tag color={tracking.status === AffiliateTrackingStatus.Active ? 'green' : 'gray'}>
                  {toLower(tracking.status)}
                </Tag>
              }
              onBack={onBack}
              extra={[
                <RefreshButton key="refresh" size="small" onClick={refetch} loading={loading} />,
                <MoreActionsButton key="more" size="small" menuProps={menuProps} />,
              ]}
              footer={
                <Tabs
                  className={styles.tabs}
                  size="small"
                  items={tabItems}
                  activeKey={activeTab}
                  onChange={onActiveTabChange}
                />
              }
            >
              <div className={styles.detailsAndMetrics}>
                <Descriptions className={styles.details} column={1}>
                  <Descriptions.Item label="Program">
                    <UserAvatar
                      size="small"
                      shape="square"
                      name={tracking.program.info.name}
                      picture={tracking.program.cover?.previewUrl || tracking.program.cover?.url}
                      showBoth
                    />
                  </Descriptions.Item>
                  <Descriptions.Item label="Referrer">
                    <UserAvatar size="small" name={tracking.member.info.fullName} showBoth />
                  </Descriptions.Item>
                  <Descriptions.Item label="Incentive">
                    <ProgramIncentiveTag showTooltip program={tracking.program} />
                  </Descriptions.Item>
                  <Descriptions.Item label="Joined Date">
                    {formatDate(new Date(tracking.createdDate))}
                  </Descriptions.Item>
                  <Descriptions.Item
                    label={
                      <span className={styles.title}>
                        Recruited
                        <Tooltip title="Total number of downstream affiliates recruited by this affiliate directly or indirectly.">
                          <QuestionCircleOutlined className={styles.question} />
                        </Tooltip>
                      </span>
                    }
                  >
                    {numeral(tracking.descendantCount).format('0,0')}
                  </Descriptions.Item>
                </Descriptions>
                <TrackingStatsDisplay
                  className={styles.metrics}
                  stats={tracking.stats}
                  name={tracking.info.name}
                />
              </div>
            </PageHeader>
            <div className={styles.tabContent}>
              {activeTab === 'metrics' && (
                <SalesTrackingMetrics
                  filters={{
                    affiliateTrackingId: trackingId,
                  }}
                />
              )}
            </div>
          </>
        )}
      </div>
    );
  },
);
