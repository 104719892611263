import { gql } from '@apollo/client';

export const NOTIFICATION_FRAGMENT = gql`
  fragment Notification on Notification {
    id
    type
    payload {
      type
      data
    }
    read
    createdDate
    updatedDate
  }
`;
