import React from 'react';
import cx from 'classnames';
import { includes, map } from 'lodash';

import { Drawer, Button, Form, Alert, Typography, Divider, Input, Upload } from 'antd';
import { CloudUploadOutlined } from '@ant-design/icons';

import type { UploadFile } from 'antd';
import { isCustomPostType, useUploadContent } from '@utils';
import { ContentCategory, IContentReview, IProgramCollab, WorkflowEvent } from '@types';
import { useWorkflowCheckIn } from '@hooks';

import styles from './SubmitContentPanel.module.scss';

interface IProps {
  collab: IProgramCollab;
  review: IContentReview;
  show: boolean;
  onRequestClose(): void;

  className?: string;
}
interface ISubmitContentsFormParams {
  contentIds?: string[];
  contents?: UploadFile[];
  caption?: string;
}

const { useCallback, useMemo, useState } = React;
const { Text } = Typography;
const { TextArea } = Input;

/**
 * @type {React.FC}
 */
export const SubmitContentPanel: React.FC<React.PropsWithChildren<IProps>> = React.memo(
  ({ collab, review, show, onRequestClose, className }) => {
    const { workflowCheckIn } = useWorkflowCheckIn();
    const { uploadContent } = useUploadContent();
    const [loading, setLoading] = useState(false);

    const handleSubmit = useCallback(
      async (params: ISubmitContentsFormParams) => {
        const { contents, contentIds: previousContentIds, caption } = params;

        setLoading(true);
        try {
          const contentIds = await Promise.all(
            map(contents, async (content) => {
              // skip upload if it's already uploaded
              if (includes(previousContentIds, content.uid)) {
                return content.uid;
              }

              const { originFileObj: file } = content;
              const { id } = await uploadContent({
                category: ContentCategory.ProgramCollab,
                entityId: review.collabId,
                file,
              });

              return id;
            }),
          );

          await workflowCheckIn({
            variables: {
              collabId: review.collabId,
              event: WorkflowEvent.ContentReviewStageUpdateContents,
              params: {
                contentReview: {
                  contentReviewId: review.id,
                  contentIds,
                  caption,
                },
              },
            },
          });
        } finally {
          setLoading(false);

          onRequestClose();
        }
      },
      [uploadContent, review, workflowCheckIn, onRequestClose],
    );

    return (
      <Drawer
        className={cx(styles.SubmitContentPanel, className)}
        open={show}
        onClose={onRequestClose}
        title="Select Contents"
        footer={null}
        width={700}
        destroyOnClose
      >
        <SubmitContentsForm
          collab={collab}
          review={review}
          onSubmit={handleSubmit}
          isSubmitting={loading}
        />
      </Drawer>
    );
  },
);

interface ISubmitContentsFormProps {
  collab: IProgramCollab;
  review: IContentReview;
  onSubmit(params: ISubmitContentsFormParams): void;
  isSubmitting: boolean;
}
const SubmitContentsForm: React.FC<
  React.PropsWithChildren<React.PropsWithChildren<ISubmitContentsFormProps>>
> = React.memo(({ collab, review, onSubmit, isSubmitting }) => {
  const [form] = Form.useForm();

  // enable caption when post tracking is enabled and the post is a social post
  const captionEnabled = useMemo(() => {
    const { enablePostTracking } = collab.program.workflowConfig.data.contentReview;

    return enablePostTracking && !isCustomPostType(review.requirement.type);
  }, [collab, review]);
  const defaultContents = useMemo(() => {
    return map(review.contents, (content): UploadFile => {
      return {
        uid: content.id,
        status: 'done',
        name: content.originalName,
        size: content.size,
        url: content.url,
        type: content.mimeType,
      };
    });
  }, [review]);
  const initialValues: ISubmitContentsFormParams = useMemo(() => {
    return {
      contentIds: review.contentIds,
      contents: defaultContents,
      caption: review.info.caption,
    };
  }, [review, defaultContents]);
  const normFile = useCallback((e: any) => {
    if (Array.isArray(e)) {
      return e;
    }

    return e?.fileList;
  }, []);
  const brandFeedback = useMemo(() => review.info.brandComment, [review]);
  const onFinish = useCallback(
    async (values: ISubmitContentsFormParams) => onSubmit(values),
    [onSubmit],
  );

  return (
    <Form
      className={styles.SubmitContentsForm}
      form={form}
      layout="vertical"
      onFinish={onFinish}
      scrollToFirstError
      initialValues={initialValues}
    >
      <div className={styles.content}>
        {brandFeedback && (
          <>
            <Alert
              className={styles.notice}
              type="warning"
              message={<Text type="secondary">Feedback from the brand: "{brandFeedback}"</Text>}
            />
            <Divider />
          </>
        )}
        <Form.Item hidden name="contentIds">
          <Input disabled />
        </Form.Item>
        <Form.Item
          label="Contents"
          name="contents"
          valuePropName="fileList"
          getValueFromEvent={normFile}
          rules={[{ required: true, message: 'Please upload contents.' }]}
        >
          <Upload.Dragger
            className={styles.uploader}
            multiple={true}
            beforeUpload={() => false}
            accept="image/*, video/*"
            listType="picture-card"
            defaultFileList={defaultContents}
          >
            <p className="ant-upload-drag-icon">
              <CloudUploadOutlined />
            </p>
            <p className="ant-upload-text">Click or drag files to this area to upload</p>
            <p className="ant-upload-hint">Only accept .doc, .docx or .pdf files.</p>
          </Upload.Dragger>
        </Form.Item>
        {captionEnabled && (
          <Form.Item
            label="Caption"
            name="caption"
            extra="The caption you intended to use when posting the contents on social media."
          >
            <TextArea
              placeholder="Enter caption for the post..."
              autoSize={{ minRows: 3, maxRows: 6 }}
            />
          </Form.Item>
        )}
      </div>
      <Divider />
      <div className={styles.submit}>
        <Button
          className={styles.submitButton}
          type="primary"
          htmlType="submit"
          loading={isSubmitting}
        >
          Update Contents
        </Button>
      </div>
    </Form>
  );
});
