import { WorkflowStage } from '@types';

const WorkflowStageName: Record<WorkflowStage, string> = {
  [WorkflowStage.Prospect]: 'Prospect',
  [WorkflowStage.Term]: 'Send Terms',
  [WorkflowStage.Email]: 'Send Welcome Email',
  [WorkflowStage.Contract]: 'Send Contract',
  [WorkflowStage.InfoUpdate]: 'Request Info Update',
  [WorkflowStage.ProductFulfillment]: 'Create Gift Order',
  [WorkflowStage.ContentReview]: 'Review Contents',
  [WorkflowStage.PostTracking]: 'Post Tracking',
  [WorkflowStage.Payment]: 'Payment',
  [WorkflowStage.SalesTracking]: 'Sales Tracking',
  [WorkflowStage.PostbackTracking]: 'Postback Tracking',
  [WorkflowStage.Completed]: 'Completed',
} as const;

export function getWorkflowStageName(stage: WorkflowStage, isSalesTrackingProgram = false): string {
  if (isSalesTrackingProgram && stage === WorkflowStage.Completed) {
    return 'Active';
  }

  return WorkflowStageName[stage];
}
