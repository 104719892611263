import { useLocation } from 'react-router-dom';
import { split } from 'lodash';

export function useActiveRoute(targets: string[], reverse = true) {
  const location = useLocation();
  const tokens = split(location.pathname, '/');

  for (const token of reverse ? tokens.reverse() : tokens) {
    if (targets.includes(token)) {
      return token;
    }
  }

  return null;
}
