import { gql } from '@apollo/client';

export const SOCIAL_ACCOUNT_FRAGMENT = gql`
  fragment SocialAccount on SocialAccount {
    id
    type
    accountId
    info {
      username
      segment
      displayName
      link
      bio
      lastPostDate
      profileImage
      cover
      gender
      ageGroup
      language
      country
      state
      city
      interests
      topHashtags
    }
    stats {
      followers
      posts
      engagement
      engagementRate
      avgViews
      avgLikes
      avgComments
      history {
        followers
        avgLikes
        month
      }
    }
    demographics {
      authenticity
      ages
      ethnicities
      countries
      states
      cities
      languages
      genders
      gendersPerAge
      interests
      reachability
    }
    createdDate
    updatedDate
  }
`;
