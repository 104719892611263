import React from 'react';
import cx from 'classnames';
import { noop } from 'lodash';

import { Alert, Button, Form, Input } from 'antd';

import { requestResetPassword } from './useRequestResetPassword';

import styles from './ForgetPasswordForm.module.scss';

interface IProps {
  onComplete?(): void;
  className?: string;
}
interface ISubmitParams {
  email: string;
}

const { useState, useCallback } = React;

export const ForgetPasswordForm: React.FC<React.PropsWithChildren<IProps>> = React.memo(
  ({ onComplete = noop, className }) => {
    const [form] = Form.useForm();
    const [sendingRequest, setSendingRequest] = useState(false);
    const [errorMessage, setErrorMessage] = useState<string>(null);

    const onFinish = useCallback(
      async (params: ISubmitParams) => {
        setSendingRequest(true);
        setErrorMessage(null);

        try {
          await requestResetPassword(params);

          onComplete();
        } catch (err) {
          setErrorMessage((err as Error)?.message);
        }

        setSendingRequest(false);
      },
      [onComplete],
    );

    return (
      <Form
        className={cx(styles.ForgetPasswordForm, className)}
        form={form}
        layout="vertical"
        onFinish={onFinish}
        scrollToFirstError
      >
        <Form.Item
          name="email"
          label="Please enter your email"
          rules={[
            {
              type: 'email',
              message: `The entered value is not a valid email.`,
            },
            {
              required: true,
              message: `Please enter your email.`,
            },
          ]}
        >
          <Input name="email" type="email" autoComplete="email" size="large" />
        </Form.Item>
        {errorMessage && (
          <Alert className={styles.alert} type="error" message={errorMessage} closable />
        )}
        <Button
          className={styles.submit}
          type="primary"
          size="large"
          htmlType="submit"
          loading={sendingRequest}
        >
          Request Password Reset
        </Button>
        <div className={styles.extra}>
          We'll email you a magic link for password-free sign-in. You can set your new password
          after signing in.
        </div>
      </Form>
    );
  },
);
