import { gql } from '@apollo/client';

export const MEMBER_FIELD_SCHEMA_FRAGMENT = gql`
  fragment MemberFieldSchema on MemberFieldSchema {
    id
    name
    type
    config {
      options
    }
    createdDate
    updatedDate
  }
`;
