import React from 'react';
import cx from 'classnames';

import { Button, ButtonProps, Tooltip } from 'antd';
import { RotateRightIcon } from '@customIcons';

import styles from './RefreshButton.module.scss';

interface IProps extends ButtonProps {
  onClick(): void;

  loading?: boolean;
  className?: string;
}

const { useCallback } = React;

/**
 * @type {React.FC}
 */
export const RefreshButton: React.FC<React.PropsWithChildren<IProps>> = React.memo(
  ({ onClick, loading, className, ...rest }) => {
    // NOTE: this ensures event won't be passed to onClick, which is super important
    const handleOnClick = useCallback(() => onClick(), [onClick]);

    return (
      <Tooltip trigger={['click', 'hover']} placement="topLeft" title="Refresh">
        <Button
          {...rest}
          className={cx(styles.RefreshButton, className)}
          icon={<RotateRightIcon />}
          shape="circle"
          type="text"
          loading={loading}
          onClick={handleOnClick}
        />
      </Tooltip>
    );
  },
);
