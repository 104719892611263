import { ProductCategory } from '@types';

export const ProductCategoryLabel: Record<ProductCategory, string> = {
  [ProductCategory.AnimalAndPetSupplies]: 'Animals & Pet Supplies',
  [ProductCategory.ApparelAndAccessories]: 'Apparel & Accessories',
  [ProductCategory.ArtsAndEntertainment]: 'Arts & Entertainment',
  [ProductCategory.BabyAndToddler]: 'Baby & Toddler',
  [ProductCategory.BusinessAndIndustrial]: 'Business & Industrial',
  [ProductCategory.CamerasAndOptics]: 'Cameras & Optics',
  [ProductCategory.Electronics]: 'Electronics',
  [ProductCategory.FoodBeveragesAndTobacco]: 'Food, Beverages & Tobacco',
  [ProductCategory.Furniture]: 'Furniture',
  [ProductCategory.Hardware]: 'Hardware',
  [ProductCategory.HealthAndBeauty]: 'Health & Beauty',
  [ProductCategory.HomeAndGarden]: 'Home & Garden',
  [ProductCategory.LuggageAndBags]: 'Luggage & Bags',
  [ProductCategory.Erotic]: 'Erotic',
  [ProductCategory.Media]: 'Media',
  [ProductCategory.OfficeSupplies]: 'Office Supplies',
  [ProductCategory.ReligiousAndCeremonial]: 'Religious & Ceremonial',
  [ProductCategory.Software]: 'Software',
  [ProductCategory.SportingGoods]: 'Sporting Goods',
  [ProductCategory.ToysAndGames]: 'Toys & Games',
  [ProductCategory.VehiclesAndParts]: 'Vehicles & Parts',
};
