import React from 'react';
import { useQuery, QueryHookOptions } from '@apollo/client';

import { message } from 'antd';

import { GET_PROGRAM_COUNT_QUERY } from '@queries';
import { GetProgramCountQuery, GetProgramCountQueryVariables } from '@types';

const { useEffect, useMemo } = React;
type IOptions = QueryHookOptions<GetProgramCountQuery, GetProgramCountQueryVariables>;

export function useGetProgramCount(options: IOptions = {}) {
  const {
    loading,
    data: { count } = {},
    error,
    refetch,
  } = useQuery<GetProgramCountQuery, GetProgramCountQueryVariables>(GET_PROGRAM_COUNT_QUERY, {
    ...options,
    fetchPolicy: 'cache-and-network',
    notifyOnNetworkStatusChange: true,
  });

  const errorMessage = useMemo(() => error?.message, [error]);
  useEffect(() => {
    if (!loading && errorMessage) {
      message.error(errorMessage);
    }
  }, [loading, errorMessage]);

  return {
    loading,
    count,
    error,
    refetch,
  };
}
