import { WorkflowStage } from '@types';

const WorkflowStageDescription: Record<WorkflowStage, string> = {
  [WorkflowStage.Prospect]: 'Program invitations and applications.',
  [WorkflowStage.Term]: 'Send collab terms to members.',
  [WorkflowStage.Email]: 'Send welcome email to members when they join the program.',
  [WorkflowStage.Contract]: 'Send Docusign contracts to members.',
  [WorkflowStage.InfoUpdate]:
    'Request information(contact info, address, etc) update from members.',
  [WorkflowStage.ProductFulfillment]: 'Send sample products to members.',
  [WorkflowStage.ContentReview]: 'Review post contents from members.',
  [WorkflowStage.PostTracking]: 'Tracking stats for member posts.',
  [WorkflowStage.Payment]: 'Send payment to members.',
  [WorkflowStage.SalesTracking]: 'Generate tracking link and discount code for members.',
  [WorkflowStage.PostbackTracking]: 'Generate postback tracking link for members.',
  [WorkflowStage.Completed]: '',
} as const;

export function getWorkflowStageDescription(stage: WorkflowStage): string {
  return WorkflowStageDescription[stage];
}
