import React from 'react';
import { format } from 'date-fns';
import cx from 'classnames';
import { includes, keys, map, pickBy, size, sumBy } from 'lodash';

import { Alert, Button, Drawer, PageHeader, Progress } from 'antd';
import { PaperPlaneIcon } from '@customIcons';
import { ProgramIncentiveTag, UserAvatar } from '@components';
import { InfoUpdateButton, ProductFulfillmentButton, TermButton } from './components';

import { IProgram, WorkflowCheckInParams, WorkflowStage } from '@types';
import { useApplyToProgram } from '@hooks';

import styles from './ApplicationPanel.module.scss';

interface IProps {
  program: IProgram;
  show: boolean;
  onRequestClose(): void;

  className?: string;
}
type TStageKey = keyof WorkflowCheckInParams;

const { useState, useMemo, useCallback } = React;

/**
 * @type {React.FC}
 */
export const ApplicationPanel: React.FC<React.PropsWithChildren<IProps>> = React.memo(
  ({ program, show, onRequestClose, className }) => {
    const [params, setParams] = useState<WorkflowCheckInParams>({});
    const [errorMessage, setErrorMessage] = useState('');
    const { applyToProgram, loading: applying } = useApplyToProgram();

    const stages = useMemo(() => map(program.workflowConfig.stages, (s) => s.type), [program]);
    const applyPrefillConfig = useMemo(
      () => program.workflowConfig.data.prospect.prefillInfoWhenApply.stages,
      [program],
    );
    const [completed, percentage] = useMemo(() => {
      // this also omits the __typename key
      const requiredStages = keys(pickBy(applyPrefillConfig, (required) => required === true));
      const requiredTotal = size(requiredStages);
      const requiredCompleted = sumBy(requiredStages, (stage) => {
        if (!!params[stage]) {
          return 1;
        }

        return 0;
      });

      return [
        requiredCompleted === requiredTotal,
        Math.floor((requiredCompleted * 100) / requiredTotal),
      ];
    }, [params, applyPrefillConfig]);
    const [hasTerm, hasInfoUpdate, hasProductFulfillment] = useMemo(
      () => [
        includes(stages, WorkflowStage.Term),
        includes(stages, WorkflowStage.InfoUpdate),
        includes(stages, WorkflowStage.ProductFulfillment),
      ],
      [stages],
    );
    const onFillSection = useCallback((key: TStageKey, values: any) => {
      setParams((params) => {
        return {
          ...params,
          [key]: values,
        };
      });
    }, []);
    const onSubmit = useCallback(async () => {
      setErrorMessage('');

      try {
        await applyToProgram({
          variables: {
            id: program.id,
            params: {
              prefilledData: params,
            },
          },
        });

        onRequestClose();
      } catch (err) {
        console.log(err);
        setErrorMessage((err as Error)?.message);
      }
    }, [program, params, applyToProgram, onRequestClose]);

    return (
      <Drawer
        className={cx(styles.ApplicationPanel, className)}
        open={show}
        onClose={onRequestClose}
        maskClosable={!applying}
        title={
          <PageHeader
            className={styles.header}
            title={
              <UserAvatar
                className={styles.name}
                shape="square"
                name={program.info.name}
                picture={program.cover?.previewUrl || program.cover?.url}
                showBoth
              />
            }
            subTitle={`Created on ${format(new Date(program.createdDate), 'PPpp')}`}
            tags={<ProgramIncentiveTag showTooltip program={program} />}
          />
        }
        footer={
          <div className={styles.footer}>
            <Progress className={styles.progress} percent={percentage} showInfo={false} />
            <div
              className={cx(styles.actions, {
                [styles.applying]: applying,
              })}
            >
              <Button className={styles.button} onClick={onRequestClose}>
                Cancel
              </Button>
              <Button
                className={styles.button}
                type="primary"
                onClick={onSubmit}
                icon={<PaperPlaneIcon />}
                disabled={!completed}
                loading={applying}
              >
                Apply
              </Button>
            </div>
          </div>
        }
        width={700}
        closable={false}
        destroyOnClose
      >
        <div className={styles.content}>
          <div className={styles.text}>
            Please finish the following sections to apply to the program.
          </div>
          <div className={styles.stages}>
            {hasTerm && (
              <TermButton
                className={styles.button}
                program={program}
                onSubmit={(params) => onFillSection('term', params)}
                required={applyPrefillConfig.term}
                completed={!!params.term}
              />
            )}
            {hasInfoUpdate && (
              <InfoUpdateButton
                className={styles.button}
                program={program}
                onSubmit={(params) => onFillSection('infoUpdate', params)}
                required={applyPrefillConfig.infoUpdate}
                completed={!!params.infoUpdate}
              />
            )}
            {hasProductFulfillment && (
              <ProductFulfillmentButton
                className={styles.button}
                program={program}
                onSubmit={(params) => onFillSection('productFulfillment', params)}
                required={applyPrefillConfig.productFulfillment}
                completed={!!params.productFulfillment}
              />
            )}
          </div>
          {errorMessage && (
            <Alert
              className={styles.alert}
              type="error"
              message="Failed to apply to the program"
              description={errorMessage}
              closable
            />
          )}
        </div>
      </Drawer>
    );
  },
);
