import React from 'react';
import { useQuery, QueryHookOptions } from '@apollo/client';
import { isEmpty } from 'lodash';

import { message } from 'antd';

import { GET_ALL_PROGRAM_COLLAB_COUNT_QUERY } from '@queries';
import { GetAllProgramCollabCountQuery, GetAllProgramCollabCountQueryVariables } from '@types';

const { useEffect, useMemo } = React;
type IOptions = QueryHookOptions<
  GetAllProgramCollabCountQuery,
  GetAllProgramCollabCountQueryVariables
>;

export function useGetAllProgramCollabCount(options: IOptions = {}) {
  const {
    loading,
    data: { result } = {},
    error,
    refetch,
  } = useQuery<GetAllProgramCollabCountQuery, GetAllProgramCollabCountQueryVariables>(
    GET_ALL_PROGRAM_COLLAB_COUNT_QUERY,
    {
      ...options,
      notifyOnNetworkStatusChange: true,
      fetchPolicy: 'cache-and-network',
      skip: isEmpty(options.variables?.params),
    },
  );

  const errorMessage = useMemo(() => error?.message, [error]);
  useEffect(() => {
    if (!loading && errorMessage) {
      message.error(errorMessage);
    }
  }, [loading, errorMessage]);

  return {
    loading,
    result,
    error,
    refetch,
  };
}
