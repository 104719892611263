import React from 'react';
import cx from 'classnames';

import { ThunderboltFilled } from '@ant-design/icons';
import { FlagIcon } from '@customIcons';

import styles from './CompletedStageIcon.module.scss';

interface IProps {
  isSalesTracking?: boolean;
  className?: string;
}

/**
 * @type {React.FC}
 */
export const CompletedStageIcon: React.FC<React.PropsWithChildren<IProps>> = React.memo(
  ({ isSalesTracking = false, className }) => {
    return (
      <div
        className={cx(styles.CompletedStageIcon, className, {
          [styles.salesTracking]: isSalesTracking,
        })}
      >
        {!isSalesTracking && <FlagIcon className={styles.icon} />}
        {isSalesTracking && <ThunderboltFilled className={styles.icon} />}
      </div>
    );
  },
);
