import React from 'react';
import qs from 'qs';

import { ISocialPost, SocialPostFilters, EntitySortBy, ICreatorAccountResource } from '@types';
import { useParsedRouteSearch } from '@utils';
import { useGetSocialPosts, useGetSocialPostCount } from '@hooks';

interface ISelectPostListContext {
  resource: ICreatorAccountResource;

  count: number;
  loading: boolean;
  posts: ISocialPost[];
  refetch(): void;

  limit: number;
  setLimit(limit: number): void;
  offset: number;
  setOffset(offset: number): void;

  sortBy: EntitySortBy;
  setSortBy(sortBy: EntitySortBy): void;

  filters: SocialPostFilters;
  setFilters(filters: SocialPostFilters): void;
}
export interface ISelectPostListContextProps {
  resource: ICreatorAccountResource;
}

const { useContext, useEffect, useMemo, useCallback, useState } = React;
const DEFAULT_FILTERS: SocialPostFilters = {};
const DEFAULT_OFFSET = 0;
const DEFAULT_LIMIT = 20;

const SelectPostListContext = React.createContext<ISelectPostListContext>(null);
export const useSelectPostListContext = () => useContext(SelectPostListContext);
export const SelectPostListContextProvider: React.FC<
  React.PropsWithChildren<ISelectPostListContextProps>
> = React.memo(({ children, resource }) => {
  const urlSearch = useParsedRouteSearch();
  const [offset, setOffset] = useState(parseInt(urlSearch.offset as string, 10) || DEFAULT_OFFSET);
  const [limit, setLimit] = useState(parseInt(urlSearch.limit as string, 10) || DEFAULT_LIMIT);
  const [filters, setFilters] = useState<SocialPostFilters>(
    (urlSearch.filters as qs.ParsedQs) || DEFAULT_FILTERS,
  );
  const [sortBy, setSortBy] = useState<EntitySortBy>(
    (urlSearch.sortBy as EntitySortBy) || EntitySortBy.Newest,
  );

  const accountId = useMemo(() => resource.accountId, [resource]);
  const combinedFilters: SocialPostFilters = useMemo(
    () => ({
      ...filters,
      accountId,
    }),
    [filters, accountId],
  );

  const {
    count,
    loading: loadingCount,
    refetch: refetchCount,
  } = useGetSocialPostCount({
    variables: {
      filters: combinedFilters,
    },
  });
  const {
    posts,
    loading: loadingPayments,
    refetch: refetchPosts,
  } = useGetSocialPosts({
    variables: {
      limit,
      offset,
      sortBy,
      filters: combinedFilters,
    },
  });

  const loading = useMemo(() => loadingPayments || loadingCount, [loadingPayments, loadingCount]);
  const refetch = useCallback(() => {
    refetchPosts();
    refetchCount();
  }, [refetchPosts, refetchCount]);

  // reset offset if it's exceeded total count
  useEffect(() => {
    if (!loadingCount && count <= offset) {
      setOffset(0);
    }
  }, [count, loadingCount, offset]);
  useEffect(() => {
    refetchPosts({
      limit,
      offset,
      sortBy,
      filters: combinedFilters,
    });
    refetchCount({
      filters: combinedFilters,
    });
  }, [combinedFilters, refetchPosts, refetchCount, sortBy, limit, offset]);

  return (
    <SelectPostListContext.Provider
      value={{
        resource,

        loading,
        count,
        posts,
        refetch,

        limit,
        setLimit,
        offset,
        setOffset,

        sortBy,
        setSortBy,

        filters,
        setFilters,
      }}
    >
      {children}
    </SelectPostListContext.Provider>
  );
});
