import React from 'react';
import { useMutation, MutationHookOptions, MutationFunctionOptions } from '@apollo/client';
import { isFunction } from 'lodash';

import { message } from 'antd';

import { UPDATE_LAST_NOTIFICATION_OPENED_MUTATION } from '@queries';
import { UpdateLastNotificationOpenedMutation } from '@types';
import { useGetProfile } from './useGetProfile';

type IOptions = MutationHookOptions<UpdateLastNotificationOpenedMutation>;
type IFunctionOptions = MutationFunctionOptions<UpdateLastNotificationOpenedMutation>;

const { useCallback } = React;

export const useUpdateLastNotificationOpened = (options: IOptions = {}) => {
  const { refetch: refetchProfile } = useGetProfile();
  const [_updateLastNotificationOpened, { loading, error }] =
    useMutation<UpdateLastNotificationOpenedMutation>(UPDATE_LAST_NOTIFICATION_OPENED_MUTATION, {
      ...options,
      onCompleted(data) {
        if (isFunction(options.onCompleted)) {
          options.onCompleted(data);
        }

        // IMPORTANT: we do it from here cuz update and refetch takes time
        // and we don't want user to notice something is happening
        refetchProfile();
      },
    });

  const updateLastNotificationOpened = useCallback(
    async (options: IFunctionOptions = {}) => {
      try {
        const result = await _updateLastNotificationOpened(options);

        return result;
      } catch (err) {
        message.error((err as Error).message);
      }
    },
    [_updateLastNotificationOpened],
  );

  return {
    updateLastNotificationOpened,
    loading,
    error,
  };
};
