import React from 'react';
import { useQuery, QueryHookOptions } from '@apollo/client';

import { message } from 'antd';

import { GET_NOTIFICATIONS_QUERY } from '@queries';
import { GetNotificationsQuery } from '@types';

type IOptions = QueryHookOptions<GetNotificationsQuery>;

const { useEffect, useMemo } = React;

export function useGetNotifications(options: IOptions = {}) {
  const {
    loading,
    data: { notifications = [] } = {},
    error,
    refetch,
  } = useQuery<GetNotificationsQuery>(GET_NOTIFICATIONS_QUERY, options);

  const errorMessage = useMemo(() => error?.message, [error]);
  useEffect(() => {
    if (!loading && errorMessage) {
      message.error(errorMessage);
    }
  }, [loading, errorMessage]);

  return {
    loading,
    notifications,
    error,
    refetch,
  };
}
