import React from 'react';
import { useQuery, QueryHookOptions } from '@apollo/client';
import { isEmpty } from 'lodash';

import { message } from 'antd';

import { GET_PROGRAM_COLLAB_ACTIVITIES_QUERY } from '@queries';
import { GetProgramCollabActivitiesQuery, GetProgramCollabActivitiesQueryVariables } from '@types';

type IOptions = QueryHookOptions<
  GetProgramCollabActivitiesQuery,
  GetProgramCollabActivitiesQueryVariables
>;

const { useEffect, useMemo } = React;

export function useGetProgramCollabActivities(options: IOptions = {}) {
  const {
    loading,
    data: { activities = [] } = {},
    error,
    refetch,
  } = useQuery<GetProgramCollabActivitiesQuery, GetProgramCollabActivitiesQueryVariables>(
    GET_PROGRAM_COLLAB_ACTIVITIES_QUERY,
    {
      ...options,
      fetchPolicy: 'cache-and-network',
      nextFetchPolicy: 'cache-first',
      notifyOnNetworkStatusChange: true,
      skip: isEmpty(options.variables?.collabId),
    },
  );

  const errorMessage = useMemo(() => error?.message, [error]);
  useEffect(() => {
    if (!loading && errorMessage) {
      message.error(errorMessage);
    }
  }, [loading, errorMessage]);

  return {
    loading,
    activities,
    error,
    refetch,
  };
}
