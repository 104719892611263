import { gql } from '@apollo/client';

import { SHOPIFY_PRODUCT_FRAGMENT } from './fragments';

//========================================================
//= Queries
//========================================================
export const GET_SHOPIFY_PRODUCTS_QUERY = gql`
  query GetShopifyProducts(
    $limit: Int
    $offset: Int
    $sortBy: EntitySortBy
    $filters: ShopifyProductFilters!
  ) {
    products: creatorGetShopifyProducts(
      limit: $limit
      offset: $offset
      sortBy: $sortBy
      filters: $filters
    ) {
      ...ShopifyProduct
    }
  }
  ${SHOPIFY_PRODUCT_FRAGMENT}
`;

export const GET_SHOPIFY_PRODUCT_COUNT_QUERY = gql`
  query GetShopifyProductCount($filters: ShopifyProductFilters!) {
    count: creatorGetShopifyProductCount(filters: $filters)
  }
`;

export const GET_SHOPIFY_PRODUCTS_BY_IDS_QUERY = gql`
  query GetShopifyProductsByIds($ids: [Float!]!) {
    products: creatorGetShopifyProductsByIds(ids: $ids) {
      ...ShopifyProduct
    }
  }
  ${SHOPIFY_PRODUCT_FRAGMENT}
`;
