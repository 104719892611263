import React from 'react';
import cx from 'classnames';

import { Drawer } from 'antd';
import { CollabTerm } from '@components';

import styles from './ViewTermPanel.module.scss';

interface IProps {
  collabId: string;
  show: boolean;
  onRequestClose(): void;

  className?: string;
}

/**
 * @type {React.FC}
 */
export const ViewTermPanel: React.FC<React.PropsWithChildren<IProps>> = React.memo(
  ({ collabId, show, onRequestClose, className }) => {
    return (
      <Drawer
        className={cx(styles.ViewTermPanel, className)}
        open={show}
        onClose={onRequestClose}
        title="Collab Terms"
        footer={null}
        width={700}
        destroyOnClose
      >
        <CollabTerm collabId={collabId} />
      </Drawer>
    );
  },
);
