import React from 'react';
import cx from 'classnames';

import { Modal, Input, Checkbox, Button, Form, Tooltip } from 'antd';
import { QuestionCircleOutlined } from '@ant-design/icons';
import { AddressInput } from '@components';

import { ICreatorAddress, SaveCreatorAddressParams } from '@types';
import { useSaveAddress } from '@hooks';

import styles from './SaveAddressModal.module.scss';

interface IProps {
  show: boolean;
  onRequestClose(): void;
  onSave(): void;

  address?: ICreatorAddress;

  className?: string;
}

const { TextArea } = Input;
const { useMemo, useCallback, useEffect } = React;

/**
 * @type {React.FC}
 */
export const SaveAddressModal: React.FC<React.PropsWithChildren<IProps>> = React.memo(
  ({ address, show, onRequestClose, onSave, className }) => {
    const { saveAddress, loading: saving } = useSaveAddress();

    const isNewAddress = useMemo(() => !address, [address]);

    const handleSubmit = useCallback(
      async (params: SaveCreatorAddressParams) => {
        await saveAddress({
          variables: {
            params,
          },
        });

        onRequestClose();
        onSave();
      },
      [saveAddress, onRequestClose, onSave],
    );

    return (
      <Modal
        className={cx(styles.SaveAddressModal, className)}
        open={show}
        onCancel={onRequestClose}
        title={isNewAddress ? 'New Address' : 'Edit Address'}
        footer={null}
        width={700}
        destroyOnClose
      >
        <EditAddressForm onSubmit={handleSubmit} isSubmitting={saving} params={address} />
      </Modal>
    );
  },
);

const layout = {
  labelCol: { span: 8 },
  wrapperCol: { span: 16 },
};
interface IEditAddressFormProps {
  onSubmit(params: SaveCreatorAddressParams): void;
  isSubmitting: boolean;

  params?: ICreatorAddress;
}
const EditAddressForm: React.FC<
  React.PropsWithChildren<React.PropsWithChildren<IEditAddressFormProps>>
> = React.memo(({ params, onSubmit, isSubmitting }) => {
  const [form] = Form.useForm();

  const onFinish = useCallback(
    async (values: SaveCreatorAddressParams) => onSubmit(values),
    [onSubmit],
  );

  useEffect(() => {
    form.setFieldsValue(params);
  }, [params, form]);

  return (
    <Form
      {...layout}
      className={styles.form}
      form={form}
      onFinish={onFinish}
      scrollToFirstError
      initialValues={{
        // default set as true
        // INPORTANT: do not remove this
        // otherwise primary(required by resolver input) will be undefined if untouched
        primary: true,
      }}
    >
      {!!params?.id && (
        <Form.Item label="Id" name="id" rules={[{ required: true }]}>
          <Input value={params.id} disabled />
        </Form.Item>
      )}
      <Form.Item
        label="First Name"
        name={['address', 'firstName']}
        rules={[{ required: true, message: 'Please enter your first name.' }]}
      >
        <Input autoComplete="cc-given-name" />
      </Form.Item>
      <Form.Item
        label="Last Name"
        name={['address', 'lastName']}
        rules={[{ required: true, message: 'Please enter your last name.' }]}
      >
        <Input autoComplete="cc-family-name" />
      </Form.Item>
      <Form.Item
        label="Address"
        name={['address', 'place']}
        rules={[{ required: true, message: 'Please enter your address.' }]}
      >
        <AddressInput />
      </Form.Item>
      <Form.Item label="Unit/Apartment number" name={['address', 'unit']}>
        <Input autoComplete="new-unit" />
      </Form.Item>
      <Form.Item label="Delivery instruction" name={['address', 'instruction']}>
        <TextArea
          placeholder="Enter delivery instruction, door code, etc..."
          autoSize={{ minRows: 3, maxRows: 6 }}
        />
      </Form.Item>
      <Form.Item
        label={
          <span>
            Set as primary address
            <Tooltip title="The gifted orders will be send to this address by default.">
              <QuestionCircleOutlined className={styles.question} />
            </Tooltip>
          </span>
        }
        valuePropName="checked"
        name="primary"
      >
        <Checkbox />
      </Form.Item>
      <Button
        className={styles.submitButton}
        type="primary"
        htmlType="submit"
        loading={isSubmitting}
      >
        Save Address
      </Button>
    </Form>
  );
});
