import React from 'react';

import {
  ApolloClient,
  ApolloLink,
  createHttpLink,
  from,
  InMemoryCache,
  NormalizedCacheObject,
  ServerParseError,
} from '@apollo/client';
import { onError } from '@apollo/client/link/error';
import { setContext } from '@apollo/client/link/context';

import { omitDeep } from '@utils';

const { useMemo, useState } = React;
function removeTypenames<T>(variables: T): T {
  return omitDeep(variables, ['__typename']);
}

export function useInitializeApolloClient(token: string) {
  const [sessionExpired, setSessionExpired] = useState(false);
  const apolloClient = useMemo<ApolloClient<NormalizedCacheObject>>(() => {
    if (!token) {
      return null;
    }

    const errorLink = onError(({ networkError }) => {
      if ((networkError as ServerParseError)?.statusCode === 401) {
        // this might trigger an infinite reload, ask the user to log back in instead
        // window.location.reload();
        setSessionExpired(true);
      }
    });
    const httpLink = createHttpLink({ uri: '/graphql_creator' }) as any;
    const removeTypenamesLink = new ApolloLink((operation, forward) => {
      operation.variables = removeTypenames(operation.variables);

      return forward(operation);
    });
    const authLink = setContext((_, { headers }) => ({
      headers: {
        ...headers,
        authorization: `Bearer ${token}`,
      },
    }));

    const client = new ApolloClient({
      link: from([authLink, errorLink, removeTypenamesLink, httpLink]),
      cache: new InMemoryCache({
        addTypename: true,
      }),
    });

    return client;
  }, [token]);

  return {
    sessionExpired,
    apolloClient,
  };
}
