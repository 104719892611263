import React from 'react';
import cx from 'classnames';
import { compact, isEmpty, map, mapValues } from 'lodash';

import { Button } from 'antd';
import { SendTermPanel } from '@components';
import { CheckIcon } from '@customIcons';

import {
  ICollabTermData,
  IProgramCollab,
  WorkflowEvent,
  WorkflowTermStageCheckInParams,
} from '@types';
import { useWorkflowCheckIn, useWorkflowPreCheckIn } from '@hooks';

import styles from './SendTermButton.module.scss';

interface IProps {
  collab: IProgramCollab;

  isPreCheckIn?: boolean;
  className?: string;
}

const { useState, useMemo, useCallback } = React;

/**
 * @type {React.FC}
 */
export const SendTermButton: React.FC<React.PropsWithChildren<IProps>> = React.memo(
  ({ collab, isPreCheckIn = false, className }) => {
    const [errorMessage, setErrorMessage] = useState('');
    const [showModal, setShowModal] = useState(false);
    const { workflowCheckIn, loading: checkingIn } = useWorkflowCheckIn();
    const { workflowPreCheckIn, loading: preCheckingIn } = useWorkflowPreCheckIn();

    const loading = useMemo(() => checkingIn || preCheckingIn, [checkingIn, preCheckingIn]);
    const alreadyPrefilled = useMemo(() => {
      return isPreCheckIn && !isEmpty(collab.workflowItem.prefilledData.term);
    }, [isPreCheckIn, collab]);
    const buttonLabel = useMemo(() => {
      if (alreadyPrefilled) {
        return 'Terms Sent';
      }

      return 'Send Terms';
    }, [alreadyPrefilled]);
    const buttonType = useMemo(() => {
      if (!isPreCheckIn) {
        return 'primary';
      }

      if (!alreadyPrefilled) {
        return 'link';
      }

      return 'text';
    }, [isPreCheckIn, alreadyPrefilled]);
    const stopPropagation = useCallback((event: React.MouseEvent) => {
      // NOTE: do not preventDefault(), otherwise form submission wont work
      // do not open detail panel
      event.stopPropagation();
    }, []);
    const openModal = useCallback(() => setShowModal(true), []);
    const closeModal = useCallback(() => setShowModal(false), []);
    const handleSubmit = useCallback(
      async (values: ICollabTermData) => {
        const { requirements } = values.contentReview || {};

        const params: WorkflowTermStageCheckInParams = {
          termData: values,
        };
        if (!isEmpty(requirements)) {
          params.termData.contentReview.requirements = map(requirements, (r) => ({
            ...r,
            guidelines: mapValues(r.guidelines, compact),
          }));
        }

        setErrorMessage('');

        try {
          if (isPreCheckIn) {
            await workflowPreCheckIn({
              variables: {
                collabId: collab.id,
                params: {
                  term: params,
                },
              },
            });
          } else {
            await workflowCheckIn({
              variables: {
                collabId: collab.id,
                event: WorkflowEvent.TermStageCreatorProposeTerm,
                params: {
                  term: params,
                },
              },
            });
          }

          setShowModal(false);
        } catch (err) {
          console.log(err);
          setErrorMessage((err as Error)?.message);
        }
      },
      [collab, isPreCheckIn, workflowCheckIn, workflowPreCheckIn],
    );

    return (
      <div onClick={stopPropagation}>
        <Button
          className={cx(styles.SendTermButton, className, {
            [styles.prefilled]: alreadyPrefilled,
          })}
          shape="round"
          type={buttonType}
          onClick={openModal}
          icon={alreadyPrefilled && <CheckIcon />}
        >
          {buttonLabel}
        </Button>
        <SendTermPanel
          program={collab.program}
          accountResources={collab.member.accountResources}
          show={showModal}
          onRequestClose={closeModal}
          loading={loading}
          onSubmit={handleSubmit}
          errorMessage={errorMessage}
        />
      </div>
    );
  },
);
