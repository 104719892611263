import React from 'react';
import cx from 'classnames';
import { startCase } from 'lodash';

import { Tag } from 'antd';
import {
  CheckOutlined,
  CloseOutlined,
  FieldTimeOutlined,
  InfoCircleFilled,
} from '@ant-design/icons';

import { PostTrackingStatus } from '@types';

import styles from './PostTrackingStatusTag.module.scss';

interface IProps {
  status: PostTrackingStatus;

  className?: string;
}

const { useMemo } = React;

/**
 * @type {React.FC}
 */
export const PostTrackingStatusTag: React.FC<React.PropsWithChildren<IProps>> = React.memo(
  ({ status, className }) => {
    const color = useMemo(() => {
      if (status === PostTrackingStatus.PendingSubmit) {
        return 'gray';
      } else if (status === PostTrackingStatus.PendingVerify) {
        return 'blue';
      } else if (status === PostTrackingStatus.Rejected) {
        return 'red';
      }

      // approved
      return 'green';
    }, [status]);
    const icon = useMemo(() => {
      if (status === PostTrackingStatus.PendingSubmit) {
        return <FieldTimeOutlined />;
      } else if (status === PostTrackingStatus.PendingVerify) {
        return <InfoCircleFilled />;
      } else if (status === PostTrackingStatus.Rejected) {
        return <CloseOutlined />;
      }

      // approved and approved with comments
      return <CheckOutlined />;
    }, [status]);

    return (
      <Tag className={cx(styles.PostTrackingStatusTag, className)} color={color}>
        {icon}
        {startCase(status)}
      </Tag>
    );
  },
);
