import React from 'react';
import cx from 'classnames';

import { PhotoFilmIcon } from '@customIcons';

import styles from './ContentReviewStageIcon.module.scss';

interface IProps {
  className?: string;
}

/**
 * @type {React.FC}
 */
export const ContentReviewStageIcon: React.FC<React.PropsWithChildren<IProps>> = React.memo(
  ({ className }) => {
    return (
      <div className={cx(styles.ContentReviewStageIcon, className)}>
        <PhotoFilmIcon className={styles.icon} />
      </div>
    );
  },
);
