import { gql } from '@apollo/client';

export const WORKFLOW_ITEM_FRAGMENT = gql`
  fragment WorkflowItem on WorkflowItem {
    id
    status
    stage
    state
    info {
      enterStageTs
      error
      errorMessage
    }
    data {
      prospect {
        source
        createdTs
        acceptedTs
        rejectedTs
      }
      email {
        managerId
        threadId
        sentTs
      }
      contract {
        sentTs
        signedTs
      }
      infoUpdate {
        sentTs
        updatedTs
        updatedInfo {
          info {
            firstName
            lastName
            phone
          }
          address {
            firstName
            lastName
            place
            unit
            instruction
          }
          fields
        }
      }
      productFulfillment {
        preferredProducts {
          productId
          variantId
          title
          quantity
          price
          image
        }
        products {
          productId
          variantId
          title
          quantity
          price
          image
        }
        pickProductsTs
        skipPickingProductsTs
        createOrderTs
        fulfillOrderTs
        orderDeliveredTs
      }
      salesTracking {
        linkCreatedTs
      }
    }
    prefilledData {
      term
      infoUpdate
      productFulfillment
    }
    createdDate
    updatedDate
  }
`;
