export const resourceEn = {
  translation: {
    ACCEPT: 'Accept',
    ACCOUNT: 'Account',
    ACCOUNT_SECURITY: 'Account Security',
    BILLING_PLURAL: 'Billings',
    CAMPAIGN: 'Campaign',
    CAMPAIGN_PLURAL: 'Campaigns',
    COLLABORATION_PLURAL: 'Collaborations',
    DELETE: 'Delete',
    DISCOVER: 'Discover',
    EDIT: 'Edit',
    FORECASTED_COST: 'Forecasted Cost',
    INVITE: 'Invite',
    LANGUAGE: 'Language',
    LOG_OUT: 'Log out',
    MY_LISTS: 'My Lists',
    NOTIFICATION_PLURAL: 'Notifications',
    PASSWORD: 'Password',
    PAYMENT: 'Payment',
    PAYMENT_HISTORY: 'Payment History',
    PAYMENT_METHOD: 'Payment Method',
    PROFILE: 'Profile',
    REJECT: 'Reject',
    SAVE: 'Save',
    SEARCH: 'Search',
    SET_AS_DEFAULT_PAYMENT_METHOD: 'Set as default payment method',
    SETTING_PLURAL: 'Settings',
    SUBSCRIPTION: 'Subscription',
    SWITCH_BRAND: 'Routes brand',
    TERMINATE: 'Terminate',
  },
};
