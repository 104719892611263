import React from 'react';

import {
  ITrackingConversion,
  TrackingConversionFilters,
  EntitySortBy,
  SalesTrackingLastDate,
} from '@types';
import { useGetTrackingConversions, useGetTrackingConversionCount } from '@hooks';

export interface ITrackingConversionsContextProps {
  collabId: string;
}
interface ITrackingConversionsContext {
  collabId: string;

  loading: boolean;
  count: number;
  orders: ITrackingConversion[];
  refetch(): void;

  limit: number;
  setLimit(limit: number): void;
  offset: number;
  setOffset(offset: number): void;

  sortBy: EntitySortBy;
  setSortBy(sortBy: EntitySortBy): void;

  filters: TrackingConversionFilters;
  setFilters(filters: TrackingConversionFilters): void;
}

const { useContext, useEffect, useMemo, useCallback, useState } = React;
const DEFAULT_OFFSET = 0;
const DEFAULT_LIMIT = 20;

const TrackingConversionsContext = React.createContext<ITrackingConversionsContext>(null);
export const useTrackingConversionsContext = () => useContext(TrackingConversionsContext);
export const TrackingConversionsContextProvider: React.FC<
  React.PropsWithChildren<React.PropsWithChildren<ITrackingConversionsContextProps>>
> = React.memo(({ children, collabId }) => {
  const [offset, setOffset] = useState(DEFAULT_OFFSET);
  const [limit, setLimit] = useState(DEFAULT_LIMIT);
  const [filters, setFilters] = useState<TrackingConversionFilters>({
    collabId,
    lastDays: SalesTrackingLastDate.PastWeek,
  });
  const [sortBy, setSortBy] = useState<EntitySortBy>(EntitySortBy.Newest);

  const combinedFilters: TrackingConversionFilters = useMemo(() => {
    return {
      ...filters,
      collabId,
    };
  }, [collabId, filters]);

  const {
    orders,
    loading: loadingOrders,
    refetch: refetchOrders,
  } = useGetTrackingConversions({
    variables: {
      filters: combinedFilters,
      sortBy,
      offset,
      limit,
      includeInfo: true,
    },
  });
  const {
    count,
    loading: loadingCount,
    refetch: refetchCount,
  } = useGetTrackingConversionCount({
    variables: {
      filters: combinedFilters,
    },
  });

  const loading = useMemo(() => loadingOrders || loadingCount, [loadingOrders, loadingCount]);
  const refetch = useCallback(() => {
    // reset selection, mostly after taking stage actions

    refetchOrders();
    refetchCount();
  }, [refetchOrders, refetchCount]);

  // reset offset if it's exceeded total count
  useEffect(() => {
    if (!loadingCount && count <= offset) {
      setOffset(0);
    }
  }, [count, loadingCount, offset]);
  useEffect(() => {
    refetchOrders({
      limit,
      offset,
      sortBy,
      filters: combinedFilters,
    });
    refetchCount({
      filters: combinedFilters,
    });
  }, [combinedFilters, refetchOrders, refetchCount, sortBy, limit, offset]);

  return (
    <TrackingConversionsContext.Provider
      value={{
        collabId,

        loading,
        count,
        orders,
        refetch,

        limit,
        setLimit,
        offset,
        setOffset,

        sortBy,
        setSortBy,

        filters,
        setFilters,
      }}
    >
      {children}
    </TrackingConversionsContext.Provider>
  );
});
