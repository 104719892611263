import { gql } from '@apollo/client';

export const SHARED_DISCOUNT_CODE_FRAGMENT = gql`
  fragment SharedDiscountCode on SharedDiscountCode {
    id
    brandResourceId
    createdDate
    updatedDate
  }
`;
