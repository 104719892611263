import React from 'react';
import cx from 'classnames';
import { map, isEmpty } from 'lodash';

import { Timeline, Empty } from 'antd';
import { LoadSpinner } from '@components';
import { ActivityItem } from './ActivityItem';

import { IProgramCollabDetail } from '@types';
import { useGetProgramCollabActivities } from '@hooks';
import { getNextStep } from './utils';

import styles from './CollabActivities.module.scss';

interface IProps {
  collab: IProgramCollabDetail;

  className?: string;
}

const { useEffect, useRef } = React;

/**
 * @type {React.FC}
 */
export const CollabActivities: React.FC<React.PropsWithChildren<IProps>> = React.memo(
  ({ collab, className }) => {
    const ref = useRef<HTMLDivElement>(null);
    const { loading, activities } = useGetProgramCollabActivities({
      variables: {
        collabId: collab.id,
      },
    });

    console.log(loading, activities);

    useEffect(() => {
      ref.current.scrollIntoView({
        behavior: 'smooth',
        block: 'start',
      });
    }, [ref, activities]);

    return (
      <div className={cx(styles.CollabActivities, className)}>
        {loading && <LoadSpinner />}
        {!loading && isEmpty(activities) && (
          <Empty description="Activities for this collab live here." />
        )}
        {!loading && !isEmpty(activities) && (
          <Timeline pending={getNextStep(collab)}>
            {map(activities, (activity) => (
              <Timeline.Item key={activity.id}>
                <ActivityItem activity={activity} />
              </Timeline.Item>
            ))}
          </Timeline>
        )}
        <div ref={ref} />
      </div>
    );
  },
);
