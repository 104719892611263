import { gql } from '@apollo/client';

import { CREATOR_ADDRESS_FRAGMENT } from './fragments';

//========================================================
//= Queries
//========================================================
export const GET_ADDRESSES_QUERY = gql`
  query GetAddresses {
    addresses: creatorGetAddresses {
      ...CreatorAddress
    }
  }
  ${CREATOR_ADDRESS_FRAGMENT}
`;

//========================================================
//= Mutations
//========================================================
export const SAVE_ADDRESS_MUTATION = gql`
  mutation SaveAddress($params: SaveCreatorAddressParams!) {
    address: creatorSaveAddress(params: $params) {
      ...CreatorAddress
    }
  }
  ${CREATOR_ADDRESS_FRAGMENT}
`;

export const SET_ADDRESS_AS_PRIMARY_MUTATION = gql`
  mutation SetAddressAsPrimary($id: String!) {
    succeeded: creatorSetAddressAsPrimary(id: $id)
  }
`;

export const DELETE_ADDRESS_MUTATION = gql`
  mutation DeleteAddress($id: String!) {
    succeeded: creatorDeleteAddressById(id: $id)
  }
`;
