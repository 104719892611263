import React from 'react';
import cx from 'classnames';
import { map, debounce, first, slice, split, sortBy, each, isEmpty, noop } from 'lodash';

import { Input, Dropdown, MenuProps } from 'antd';
import { SearchOutlined, EnvironmentFilled } from '@ant-design/icons';
import { AddressMap } from './AddressMap';

import type { SizeType } from 'antd/lib/config-provider/SizeContext';
import { IGooglePlace } from './types';
import { parseRawAddress, useGooglePlacesAutocomplete } from './utils';

import styles from './AddressInput.module.scss';

interface IAddressOption {
  placeId: string;
  description: string;
  matchedSubstrings: Array<{
    offset: number;
    length: number;
  }>;
}
interface IProps {
  value?: IGooglePlace;
  onChange?(address: IGooglePlace): void;
  size?: SizeType;
  autoFocus?: boolean;
  disabled?: boolean;
  onKeyDown?(e: React.KeyboardEvent<HTMLInputElement>): void;
  className?: string;
}

const { useState, useEffect, useCallback, useMemo } = React;
function formatAddress(
  description: string,
  matchedSubstrings: Array<{
    offset: number;
    length: number;
  }>,
): React.ReactNode {
  const sortedMatches = sortBy(matchedSubstrings, (match) => match.offset);
  const parts = split(description, ',');
  const main = first(parts);
  const sub = slice(parts, 1).join(',');
  let start = 0;
  const result = [];

  each(sortedMatches, (match) => {
    if (match.offset > main.length - 1) {
      return;
    }

    if (start < match.offset) {
      result.push(<span>{main.substring(start, match.offset)}</span>);
    }

    const end = Math.min(match.offset + match.length, main.length);
    result.push(<b>{main.substring(match.offset, end)}</b>);

    start = end;
  });

  if (start < main.length) {
    result.push(<span>{main.substring(start)}</span>);
  }

  return (
    <span className={styles.address}>
      <EnvironmentFilled className={styles.icon} />
      {React.Children.toArray(result)}
      <span className={styles.sub}>{sub}</span>
    </span>
  );
}

/**
 * @type {React.FC}
 */
export const AddressInput: React.FC<React.PropsWithChildren<IProps>> = React.memo(
  ({
    value: initialValue = null,
    onChange = noop,
    size,
    autoFocus,
    disabled,
    onKeyDown = noop,
    className,
  }) => {
    const [getPlaces, getPlaceDetails] = useGooglePlacesAutocomplete();
    const [menuVisible, setMenuVisible] = useState(false);
    const [value, setValue] = useState(initialValue?.fullAddress || '');
    const [options, setOptions] = useState<IAddressOption[]>([]);
    const [selectedAddress, setSelectedAddress] = useState<IGooglePlace>(null);

    const updateAddressOptions = useMemo(
      () =>
        debounce(async (value: string) => {
          const rawOptions = await getPlaces({
            input: value,
          });

          const newOptions = map(rawOptions, (o) => ({
            placeId: o.place_id,
            description: o.description,
            matchedSubstrings: o.matched_substrings,
          }));
          setOptions(newOptions);
        }, 300),
      [getPlaces, setOptions],
    );
    const onOptionSelected = useCallback(
      async (option: IAddressOption) => {
        setValue(option.description);

        const place = await getPlaceDetails({
          placeId: option.placeId,
          fields: ['address_components', 'formatted_address', 'place_id'],
        });

        const address = parseRawAddress(place);
        setSelectedAddress(address);
        onChange(address);
      },
      [setValue, getPlaceDetails, onChange],
    );
    const onInputFocus = useCallback(() => setMenuVisible(true), []);
    const onInputBlur = useCallback(() => setMenuVisible(false), []);

    // only set input value when value passed from props is address result
    useEffect(() => {
      if (initialValue?.fullAddress) {
        setValue(initialValue.fullAddress);

        updateAddressOptions(initialValue.fullAddress);
        setSelectedAddress(initialValue);
      }
    }, [setValue, updateAddressOptions, initialValue]);

    const addressMenuProps: MenuProps = useMemo(() => {
      return {
        className: styles.Menu,
        items: [
          ...map(options, (option) => {
            return {
              key: option.placeId,
              // NOTE: use onMouseDown to select option before input lose focus
              onMouseDown: () => onOptionSelected(option),
              label: formatAddress(option.description, option.matchedSubstrings),
            };
          }),
          isEmpty(options) && {
            key: 'empty',
            disabled: true,
            label: 'No addresses matched your search.',
          },
        ],
      };
    }, [options, onOptionSelected]);

    return (
      <div className={cx(styles.AddressInput, className)}>
        <Dropdown menu={addressMenuProps} open={menuVisible}>
          <Input
            className={styles.input}
            size={size}
            autoFocus={autoFocus}
            disabled={disabled}
            onKeyDown={onKeyDown}
            prefix={<SearchOutlined className={styles.icon} />}
            value={value}
            autoComplete="new-address"
            placeholder="Search address..."
            onChange={async (event) => {
              const newValue = event.target.value;
              setValue(newValue);

              // invalidate selected address whenever user makes a change
              // this ensures only the address selected from the options is valid
              onChange(null);
              setSelectedAddress(null);

              await updateAddressOptions(newValue);
            }}
            onFocus={onInputFocus}
            onBlur={onInputBlur}
          />
        </Dropdown>
        {selectedAddress && (
          <AddressMap className={styles.map} address={selectedAddress.fullAddress} />
        )}
      </div>
    );
  },
);
