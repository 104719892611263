import React from 'react';
import { useQuery, QueryHookOptions } from '@apollo/client';
import { isEmpty } from 'lodash';

import { message } from 'antd';

import { GET_MEMBER_FIELD_SCHEMAS_QUERY } from '@queries';
import { GetMemberFieldSchemasQuery, GetMemberFieldSchemasQueryVariables } from '@types';

type IOptions = QueryHookOptions<GetMemberFieldSchemasQuery, GetMemberFieldSchemasQueryVariables>;

const { useEffect, useMemo } = React;

export function useGetMemberFieldSchemas(options: IOptions = {}) {
  const {
    loading,
    data: { schemas = [] } = {},
    error,
    refetch,
  } = useQuery<GetMemberFieldSchemasQuery, GetMemberFieldSchemasQueryVariables>(
    GET_MEMBER_FIELD_SCHEMAS_QUERY,
    {
      ...options,
      skip: isEmpty(options.variables?.brandId),
      fetchPolicy: 'cache-and-network',
      nextFetchPolicy: 'cache-first',
      notifyOnNetworkStatusChange: true,
    },
  );

  const errorMessage = useMemo(() => error?.message, [error]);
  useEffect(() => {
    if (!loading && errorMessage) {
      message.error(errorMessage);
    }
  }, [loading, errorMessage]);

  return {
    loading,
    schemas,
    error,
    refetch,
  };
}
