import React from 'react';
import cx from 'classnames';

import {
  ProspectStageIcon,
  TermStageIcon,
  ContractStageIcon,
  EmailStageIcon,
  InfoUpdateStageIcon,
  ProductFulfillmentStageIcon,
  ContentReviewStageIcon,
  PostTrackingStageIcon,
  PaymentStageIcon,
  SalesTrackingStageIcon,
  PostbackTrackingStageIcon,
  CompletedStageIcon,
} from '@customIcons';

import { WorkflowStage } from '@types';

import styles from './WorkflowStageIcon.module.scss';

interface IIconProps {
  isSalesTracking?: boolean;
  className?: string;
}
interface IProps extends IIconProps {
  stage: WorkflowStage;
}

const { useMemo } = React;
const StageIcon: Record<WorkflowStage, React.FC<React.PropsWithChildren<IIconProps>>> = {
  [WorkflowStage.Prospect]: ProspectStageIcon,
  [WorkflowStage.Term]: TermStageIcon,
  [WorkflowStage.Email]: EmailStageIcon,
  [WorkflowStage.Contract]: ContractStageIcon,
  [WorkflowStage.InfoUpdate]: InfoUpdateStageIcon,
  [WorkflowStage.ProductFulfillment]: ProductFulfillmentStageIcon,
  [WorkflowStage.ContentReview]: ContentReviewStageIcon,
  [WorkflowStage.PostTracking]: PostTrackingStageIcon,
  [WorkflowStage.Payment]: PaymentStageIcon,
  [WorkflowStage.SalesTracking]: SalesTrackingStageIcon,
  [WorkflowStage.PostbackTracking]: PostbackTrackingStageIcon,
  [WorkflowStage.Completed]: CompletedStageIcon,
};

/**
 * @type {React.FC}
 */
export const WorkflowStageIcon: React.FC<React.PropsWithChildren<IProps>> = React.memo(
  ({ stage, isSalesTracking, className }) => {
    const Icon = useMemo(() => StageIcon[stage], [stage]);

    return (
      <Icon isSalesTracking={isSalesTracking} className={cx(styles.WorkflowStageIcon, className)} />
    );
  },
);
