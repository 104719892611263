import React from 'react';
import cx from 'classnames';

import { Tag, Tooltip } from 'antd';
import { QuestionCircleOutlined } from '@ant-design/icons';

import { IProgram, ProgramType, SalesTrackingIncentiveType } from '@types';
import { getSalesTrackingIncentiveDescription } from '@utils';

import styles from './ProgramIncentiveTag.module.scss';

interface IProps {
  program: Pick<IProgram, 'type' | 'workflowConfig'>;

  showTooltip?: boolean;
  className?: string;
}

const { useMemo } = React;

/**
 * @type {React.FC}
 */
export const ProgramIncentiveTag: React.FC<React.PropsWithChildren<IProps>> = React.memo(
  ({ program, showTooltip, className }) => {
    const isSalesTracking = useMemo(() => program.type === ProgramType.SalesTracking, [program]);
    const incentive = useMemo(
      () => program.workflowConfig.data.salesTracking?.incentive,
      [program],
    );
    const IncentiveTag = useMemo(() => {
      if (!isSalesTracking) {
        return (
          <Tag color="cyan" className={cx(styles.ProgramIncentiveTag, className)}>
            custom
          </Tag>
        );
      }

      return (
        <Tag color="gold" className={cx(styles.ProgramIncentiveTag, className)}>
          <div className={styles.content}>
            <span>
              {incentive.type === SalesTrackingIncentiveType.CPS
                ? `${incentive.value}%`
                : `$${incentive.value}`}
            </span>
            <span>{incentive.type}</span>
            {showTooltip && <QuestionCircleOutlined />}
          </div>
        </Tag>
      );
    }, [isSalesTracking, incentive, className, showTooltip]);

    if (isSalesTracking && incentive && showTooltip) {
      return (
        <Tooltip
          overlayClassName={styles.TooltipOverlay}
          title={getSalesTrackingIncentiveDescription(incentive)}
        >
          {IncentiveTag}
        </Tooltip>
      );
    }

    return <>{IncentiveTag}</>;
  },
);
