import React from 'react';
import cx from 'classnames';
import { compact, find, isEmpty, map, mapValues, noop } from 'lodash';

import { Drawer } from 'antd';
import { ErrorNotice, LoadSpinner, SendTermForm } from '@components';
import { ReviewTermForm } from './ReviewTermForm';

import {
  IProgramCollab,
  WorkflowEvent,
  ICollabTermData,
  WorkflowTermStageCheckInParams,
} from '@types';
import { useGetCollabTermById, useWorkflowCheckIn } from '@hooks';

import styles from './ReviewTermPanel.module.scss';

interface IProps {
  show: boolean;
  onRequestClose(): void;
  collab: IProgramCollab;

  onComplete?(): void;
  className?: string;
}

const { useState, useMemo, useCallback } = React;

/**
 * @type {React.FC}
 */
export const ReviewTermPanel: React.FC<React.PropsWithChildren<IProps>> = React.memo(
  ({ collab, show, onRequestClose, onComplete = noop, className }) => {
    const [errorMessage, setErrorMessage] = useState('');
    const [requestingChanges, setRequestingChanges] = useState(false);
    const { workflowCheckIn, loading: checkingIn } = useWorkflowCheckIn();

    const { loading, term, error } = useGetCollabTermById({
      variables: {
        id: collab.id,
      },
    });

    const iteration = useMemo(() => {
      return find(term?.iterations, (i) => i.id === term.latestIterationId);
    }, [term]);
    const initialValues: ICollabTermData = useMemo(() => {
      if (iteration) {
        return iteration.data;
      }

      return null;
    }, [iteration]);
    const onClosePanel = useCallback(() => {
      onRequestClose();
      setRequestingChanges(false);
    }, [onRequestClose]);
    const onSuccess = useCallback(() => {
      onComplete();
      onClosePanel();
    }, [onClosePanel, onComplete]);
    const onRequestingChanges = useCallback(() => setRequestingChanges(true), []);
    const onCancelRequestingChanges = useCallback(() => setRequestingChanges(false), []);
    const onRequestChanges = useCallback(
      async (values: ICollabTermData) => {
        const { requirements } = values.contentReview || {};

        const params: WorkflowTermStageCheckInParams = {
          termData: values,
        };
        if (!isEmpty(requirements)) {
          params.termData.contentReview.requirements = map(requirements, (r) => ({
            ...r,
            guidelines: mapValues(r.guidelines, compact),
          }));
        }

        setErrorMessage('');

        try {
          await workflowCheckIn({
            variables: {
              collabId: collab.id,
              event: WorkflowEvent.TermStageCreatorProposeTerm,
              params: {
                term: params,
              },
            },
          });

          onSuccess();
        } catch (err) {
          console.log(err);
          setErrorMessage((err as Error)?.message);
        }
      },
      [collab, workflowCheckIn, onSuccess],
    );

    return (
      <Drawer
        className={cx(styles.ReviewTermPanel, className)}
        open={show}
        onClose={onClosePanel}
        title="Review Terms"
        footer={null}
        width={700}
        destroyOnClose
      >
        {loading && <LoadSpinner className={styles.loading} />}
        {!loading && error && <ErrorNotice className={styles.notice} action={null} />}
        {!loading && !error && term && (
          <>
            {!requestingChanges && (
              <ReviewTermForm
                collab={collab}
                term={term}
                onRequestingChanges={onRequestingChanges}
                onComplete={onSuccess}
              />
            )}
            {requestingChanges && (
              <SendTermForm
                program={collab.program}
                accountResources={collab.member.accountResources}
                initialValues={initialValues}
                onSubmit={onRequestChanges}
                onCancel={onCancelRequestingChanges}
                isSubmitting={checkingIn}
                errorMessage={errorMessage}
              />
            )}
          </>
        )}
      </Drawer>
    );
  },
);
