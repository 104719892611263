import { useQuery, QueryHookOptions } from '@apollo/client';
import React from 'react';
import { isEmpty } from 'lodash';

import { message } from 'antd';

import { GET_SOCIAL_ACCOUNT_QUERY } from '@queries';
import { GetSocialAccountQuery, GetSocialAccountQueryVariables } from '@types';

type IOptions = QueryHookOptions<GetSocialAccountQuery, GetSocialAccountQueryVariables>;

const { useEffect, useMemo } = React;

export function useGetSocialAccountById(options: IOptions = {}) {
  const {
    loading,
    data: { account } = {},
    error,
    refetch,
  } = useQuery<GetSocialAccountQuery, GetSocialAccountQueryVariables>(GET_SOCIAL_ACCOUNT_QUERY, {
    ...options,
    // https://medium.com/@galen.corey/understanding-apollo-fetch-policies-705b5ad71980
    skip: isEmpty(options.variables?.id),
    fetchPolicy: 'cache-and-network',
  });

  const errorMessage = useMemo(() => error?.message, [error]);
  useEffect(() => {
    if (!loading && errorMessage) {
      message.error(errorMessage);
    }
  }, [loading, errorMessage]);

  return {
    loading,
    account,
    error,
    refetch,
  };
}
