import React from 'react';

import { LoadSpinner } from '@components';

import styles from './lazyLoadComponent.module.scss';

const { Suspense } = React;

export function lazyLoadComponent(Component: React.LazyExoticComponent<any>, props: any = {}) {
  return (
    <Suspense fallback={<LoadSpinner className={styles.Loading} />}>
      <Component {...props} />
    </Suspense>
  );
}
