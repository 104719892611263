import React from 'react';
import cx from 'classnames';

import { Button } from 'antd';
import { PaperPlaneIcon, PenToSquareIcon } from '@customIcons';
import { ApplicationPanel } from './ApplicationPanel';

import { IProgram } from '@types';
import { useApplyToProgram } from '@hooks';

import styles from './ApplyToProgramButton.module.scss';

interface IProps {
  program: IProgram;

  className?: string;
}

const { useState, useMemo, useCallback } = React;

/**
 * @type {React.FC}
 */
export const ApplyToProgramButton: React.FC<React.PropsWithChildren<IProps>> = React.memo(
  ({ program, className }) => {
    const [showModal, setShowModal] = useState(false);
    const { applyToProgram, loading: applying } = useApplyToProgram();

    const alreadyApplied = useMemo(() => !!program.collab, [program]);
    const requirePrefillInfo = useMemo(() => {
      return program.workflowConfig.data.prospect.prefillInfoWhenApply?.enabled;
    }, [program]);
    const icon = useMemo(() => {
      if (requirePrefillInfo) {
        return <PenToSquareIcon />;
      }

      return <PaperPlaneIcon />;
    }, [requirePrefillInfo]);
    const label = useMemo(() => {
      if (alreadyApplied) {
        return 'Already Applied';
      } else if (requirePrefillInfo) {
        return 'Fill Application';
      }

      return 'Apply';
    }, [alreadyApplied, requirePrefillInfo]);
    const onWrapperClick = useCallback((event: React.MouseEvent) => {
      // do not open detail panel
      event.stopPropagation();
    }, []);
    const onApplyToProgram = useCallback(async () => {
      await applyToProgram({
        variables: {
          id: program.id,
        },
      });
    }, [program, applyToProgram]);
    const onShowModal = useCallback(() => setShowModal(true), []);
    const onCloseModal = useCallback(() => setShowModal(false), []);
    const onButtonClick = useCallback(() => {
      if (requirePrefillInfo) {
        onShowModal();

        return;
      }

      onApplyToProgram();
    }, [requirePrefillInfo, onShowModal, onApplyToProgram]);

    return (
      <div onClick={onWrapperClick}>
        <Button
          className={cx(styles.ApplyToProgramButton, className)}
          shape="round"
          type="text"
          onClick={onButtonClick}
          icon={icon}
          loading={applying}
          disabled={alreadyApplied}
        >
          {label}
        </Button>
        <ApplicationPanel
          // this ensures we preserve the state across different programs
          key={program.id}
          show={showModal}
          onRequestClose={onCloseModal}
          program={program}
        />
      </div>
    );
  },
);
