import { gql } from '@apollo/client';

export const SHOPIFY_DISCOUNT_CODE_FRAGMENT = gql`
  fragment ShopifyDiscountCode on ShopifyDiscountCode {
    id
    code
    info {
      type
      value
      selection
      namingStrategy
      oncePerCustomer
      usageLimit
      entities {
        type
        id
        productId
        title
        image
        price
      }
    }
  }
`;
