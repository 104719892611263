import React from 'react';
import cx from 'classnames';
import { map } from 'lodash';

import { SocialPostCard } from '@components';

import { ISocialPost } from '@types';

import styles from './SocialPostGrid.module.scss';

interface IProps {
  posts: ISocialPost[];

  showDetail?: boolean;
  className?: string;
}

/**
 * @type {React.FC}
 */
export const SocialPostGrid: React.FC<React.PropsWithChildren<IProps>> = React.memo(
  ({ posts, showDetail, className }) => {
    return (
      <div className={cx(styles.SocialPostGrid, className)}>
        {map(posts, (post, index) => (
          <SocialPostCard key={index} className={styles.item} post={post} showDetail={showDetail} />
        ))}
      </div>
    );
  },
);
