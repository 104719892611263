import { map } from 'lodash';

import { SalesTrackingIncentiveType } from '@types';
import { getSalesTrackingIncentiveTitle } from './getSalesTrackingIncentiveTitle';

export const SalesTrackingIncentiveOptions = map(
  [
    SalesTrackingIncentiveType.CPC,
    SalesTrackingIncentiveType.CPA,
    SalesTrackingIncentiveType.CPS,
    SalesTrackingIncentiveType.CPAS,
  ],
  (type) => ({
    value: type,
    label: getSalesTrackingIncentiveTitle(type),
  }),
);
