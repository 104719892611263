import React from 'react';
import cx from 'classnames';
import { format } from 'date-fns';

import { Tooltip } from 'antd';
import { UserAvatar } from '@components';

import { formatDate } from '@utils';
import { ITextMessage } from '../context';

import styles from './TextMessageItem.module.scss';

interface IProps {
  message: ITextMessage;

  className?: string;
}

const { useMemo } = React;

/**
 * @type {React.FC}
 */
export const TextMessageItem: React.FC<React.PropsWithChildren<IProps>> = React.memo(
  ({ message, className }) => {
    const isFromManager = useMemo(() => {
      return message.from === 'manager';
    }, [message]);
    const senderAvatar = useMemo(() => {
      const { user } = message;
      if (!user) {
        return null;
      }

      return (
        <UserAvatar
          className={styles.avatar}
          size="small"
          name={message.user.name}
          picture={message.user.profileImage}
        />
      );
    }, [message]);

    return (
      <div
        className={cx(styles.TextMessageItem, className, {
          [styles.manager]: isFromManager,
        })}
      >
        {senderAvatar}
        <div className={styles.content}>
          <div className={styles.bubble}>
            <div className={styles.details}>{message.data}</div>
          </div>
          <div className={styles.extra}>
            <Tooltip
              className={styles.timestamp}
              title={format(message.timestamp.toDate(), 'PPpp')}
            >
              {formatDate(message.timestamp.toDate(), true)}
            </Tooltip>
          </div>
        </div>
      </div>
    );
  },
);
