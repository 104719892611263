import { gql } from '@apollo/client';

import { TRACKING_CONVERSION_FRAGMENT } from './fragments';

//========================================================
//= Fragments
//========================================================
const TRACKING_CONVERSION_DETAIL_FRAGMENT = gql`
  fragment TrackingConversionDetail on TrackingConversion {
    ...TrackingConversion
    info {
      shopifyOrder {
        currency
        totalItemPrice
        discounts
        subtotal
        shippingPrice
        amount
        customer
        shippingAddress
        lineItems
        discountCodeInfo
      }
    }
  }
  ${TRACKING_CONVERSION_FRAGMENT}
`;

//========================================================
//= Queries
//========================================================
export const GET_TRACKING_CONVERSIONS_QUERY = gql`
  query GetTrackingConversions(
    $limit: Int
    $offset: Int
    $sortBy: EntitySortBy
    $filters: TrackingConversionFilters
    $includeInfo: Boolean!
  ) {
    orders: creatorGetTrackingConversions(
      limit: $limit
      offset: $offset
      sortBy: $sortBy
      filters: $filters
    ) {
      ...TrackingConversionDetail
      info @include(if: $includeInfo) {
        shopifyOrder {
          currency
          totalItemPrice
          discounts
          subtotal
          shippingPrice
          amount

          customer
          shippingAddress
          lineItems
          discountCodeInfo
        }
      }
    }
  }
  ${TRACKING_CONVERSION_DETAIL_FRAGMENT}
`;

export const GET_TRACKING_CONVERSION_COUNT_QUERY = gql`
  query GetTrackingConversionCount($filters: TrackingConversionFilters) {
    count: creatorGetTrackingConversionCount(filters: $filters)
  }
`;
