import React from 'react';
import cx from 'classnames';

import { Typography } from 'antd';
import { UserAvatar } from '@components';

import { formatDate } from '@utils';
import { IProgramCollabActivity, ProgramCollabActivitySource } from '@types';
import { getActivityDescription } from './utils';

import styles from './ActivityItem.module.scss';

interface IProps {
  activity: IProgramCollabActivity;

  className?: string;
}

const { useMemo } = React;
const { Text } = Typography;

/**
 * @type {React.FC}
 */
export const ActivityItem: React.FC<React.PropsWithChildren<IProps>> = React.memo(
  ({ activity, className }) => {
    const userAvatar = useMemo(() => {
      const { source, collab, manager } = activity;

      if (source === ProgramCollabActivitySource.Manager) {
        if (!manager) {
          return null;
        }

        return (
          <UserAvatar
            className={styles.avatar}
            size="small"
            picture={manager.profileContent?.previewUrl}
            name={manager.name}
            showBoth
          />
        );
      } else if (source === ProgramCollabActivitySource.Member) {
        const { member } = collab;

        return (
          <UserAvatar
            className={styles.avatar}
            size="small"
            picture={`https://picsum.photos/120?id=${member.id}`}
            name={member.info.fullName}
            showBoth
          />
        );
      }

      return null;
    }, [activity]);

    return (
      <div className={cx(styles.ActivityItem, className)}>
        <div className={styles.title}>
          {userAvatar}
          {getActivityDescription(activity)}
        </div>
        <Text type="secondary">{formatDate(new Date(activity.createdDate))}</Text>
      </div>
    );
  },
);
