import React from 'react';
import cx from 'classnames';
import { noop } from 'lodash';

import { Button } from 'antd';
import { SubmitPostPanel } from './SubmitPostPanel';

import { IPostTrackingDetail, IProgramCollab, PostTrackingStatus } from '@types';

import styles from './SubmitPostButton.module.scss';

interface IProps {
  collab: IProgramCollab;
  tracking: IPostTrackingDetail;

  onComplete?(): void;
  className?: string;
}

const { useState, useMemo, useCallback } = React;

/**
 * @type {React.FC}
 */
export const SubmitPostButton: React.FC<React.PropsWithChildren<IProps>> = React.memo(
  ({ collab, tracking, onComplete = noop, className }) => {
    const [showModal, setShowModal] = useState(false);

    const postSubmitted = useMemo(() => !!tracking.post, [tracking]);
    const onShowModal = useCallback(() => setShowModal(true), []);
    const onCloseModal = useCallback(() => setShowModal(false), []);
    const onWrapperClick = useCallback((event: React.MouseEvent) => {
      event.stopPropagation();
    }, []);

    if (tracking.status === PostTrackingStatus.Verified) {
      return null;
    }

    return (
      <div className={cx(styles.SubmitPostButton, className)} onClick={onWrapperClick}>
        <Button type={postSubmitted ? 'default' : 'primary'} shape="round" onClick={onShowModal}>
          {postSubmitted ? 'Update' : 'Submit'} Post
        </Button>
        <SubmitPostPanel
          collab={collab}
          tracking={tracking}
          show={showModal}
          onRequestClose={onCloseModal}
          onComplete={onComplete}
        />
      </div>
    );
  },
);
