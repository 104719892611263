import React from 'react';
import { useMutation, MutationHookOptions, MutationFunctionOptions } from '@apollo/client';

import { message } from 'antd';

import { SUBSCRIBE_TO_NOTIFICATIONS_MUTATION } from '@queries';
import { SubscribeToNotificationsMutation } from '@types';

type IOptions = MutationHookOptions<SubscribeToNotificationsMutation>;
type IFunctionOptions = MutationFunctionOptions<SubscribeToNotificationsMutation>;

const { useCallback } = React;

export const useSubscribeToNotifications = (options: IOptions = {}) => {
  const [_subscribeToNotifications, { loading, error }] =
    useMutation<SubscribeToNotificationsMutation>(SUBSCRIBE_TO_NOTIFICATIONS_MUTATION, {
      ...options,
    });

  const subscribeToNotifications = useCallback(
    async (options: IFunctionOptions = {}) => {
      try {
        const result = await _subscribeToNotifications(options);

        return result;
      } catch (err) {
        message.error((err as Error).message);
      }
    },
    [_subscribeToNotifications],
  );

  return {
    subscribeToNotifications,
    loading,
    error,
  };
};
