import { gql } from '@apollo/client';

import {
  CONTENT_FRAGMENT,
  PROGRAM_FRAGMENT,
  PROGRAM_COLLAB_FRAGMENT,
  WORKFLOW_ITEM_FRAGMENT,
  MEMBER_FRAGMENT,
  PROGRAM_COLLAB_USER_STATUS_FRAGMENT,
} from './fragments';
import { BRAND_OVERVIEW_FRAGMENT } from './brands';

//========================================================
//= Fragments
//========================================================
export const PROGRAM_OVERVIEW_FRAGMENT = gql`
  fragment ProgramOverview on Program {
    ...Program
    brand {
      ...BrandOverview
    }
    cover {
      ...Content
    }
    collab {
      ...ProgramCollab
      workflowItem {
        ...WorkflowItem
      }
    }
  }
  ${BRAND_OVERVIEW_FRAGMENT}
  ${CONTENT_FRAGMENT}
  ${PROGRAM_FRAGMENT}
  ${PROGRAM_COLLAB_FRAGMENT}
  ${WORKFLOW_ITEM_FRAGMENT}
`;

const PROGRAM_DETAIL_FRAGMENT = gql`
  fragment ProgramDetail on Program {
    ...Program
    brand {
      ...BrandOverview
    }
    cover {
      ...Content
    }
    collab {
      ...ProgramCollab
      member {
        ...Member
      }
      userStatus {
        ...ProgramCollabUserStatus
      }
      workflowItem {
        ...WorkflowItem
      }
    }
  }
  ${BRAND_OVERVIEW_FRAGMENT}
  ${CONTENT_FRAGMENT}
  ${PROGRAM_FRAGMENT}
  ${PROGRAM_COLLAB_FRAGMENT}
  ${WORKFLOW_ITEM_FRAGMENT}
  ${MEMBER_FRAGMENT}
  ${PROGRAM_COLLAB_USER_STATUS_FRAGMENT}
`;

//========================================================
//= Queries
//========================================================
export const GET_PROGRAM_QUERY = gql`
  query GetProgram($id: String!) {
    program: creatorGetProgramById(id: $id) {
      ...ProgramDetail
    }
  }
  ${PROGRAM_DETAIL_FRAGMENT}
`;

export const GET_PROGRAMS_QUERY = gql`
  query GetPrograms($limit: Int, $offset: Int, $sortBy: ProgramSortBy, $filters: ProgramFilters) {
    programs: creatorGetPrograms(
      limit: $limit
      offset: $offset
      sortBy: $sortBy
      filters: $filters
    ) {
      ...ProgramOverview
    }
  }
  ${PROGRAM_OVERVIEW_FRAGMENT}
`;

export const GET_PROGRAM_COUNT_QUERY = gql`
  query GetProgramCount($filters: ProgramFilters) {
    count: creatorGetProgramCount(filters: $filters)
  }
`;

export const GET_PROGRAM_COUNT_FOR_ALL_CATEGORIES_QUERY = gql`
  query GetProgramCountForAllCategories {
    result: creatorGetProgramCountForAllCategories
  }
`;

export const GET_ACTION_REQUIRED_COLLAB_COUNT_QUERY = gql`
  query GetActionRequiredCollabCount {
    result: creatorGetActionRequiredCollabCount
  }
`;

export const APPLY_TO_PROGRAM_MUTATION = gql`
  mutation ApplyToProgram($id: String!, $params: PrefillApplicationParams) {
    program: creatorApplyToProgram(id: $id, params: $params) {
      ...ProgramDetail
    }
  }
  ${PROGRAM_DETAIL_FRAGMENT}
`;
