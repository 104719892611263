import React from 'react';
import cx from 'classnames';

import { Button } from 'antd';
import { FileSignatureIcon } from '@customIcons';
import { ViewTermPanel } from './ViewTermPanel';

import { IProgramCollab } from '@types';

import styles from './ViewTermButton.module.scss';

interface IProps {
  collab: IProgramCollab;

  className?: string;
}

const { useState, useCallback } = React;

/**
 * @type {React.FC}
 */
export const ViewTermButton: React.FC<React.PropsWithChildren<IProps>> = React.memo(
  ({ collab, className }) => {
    const [showModal, setShowModal] = useState(false);

    const onWrapperClick = useCallback((event: React.MouseEvent) => {
      // NOTE: do not preventDefault(), otherwise form submission wont work
      // do not open detail panel
      event.stopPropagation();
    }, []);
    const openModal = useCallback(() => setShowModal(true), []);
    const closeModal = useCallback(() => setShowModal(false), []);

    return (
      <div onClick={onWrapperClick}>
        <Button
          className={cx(styles.ViewTermButton, className)}
          shape="round"
          icon={<FileSignatureIcon />}
          onClick={openModal}
        >
          View Terms
        </Button>
        <ViewTermPanel collabId={collab.id} show={showModal} onRequestClose={closeModal} />
      </div>
    );
  },
);
