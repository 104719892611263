import { gql } from '@apollo/client';

export const PROGRAM_COLLAB_ORDER_FRAGMENT = gql`
  fragment ProgramCollabOrder on ProgramCollabOrder {
    id
    orderId
    trackingId
    resourceId
    createdDate
    updatedDate
  }
`;
