import React from 'react';
import { useMutation, MutationHookOptions, MutationFunctionOptions } from '@apollo/client';

import { message } from 'antd';

import { MARK_NOTIFICATION_READ_MUTATION } from '@queries';
import { MarkNotificationReadMutation, MarkNotificationReadMutationVariables } from '@types';

type IOptions = MutationHookOptions<
  MarkNotificationReadMutation,
  MarkNotificationReadMutationVariables
>;
type IFunctionOptions = MutationFunctionOptions<
  MarkNotificationReadMutation,
  MarkNotificationReadMutationVariables
>;

const { useCallback } = React;

export const useMarkNotificationRead = (options: IOptions = {}) => {
  const [_markNotificationRead, { loading, error }] = useMutation<
    MarkNotificationReadMutation,
    MarkNotificationReadMutationVariables
  >(MARK_NOTIFICATION_READ_MUTATION, {
    ...options,
  });

  const markNotificationRead = useCallback(
    async (options: IFunctionOptions = {}) => {
      try {
        const result = await _markNotificationRead(options);

        return result;
      } catch (err) {
        message.error((err as Error).message);
      }
    },
    [_markNotificationRead],
  );

  return {
    markNotificationRead,
    loading,
    error,
  };
};
