import { gql } from '@apollo/client';

import { PROFILE_FRAGMENT, CREATOR_BILLING_FRAGMENT, CREATOR_ADDRESS_FRAGMENT } from './fragments';
import { CREATOR_ACCOUNT_RESOURCE_DETAIL_FRAGMENT } from './creatorAccountResources';

//========================================================
//= Fragments
//========================================================
const CREATOR_PROFILE_FRAGMENT = gql`
  fragment CreatorProfile on Creator {
    ...Profile
    billing {
      ...CreatorBilling
    }
    accountResources {
      ...CreatorAccountResourceDetail
    }
    addresses {
      ...CreatorAddress
    }
  }
  ${PROFILE_FRAGMENT}
  ${CREATOR_BILLING_FRAGMENT}
  ${CREATOR_ACCOUNT_RESOURCE_DETAIL_FRAGMENT}
  ${CREATOR_ADDRESS_FRAGMENT}
`;

//========================================================
//= Queries
//========================================================
export const GET_PROFILE_QUERY = gql`
  query GetProfile {
    profile: creatorGetProfile {
      ...CreatorProfile
    }
  }
  ${CREATOR_PROFILE_FRAGMENT}
`;

export const UPDATE_PROFILE_MUTATION = gql`
  mutation UpdateProfile($params: UpdateCreatorParams!) {
    profile: creatorUpdateProfile(params: $params) {
      ...CreatorProfile
    }
  }
  ${CREATOR_PROFILE_FRAGMENT}
`;

export const UPDATE_LAST_NOTIFICATION_OPENED_MUTATION = gql`
  mutation UpdateLastNotificationOpened {
    succeeded: creatorUpdateLastNotificationOpened
  }
`;
