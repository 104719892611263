import React from 'react';
import { useMutation, MutationHookOptions, MutationFunctionOptions } from '@apollo/client';

import { WORKFLOW_CHECK_IN_MUTATION } from '@queries';
import { WorkflowCheckInMutation, WorkflowCheckInMutationVariables } from '@types';

type IOptions = MutationHookOptions<WorkflowCheckInMutation, WorkflowCheckInMutationVariables>;
type IFunctionOptions = MutationFunctionOptions<
  WorkflowCheckInMutation,
  WorkflowCheckInMutationVariables
>;

const { useCallback } = React;

export const useWorkflowCheckIn = (options: IOptions = {}) => {
  const [_workflowCheckIn, { loading, error }] = useMutation<
    WorkflowCheckInMutation,
    WorkflowCheckInMutationVariables
  >(WORKFLOW_CHECK_IN_MUTATION, options);

  const workflowCheckIn = useCallback(
    async (options: IFunctionOptions = {}) => {
      try {
        const result = await _workflowCheckIn(options);

        return result;
      } catch (err) {
        // todo: log this error on Rollbar/Datadog
        throw err;
      }
    },
    [_workflowCheckIn],
  );

  return {
    workflowCheckIn,
    loading,
    error,
  };
};
