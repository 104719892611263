import React from 'react';
import cx from 'classnames';

import { Avatar, AvatarProps } from 'antd';
import { VideoCameraFilled } from '@ant-design/icons';

import styles from './VideoIcon.module.scss';

interface IProps extends AvatarProps {}

/**
 * @type {React.FC}
 */
export const VideoIcon: React.FC<React.PropsWithChildren<IProps>> = React.memo((props) => {
  return (
    <Avatar
      {...props}
      className={cx(styles.VideoIcon, props.className)}
      icon={<VideoCameraFilled />}
    />
  );
});
