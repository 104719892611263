import React from 'react';
import cx from 'classnames';

import { Button, Tag } from 'antd';
import { CheckIcon, CircleExclamationIcon } from '@customIcons';
import { InfoUpdatePanel, WorkflowStageIcon } from '@components';

import { IProgram, WorkflowInfoUpdateStageCheckInParams, WorkflowStage } from '@types';

import styles from './InfoUpdateButton.module.scss';

interface IProps {
  program: IProgram;
  onSubmit(params: WorkflowInfoUpdateStageCheckInParams): void;

  required?: boolean;
  completed?: boolean;
  className?: string;
}

const { useState, useCallback } = React;

/**
 * @type {React.FC}
 */
export const InfoUpdateButton: React.FC<React.PropsWithChildren<IProps>> = React.memo(
  ({ program, onSubmit, required = false, completed = false, className }) => {
    const [showModal, setShowModal] = useState(false);

    const onWrapperClick = useCallback((event: React.MouseEvent) => {
      // NOTE: do not preventDefault(), otherwise form submission wont work
      // do not open detail panel
      event.stopPropagation();
    }, []);
    const onShowModal = useCallback(() => setShowModal(true), []);
    const onCloseModal = useCallback(() => setShowModal(false), []);
    const handleSubmit = useCallback(
      (params: WorkflowInfoUpdateStageCheckInParams) => {
        setShowModal(false);
        onSubmit(params);
      },
      [onSubmit],
    );

    return (
      <div onClick={onWrapperClick}>
        <Button
          className={cx(styles.InfoUpdateButton, className, {
            [styles.completed]: completed,
          })}
          onClick={onShowModal}
          icon={<WorkflowStageIcon stage={WorkflowStage.InfoUpdate} />}
          size="large"
        >
          Provide additional information
          <div className={styles.extra}>
            {completed && <Tag color="green">completed</Tag>}
            {!completed && required && <Tag color="yellow">required</Tag>}
            <div className={styles.status}>
              {completed && <CheckIcon className={styles.check} />}
              {!completed && <CircleExclamationIcon className={styles.alert} />}
            </div>
          </div>
        </Button>
        <InfoUpdatePanel
          show={showModal}
          onRequestClose={onCloseModal}
          program={program}
          onSubmit={handleSubmit}
        />
      </div>
    );
  },
);
