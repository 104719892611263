import React from 'react';
import cx from 'classnames';
import { isEmpty } from 'lodash';

import { Empty } from 'antd';
import { LoadSpinner } from '@components';
import { NewThread, ThreadList } from './components';

import {
  useCollabThreadsContext,
  CollabThreadsContextProvider,
  ICollabThreadsContextProps,
} from './context';

import styles from './CollabThreads.module.scss';

interface IProps {
  className?: string;
}

/**
 * @type {React.FC}
 */
const CollabThreadsInternal: React.FC<React.PropsWithChildren<IProps>> = React.memo(
  ({ className }) => {
    const { collab, loading, messages, refetch } = useCollabThreadsContext();

    return (
      <div className={cx(styles.CollabThreads, className)}>
        <div className={styles.main}>
          {!isEmpty(messages) && <ThreadList className={styles.list} />}
          {isEmpty(messages) && (
            <>
              {loading && <LoadSpinner className={styles.loading} />}
              {!loading && (
                <Empty
                  className={styles.empty}
                  description="Messages for this collaboration live here."
                />
              )}
            </>
          )}
        </div>
        <NewThread className={styles.newThread} collab={collab} onComplete={refetch} />
      </div>
    );
  },
);

export const CollabThreads = React.memo<IProps & ICollabThreadsContextProps>(
  ({ collab, ...rest }) => {
    return (
      <CollabThreadsContextProvider collab={collab}>
        <CollabThreadsInternal {...rest} />
      </CollabThreadsContextProvider>
    );
  },
);
