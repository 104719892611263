import React from 'react';
import { useQuery, QueryHookOptions } from '@apollo/client';

import { message } from 'antd';

import { GET_MESSAGE_THREADS_QUERY } from '@queries';
import { GetMessageThreadsQuery, GetMessageThreadsQueryVariables } from '@types';

type IOptions = QueryHookOptions<GetMessageThreadsQuery, GetMessageThreadsQueryVariables>;

const { useEffect, useMemo } = React;

export function useGetMessageThreads(options: IOptions = {}) {
  const {
    loading,
    data: { threads = [] } = {},
    error,
    refetch,
    fetchMore,
  } = useQuery<GetMessageThreadsQuery, GetMessageThreadsQueryVariables>(GET_MESSAGE_THREADS_QUERY, {
    ...options,
    fetchPolicy: 'cache-and-network',
    nextFetchPolicy: 'cache-first',
    notifyOnNetworkStatusChange: true,
  });

  const errorMessage = useMemo(() => error?.message, [error]);
  useEffect(() => {
    if (!loading && errorMessage) {
      message.error(errorMessage);
    }
  }, [loading, errorMessage]);

  return {
    loading,
    threads,
    error,
    refetch,
    fetchMore,
  };
}
