import React from 'react';
import { useMutation, MutationHookOptions, MutationFunctionOptions } from '@apollo/client';

import { message } from 'antd';

import { SYNC_CREATOR_ACCOUNT_RESOURCE_MUTATION } from '@queries';
import {
  SyncCreatorAccountResourceMutation,
  SyncCreatorAccountResourceMutationVariables,
} from '@types';

type IOptions = MutationHookOptions<
  SyncCreatorAccountResourceMutation,
  SyncCreatorAccountResourceMutationVariables
>;
type IFunctionOptions = MutationFunctionOptions<
  SyncCreatorAccountResourceMutation,
  SyncCreatorAccountResourceMutationVariables
>;

const { useCallback } = React;

export const useSyncCreatorAccountResource = (options: IOptions = {}) => {
  const [_syncCreatorAccountResource, { loading, error }] = useMutation<
    SyncCreatorAccountResourceMutation,
    SyncCreatorAccountResourceMutationVariables
  >(SYNC_CREATOR_ACCOUNT_RESOURCE_MUTATION, {
    ...options,
  });

  const syncCreatorAccountResource = useCallback(
    async (options: IFunctionOptions = {}) => {
      try {
        const result = await _syncCreatorAccountResource(options);

        return result;
      } catch (err) {
        message.error((err as Error).message);
      }
    },
    [_syncCreatorAccountResource],
  );

  return {
    syncCreatorAccountResource,
    loading,
    error,
  };
};
