import React from 'react';
import { toUpper } from 'lodash';

import { INotification, NotificationType } from '@types';
import { useGetNotifications, useSubscribeToNotifications, useUpdateProfile } from '@hooks';
import { useAppContext, useProfileContext } from '@context';
import { firebaseMessagingVapidKey } from './utils';

interface INotificationContext {
  loading: boolean;
  notifications: INotification[];
}

const { useContext, useState, useEffect, useCallback } = React;

const NotificationContext = React.createContext<INotificationContext>(null);
export const useNotificationContext = () => useContext(NotificationContext);
export const NotificationContextProvider: React.FC<
  React.PropsWithChildren<React.PropsWithChildren<{}>>
> = React.memo(({ children }) => {
  const { firebaseApp } = useAppContext();
  const { profile } = useProfileContext();
  const { updateProfile } = useUpdateProfile();
  const { subscribeToNotifications } = useSubscribeToNotifications();
  const [fcmToken, setFcmToken] = useState<string>(null);

  const { notifications, loading, refetch: refetchNotifications } = useGetNotifications();

  // handle messages
  const handleMessage = useCallback(
    (message) => {
      const payload = JSON.parse(message.data.payload);
      const notificationType = toUpper(payload.type) as NotificationType;
      console.log('notificationpayload', payload);

      // handles the message
      switch (notificationType) {
        default: {
          break;
        }
      }

      // always refetch notifications
      refetchNotifications();
    },
    [refetchNotifications],
  );
  const handleVisibilityChange = useCallback(() => {
    if (!document['webkitHidden']) {
      refetchNotifications();
    }
  }, [refetchNotifications]);
  // refetch notifications when tab becomes visible
  useEffect(() => {
    document.addEventListener('webkitvisibilitychange', handleVisibilityChange, false);

    return () => {
      document.removeEventListener('webkitvisibilitychange', handleVisibilityChange, false);
    };
  }, [handleVisibilityChange]);
  // register message handler and update token if needed
  useEffect(() => {
    if (!profile) {
      return;
    }

    const messaging = firebaseApp.messaging();
    messaging.onMessage((message) => {
      console.log('Message received. ', message);
      handleMessage(message);
    });

    updateToken();
    async function updateToken() {
      try {
        const currentToken = await messaging.getToken({
          vapidKey: firebaseMessagingVapidKey,
        });

        // save token on server
        if (currentToken !== profile.metadata.fcmToken) {
          console.log(`updating fcm token from ${profile.metadata.fcmToken} to ${currentToken}.`);
          await updateProfile({
            variables: {
              params: {
                metadata: {
                  fcmToken: currentToken,
                },
              },
            },
          });

          // only set the token after it's updated
          setFcmToken(currentToken);
        } else {
          // set token directly
          setFcmToken(currentToken);
        }
      } catch (err) {
        console.log('An error occurred while retrieving token. ', err);
      }
    }
  }, [profile, firebaseApp, updateProfile, handleMessage]);
  // subscribe to notification
  useEffect(() => {
    if (fcmToken) {
      subscribeToNotifications();
    }
  }, [fcmToken, subscribeToNotifications]);

  return (
    <NotificationContext.Provider
      value={{
        loading,
        notifications,
      }}
    >
      {children}
    </NotificationContext.Provider>
  );
});
