import React from 'react';
import cx from 'classnames';
import numeral from 'numeral';
import { format } from 'date-fns';

import { Tooltip, Card } from 'antd';
import { HeartOutlined, UserOutlined } from '@ant-design/icons';
import { Image, SocialAccountAvatar } from '@components';

import { ISocialAccount } from '@types';

import styles from './SocialAccountItem.module.scss';

export type TItemSize = 'default' | 'small';
interface IProps {
  account: ISocialAccount;

  size?: TItemSize;
  actions?: React.ReactNode[];
  className?: string;
}

const { useMemo } = React;
const { Meta } = Card;
const ItemSize: Record<TItemSize, number> = {
  default: 360,
  small: 280,
};

/**
 * @type {React.FC}
 */
export const SocialAccountItem: React.FC<React.PropsWithChildren<IProps>> = React.memo(
  ({ account, size = 'default', actions, className }) => {
    const showLastPostDate = useMemo(
      () => !!account.info.lastPostDate && size !== 'small',
      [account, size],
    );

    return (
      <>
        <Card
          className={cx(styles.SocialAccountItem, className)}
          hoverable
          style={{ width: ItemSize[size] }}
          cover={
            <Image
              className={styles.image}
              src={
                account.info.cover || `https://picsum.photos/${ItemSize[size]}/240?id=${account.id}`
              }
            />
          }
          actions={actions}
        >
          <Meta
            title={<SocialAccountAvatar className={styles.profile} account={account} />}
            description={
              <div className={styles.stats}>
                <Tooltip title={`${numeral(account.stats.followers).format('0,0')} followers`}>
                  <div className={styles.item}>
                    <UserOutlined />
                    <span className={styles.count}>
                      {numeral(account.stats.followers).format('0.[00] a')}
                    </span>
                  </div>
                </Tooltip>
                <Tooltip
                  title={`Average ${numeral(account.stats.engagement).format(
                    '0,0',
                  )} engagement for recent posts`}
                >
                  <div
                    className={cx(styles.item, {
                      [styles.hideDot]: !showLastPostDate,
                    })}
                  >
                    <HeartOutlined />
                    <span className={styles.count}>
                      {numeral(account.stats.engagement).format('0.[00] a')}
                    </span>
                  </div>
                </Tooltip>
                {showLastPostDate && (
                  <Tooltip
                    title={
                      <>
                        <div>Last post on</div>
                        <div>{format(new Date(account.info.lastPostDate), 'PPpp')}</div>
                      </>
                    }
                  >
                    {format(new Date(account.info.lastPostDate), 'MMM dd, yyyy')}
                  </Tooltip>
                )}
              </div>
            }
          />
        </Card>
      </>
    );
  },
);
