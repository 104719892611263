import React from 'react';
import numeral from 'numeral';
import cx from 'classnames';
import { toLower } from 'lodash';

import { Button, Modal, Typography } from 'antd';
import { CloseOutlined, CloudDownloadOutlined } from '@ant-design/icons';
import { FileContent, Image, Video } from '@components';

import { ContentType, IContent } from '@types';

import styles from './ContentModal.module.scss';

interface IProps {
  content: IContent;
  open: boolean;
  onRequestClose(): void;

  name?: string;
  className?: string;
}

const { useCallback } = React;
const { Title } = Typography;

/**
 * @type {React.FC}
 */
export const ContentModal: React.FC<IProps> = React.memo(
  ({ content, open, onRequestClose, name, className }) => {
    const onDownloadContent = useCallback(() => {
      const element = document.createElement('a');
      element.setAttribute('target', '_blank');
      element.setAttribute('href', content.url);
      element.setAttribute('download', content.originalName);
      element.style.display = 'none';
      document.body.appendChild(element);
      element.click();
      document.body.removeChild(element);
    }, [content]);

    if (!content) {
      return null;
    }

    return (
      <Modal
        className={cx(styles.ContentModal, className)}
        centered
        closable={false}
        footer={null}
        title={null}
        open={open}
        onCancel={onRequestClose}
        width="100%"
        destroyOnClose
      >
        <div className={styles.content}>
          <div className={styles.header}>
            <div className={styles.info}>
              <Title className={styles.name} level={5}>
                {name || content.originalName}
              </Title>
              <div className={styles.extra}>
                <div className={styles.type}>{toLower(content.type)}</div>
                <div className={styles.dot} />
                <div className={styles.size}>{numeral(content.size).format('0.[0] b')}</div>
              </div>
            </div>
            <div className={styles.actions}>
              <Button
                className={styles.action}
                type="text"
                size="large"
                shape="circle"
                icon={<CloudDownloadOutlined />}
                onClick={onDownloadContent}
              />
              <Button
                className={styles.action}
                type="text"
                size="large"
                shape="circle"
                icon={<CloseOutlined />}
                onClick={onRequestClose}
              />
            </div>
          </div>
          <div className={styles.body}>
            {content.type === ContentType.Image && (
              <Image className={styles.image} cover={false} round src={content.url} />
            )}
            {content.type === ContentType.Video && (
              <Video className={styles.video} controls round src={content.url} />
            )}
            {content.type === ContentType.Application && <FileContent className={styles.file} />}
          </div>
        </div>
      </Modal>
    );
  },
);
