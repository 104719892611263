import React from 'react';
import cx from 'classnames';
import { map } from 'lodash';

import { ProductGridItem } from './ProductGridItem';

import { usePickProductsContext } from '../context';

import styles from './ProductsGrid.module.scss';

interface IProps {
  className?: string;
}

/**
 * @type {React.FC}
 */
export const ProductsGrid: React.FC<React.PropsWithChildren<IProps>> = React.memo(
  ({ className }) => {
    const { products } = usePickProductsContext();

    return (
      <div className={cx(styles.ProductsGrid, className)}>
        {map(products, (product) => (
          <ProductGridItem key={product.id} className={styles.item} product={product} />
        ))}
      </div>
    );
  },
);
