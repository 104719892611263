import React from 'react';
import cx from 'classnames';
import { find } from 'lodash';

import { Button } from 'antd';
import { PhotoFilmIcon } from '@customIcons';
import { SelectContentReviewPanel } from './SelectContentReviewPanel';

import { IProgramCollab, ContentReviewStatus } from '@types';

import styles from './UpdateContentsButton.module.scss';

interface IProps {
  collab: IProgramCollab;

  className?: string;
}

const { useMemo, useState, useCallback } = React;

/**
 * @type {React.FC}
 */
export const UpdateContentsButton: React.FC<React.PropsWithChildren<IProps>> = React.memo(
  ({ collab, className }) => {
    const [showModal, setShowModal] = useState(false);

    const canUploadContents = useMemo(() => {
      return !!find(collab.contentReviews, (c) =>
        [ContentReviewStatus.PendingUpload, ContentReviewStatus.Rejected].includes(c.status),
      );
    }, [collab]);
    const stopPropagation = useCallback((event: React.MouseEvent) => {
      // NOTE: do not preventDefault(), otherwise form submission wont work
      // do not open detail panel
      event.stopPropagation();
    }, []);
    const onShowModal = useCallback(() => setShowModal(true), []);
    const onCloseModal = useCallback(() => setShowModal(false), []);

    return (
      <div onClick={stopPropagation}>
        <Button
          className={cx(styles.UpdateContentsButton, className)}
          icon={<PhotoFilmIcon className={styles.icon} />}
          shape="round"
          type="primary"
          onClick={onShowModal}
          disabled={!canUploadContents}
        >
          Update Contents
        </Button>
        <SelectContentReviewPanel show={showModal} onRequestClose={onCloseModal} collab={collab} />
      </div>
    );
  },
);
