import React from 'react';
import { isNumber } from 'lodash';

const { useEffect, useRef } = React;

/**
 * Unlike useInterval, this only schedules next tick after current one finishes.
 * It's perfect for long polling.
 * Set delay to null to stop the interval.
 */
export function useRelaxedInterval(callback: Function, delay: number) {
  const savedCallback = useRef<Function>();

  useEffect(() => {
    savedCallback.current = callback;
  }, [callback]);

  useEffect(() => {
    let running = false;
    let savedTimeout = null;

    const tick = async () => {
      if (running) {
        await savedCallback.current();
      }

      // schedule the next tick if it's till running
      if (running) {
        savedTimeout = setTimeout(tick, delay);
      }
    };

    const stop = () => {
      running = false;

      if (savedTimeout !== null) {
        clearTimeout(savedTimeout);
      }
    };

    if (isNumber(delay)) {
      running = true;
      savedTimeout = setTimeout(tick, delay);

      return stop;
    }
  }, [delay]);
}
