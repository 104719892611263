import React from 'react';
import cx from 'classnames';
import { format } from 'date-fns';
import { find, isEmpty, map } from 'lodash';

import { Badge } from 'antd';
import { SocialAccountIcon, UserAvatar } from '@components';

import { ContentGuidelineSections, isCustomPostType } from '@utils';
import { IContentRequirement, IProgramCollab } from '@types';

import styles from './ContentRequirement.module.scss';

interface IProps {
  collab: IProgramCollab;
  requirement: IContentRequirement;

  className?: string;
}

const { useMemo } = React;

/**
 * @type {React.FC}
 */
export const ContentRequirement: React.FC<IProps> = React.memo(
  ({ collab, requirement, className }) => {
    const { type, accountResourceId, dueDateTs, guidelines } = requirement;

    const resource = useMemo(() => {
      return find(collab.member.accountResources, (r) => r.id === accountResourceId);
    }, [collab, accountResourceId]);

    return (
      <div className={cx(styles.ContentRequirement, className)}>
        {!isCustomPostType(type) && (
          <div className={styles.entry}>
            <div className={styles.title}>Account</div>
            <div className={styles.body}>
              {resource && (
                <UserAvatar
                  className={styles.avatar}
                  size="small"
                  showBoth
                  name={resource.account.info.displayName}
                  icon={<SocialAccountIcon type={resource.account.type} />}
                />
              )}
              {!resource && '-'}
            </div>
          </div>
        )}
        <div className={styles.entry}>
          <div className={styles.title}>Due Date</div>
          <div className={styles.body}>{dueDateTs ? format(dueDateTs, 'PPpp') : '-'}</div>
        </div>
        {map(ContentGuidelineSections, (section) => {
          const data = guidelines[section.key];
          if (isEmpty(data)) {
            return null;
          }

          return (
            <div key={section.key} className={styles.entry}>
              <div className={styles.title}>{section.label}</div>
              {map(data, (text, index) => {
                return (
                  <div key={index} className={styles.body}>
                    <Badge className={styles.badge} color="gray" />
                    <span className={styles.text}>{text}</span>
                  </div>
                );
              })}
            </div>
          );
        })}
      </div>
    );
  },
);
