import axios from 'axios';

export const requestResetPassword = async (data: { email: string }): Promise<void> => {
  const resp = await axios.post(`/api/public/request_reset_password`, data, {
    headers: {
      ContentType: 'application/json',
    },
  });

  if (resp.status !== 200) {
    console.error(resp);
    throw new Error('Failed to send forget password email.');
  }

  return resp.data;
};
