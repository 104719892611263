import { gql } from '@apollo/client';

export const PROGRAM_COLLAB_FRAGMENT = gql`
  fragment ProgramCollab on ProgramCollab {
    id
    programId
    memberId
    status
    createdDate
    updatedDate
  }
`;
