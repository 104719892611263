import React from 'react';
import cx from 'classnames';
import { formatDistanceToNow } from 'date-fns';
import { noop, startCase } from 'lodash';

import { Drawer } from 'antd';
import { UserAvatar, SocialPostIcon, PostTrackingStatusTag, SocialPostDetail } from '@components';

import { IPostTrackingDetail } from '@types';

import styles from './PostTrackingPanel.module.scss';

interface IProps {
  show: boolean;
  onRequestClose(): void;
  tracking: IPostTrackingDetail;

  onComplete?(): void;
  className?: string;
}

/**
 * @type {React.FC}
 */
export const PostTrackingPanel: React.FC<React.PropsWithChildren<IProps>> = React.memo(
  ({ tracking, show, onRequestClose, onComplete = noop, className }) => {
    return (
      <Drawer
        className={cx(styles.PostTrackingPanel, className)}
        title={
          <div className={styles.title}>
            <UserAvatar
              className={styles.name}
              name={startCase(tracking.requirement.type)}
              icon={<SocialPostIcon type={tracking.requirement.type} size="small" />}
              showBoth
            />
            {tracking.requirement.dueDateTs && (
              <div className={styles.dueDate}>
                due in <b>{formatDistanceToNow(tracking.requirement.dueDateTs)}</b>
              </div>
            )}
            <PostTrackingStatusTag className={styles.status} status={tracking.status} />
          </div>
        }
        footer={null}
        open={show}
        onClose={onRequestClose}
        width={1200}
        destroyOnClose
      >
        {tracking.post && <SocialPostDetail className={styles.content} post={tracking.post} />}
      </Drawer>
    );
  },
);
