import React from 'react';
import cx from 'classnames';
import { format, formatDistanceToNow } from 'date-fns';
import { isEmpty, map, sortBy } from 'lodash';

import { Input, Tooltip, Comment, Empty } from 'antd';
import { LoadSpinner, UserAvatar } from '@components';
import { SystemMessage } from './SystemMessage';

import { IProgramCollabDetail } from '@types';
import { useCollabMessagingContext, CollabMessagingContextProvider } from './context';

import styles from './CollabChat.module.scss';

interface IProps {
  collab: IProgramCollabDetail;

  className?: string;
}

const { useState, useMemo, useEffect, useRef } = React;
const { TextArea } = Input;

/**
 * @type {React.FC}
 */
const CollabChatInternal: React.FC<React.PropsWithChildren<IProps>> = React.memo(
  ({ collab, className }) => {
    const { loading, messages, sendMessge } = useCollabMessagingContext();
    const [value, setValue] = useState<string>('');
    const ref = useRef<HTMLDivElement>(null);

    const sortedMessages = useMemo(
      () => sortBy(messages, (m) => m.timestamp?.toDate()),
      [messages],
    );

    useEffect(() => {
      ref.current.scrollIntoView({
        behavior: 'smooth',
        block: 'start',
      });
    }, [ref, messages]);

    return (
      <div className={cx(styles.CollabChat, className)}>
        <div className={styles.messageList}>
          {loading && <LoadSpinner />}
          {!loading && isEmpty(messages) && (
            <Empty description="Messages between you and the member live here." />
          )}
          {!loading &&
            !isEmpty(messages) &&
            map(sortedMessages, (message) => {
              if (message.type === 'text') {
                return (
                  <Comment
                    key={message.id}
                    className={styles.comment}
                    author={message.name}
                    avatar={<UserAvatar picture={message.profileImage} name={message.name} />}
                    content={message.payload.data}
                    datetime={
                      <Tooltip title={format(message.timestamp.toDate(), 'PPpp')}>
                        <span>{formatDistanceToNow(message.timestamp?.toDate())} ago</span>
                      </Tooltip>
                    }
                  />
                );
              } else if (message.type === 'event') {
                return <SystemMessage key={message.id} message={message} />;
              }
            })}
          <div ref={ref} />
        </div>
        <TextArea
          className={styles.composer}
          placeholder="Press enter to send message..."
          rows={4}
          value={value}
          onChange={(event) => setValue(event.target.value)}
          onPressEnter={(event) => {
            event.preventDefault();
            sendMessge(value);

            setValue('');
          }}
        />
      </div>
    );
  },
);

export const CollabChat = React.memo<IProps>((props) => {
  return (
    <CollabMessagingContextProvider collabId={props.collab.id}>
      <CollabChatInternal {...props} />
    </CollabMessagingContextProvider>
  );
});
