import { gql } from '@apollo/client';

export const CREATOR_ACCOUNT_RESOURCE_FRAGMENT = gql`
  fragment CreatorAccountResource on CreatorAccountResource {
    id
    creatorId
    accountId
    resourceId
    createdDate
    updatedDate
  }
`;
