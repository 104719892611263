import React from 'react';
import { useMutation, MutationHookOptions, MutationFunctionOptions } from '@apollo/client';

import { GENERATE_UPLOAD_URL_MUTATION } from '@queries';
import { GenerateUploadUrlMutation, GenerateUploadUrlMutationVariables } from '@types';

type IOptions = MutationHookOptions<GenerateUploadUrlMutation, GenerateUploadUrlMutationVariables>;
type IFunctionOptions = MutationFunctionOptions<
  GenerateUploadUrlMutation,
  GenerateUploadUrlMutationVariables
>;

const { useCallback } = React;

export const useGenerateUploadUrl = (options: IOptions = {}) => {
  const [_generateUploadUrl, { loading, error }] = useMutation<
    GenerateUploadUrlMutation,
    GenerateUploadUrlMutationVariables
  >(GENERATE_UPLOAD_URL_MUTATION, {
    ...options,
  });

  const generateUploadUrl = useCallback(
    async (options: IFunctionOptions = {}) => {
      try {
        const result = await _generateUploadUrl(options);

        return result;
      } catch (err) {
        throw err;
      }
    },
    [_generateUploadUrl],
  );

  return {
    generateUploadUrl,
    loading,
    error,
  };
};
