import React from 'react';
import cx from 'classnames';

import { Avatar, AvatarProps } from 'antd';

import gmailImage from '@assets/outlook_icon.png';

import styles from './OutlookIcon.module.scss';

interface IProps extends AvatarProps {}

/**
 * @type {React.FC}
 */
export const OutlookIcon: React.FC<React.PropsWithChildren<IProps>> = React.memo((props) => {
  return (
    <Avatar
      {...props}
      className={cx(styles.OutlookIcon, props.className)}
      src={gmailImage}
      shape="square"
    />
  );
});

OutlookIcon.defaultProps = {
  size: 18,
};
