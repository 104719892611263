import React from 'react';
import cx from 'classnames';
import { map, sortBy } from 'lodash';

import { Drawer } from 'antd';
import { ContentReviewItem } from './ContentReviewItem';

import { ContentReviewStatus, IProgramCollab } from '@types';

import styles from './SelectContentReviewPanel.module.scss';

interface IProps {
  show: boolean;
  onRequestClose(): void;
  collab: IProgramCollab;

  className?: string;
}

const { useMemo } = React;
const ReviewStatusOrder: ContentReviewStatus[] = [
  ContentReviewStatus.PendingUpload,
  ContentReviewStatus.PendingReview,
  ContentReviewStatus.Rejected,
  ContentReviewStatus.ApprovedWithComments,
  ContentReviewStatus.Approved,
];

/**
 * @type {React.FC}
 */
export const SelectContentReviewPanel: React.FC<React.PropsWithChildren<IProps>> = React.memo(
  ({ collab, show, onRequestClose, className }) => {
    const sortedReviews = useMemo(() => {
      return sortBy(collab.contentReviews, (c) => ReviewStatusOrder.indexOf(c.status));
    }, [collab]);

    return (
      <Drawer
        className={cx(styles.SelectContentReviewPanel, className)}
        open={show}
        onClose={onRequestClose}
        title="Update Contents"
        footer={null}
        width={700}
      >
        <div className={styles.content}>
          {map(sortedReviews, (review) => (
            <ContentReviewItem key={review.id} collab={collab} review={review} />
          ))}
        </div>
      </Drawer>
    );
  },
);
