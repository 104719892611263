import React from 'react';
import cx from 'classnames';

import { Tabs } from 'antd';
import { SalesTrackingMetrics, TrackingConversions } from '@components';

import { IProgramCollabDetail } from '@types';

import styles from './CollabSalesTracking.module.scss';

interface IProps {
  collab: IProgramCollabDetail;

  className?: string;
}
type TActiveTab = 'metrics' | 'orders';

const { useState, useCallback } = React;
const tabItems = [
  {
    label: 'Metrics',
    key: 'metrics',
  },
  {
    label: 'Orders',
    key: 'orders',
  },
];

/**
 * @type {React.FC}
 */
export const CollabSalesTracking: React.FC<React.PropsWithChildren<IProps>> = React.memo(
  ({ collab, className }) => {
    const [activeTab, setActiveTab] = useState<TActiveTab>('metrics');

    const onActiveTabChange = useCallback(
      (activeKey: string) => setActiveTab(activeKey as TActiveTab),
      [],
    );

    return (
      <div className={cx(styles.CollabSalesTracking, className)}>
        <Tabs
          className={styles.tabs}
          tabPosition="left"
          items={tabItems}
          activeKey={activeTab}
          onChange={onActiveTabChange}
        />
        <div className={styles.content}>
          {activeTab === 'metrics' && (
            <SalesTrackingMetrics
              filters={{
                programId: collab.programId,
              }}
            />
          )}
          {activeTab === 'orders' && <TrackingConversions collabId={collab.id} />}
        </div>
      </div>
    );
  },
);
