import React from 'react';
import { useQuery, QueryHookOptions } from '@apollo/client';

import { message } from 'antd';

import { GET_UNREAD_PROGRAM_COLLAB_COUNT_QUERY } from '@queries';
import { GetUnreadProgramCollabCountQuery } from '@types';

type IOptions = QueryHookOptions<GetUnreadProgramCollabCountQuery>;

const { useEffect, useMemo } = React;

export function useGetUnreadProgramCollabCount(options: IOptions = {}) {
  const {
    loading,
    data: { count } = {},
    error,
    refetch,
  } = useQuery<GetUnreadProgramCollabCountQuery>(GET_UNREAD_PROGRAM_COLLAB_COUNT_QUERY, {
    ...options,
    notifyOnNetworkStatusChange: true,
    fetchPolicy: 'cache-and-network',
  });

  const errorMessage = useMemo(() => error?.message, [error]);
  useEffect(() => {
    if (!loading && errorMessage) {
      message.error(errorMessage);
    }
  }, [loading, errorMessage]);

  return {
    loading,
    count,
    error,
    refetch,
  };
}
