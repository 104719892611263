import React from 'react';
import { isNil, get } from 'lodash';

const { useMemo, useCallback, useEffect, useState } = React;

export class GoogleAutocompleteError extends Error {
  protected status: string;

  constructor(
    message = 'Google Autocomplete Error.',
    status: google.maps.places.PlacesServiceStatus,
  ) {
    // Calling parent constructor of base Error class.
    super();

    // Sets the error properties.
    this.name = 'GoogleAutocompleteError';
    this.status = String(status);
    this.message = message;

    if (typeof Error.captureStackTrace === 'function') {
      // Capturing stack trace, excluding constructor call from it.
      Error.captureStackTrace(this, GoogleAutocompleteError);
    } else {
      this.stack = new Error().stack;
    }
  }
}

const getPlaces = (
  googleService: google.maps.places.AutocompleteService,
  options: google.maps.places.AutocompletionRequest,
): Promise<google.maps.places.AutocompletePrediction[]> => {
  return new Promise((resolve, reject) => {
    if (!googleService) {
      resolve([]);
      return;
    }

    if (!options.input) {
      resolve([]);
      return;
    }

    googleService.getPlacePredictions(options, (result, status) => {
      if (status === google.maps.places.PlacesServiceStatus.OK) {
        resolve(result);
      } else if (status === google.maps.places.PlacesServiceStatus.ZERO_RESULTS) {
        resolve([]);
      } else {
        const error = new GoogleAutocompleteError('Failed to fetch places', status);
        reject(error);
      }
    });
  });
};

const getPlaceDetails = (
  googleService: google.maps.places.PlacesService,
  options: google.maps.places.PlaceDetailsRequest,
): Promise<google.maps.places.PlaceResult> => {
  return new Promise((resolve, reject) => {
    if (!googleService) {
      resolve(null);
      return;
    }

    if (!options.placeId) {
      resolve(null);
      return;
    }

    googleService.getDetails(options, (result, status) => {
      if (status === google.maps.places.PlacesServiceStatus.OK) {
        resolve(result);
      } else if (status === google.maps.places.PlacesServiceStatus.ZERO_RESULTS) {
        resolve(null);
      } else {
        const error = new GoogleAutocompleteError('Failed to fetch place details', status);
        reject(error);
      }
    });
  });
};

type TAutocompleteCallback = (
  options: google.maps.places.AutocompletionRequest,
) => Promise<google.maps.places.AutocompletePrediction[]>;
type TPlacesCallback = (
  options: google.maps.places.PlaceDetailsRequest,
) => Promise<google.maps.places.PlaceResult>;

export const useGooglePlacesAutocomplete = (): [TAutocompleteCallback, TPlacesCallback] => {
  const [scriptLoaded, setScriptLoaded] = useState<boolean>(false); // eslint-disable-line
  useEffect(() => {
    const maxLoop = 20; // 10 seconds
    let step = 1;
    const interval = setInterval(() => {
      if (step > maxLoop) {
        clearInterval(interval);
        return;
      } else {
        step++;
      }
      if (isNil(window) || 'google' in window) {
        clearInterval(interval);
        setScriptLoaded(true);
      }
    }, 500);
    return () => {
      clearInterval(interval);
    };
  }, []);

  const googleAutocompleteService: google.maps.places.AutocompleteService = useMemo(() => {
    if (typeof google === undefined) {
      return;
    }
    if (!get(google, 'maps.places.AutocompleteService')) {
      throw new Error('google.maps.places.AutocompleteService does not exist.');
    }

    return new google.maps.places.AutocompleteService();
    // eslint-disable-next-line
  }, [scriptLoaded]);

  const googlePlacesService: google.maps.places.PlacesService = useMemo(() => {
    if (typeof google === undefined) {
      return;
    }

    if (!get(google, 'maps.places.PlacesService')) {
      throw new Error('google.maps.places.PlacesService does not exist.');
    }

    return new google.maps.places.PlacesService(document.createElement('div'));
    // eslint-disable-next-line
  }, [scriptLoaded]);

  const getPlacesCallback: TAutocompleteCallback = useCallback(
    (options) => getPlaces(googleAutocompleteService, options),
    [googleAutocompleteService],
  );

  const getPlaceDetailsCallback: TPlacesCallback = useCallback(
    (options) => getPlaceDetails(googlePlacesService, options),
    [googlePlacesService],
  );

  return [getPlacesCallback, getPlaceDetailsCallback];
};
