import React from 'react';
import { useMutation, MutationHookOptions, MutationFunctionOptions } from '@apollo/client';

import { message } from 'antd';

import { SET_ADDRESS_AS_PRIMARY_MUTATION } from '@queries';
import { SetAddressAsPrimaryMutation, SetAddressAsPrimaryMutationVariables } from '@types';

type IOptions = MutationHookOptions<
  SetAddressAsPrimaryMutation,
  SetAddressAsPrimaryMutationVariables
>;
type IFunctionOptions = MutationFunctionOptions<
  SetAddressAsPrimaryMutation,
  SetAddressAsPrimaryMutationVariables
>;

const { useCallback } = React;

export const useSetAddressAsPrimary = (options: IOptions = {}) => {
  const [_setAddressAsPrimary, { loading, error }] = useMutation<
    SetAddressAsPrimaryMutation,
    SetAddressAsPrimaryMutationVariables
  >(SET_ADDRESS_AS_PRIMARY_MUTATION, {
    ...options,
  });

  const setAddressAsPrimary = useCallback(
    async (options: IFunctionOptions = {}) => {
      try {
        const result = await _setAddressAsPrimary(options);

        return result;
      } catch (err) {
        message.error((err as Error).message);
      }
    },
    [_setAddressAsPrimary],
  );

  return {
    setAddressAsPrimary,
    loading,
    error,
  };
};
