import { useQuery, QueryHookOptions } from '@apollo/client';
import React from 'react';
import { isEmpty } from 'lodash';

import { message } from 'antd';

import { GET_AFFILIATE_TRACKING_QUERY } from '@queries';
import { GetAffiliateTrackingQuery, GetAffiliateTrackingQueryVariables } from '@types';

type IOptions = QueryHookOptions<GetAffiliateTrackingQuery, GetAffiliateTrackingQueryVariables>;

const { useEffect, useMemo } = React;

export function useGetAffiliateTrackingById(options: IOptions = {}) {
  const {
    loading,
    data: { tracking } = {},
    error,
    refetch,
  } = useQuery<GetAffiliateTrackingQuery, GetAffiliateTrackingQueryVariables>(
    GET_AFFILIATE_TRACKING_QUERY,
    {
      ...options,
      skip: isEmpty(options.variables?.id),
      fetchPolicy: 'cache-and-network',
      nextFetchPolicy: 'cache-first',
      notifyOnNetworkStatusChange: true,
    },
  );

  const errorMessage = useMemo(() => error?.message, [error]);
  useEffect(() => {
    if (!loading && errorMessage) {
      message.error(errorMessage);
    }
  }, [loading, errorMessage]);

  return {
    loading,
    tracking,
    error,
    refetch,
  };
}
