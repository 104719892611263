import {
  format,
  isToday,
  isYesterday,
  isThisWeek,
  isThisHour,
  differenceInDays,
  formatDistanceToNow,
  formatDistanceToNowStrict,
  isThisYear,
} from 'date-fns';
import { enUS } from 'date-fns/locale';

const formatDistanceOptions = {
  locale: {
    ...enUS,
    formatDistance: (unit: string, count: number) => {
      switch (unit) {
        case 'xSeconds':
          return `${count}s`;
        case 'xMinutes':
          return `${count}m`;
        case 'xHours':
          return `${count}h`;
        case 'xDays':
          return `${count}d`;
        case 'xMonths':
          return `${count}mo`;
        case 'xYears':
          return `${count}y`;
      }

      return '';
    },
  },
};

export function formatDate(date: Date | number, concise = false): string {
  if (concise) {
    const diffDays = differenceInDays(Date.now(), date);

    if (diffDays < 30) {
      return formatDistanceToNowStrict(date, formatDistanceOptions);
    } else if (isThisYear(date)) {
      return format(date, 'MM/dd');
    }

    return format(date, 'MMM dd, yyyy');
  }

  if (isThisHour(date)) {
    return `${formatDistanceToNow(date)} ago`;
  } else if (isToday(date)) {
    return `Today at ${format(date, 'HH:mm aaaa')}`;
  } else if (isYesterday(date)) {
    return `Yesterday at ${format(date, 'HH:mm aaaa')}`;
  } else if (isThisWeek(date)) {
    return `${format(date, 'EEEE')} at ${format(date, 'HH:mm aaaa')}`;
  }

  return format(date, 'PPpp');
}
