import React from 'react';
import cx from 'classnames';

import { CollabThreads } from '@components';

import { IProgramCollabDetail } from '@types';

import styles from './CollabMessages.module.scss';

interface IProps {
  collab: IProgramCollabDetail;

  className?: string;
}

/**
 * @type {React.FC}
 */
export const CollabMessages: React.FC<React.PropsWithChildren<IProps>> = React.memo(
  ({ collab, className }) => {
    return (
      <div className={cx(styles.CollabMessages, className)}>
        <CollabThreads className={styles.list} collab={collab} />
      </div>
    );
  },
);
