import React from 'react';
import cx from 'classnames';
import { map } from 'lodash';

import { Typography } from 'antd';
import { ThreadItem } from './ThreadItem';
import { TextMessageItem } from './TextMessageItem';

import { isTextMessage, useCollabThreadsContext } from '../context';

import styles from './ThreadList.module.scss';

interface IProps {
  className?: string;
}

const { useRef, useMemo, useEffect } = React;
const { Text } = Typography;

/**
 * @type {React.FC}
 */
export const ThreadList: React.FC<IProps> = React.memo(({ className }) => {
  const { loading, messages } = useCollabThreadsContext();
  const listRef = useRef<HTMLDivElement>(null);
  const ref = useRef<HTMLDivElement>(null);
  const mounted = useRef(false);

  // use string of messageIds to avoid triggering another useEffect cycle
  const messageIds = useMemo(() => map(messages, (m) => m.id).toString(), [messages]);

  // scroll to bottom when new messages are added
  useEffect(() => {
    const isMounted = mounted.current;

    if (!loading && isMounted) {
      ref.current.scrollIntoView({
        block: 'start',
        behavior: 'smooth',
      });
    }

    if (!loading) {
      mounted.current = true;
    }
  }, [loading, messageIds]);

  return (
    <div ref={listRef} className={cx(styles.ThreadList, className)}>
      {map(messages, (message) => {
        if (isTextMessage(message)) {
          if (message.from === 'system') {
            return (
              <Text key={message.id} className={styles.text} type="secondary">
                {message.data}
              </Text>
            );
          }

          return <TextMessageItem key={message.id} message={message} />;
        }

        return <ThreadItem key={message.id} className={styles.thread} thread={message} />;
      })}
      <div ref={ref} />
    </div>
  );
});
