import React from 'react';
import cx from 'classnames';
import { map } from 'lodash';

import { Select } from 'antd';

import { SalesTrackingLastDate } from '@types';
import { useTrackingConversionsContext } from '../TrackingConversionsContext';

import styles from './LastDaysFilter.module.scss';

interface IProps {
  className?: string;
}

const { useCallback } = React;
const { Option } = Select;
const LAST_DAYS_OPTIONS: Array<{
  label: string;
  value: SalesTrackingLastDate;
}> = [
  {
    value: SalesTrackingLastDate.PastWeek,
    label: 'Past week',
  },
  {
    value: SalesTrackingLastDate.PastTwoWeeks,
    label: 'Past two weeks',
  },
  {
    value: SalesTrackingLastDate.PastMonth,
    label: 'Past month',
  },
  {
    value: SalesTrackingLastDate.PastSixMonths,
    label: 'Past six months',
  },
  {
    value: SalesTrackingLastDate.PastTwoYears,
    label: 'Past two years',
  },
  {
    value: null,
    label: 'All time',
  },
];

/**
 * @type {React.FC}
 */
export const LastDaysFilter: React.FC<React.PropsWithChildren<IProps>> = React.memo(
  ({ className }) => {
    const { filters, setFilters } = useTrackingConversionsContext();

    const onChange = useCallback(
      (lastDays: SalesTrackingLastDate) =>
        setFilters({
          ...filters,
          lastDays,
        }),
      [filters, setFilters],
    );

    return (
      <Select
        className={cx(styles.LastDaysFilter, className)}
        value={filters.lastDays}
        onChange={onChange}
        bordered={false}
      >
        {map(LAST_DAYS_OPTIONS, (option) => (
          <Option key={option.label} value={option.value}>
            {option.label}
          </Option>
        ))}
      </Select>
    );
  },
);
