import React from 'react';
import cx from 'classnames';

import { Alert, Button, Divider, Tooltip } from 'antd';
import { CheckOutlined } from '@ant-design/icons';
import { CollabTerm } from '@components';

import { ICollabTerm, IProgramCollab, WorkflowEvent } from '@types';
import { useWorkflowCheckIn } from '@hooks';

import styles from './ReviewTermForm.module.scss';

interface IProps {
  collab: IProgramCollab;
  term: ICollabTerm;
  onRequestingChanges(): void;
  onComplete(): void;

  className?: string;
}

const { useState, useMemo, useCallback } = React;

/**
 * @type {React.FC}
 */
export const ReviewTermForm: React.FC<IProps> = React.memo(
  ({ collab, term, onRequestingChanges, onComplete, className }) => {
    const [errorMessage, setErrorMessage] = useState('');
    const { workflowCheckIn, loading } = useWorkflowCheckIn();

    const requestChangesDisabledMessage = useMemo(() => {
      if (!term.negotiable) {
        return 'Term negotiation is disabled by the brand.';
      }

      return null;
    }, [term]);

    const onAcceptRequirements = useCallback(async () => {
      setErrorMessage('');

      try {
        await workflowCheckIn({
          variables: {
            collabId: collab.id,
            event: WorkflowEvent.TermStageCreatorAcceptTerm,
          },
        });

        onComplete();
      } catch (err) {
        console.log(err);
        setErrorMessage((err as Error)?.message);
      }
    }, [collab, workflowCheckIn, onComplete]);

    return (
      <div className={cx(styles.ReviewTermForm, className)}>
        <div className={styles.content}>
          <CollabTerm collabId={collab.id} />
        </div>
        {errorMessage && (
          <Alert
            className={styles.alert}
            type="error"
            message="Failed to accept term"
            description={errorMessage}
            closable
          />
        )}
        <Divider />
        <div className={styles.submit}>
          <Tooltip
            overlayStyle={{
              display: !!requestChangesDisabledMessage ? undefined : 'none',
            }}
            title={requestChangesDisabledMessage}
          >
            <Button disabled={!!requestChangesDisabledMessage} onClick={onRequestingChanges}>
              Propose Changes
            </Button>
          </Tooltip>
          <Button
            type="primary"
            htmlType="submit"
            icon={<CheckOutlined />}
            onClick={onAcceptRequirements}
            loading={loading}
          >
            Accept Terms
          </Button>
        </div>
      </div>
    );
  },
);
