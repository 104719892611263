import React from 'react';
import cx from 'classnames';

import AntdIcon from '@ant-design/icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { IconDefinition } from '@fortawesome/fontawesome-common-types';

import styles from './Icon.module.scss';

export interface IIconProps {
  icon: IconDefinition;

  className?: string;
}

/**
 * @type {React.FC}
 */
export const Icon: React.FC<React.PropsWithChildren<IIconProps>> = React.memo(
  ({ icon, className }) => {
    return (
      <AntdIcon className={cx(styles.Icon, className)} viewBox="0 0 1024 1024">
        <FontAwesomeIcon icon={icon} />
      </AntdIcon>
    );
  },
);
