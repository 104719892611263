import React from 'react';
import { useMutation, MutationHookOptions, MutationFunctionOptions } from '@apollo/client';

import { message } from 'antd';

import { REVOKE_CREATOR_RESOURCE_MUTATION } from '@queries';
import { RevokeCreatorResourceMutation, RevokeCreatorResourceMutationVariables } from '@types';

type IOptions = MutationHookOptions<
  RevokeCreatorResourceMutation,
  RevokeCreatorResourceMutationVariables
>;
type IFunctionOptions = MutationFunctionOptions<
  RevokeCreatorResourceMutation,
  RevokeCreatorResourceMutationVariables
>;

const { useCallback } = React;

export const useRevokeCreatorResource = (options: IOptions = {}) => {
  const [_revokeCreatorResource, { loading, error }] = useMutation<
    RevokeCreatorResourceMutation,
    RevokeCreatorResourceMutationVariables
  >(REVOKE_CREATOR_RESOURCE_MUTATION, {
    ...options,
  });

  const revokeCreatorResource = useCallback(
    async (options: IFunctionOptions = {}) => {
      try {
        const result = await _revokeCreatorResource(options);

        return result;
      } catch (err) {
        message.error((err as Error).message);
      }
    },
    [_revokeCreatorResource],
  );

  return {
    revokeCreatorResource,
    loading,
    error,
  };
};
