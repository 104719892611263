import { gql } from '@apollo/client';

import { CONTENT_FRAGMENT } from './fragments';

//========================================================
//= Mutations
//========================================================
export const CREATE_CONTENT_MUTATION = gql`
  mutation CreateContent($params: CreateContentParams!) {
    content: creatorCreateContent(params: $params) {
      ...Content
    }
  }
  ${CONTENT_FRAGMENT}
`;

export const GENERATE_UPLOAD_URL_MUTATION = gql`
  mutation GenerateUploadUrl($params: GenerateUploadUrlParams!) {
    response: creatorGenerateUploadUrl(params: $params) {
      filename
      url
      uploadUrl
    }
  }
`;

export const DELETE_CONTENT_MUTATION = gql`
  mutation DeleteContent($id: String!) {
    deleted: creatorDeleteContentById(id: $id)
  }
`;
