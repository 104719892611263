import { gql } from '@apollo/client';

import { NOTIFICATION_FRAGMENT } from './fragments';

//========================================================
//= Queries
//========================================================
export const GET_NOTIFICATIONS_QUERY = gql`
  query GetNotifications {
    notifications: creatorGetNotifications {
      ...Notification
    }
  }
  ${NOTIFICATION_FRAGMENT}
`;

//========================================================
//= Mutations
//========================================================
export const MARK_NOTIFICATION_READ_MUTATION = gql`
  mutation MarkNotificationRead($notificationId: String!) {
    notification: creatorMarkNotificationRead(notificationId: $notificationId) {
      ...Notification
    }
  }
  ${NOTIFICATION_FRAGMENT}
`;

export const SUBSCRIBE_TO_NOTIFICATIONS_MUTATION = gql`
  mutation SubscribeToNotifications {
    succeeded: creatorSubscribeToNotifications
  }
`;
