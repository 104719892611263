import React from 'react';
import cx from 'classnames';

import { Avatar, AvatarProps } from 'antd';

import googleSvg from '@assets/google.svg';

import styles from './GoogleIcon.module.scss';

interface IProps extends AvatarProps {}

/**
 * @type {React.FC}
 */
export const GoogleIcon: React.FC<React.PropsWithChildren<IProps>> = React.memo((props) => {
  return (
    <Avatar
      {...props}
      className={cx(styles.GoogleIcon, props.className)}
      src={googleSvg}
      shape="square"
    />
  );
});

GoogleIcon.defaultProps = {
  size: 18,
};
