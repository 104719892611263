import React from 'react';
import { useNavigate } from 'react-router-dom';
import cx from 'classnames';

import { Alert, Button, Form, Input } from 'antd';

import { useAuthContext } from '@context';

import styles from './EmailPasswordForm.module.scss';

interface IProps {
  className?: string;
}
interface ISubmitParams {
  email: string;
  password: string;
}

const { useState, useCallback } = React;

export const EmailPasswordForm: React.FC<React.PropsWithChildren<IProps>> = React.memo(
  ({ className }) => {
    const navigate = useNavigate();
    const { signIn } = useAuthContext();
    const [form] = Form.useForm();
    const [signingIn, setSigningIn] = useState(false);
    const [errorMessage, setErrorMessage] = useState<string>(null);

    const goToForgetPassword = useCallback(() => navigate('/forget_password'), [navigate]);
    const onFinish = useCallback(
      async ({ email, password }: ISubmitParams) => {
        setSigningIn(true);
        setErrorMessage(null);

        try {
          await signIn(email, password);
        } catch (err) {
          setErrorMessage('Wrong email/password.');
        }

        setSigningIn(false);
      },
      [signIn],
    );

    return (
      <Form
        className={cx(styles.EmailPasswordForm, className)}
        form={form}
        layout="vertical"
        onFinish={onFinish}
        scrollToFirstError
      >
        <Form.Item
          name="email"
          rules={[
            {
              type: 'email',
              message: `The entered value is not a valid email.`,
            },
            {
              required: true,
              message: `Please enter your email.`,
            },
          ]}
        >
          <Input
            size="large"
            placeholder="Email address"
            name="email"
            type="email"
            autoComplete="email"
          />
        </Form.Item>
        <Form.Item
          name="password"
          extra={
            <>
              Forgot your password?{' '}
              <span className={styles.link} onClick={goToForgetPassword}>
                Click here
              </span>{' '}
              to request a password reset.
            </>
          }
          rules={[
            {
              required: true,
              message: 'Please enter your password.',
            },
          ]}
          hasFeedback
        >
          <Input.Password size="large" placeholder="Password" />
        </Form.Item>
        {errorMessage && (
          <Alert className={styles.alert} type="error" message={errorMessage} closable />
        )}
        <Button
          className={styles.submit}
          type="primary"
          size="large"
          htmlType="submit"
          loading={signingIn}
        >
          Continue
        </Button>
      </Form>
    );
  },
);
