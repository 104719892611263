import { gql } from '@apollo/client';

import {
  MEMBER_FRAGMENT,
  AFFILIATE_TRACKING_FRAGMENT,
  SHOPIFY_DISCOUNT_CODE_FRAGMENT,
} from './fragments';
import { PROGRAM_OVERVIEW_FRAGMENT } from './programs';

//========================================================
//= Fragments
//========================================================
const AFFILIATE_TRACKING_OVERVIEW_FRAGMENT = gql`
  fragment AffiliateTrackingOverview on AffiliateTracking {
    ...AffiliateTracking
    program {
      ...ProgramOverview
    }
    descendantCount
  }
  ${AFFILIATE_TRACKING_FRAGMENT}
  ${PROGRAM_OVERVIEW_FRAGMENT}
`;

const AFFILIATE_TRACKING_DETAIL_FRAGMENT = gql`
  fragment AffiliateTrackingDetail on AffiliateTracking {
    ...AffiliateTracking
    program {
      ...ProgramOverview
    }
    member {
      ...Member
    }
    discountCode {
      ...ShopifyDiscountCode
    }
    descendantCount
  }
  ${AFFILIATE_TRACKING_FRAGMENT}
  ${PROGRAM_OVERVIEW_FRAGMENT}
  ${MEMBER_FRAGMENT}
  ${SHOPIFY_DISCOUNT_CODE_FRAGMENT}
`;

//========================================================
//= Queries
//========================================================
export const GET_AFFILIATE_TRACKING_QUERY = gql`
  query GetAffiliateTracking($id: String!) {
    tracking: creatorGetAffiliateTrackingById(id: $id) {
      ...AffiliateTrackingDetail
    }
  }
  ${AFFILIATE_TRACKING_DETAIL_FRAGMENT}
`;

export const GET_AFFILIATE_TRACKINGS_QUERY = gql`
  query GetAffiliateTrackings(
    $limit: Int
    $offset: Int
    $sortBy: EntitySortBy
    $filters: AffiliateTrackingFilters
  ) {
    trackings: creatorGetAffiliateTrackings(
      limit: $limit
      offset: $offset
      sortBy: $sortBy
      filters: $filters
    ) {
      ...AffiliateTrackingOverview
    }
  }
  ${AFFILIATE_TRACKING_OVERVIEW_FRAGMENT}
`;

export const GET_AFFILIATE_TRACKING_COUNT_QUERY = gql`
  query GetAffiliateTrackingCount($filters: AffiliateTrackingFilters) {
    count: creatorGetAffiliateTrackingCount(filters: $filters)
  }
`;
