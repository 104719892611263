import { IContentGuidelines } from '@types';

type TSectionKey = keyof IContentGuidelines;
interface IContentGuidelineSection {
  key: TSectionKey;
  label: string;
  placeholder: string;
}

export const ContentGuidelineSections: Array<IContentGuidelineSection> = [
  {
    key: 'conceptAndFormat',
    label: 'Content Concept and Format',
    placeholder:
      'Enter a concept or format for the content, anything the creator must mention, length of the video, etc...',
  },
  {
    key: 'talkingPoints',
    label: 'Talking Points',
    placeholder: 'E.g. products are all natural, free shipping with every order, etc...',
  },
  {
    key: 'caption',
    label: 'Caption Guidelines',
    placeholder: 'E.g. must mention @brand, must include links to products, etc...',
  },
  {
    key: 'postInstructions',
    label: 'Post Instructions',
    placeholder: 'E.g. time of day to post, post must be permanent, social amplifications, etc...',
  },
  {
    key: 'other',
    label: 'Other Guidelines',
    placeholder: 'Enter any additional guidelines...',
  },
];
