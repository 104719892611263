import React from 'react';
import { useMutation, MutationHookOptions, MutationFunctionOptions } from '@apollo/client';

import { message } from 'antd';

import { SAVE_ADDRESS_MUTATION } from '@queries';
import { SaveAddressMutation, SaveAddressMutationVariables } from '@types';

type IOptions = MutationHookOptions<SaveAddressMutation, SaveAddressMutationVariables>;
type IFunctionOptions = MutationFunctionOptions<SaveAddressMutation, SaveAddressMutationVariables>;

const { useCallback } = React;

export const useSaveAddress = (options: IOptions = {}) => {
  const [_saveAddress, { loading, error }] = useMutation<
    SaveAddressMutation,
    SaveAddressMutationVariables
  >(SAVE_ADDRESS_MUTATION, {
    ...options,
    onCompleted() {
      message.info('Successfully saved address');
    },
  });

  const saveAddress = useCallback(
    async (options: IFunctionOptions = {}) => {
      try {
        const result = await _saveAddress(options);

        return result;
      } catch (err) {
        message.error((err as Error).message);
      }
    },
    [_saveAddress],
  );

  return {
    saveAddress,
    loading,
    error,
  };
};
