import { gql } from '@apollo/client';

import { SHOPIFY_DISCOUNT_CODE_FRAGMENT } from './shopifyDiscountCode';
import { SHARED_DISCOUNT_CODE_FRAGMENT } from './sharedDiscountCode';

export const COLLAB_SALES_TRACKING_FRAGMENT = gql`
  fragment CollabSalesTracking on CollabSalesTracking {
    id
    info {
      incentive {
        type
        value
        cpsValue
      }
      trackingUrl
      recruitUrl
    }
    stats {
      clicks
      conversions
      sales
      cost
    }
    discountCode {
      ...ShopifyDiscountCode
    }
    sharedDiscountCode {
      ...SharedDiscountCode
      shopifyDiscountCode {
        ...ShopifyDiscountCode
      }
    }
  }
  ${SHOPIFY_DISCOUNT_CODE_FRAGMENT}
  ${SHARED_DISCOUNT_CODE_FRAGMENT}
`;
