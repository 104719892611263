import React from 'react';
import cx from 'classnames';

import { FileIcon, FileImageIcon, FileVideoIcon } from '@customIcons';

import { IContent, ContentType } from '@types';

import styles from './AttachmentItem.module.scss';

interface IProps {
  attachment: IContent;

  className?: string;
}

const { useCallback } = React;

/**
 * @type {React.FC}
 */
export const AttachmentItem: React.FC<React.PropsWithChildren<IProps>> = React.memo(
  ({ attachment, className }) => {
    const onDownloadAttachment = useCallback(() => {
      const element = document.createElement('a');
      element.setAttribute('target', '_blank');
      element.setAttribute('href', attachment.url);
      element.setAttribute('download', attachment.originalName);
      element.style.display = 'none';
      document.body.appendChild(element);
      element.click();
      document.body.removeChild(element);
    }, [attachment]);

    return (
      <div className={cx(styles.AttachmentItem, className)} onClick={onDownloadAttachment}>
        {attachment.type === ContentType.Image && (
          <FileImageIcon className={cx(styles.icon, styles.image)} />
        )}
        {attachment.type === ContentType.Video && (
          <FileVideoIcon className={cx(styles.icon, styles.video)} />
        )}
        {attachment.type === ContentType.Application && (
          <FileIcon className={cx(styles.icon, styles.application)} />
        )}
        <span className={styles.name}>{attachment.originalName}</span>
      </div>
    );
  },
);
