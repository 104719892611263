import { gql } from '@apollo/client';

export const BRAND_FRAGMENT = gql`
  fragment Brand on Brand {
    id
    name
    createdDate
    updatedDate
  }
`;
