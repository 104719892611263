import { gql } from '@apollo/client';

export const SHOPIFY_ORDER_FRAGMENT = gql`
  fragment ShopifyOrder on ShopifyOrder {
    id
    fulfillmentStatus
    info {
      items {
        name
        quantity
      }
      email
      address
    }
    createdDate
    updatedDate
  }
`;
