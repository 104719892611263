import React, { Fragment } from 'react';
import { format } from 'date-fns';
import numeral from 'numeral';
import cx from 'classnames';
import { isEmpty, map, sumBy } from 'lodash';

import { Comment, Empty, Tag, Tooltip } from 'antd';
import { LoadSpinner } from '@components';
import { OrderListHeader } from './OrderListHeader';

import { formatDate } from '@utils';
import {
  ITrackingConversionsContextProps,
  TrackingConversionsContextProvider,
  useTrackingConversionsContext,
} from './TrackingConversionsContext';

import styles from './TrackingConversions.module.scss';

interface IProps {
  className?: string;
}

/**
 * @type {React.FC}
 */
const TrackingConversionsInternal: React.FC<React.PropsWithChildren<IProps>> = React.memo(
  ({ className }) => {
    const { orders, loading } = useTrackingConversionsContext();

    return (
      <div className={cx(styles.TrackingConversions, className)}>
        <OrderListHeader className={styles.header} />
        <div className={styles.list}>
          {loading && <LoadSpinner />}
          {!loading && isEmpty(orders) && <Empty description="No orders at this time." />}
          {!loading &&
            !isEmpty(orders) &&
            map(orders, (order) => {
              const { shopifyOrder } = order.info || {};
              if (!shopifyOrder) {
                return null;
              }

              const totalQuantitiy = sumBy(shopifyOrder.lineItems, (item: any) => item.quantity);
              const items = map(shopifyOrder.lineItems as any[], (item, index) => {
                return (
                  <Fragment key={index}>
                    <span>{index > 0 && ', '}</span>
                    <b>{item.title}</b>
                    <span> x {item.quantity}</span>
                  </Fragment>
                );
              });

              return (
                <Comment
                  key={order.id}
                  className={styles.item}
                  author={`${shopifyOrder.customer?.first_name} ${shopifyOrder.customer?.last_name}`}
                  content={
                    <div>
                      placed an order for {totalQuantitiy} product(s): {items}, using discount code{' '}
                      <Tag className={styles.tag} color="cyan">
                        {shopifyOrder.discountCodeInfo.code}
                      </Tag>{' '}
                      , for a total of{' '}
                      <Tooltip
                        overlayClassName={styles.PaymentTooltipOverlay}
                        title={
                          <div className={styles.paymentBreakdown}>
                            <div className={styles.entry}>
                              <span className={styles.label}>Items Price</span>
                              <span className={styles.amount}>
                                {numeral(shopifyOrder.totalItemPrice).format('$0,0.[00]')}
                              </span>
                            </div>
                            <div className={styles.entry}>
                              <span className={styles.label}>Discount</span>
                              <span className={styles.amount}>
                                - {numeral(shopifyOrder.discounts).format('$0,0.[00]')}
                              </span>
                            </div>
                            <div className={styles.entry}>
                              <span className={styles.label}>Subtotal</span>
                              <span className={styles.amount}>
                                {numeral(shopifyOrder.subtotal).format('$0,0.[00]')}
                              </span>
                            </div>
                            <div className={styles.entry}>
                              <span className={styles.label}>Shipping Price</span>
                              <span className={styles.amount}>
                                {numeral(shopifyOrder.shippingPrice).format('$0,0.[00]')}
                              </span>
                            </div>
                            <div className={cx(styles.entry, styles.bold)}>
                              <span className={styles.label}>Total</span>
                              <span className={cx(styles.amount, styles.gold)}>
                                {numeral(order.amount).format('$0,0.[00]')}
                              </span>
                            </div>
                          </div>
                        }
                      >
                        <Tag className={styles.tag} color="gold">
                          {numeral(order.amount).format('$0,0.[00]')}
                        </Tag>
                      </Tooltip>
                      .
                    </div>
                  }
                  datetime={
                    <Tooltip title={format(new Date(order.internalDate), 'PPpp')}>
                      <span className={styles.date}>
                        {formatDate(new Date(order.internalDate))}
                      </span>
                    </Tooltip>
                  }
                />
              );
            })}
        </div>
      </div>
    );
  },
);

export const TrackingConversions = React.memo<IProps & ITrackingConversionsContextProps>(
  ({ collabId, ...orderListProps }) => {
    return (
      <TrackingConversionsContextProvider collabId={collabId}>
        <TrackingConversionsInternal {...orderListProps} />
      </TrackingConversionsContextProvider>
    );
  },
);
