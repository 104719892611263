import { WorkflowState } from '@types';

export const WorkflowPendingStates: WorkflowState[] = [
  WorkflowState.ProductFulfillmentStateAwaitOrderDelivery,
];
export const WorkflowMemberActionRequiredStates: WorkflowState[] = [
  WorkflowState.ProspectStateInvitationReceived,
  WorkflowState.TermStateBrandProposedTerm,
  WorkflowState.ContractStateAwaitAcceptContract,
  WorkflowState.InfoUpdateStateAwaitUpdateInfo,
  WorkflowState.ProductFulfillmentStateAwaitPickProducts,
  WorkflowState.ContentReviewStateReviewingContents,
  WorkflowState.ContentReviewStateVerifyingPosts,
];
export const WorkflowManagerActionRequiredStates: WorkflowState[] = [
  WorkflowState.ProspectStateApplicationReceived,
  WorkflowState.TermStateAwaitProposeTerm,
  WorkflowState.TermStateCreatorProposedTerm,
  WorkflowState.EmailStateAwaitSendEmail,
  WorkflowState.ContractStateAwaitSendContract,
  WorkflowState.ProductFulfillmentStateAwaitCreateOrder,
  WorkflowState.ProductFulfillmentStateAwaitOrderFulfillment,
  WorkflowState.PaymentStateAwaitCreatePayment,
  WorkflowState.PaymentStateAwaitConfirmPayment,
  WorkflowState.SalesTrackingStateAwaitCreateLink,
];
