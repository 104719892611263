import axios from 'axios';

export const signUp = async (data: { email: string; name: string }): Promise<void> => {
  const resp = await axios.post(`/api/public/signup`, data, {
    headers: {
      ContentType: 'application/json',
    },
  });

  if (resp.status !== 200) {
    console.error(resp);
    throw new Error('Failed to sign up.');
  }

  return resp.data;
};
