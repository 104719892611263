import React from 'react';
import cx from 'classnames';

import { Drawer } from 'antd';
import { ProgramDetail } from '@components';

import { ErrorBoundary } from '@utils';
import { useGlobalOverlayContext } from '../GlobalOverlayContext';

import styles from './ProgramOverlay.module.scss';

interface IProps {
  className?: string;
}

const { useMemo } = React;

/**
 * @type {React.FC}
 */
export const ProgramOverlay: React.FC<React.PropsWithChildren<IProps>> = React.memo(
  ({ className }) => {
    const { entity, closeOverlay } = useGlobalOverlayContext();

    const programId = useMemo(() => {
      if (entity?.type === 'program') {
        return entity.id;
      }

      return null;
    }, [entity]);

    return (
      <Drawer
        className={cx(styles.ProgramOverlay, className)}
        open={!!programId}
        onClose={closeOverlay}
        width={800}
        closable={false}
        destroyOnClose
      >
        {programId && (
          <ErrorBoundary>
            <ProgramDetail programId={programId} />
          </ErrorBoundary>
        )}
      </Drawer>
    );
  },
);
