import React from 'react';
import cx from 'classnames';

import { Typography } from 'antd';

import { INotification, WorkflowEvent, NotificationType, NotificationPayloadType } from '@types';

import styles from './NotificationContent.module.scss';

interface IProps {
  notification: INotification;

  className?: string;
}

const { Paragraph } = Typography;
const { useMemo } = React;

/**
 * @type {React.FC}
 */
export const NotificationContent: React.FC<React.PropsWithChildren<IProps>> = React.memo(
  (props) => {
    const { notification } = props;
    const { type, payload } = notification;

    const Description = useMemo(() => {
      switch (type) {
        case NotificationType.Program: {
          if (payload.type === NotificationPayloadType.ProgramCollabWorkflowCheckIn) {
            const { event, brand, manager, program } = payload.data;

            switch (event) {
              case WorkflowEvent.ProspectStageInvite: {
                return (
                  <>
                    <span className={styles.name}>{manager?.name}</span> from{' '}
                    <span className={styles.name}>{brand?.name}</span> has sent you an invitation to
                    program <span className={styles.name}>{program?.info.name}</span>.
                  </>
                );
              }

              case WorkflowEvent.ProspectStageDirectAdd: {
                return (
                  <>
                    <span className={styles.name}>{manager?.name}</span> from{' '}
                    <span className={styles.name}>{brand?.name}</span> has added you to program{' '}
                    <span className={styles.name}>{program?.info.name}</span>
                  </>
                );
              }

              case WorkflowEvent.ProspectStageAcceptApplication: {
                return (
                  <>
                    <span className={styles.name}>{manager?.name}</span> from{' '}
                    <span className={styles.name}>{brand?.name}</span> has accepted your application
                    to program <span className={styles.name}>{program?.info.name}</span>
                  </>
                );
              }

              case WorkflowEvent.ProspectStageRejectApplication: {
                return (
                  <>
                    <span className={styles.name}>{manager?.name}</span> from{' '}
                    <span className={styles.name}>{brand?.name}</span> has rejected your application
                    to program <span className={styles.name}>{program?.info.name}</span>
                  </>
                );
              }

              default: {
                return null;
              }
            }
          }

          return null;
        }

        default: {
          return null;
        }
      }
    }, [type, payload]);

    return (
      <Paragraph className={cx(styles.NotificationContent, props.className)} ellipsis={{ rows: 2 }}>
        {Description}
      </Paragraph>
    );
  },
);
