import React from 'react';
import cx from 'classnames';

import { Avatar, AvatarProps } from 'antd';

import youtubeIcon from '@assets/youtube.svg';

import styles from './YoutubeIcon.module.scss';

interface IProps extends AvatarProps {}

/**
 * @type {React.FC}
 */
export const YoutubeIcon: React.FC<React.PropsWithChildren<IProps>> = React.memo((props) => {
  return (
    <Avatar {...props} className={cx(styles.YoutubeIcon, props.className)} src={youtubeIcon} />
  );
});
