import {
  AcceptInviteButton,
  DeclineInviteButton,
  PickProductsButton,
  SendTermButton,
  ReviewTermButton,
  ViewTermButton,
  SubmitPostsButton,
  UpdateContentsButton,
  UpdateInfoButton,
} from '@components';

import { IProgramCollabDetail, WorkflowStage, WorkflowState } from '@types';

export function getPanelActions(
  stage: WorkflowStage,
  collab: IProgramCollabDetail,
): JSX.Element | JSX.Element[] {
  const { state } = collab.workflowItem;

  switch (stage) {
    case WorkflowStage.Prospect: {
      if (state === WorkflowState.ProspectStateInvitationReceived) {
        return [<AcceptInviteButton collab={collab} />, <DeclineInviteButton collab={collab} />];
      }

      return null;
    }
    case WorkflowStage.Term: {
      if (state === WorkflowState.TermStateAwaitProposeTerm) {
        const { negotiable } = collab.term || {};
        if (negotiable) {
          return [<SendTermButton collab={collab} />];
        }
      }
      if (state === WorkflowState.TermStateBrandProposedTerm) {
        return [<ReviewTermButton collab={collab} />];
      }
      if (state === WorkflowState.TermStateCreatorProposedTerm) {
        return [<ViewTermButton collab={collab} />];
      }

      return null;
    }
    case WorkflowStage.InfoUpdate: {
      if (state === WorkflowState.InfoUpdateStateAwaitUpdateInfo) {
        return [<UpdateInfoButton collab={collab} />];
      }

      return null;
    }
    case WorkflowStage.ProductFulfillment: {
      if (state === WorkflowState.ProductFulfillmentStateAwaitPickProducts) {
        return [<PickProductsButton collab={collab} />];
      }

      return null;
    }
    case WorkflowStage.ContentReview: {
      if (state === WorkflowState.ContentReviewStateReviewingContents) {
        return [<UpdateContentsButton collab={collab} />];
      }
      if (state === WorkflowState.ContentReviewStateVerifyingPosts) {
        return [<SubmitPostsButton collab={collab} />];
      }

      return null;
    }
    default: {
      break;
    }
  }

  return null;
}
