import { gql } from '@apollo/client';

import { POST_TRACKING_FRAGMENT, SOCIAL_ACCOUNT_FRAGMENT, SOCIAL_POST_FRAGMENT } from './fragments';

//========================================================
//= Fragments
//========================================================
export const POST_TRACKING_OVERVIEW_FRAGMENT = gql`
  fragment PostTrackingOverview on PostTracking {
    ...PostTracking
  }
  ${POST_TRACKING_FRAGMENT}
`;

export const POST_TRACKING_DETAIL_FRAGMENT = gql`
  fragment PostTrackingDetail on PostTracking {
    ...PostTracking
    post {
      ...SocialPost
      account {
        ...SocialAccount
      }
    }
  }
  ${POST_TRACKING_FRAGMENT}
  ${SOCIAL_ACCOUNT_FRAGMENT}
  ${SOCIAL_POST_FRAGMENT}
`;
