import { gql } from '@apollo/client';

export const SHOPIFY_PRODUCT_FRAGMENT = gql`
  fragment ShopifyProduct on ShopifyProduct {
    id
    info {
      title
      lowestPrice
      bodyHtml
      image
      medias {
        id
        src
      }
      variants {
        id
        productId
        title
        price
        inventoryQuantity
        inventoryPolicy
        inventoryManagement
        sku
        image
        option1
        option2
        option3
      }
      options {
        id
        name
        position
        values
      }
    }
  }
`;
