import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';

import {
  resourceEn,
  resourceZhCN,
} from './locales';

i18n
  .use(initReactI18next)
  .init({
    fallbackLng: 'en',
    lng: 'en',
    debug: true,
    resources: {
      en: resourceEn,
      zh_CN: resourceZhCN,
    },
  });

export default i18n;