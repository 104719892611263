import { createRoot } from 'react-dom/client';
import { BrowserRouter } from 'react-router-dom';

// import antd styles first so our styles can overrides it without !important
import './i18n';
import './base.less';
import './overrides.module.scss';

import * as serviceWorker from './serviceWorker';
import { AuthContextProvider, AppContextProvider } from '@context';
import { App } from './containers';

const container = document.getElementById('root');
const root = createRoot(container);
root.render(
  <BrowserRouter>
    <AuthContextProvider>
      <AppContextProvider>
        <App />
      </AppContextProvider>
    </AuthContextProvider>
  </BrowserRouter>,
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
