import { gql } from '@apollo/client';

export const TRACKING_CONVERSION_FRAGMENT = gql`
  fragment TrackingConversion on TrackingConversion {
    id
    programId
    collabId
    internalDate
    amount
    updatedDate
    createdDate
  }
`;
