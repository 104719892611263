import { format } from 'date-fns';

import { IProgramCollabDetail, WorkflowProspectSource, WorkflowStage } from '@types';

export function getPanelDescription(
  stage: WorkflowStage,
  collab: IProgramCollabDetail,
): string | JSX.Element {
  const { data } = collab.workflowItem;

  switch (stage) {
    case WorkflowStage.Prospect: {
      const { prospect } = data;

      // this shouldn't happen
      if (!prospect) {
        return '-';
      }

      const { source, createdTs, acceptedTs, rejectedTs } = prospect;
      if (acceptedTs) {
        if (source === WorkflowProspectSource.Application) {
          return `Application accepted on ${format(new Date(acceptedTs), 'PPpp')}.`;
        } else if (source === WorkflowProspectSource.Invitation) {
          return `Invitation accepted on ${format(new Date(acceptedTs), 'PPpp')}.`;
        }
      } else if (rejectedTs) {
        if (source === WorkflowProspectSource.Application) {
          return `Application rejected on ${format(new Date(acceptedTs), 'PPpp')}.`;
        } else if (source === WorkflowProspectSource.Invitation) {
          return `Invitation rejected on ${format(new Date(acceptedTs), 'PPpp')}.`;
        }
      }

      if (source === WorkflowProspectSource.Application) {
        return `Application received on ${format(new Date(createdTs), 'PPpp')}.`;
      } else if (source === WorkflowProspectSource.Invitation) {
        return `Invitation received on ${format(new Date(createdTs), 'PPpp')}.`;
      } else {
        return `Added to program on ${format(new Date(createdTs), 'PPpp')}.`;
      }
    }
    case WorkflowStage.Email: {
      const { email } = data;

      if (!email) {
        return null;
      }

      const { sentTs } = email;
      if (sentTs) {
        return `Email sent on ${format(new Date(sentTs), 'PPpp')}.`;
      }

      break;
    }
    case WorkflowStage.Contract: {
      const { contract } = data;

      if (!contract) {
        return null;
      }

      const { sentTs, signedTs } = contract;
      if (signedTs) {
        return `Contracts signed on ${format(new Date(signedTs), 'PPpp')}.`;
      } else if (sentTs) {
        return `Contracts sent on ${format(new Date(sentTs), 'PPpp')}.`;
      }

      break;
    }
    case WorkflowStage.InfoUpdate: {
      const { infoUpdate } = data;

      if (!infoUpdate) {
        return null;
      }

      const { sentTs, updatedTs } = infoUpdate;
      if (updatedTs) {
        return `Information updated on ${format(new Date(updatedTs), 'PPpp')}.`;
      } else if (sentTs) {
        return `Update info request sent on ${format(new Date(sentTs), 'PPpp')}.`;
      }

      break;
    }
    case WorkflowStage.ProductFulfillment: {
      const { productFulfillment } = data;

      if (!productFulfillment) {
        return null;
      }

      const {
        pickProductsTs,
        skipPickingProductsTs,
        createOrderTs,
        fulfillOrderTs,
        orderDeliveredTs,
      } = productFulfillment;
      if (orderDeliveredTs) {
        return `Order has been delivered on ${format(new Date(orderDeliveredTs), 'PPpp')}.`;
      } else if (fulfillOrderTs) {
        return `Order has been fulfilled on ${format(new Date(fulfillOrderTs), 'PPpp')}.`;
      } else if (createOrderTs) {
        return `Order has been created on ${format(new Date(createOrderTs), 'PPpp')}.`;
      } else if (skipPickingProductsTs) {
        return `Skipped picking products on ${format(new Date(skipPickingProductsTs), 'PPpp')}.`;
      } else if (pickProductsTs) {
        return `Member has picked preferred products on ${format(
          new Date(pickProductsTs),
          'PPpp',
        )}.`;
      }

      break;
    }
    default: {
      break;
    }
  }

  return null;
}
