import { gql } from '@apollo/client';

export const CREATOR_BILLING_FRAGMENT = gql`
  fragment CreatorBilling on CreatorBilling {
    id
    account {
      id
      email
      payoutsEnabled
      detailsSubmitted
    }
    createdDate
    updatedDate
  }
`;
