import { gql } from '@apollo/client';

export const PROGRAM_COLLAB_ACTIVITY_FRAGMENT = gql`
  fragment ProgramCollabActivity on ProgramCollabActivity {
    id
    source
    event
    params
    createdDate
    updatedDate
  }
`;
