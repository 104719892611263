import React from 'react';
import cx from 'classnames';
import { compact, find, isEmpty, map } from 'lodash';

import {
  Drawer,
  Input,
  Button,
  Form,
  Divider,
  InputNumber,
  Checkbox,
  DatePicker,
  Select,
} from 'antd';
import { IGooglePlace, AddressInput, LoadSpinner } from '@components';

import {
  IProgram,
  IWorkflowInfoUpdateStageConfig,
  MemberDefaultFieldType,
  MemberFieldSchemaType,
  WorkflowInfoUpdateStageCheckInParams,
} from '@types';
import { useGetMemberFieldSchemas } from '@hooks';

import styles from './InfoUpdatePanel.module.scss';

interface IProps {
  program: IProgram;
  show: boolean;
  onRequestClose(): void;

  loading?: boolean;
  onSubmit(params: WorkflowInfoUpdateStageCheckInParams): void;

  className?: string;
}

const { useMemo, useCallback, useState } = React;
const { TextArea } = Input;

/**
 * @type {React.FC}
 */
export const InfoUpdatePanel: React.FC<React.PropsWithChildren<IProps>> = React.memo(
  ({ program, show, onRequestClose, onSubmit, loading = false, className }) => {
    return (
      <Drawer
        className={cx(styles.InfoUpdatePanel, className)}
        title="Update Information"
        open={show}
        onClose={onRequestClose}
        width={700}
        destroyOnClose
      >
        <InfoUpdateForm onSubmit={onSubmit} isSubmitting={loading} program={program} />
      </Drawer>
    );
  },
);

interface IInfoUpdateFormProps {
  program: IProgram;
  onSubmit(params: WorkflowInfoUpdateStageCheckInParams): void;
  isSubmitting: boolean;
}

const InfoUpdateForm: React.FC<
  React.PropsWithChildren<React.PropsWithChildren<IInfoUpdateFormProps>>
> = React.memo(({ program, onSubmit, isSubmitting }) => {
  const [form] = Form.useForm();
  const [selectedPlace, setSelectedPlace] = useState<IGooglePlace>(null);

  const { schemas, loading } = useGetMemberFieldSchemas({
    variables: {
      brandId: program.brandId,
    },
  });

  const infoUpdateConfig: IWorkflowInfoUpdateStageConfig = useMemo(
    () => program.workflowConfig.data.infoUpdate,
    [program],
  );
  const phoneConfig = useMemo(() => {
    return find(
      infoUpdateConfig.defaultFields,
      ({ type }) => type === MemberDefaultFieldType.Phone,
    );
  }, [infoUpdateConfig]);
  const addressConfig = useMemo(() => {
    return find(
      infoUpdateConfig.defaultFields,
      ({ type }) => type === MemberDefaultFieldType.Address,
    );
  }, [infoUpdateConfig]);
  const socialConfig = useMemo(() => {
    return {
      instagram: find(
        infoUpdateConfig.defaultFields,
        ({ type }) => type === MemberDefaultFieldType.InstagramHandle,
      ),
      youtube: find(
        infoUpdateConfig.defaultFields,
        ({ type }) => type === MemberDefaultFieldType.YoutubeHandle,
      ),
      tiktok: find(
        infoUpdateConfig.defaultFields,
        ({ type }) => type === MemberDefaultFieldType.TiktokHandle,
      ),
      twitter: find(
        infoUpdateConfig.defaultFields,
        ({ type }) => type === MemberDefaultFieldType.TwitterHandle,
      ),
    };
  }, [infoUpdateConfig]);

  const onFinish = useCallback(
    async (values: WorkflowInfoUpdateStageCheckInParams) => {
      const { address, ...rest } = values;

      if (address?.place) {
        onSubmit({
          ...rest,
          address: {
            ...address,
            firstName: rest.info.firstName,
            lastName: rest.info.lastName,
          },
        });
      } else {
        onSubmit(rest);
      }
    },
    [onSubmit],
  );

  return (
    <Form
      className={styles.form}
      layout="vertical"
      form={form}
      onFinish={onFinish}
      scrollToFirstError
    >
      {loading && <LoadSpinner />}
      {!loading && (
        <>
          <div className={styles.content}>
            <Form.Item
              name={['info', 'firstName']}
              label="First Name"
              rules={[
                {
                  required: true,
                  message: `Please enter your first name.`,
                },
              ]}
            >
              <Input autoComplete="cc-given-name" />
            </Form.Item>
            <Form.Item
              name={['info', 'lastName']}
              label="Last Name"
              rules={[
                {
                  required: true,
                  message: `Please enter your last name.`,
                },
              ]}
            >
              <Input autoComplete="cc-family-name" />
            </Form.Item>
            {(phoneConfig || addressConfig) && (
              <>
                <Divider className={styles.divider}>Contact Information</Divider>
                {phoneConfig && (
                  <Form.Item
                    label="Phone number"
                    name={['info', 'phone']}
                    rules={compact([
                      phoneConfig.required && {
                        required: true,
                        message: `Please enter your phone number.`,
                      },
                    ])}
                  >
                    <Input type="tel" autoComplete="tel" />
                  </Form.Item>
                )}
                {addressConfig && (
                  <>
                    <Form.Item
                      label="Address"
                      name={['address', 'place']}
                      rules={compact([
                        addressConfig.required && {
                          required: true,
                          message: `Please enter your address.`,
                        },
                      ])}
                    >
                      <AddressInput onChange={setSelectedPlace} />
                    </Form.Item>
                    {selectedPlace && (
                      <>
                        <Form.Item label="Unit/Apartment number" name={['address', 'unit']}>
                          <Input autoComplete="new-unit" />
                        </Form.Item>
                        <Form.Item label="Delivery instruction" name={['address', 'instruction']}>
                          <TextArea
                            placeholder="Enter delivery instruction, door code, etc..."
                            autoSize={{ minRows: 3, maxRows: 6 }}
                          />
                        </Form.Item>
                      </>
                    )}
                  </>
                )}
              </>
            )}
            {socialConfig.instagram && (
              <Form.Item
                name={['social', 'instagram']}
                label="Instagram Handle"
                rules={compact([
                  socialConfig.instagram.required && {
                    required: true,
                    message: `Please enter your Instagram handle.`,
                  },
                ])}
              >
                <Input />
              </Form.Item>
            )}
            {socialConfig.youtube && (
              <Form.Item
                name={['social', 'youtube']}
                label="Youtube Handle"
                rules={compact([
                  socialConfig.youtube.required && {
                    required: true,
                    message: `Please enter your Youtube handle.`,
                  },
                ])}
              >
                <Input />
              </Form.Item>
            )}
            {socialConfig.tiktok && (
              <Form.Item
                name={['social', 'tiktok']}
                label="Tiktok Handle"
                rules={compact([
                  socialConfig.tiktok.required && {
                    required: true,
                    message: `Please enter your Tiktok handle.`,
                  },
                ])}
              >
                <Input />
              </Form.Item>
            )}
            {socialConfig.twitter && (
              <Form.Item
                name={['social', 'twitter']}
                label="Twitter Handle"
                rules={compact([
                  socialConfig.twitter.required && {
                    required: true,
                    message: `Please enter your Twitter handle.`,
                  },
                ])}
              >
                <Input />
              </Form.Item>
            )}
            {!isEmpty(infoUpdateConfig.customFields) && (
              <>
                <Divider className={styles.divider}>Additional Information</Divider>
                {map(infoUpdateConfig.customFields, (field) => {
                  const schema = find(schemas, (s) => s.id === field.schemaId);

                  // NOTE: antd would complain if we try to use the same Form.item
                  // and put if condition inside children
                  if (schema.type === MemberFieldSchemaType.Text) {
                    return (
                      <Form.Item
                        key={field.schemaId}
                        name={['fields', field.schemaId]}
                        label={field.alias || schema.name}
                        rules={compact([
                          field.required && {
                            required: true,
                            message: `Please enter your answer.`,
                          },
                        ])}
                      >
                        <Input />
                      </Form.Item>
                    );
                  }
                  if (schema.type === MemberFieldSchemaType.Boolean) {
                    return (
                      <Form.Item
                        key={field.schemaId}
                        name={['fields', field.schemaId]}
                        label={field.alias || schema.name}
                        valuePropName="checked"
                        rules={compact([
                          field.required && {
                            required: true,
                            message: `Please enter your answer.`,
                          },
                        ])}
                      >
                        <Checkbox />
                      </Form.Item>
                    );
                  }
                  if (schema.type === MemberFieldSchemaType.Date) {
                    return (
                      <Form.Item
                        key={field.schemaId}
                        name={['fields', field.schemaId]}
                        label={field.alias || schema.name}
                        rules={compact([
                          field.required && {
                            required: true,
                            message: `Please enter your answer.`,
                          },
                        ])}
                      >
                        <DatePicker />
                      </Form.Item>
                    );
                  }
                  if (schema.type === MemberFieldSchemaType.Number) {
                    return (
                      <Form.Item
                        key={field.schemaId}
                        name={['fields', field.schemaId]}
                        label={field.alias || schema.name}
                        rules={compact([
                          field.required && {
                            required: true,
                            message: `Please enter your answer.`,
                          },
                        ])}
                      >
                        <InputNumber />
                      </Form.Item>
                    );
                  }
                  if (schema.type === MemberFieldSchemaType.SingleSelect) {
                    const options = map(schema.config.options, (option) => ({
                      label: option,
                      value: option,
                    }));

                    return (
                      <Form.Item
                        key={field.schemaId}
                        name={['fields', field.schemaId]}
                        label={field.alias || schema.name}
                        rules={compact([
                          field.required && {
                            required: true,
                            message: `Please enter your answer.`,
                          },
                        ])}
                      >
                        <Select placeholder="Select options..." options={options} />
                      </Form.Item>
                    );
                  }
                  if (schema.type === MemberFieldSchemaType.MultiSelect) {
                    const options = map(schema.config.options, (option) => ({
                      label: option,
                      value: option,
                    }));

                    return (
                      <Form.Item
                        key={field.schemaId}
                        name={['fields', field.schemaId]}
                        label={field.alias || schema.name}
                        rules={compact([
                          field.required && {
                            required: true,
                            message: `Please enter your answer.`,
                          },
                        ])}
                      >
                        <Select
                          mode="multiple"
                          maxTagCount={2}
                          placeholder="Select options..."
                          options={options}
                        />
                      </Form.Item>
                    );
                  }

                  return null;
                })}
              </>
            )}
          </div>
          <Divider />
          <div className={styles.submit}>
            <Button
              className={styles.submitButton}
              type="primary"
              htmlType="submit"
              loading={isSubmitting}
            >
              Confirm Information
            </Button>
          </div>
        </>
      )}
    </Form>
  );
});
