import { gql } from '@apollo/client';

export const PROGRAM_COLLAB_CONTRACT_FRAGMENT = gql`
  fragment ProgramCollabContract on ProgramCollabContract {
    id
    status
    createdDate
    updatedDate
  }
`;
