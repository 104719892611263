import React from 'react';
import cx from 'classnames';
import { isEmpty } from 'lodash';

import { Button } from 'antd';
import { CheckIcon } from '@customIcons';
import { PickProductsPanel } from '@components';

import { CollabOrderItem, IProgramCollab, WorkflowEvent } from '@types';
import { useWorkflowCheckIn, useWorkflowPreCheckIn } from '@hooks';

import styles from './PickProductsButton.module.scss';

interface IProps {
  collab: IProgramCollab;

  isPreCheckIn?: boolean;
  className?: string;
}

const { useState, useMemo, useCallback } = React;

/**
 * @type {React.FC}
 */
export const PickProductsButton: React.FC<React.PropsWithChildren<IProps>> = React.memo(
  ({ collab, isPreCheckIn = false, className }) => {
    const [showModal, setShowModal] = useState(false);
    const { workflowCheckIn, loading: checkingIn } = useWorkflowCheckIn();
    const { workflowPreCheckIn, loading: preCheckingIn } = useWorkflowPreCheckIn();

    const loading = useMemo(() => checkingIn || preCheckingIn, [checkingIn, preCheckingIn]);
    const alreadyPrefilled = useMemo(() => {
      return isPreCheckIn && !isEmpty(collab.workflowItem.prefilledData.productFulfillment);
    }, [isPreCheckIn, collab]);
    const buttonLabel = useMemo(() => {
      if (alreadyPrefilled) {
        return 'Products Picked';
      }

      return 'Pick Products';
    }, [alreadyPrefilled]);
    const buttonType = useMemo(() => {
      if (!isPreCheckIn) {
        return 'primary';
      }

      if (!alreadyPrefilled) {
        return 'link';
      }

      return 'text';
    }, [isPreCheckIn, alreadyPrefilled]);
    const stopPropagation = useCallback((event: React.MouseEvent) => {
      // NOTE: do not preventDefault(), otherwise form submission wont work
      // do not open detail panel
      event.stopPropagation();
    }, []);
    const openModal = useCallback(() => setShowModal(true), []);
    const closeModal = useCallback(() => setShowModal(false), []);
    const handleSubmit = useCallback(
      async (items: CollabOrderItem[]) => {
        if (isPreCheckIn) {
          await workflowPreCheckIn({
            variables: {
              collabId: collab.id,
              params: {
                productFulfillment: {
                  preferredProducts: items,
                },
              },
            },
          });
        } else {
          await workflowCheckIn({
            variables: {
              collabId: collab.id,
              event: WorkflowEvent.ProductFulfillmentStagePickProducts,
              params: {
                productFulfillment: {
                  preferredProducts: items,
                },
              },
            },
          });
        }

        setShowModal(false);
      },
      [collab, isPreCheckIn, workflowCheckIn, workflowPreCheckIn],
    );

    return (
      <div onClick={stopPropagation}>
        <Button
          className={cx(styles.PickProductsButton, className, {
            [styles.prefilled]: alreadyPrefilled,
          })}
          shape="round"
          type={buttonType}
          onClick={openModal}
          icon={alreadyPrefilled && <CheckIcon />}
        >
          {buttonLabel}
        </Button>
        <PickProductsPanel
          program={collab.program}
          show={showModal}
          onRequestClose={closeModal}
          loading={loading}
          onSubmit={handleSubmit}
        />
      </div>
    );
  },
);
