import React, { useCallback } from 'react';
import qs from 'qs';
import { useHotkeys } from 'react-hotkeys-hook';
import { useNavigate, useLocation } from 'react-router-dom';
import { isEqual } from 'lodash';

import { ProgramOverlay } from './ProgramOverlay';
import { ProgramCollabOverlay } from './ProgramCollabOverlay';
import { AffiliateTrackingOverlay } from './AffiliateTrackingOverlay';

import { parseLocationSearch, useParsedRouteSearch } from '@utils';

type IEntityType = 'program' | 'collab' | 'affiliate_tracking';
interface IEntity {
  type: IEntityType;
  id: string;
}
interface IGlobalOverlayContext {
  entity: IEntity;
  setEntity(entity: IEntity): void;
  closeOverlay(): void;
}

const { useContext, useState, useEffect } = React;

const GlobalOverlayContext = React.createContext<IGlobalOverlayContext>(null);
export const useGlobalOverlayContext = () => useContext(GlobalOverlayContext);
export const GlobalOverlayContextProvider: React.FC<React.PropsWithChildren<{}>> = React.memo(
  ({ children }) => {
    const navigate = useNavigate();
    const location = useLocation();
    const urlSearch = useParsedRouteSearch();
    const [entity, setEntity] = useState<IEntity>(urlSearch.entity as IEntity);

    // NOTE: it's important to set entity as undefined here (instead of null)
    // because urlSearch.entity is undefined initially
    // and we compare urlSearch.entity with state entity to determine the actions
    const closeOverlay = useCallback(() => setEntity(undefined), [setEntity]);

    useHotkeys('esc', closeOverlay, [closeOverlay]);

    useEffect(() => {
      // NOTE: if a global panel opens another global panel
      // going back to the first panel will try to push another state to history
      // we need to avoid that, otherwise it takes two clicks to fully close the first panel
      if (!isEqual(location.state, entity)) {
        navigate(
          {
            ...location,
            search: qs.stringify(
              {
                ...parseLocationSearch(),
                entity,
              },
              { skipNulls: true, encode: false },
            ),
          },
          {
            // NOTE: save the entity in state to avoid pusing dup state
            // also we do not use replace here
            state: entity,
          },
        );
      }
      // eslint-disable-next-line
    }, [entity]);
    /**
     * this handles the brower go back.
     */
    useEffect(() => {
      const search = parseLocationSearch();

      if (!isEqual(search.entity, entity)) {
        setEntity(search.entity as IEntity);
      }
    }, [location, entity]);

    return (
      <GlobalOverlayContext.Provider
        value={{
          entity,
          setEntity,
          closeOverlay,
        }}
      >
        {children}
        <ProgramOverlay />
        <ProgramCollabOverlay />
        <AffiliateTrackingOverlay />
      </GlobalOverlayContext.Provider>
    );
  },
);
