import React from 'react';
import firebase from 'firebase/compat/app';
import { map, sortBy, unionBy } from 'lodash';

import { IMessageThread, IProgramCollab, MessageThreadFilters } from '@types';
import { useGetMessageThreads, useGetMessageThreadCount } from '@hooks';
import { useAppContext, useProfileContext } from '@context';

type TTextMessageFrom = 'manager' | 'creator' | 'system';
export interface ITextMessage {
  id: string;
  from: TTextMessageFrom;
  timestamp: firebase.firestore.Timestamp;
  data: string;

  // only manager/creator message has user
  user?: {
    id: string;
    name: string;
    email: string;
    profileImage: string;
  };
}
type ICollabMessageKind = 'text' | 'email';
export type ICollabMessage = (IMessageThread | ITextMessage) & {
  kind?: ICollabMessageKind;
  ts?: number;
};
export interface ICollabThreadsContextProps {
  collab: Pick<IProgramCollab, 'id' | 'programId' | 'memberId'>;
}
interface ICollabThreadsContext {
  collab: Pick<IProgramCollab, 'id' | 'programId' | 'memberId'>;

  loading: boolean;
  count: number;
  threads: IMessageThread[];
  messages: ICollabMessage[];
  refetch(): void;

  sendTextMessage(text: string): Promise<void>;
}

const { useContext, useEffect, useState, useMemo, useCallback } = React;

const CollabThreadsContext = React.createContext<ICollabThreadsContext>(null);
export const useCollabThreadsContext = () => useContext(CollabThreadsContext);
export const CollabThreadsContextProvider: React.FC<
  React.PropsWithChildren<ICollabThreadsContextProps>
> = React.memo(({ collab, children }) => {
  const { firebaseApp } = useAppContext();
  const { profile } = useProfileContext();
  const [textMessages, setTextMessages] = useState<ITextMessage[]>([]);
  const [loadingTexts, setLoadingTexts] = useState(false);

  const filters: MessageThreadFilters = useMemo(
    () => ({
      collabId: collab.id,
    }),
    [collab],
  );

  const {
    count,
    loading: loadingCount,
    refetch: refetchCount,
  } = useGetMessageThreadCount({
    variables: {
      filters,
    },
  });
  const {
    threads,
    loading: loadingThreads,
    refetch: refetchThreads,
  } = useGetMessageThreads({
    variables: {
      filters,
      limit: 0,
    },
  });

  const loading = useMemo(
    () => loadingThreads || loadingCount || loadingTexts,
    [loadingThreads, loadingCount, loadingTexts],
  );
  const refetch = useCallback(() => {
    refetchThreads();
    refetchCount();
  }, [refetchThreads, refetchCount]);
  const messages: ICollabMessage[] = useMemo(() => {
    const formattedThreads: ICollabMessage[] = map(threads, (t) => ({
      ...t,
      kind: 'email',
      ts: t.internalDate,
    }));
    const formattedTextMessages: ICollabMessage[] = map(textMessages, (m) => ({
      ...m,
      kind: 'text',
      ts: m.timestamp.toMillis(),
    }));

    return sortBy([...formattedThreads, ...formattedTextMessages], 'ts');
  }, [threads, textMessages]);
  const sendTextMessage = useCallback(
    async (text: string) => {
      const db = firebaseApp.firestore();
      const messagesRef = db.collection(collab.id);

      try {
        const message: Omit<ITextMessage, 'id'> = {
          from: 'creator',
          timestamp:
            firebase.firestore.FieldValue.serverTimestamp() as firebase.firestore.Timestamp,

          data: text,
          user: {
            id: profile.id,
            name: profile.name,
            email: profile.email,
            profileImage: profile.profileImage,
          },
        };
        await messagesRef.doc().set(message);
      } catch (err) {
        console.log('err', err);
      }
    },
    [firebaseApp, profile, collab],
  );

  // update chat users
  useEffect(() => {
    let mounted = true;

    setLoadingTexts(true);

    const db = firebaseApp.firestore();
    const messagesQuery = db.collection(collab.id).orderBy('timestamp', 'desc');

    // fetch previous textMessages
    messagesQuery.get().then((snapshot) => {
      const textMessages = snapshot.docs.map((doc) => ({
        id: doc.id,
        ...doc.data(),
      }));

      if (mounted) {
        setTextMessages(textMessages as ITextMessage[]);
      }

      setLoadingTexts(false);
    });

    const unsub = messagesQuery.onSnapshot((snapshot) => {
      const newMessages = snapshot.docChanges().map((d) => {
        const data = d.doc.data();

        return {
          id: d.doc.id,
          ...data,
          // server timestamp is null at the time when the message is sent
          timestamp: data.timestamp || firebase.firestore.Timestamp.now(),
        };
      });

      setTextMessages((textMessages) => unionBy(newMessages as ITextMessage[], textMessages, 'id'));
    });

    return () => {
      unsub();
      mounted = false;
    };
  }, [collab, setTextMessages, firebaseApp]);

  useEffect(() => {
    refetchThreads({ filters });
    refetchCount({ filters });
  }, [refetchThreads, refetchCount, filters]);

  return (
    <CollabThreadsContext.Provider
      value={{
        collab,

        loading,
        count,
        threads,
        messages,
        refetch,

        sendTextMessage,
      }}
    >
      {children}
    </CollabThreadsContext.Provider>
  );
});
