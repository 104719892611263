import { gql } from '@apollo/client';

export const CONTENT_REVIEW_FRAGMENT = gql`
  fragment ContentReview on ContentReview {
    id
    collabId
    status
    contentIds
    requirement {
      type
      dueDateTs
      guidelines {
        conceptAndFormat
        talkingPoints
        caption
        postInstructions
        other
      }
    }
    info {
      caption
      brandComment
      previousContentIds
      updatedTs
      approvedTs
      approvedBy
      rejectedTs
      rejectedBy
    }
    createdDate
    updatedDate
  }
`;
