import React from 'react';
import { useQuery, QueryHookOptions } from '@apollo/client';
import { isEmpty } from 'lodash';

import { message } from 'antd';

import { GET_TRACKING_CONVERSIONS_QUERY } from '@queries';
import { GetTrackingConversionsQuery, GetTrackingConversionsQueryVariables } from '@types';

type IOptions = QueryHookOptions<GetTrackingConversionsQuery, GetTrackingConversionsQueryVariables>;

const { useEffect, useMemo } = React;

export function useGetTrackingConversions(options: IOptions = {}) {
  const {
    loading,
    data: { orders = [] } = {},
    error,
    refetch,
  } = useQuery<GetTrackingConversionsQuery, GetTrackingConversionsQueryVariables>(
    GET_TRACKING_CONVERSIONS_QUERY,
    {
      ...options,
      skip: isEmpty(options.variables?.filters?.collabId),
      fetchPolicy: 'cache-and-network',
      nextFetchPolicy: 'cache-first',
      notifyOnNetworkStatusChange: true,
    },
  );

  const errorMessage = useMemo(() => error?.message, [error]);
  useEffect(() => {
    if (!loading && errorMessage) {
      message.error(errorMessage);
    }
  }, [loading, errorMessage]);

  return {
    loading,
    orders,
    error,
    refetch,
  };
}
