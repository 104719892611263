import { gql } from '@apollo/client';

export const PROFILE_FRAGMENT = gql`
  fragment Profile on Creator {
    id
    email
    name
    profileImage
    metadata {
      lastNotificationOpened
      fcmToken
    }
    createdDate
    updatedDate
  }
`;
