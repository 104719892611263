import React from 'react';
import cx from 'classnames';

import { Drawer } from 'antd';
import { PostTrackingList } from './PostTrackingList';

import styles from './SelectPostTrackingPanel.module.scss';

interface IProps {
  collabId: string;
  show: boolean;
  onRequestClose(): void;

  onComplete?(): void;
  className?: string;
}

/**
 * @type {React.FC}
 */
export const SelectPostTrackingPanel: React.FC<React.PropsWithChildren<IProps>> = React.memo(
  ({ collabId, show, onRequestClose, className }) => {
    return (
      <Drawer
        className={cx(styles.SelectPostTrackingPanel, className)}
        open={show}
        onClose={onRequestClose}
        title="Submit Posts"
        footer={null}
        width={1100}
      >
        <PostTrackingList collabId={collabId} />
      </Drawer>
    );
  },
);
