import React from 'react';
import cx from 'classnames';
import { size } from 'lodash';

import { Badge, Button, Popover } from 'antd';
import { CartShoppingIcon } from '@customIcons';
import { SelectedItems } from './SelectedItems';

import { usePickProductsContext } from '../context';

import styles from './ShoppingCart.module.scss';

interface IProps {
  className?: string;
}

/**
 * @type {React.FC}
 */
export const ShoppingCart: React.FC<IProps> = React.memo(({ className }) => {
  const { items } = usePickProductsContext();

  return (
    <Popover
      className={cx(styles.ShoppingCart, className)}
      content={<SelectedItems />}
      trigger="click"
      placement="bottomLeft"
    >
      <Badge
        count={size(items)}
        overflowCount={9}
        style={{ backgroundColor: '#1890ff' }}
        size="small"
      >
        <Button className={styles.button} icon={<CartShoppingIcon />} shape="circle" type="text" />
      </Badge>
    </Popover>
  );
});
