import React from 'react';
import { useQuery, QueryHookOptions } from '@apollo/client';

import { message } from 'antd';

import { GET_PROGRAMS_QUERY } from '@queries';
import { GetProgramsQuery, GetProgramsQueryVariables } from '@types';

type IOptions = QueryHookOptions<GetProgramsQuery, GetProgramsQueryVariables>;

const { useEffect, useMemo } = React;

export function useGetPrograms(options: IOptions = {}) {
  const {
    loading,
    data: { programs = [] } = {},
    error,
    refetch,
  } = useQuery<GetProgramsQuery, GetProgramsQueryVariables>(GET_PROGRAMS_QUERY, {
    ...options,
    fetchPolicy: 'cache-and-network',
    nextFetchPolicy: 'cache-first',
    notifyOnNetworkStatusChange: true,
  });

  const errorMessage = useMemo(() => error?.message, [error]);
  useEffect(() => {
    if (!loading && errorMessage) {
      message.error(errorMessage);
    }
  }, [loading, errorMessage]);

  return {
    loading,
    programs,
    error,
    refetch,
  };
}
