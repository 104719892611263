import { startsWith } from 'lodash';

import { ContentType } from '@types';

export function contentTypeFromMimeType(mimeType: string): ContentType {
  if (startsWith(mimeType, 'image')) {
    return ContentType.Image;
  } else if (startsWith(mimeType, 'video')) {
    return ContentType.Video;
  }

  return ContentType.Application;
}
