import React from 'react';
import cx from 'classnames';
import { noop } from 'lodash';

import { Tabs } from 'antd';
import { TextReply } from './TextReply';

import { IProgramCollab } from '@types';

import styles from './NewThread.module.scss';

interface IProps {
  collab: Pick<IProgramCollab, 'id' | 'programId' | 'memberId'>;

  onComplete?(): void;
  className?: string;
}
type TReplyMode = 'text';
const tabItems: Array<{
  key: TReplyMode;
  label: string;
}> = [
  {
    key: 'text',
    label: 'Message',
  },
];

const { useState, useCallback } = React;

/**
 * @type {React.FC}
 */
export const NewThread: React.FC<React.PropsWithChildren<IProps>> = React.memo(
  ({ onComplete = noop, className }) => {
    const [replyMode, setReplyMode] = useState<TReplyMode>('text');
    const [focused, setFocused] = useState(false);

    const onFocus = useCallback(() => setFocused(true), []);
    const onBlur = useCallback(() => setFocused(false), []);
    const onReplyModeChange = useCallback((mode: string) => setReplyMode(mode as TReplyMode), []);

    return (
      <div
        className={cx(styles.NewThread, className, {
          [styles.focused]: focused,
        })}
        onFocus={onFocus}
        onBlur={onBlur}
      >
        <Tabs
          className={styles.tabs}
          size="small"
          tabBarGutter={12}
          activeKey={replyMode}
          onChange={onReplyModeChange}
          items={tabItems}
        />
        {replyMode === 'text' && <TextReply className={styles.reply} onComplete={onComplete} />}
      </div>
    );
  },
);
