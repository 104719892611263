import React from 'react';
import { useNavigate } from 'react-router-dom';
import cx from 'classnames';

import { Typography, Button, Divider } from 'antd';
import { EmailPasswordForm } from './EmailPasswordForm';
import { icons } from '@components';

import { useAuthContext } from '@context';

import styles from './Login.module.scss';

interface IProps {
  className?: string;
}

const { useCallback, useEffect } = React;
const { Title } = Typography;

/**
 * @type {React.FC}
 */
export const Login: React.FC<React.PropsWithChildren<IProps>> = React.memo(({ className }) => {
  const navigate = useNavigate();
  const { signInWithGoogle, user } = useAuthContext();

  const goToSignUp = useCallback(() => navigate('/signup'), [navigate]);

  useEffect(() => {
    if (user) {
      navigate('/');
    }
  }, [user, navigate]);

  return (
    <div className={cx(styles.Login, className)}>
      <div className={styles.logo} />
      <div className={styles.content}>
        <Title className={styles.title} level={3}>
          Welcome back
        </Title>
        <EmailPasswordForm className={styles.form} />
        <Divider>OR</Divider>
        <div className={styles.social}>
          <Button
            className={styles.button}
            size="large"
            icon={<icons.GoogleIcon />}
            onClick={signInWithGoogle}
          >
            Continue with Google
          </Button>
        </div>
        <div className={styles.extra}>
          Do not have an account?{' '}
          <span className={styles.signUp} onClick={goToSignUp}>
            Sign Up
          </span>{' '}
          for Superbloom.
        </div>
      </div>
      <div className={styles.footer}>
        <Button type="link">
          <a href="https://superbloom.one/terms-of-service" target="_blank" rel="noreferrer">
            Terms of service
          </a>
        </Button>
        <Divider type="vertical" />
        <Button type="link">
          <a href="https://superbloom.one/privacy-policy" target="_blank" rel="noreferrer">
            Privacy policy
          </a>
        </Button>
      </div>
    </div>
  );
});
