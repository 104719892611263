import React from 'react';
import cx from 'classnames';
import { formatDistanceToNow } from 'date-fns';
import { startCase } from 'lodash';

import { UserAvatar, ContentReviewStatusTag, SocialPostIcon } from '@components';
import { SubmitContentPanel } from './SubmitContentPanel';

import { IContentReview, ContentReviewStatus, IProgramCollab } from '@types';

import styles from './ContentReviewItem.module.scss';

interface IProps {
  collab: IProgramCollab;
  review: IContentReview;

  className?: string;
}

const { useState, useMemo, useCallback } = React;

/**
 * @type {React.FC}
 */
export const ContentReviewItem: React.FC<React.PropsWithChildren<IProps>> = React.memo(
  ({ collab, review, className }) => {
    const [showModal, setShowModal] = useState(false);

    const canUploadContents = useMemo(() => {
      return [ContentReviewStatus.PendingUpload, ContentReviewStatus.Rejected].includes(
        review.status,
      );
    }, [review]);
    const onShowModal = useCallback(() => setShowModal(true), []);
    const onCloseModal = useCallback(() => setShowModal(false), []);

    return (
      <>
        <div
          className={cx(styles.ContentReviewItem, className, {
            [styles.active]: canUploadContents,
          })}
          onClick={onShowModal}
        >
          <div className={styles.left}>
            <div className={styles.title}>
              <UserAvatar
                className={styles.name}
                name={startCase(review.requirement.type)}
                icon={<SocialPostIcon type={review.requirement.type} size="small" />}
                showBoth
              />
              {review.requirement.dueDateTs && (
                <div className={styles.dueDate}>
                  due in <b>{formatDistanceToNow(review.requirement.dueDateTs)}</b>
                </div>
              )}
            </div>
            <div className={styles.info}>
              <span className={styles.entry}>
                Last updated {formatDistanceToNow(new Date(review.updatedDate))} ago
              </span>
            </div>
          </div>
          <ContentReviewStatusTag className={styles.status} status={review.status} />
        </div>
        <SubmitContentPanel
          collab={collab}
          review={review}
          show={showModal}
          onRequestClose={onCloseModal}
        />
      </>
    );
  },
);
