import React from 'react';
import cx from 'classnames';
import { isEmpty } from 'lodash';

import { Button } from 'antd';
import { CheckIcon } from '@customIcons';
import { InfoUpdatePanel } from '@components';

import {
  IProgramCollab,
  WorkflowCheckInParams,
  WorkflowEvent,
  WorkflowInfoUpdateStageCheckInParams,
} from '@types';
import { useWorkflowCheckIn, useWorkflowPreCheckIn } from '@hooks';

import styles from './UpdateInfoButton.module.scss';

interface IProps {
  collab: IProgramCollab;

  isPreCheckIn?: boolean;
  className?: string;
}

const { useState, useMemo, useCallback } = React;

/**
 * @type {React.FC}
 */
export const UpdateInfoButton: React.FC<React.PropsWithChildren<IProps>> = React.memo(
  ({ collab, isPreCheckIn = false, className }) => {
    const [showModal, setShowModal] = useState(false);
    const { workflowCheckIn, loading: checkingIn } = useWorkflowCheckIn();
    const { workflowPreCheckIn, loading: preCheckingIn } = useWorkflowPreCheckIn();

    const loading = useMemo(() => checkingIn || preCheckingIn, [checkingIn, preCheckingIn]);
    const alreadyPrefilled = useMemo(() => {
      return isPreCheckIn && !isEmpty(collab.workflowItem.prefilledData.infoUpdate);
    }, [isPreCheckIn, collab]);
    const buttonLabel = useMemo(() => {
      if (alreadyPrefilled) {
        return 'Info Updated';
      }

      return 'Update Info';
    }, [alreadyPrefilled]);
    const buttonType = useMemo(() => {
      if (!isPreCheckIn) {
        return 'primary';
      }

      if (!alreadyPrefilled) {
        return 'link';
      }

      return 'text';
    }, [isPreCheckIn, alreadyPrefilled]);
    const onWrapperClick = useCallback((event: React.MouseEvent) => {
      // NOTE: do not preventDefault(), otherwise form submission wont work
      // do not open detail panel
      event.stopPropagation();
    }, []);
    const onShowModal = useCallback(() => setShowModal(true), []);
    const onCloseModal = useCallback(() => setShowModal(false), []);
    const handleSubmit = useCallback(
      async (params: WorkflowInfoUpdateStageCheckInParams) => {
        const checkInParams: WorkflowCheckInParams = {
          infoUpdate: params,
        };

        if (isPreCheckIn) {
          await workflowPreCheckIn({
            variables: {
              collabId: collab.id,
              params: checkInParams,
            },
          });
        } else {
          await workflowCheckIn({
            variables: {
              collabId: collab.id,
              event: WorkflowEvent.InfoUpdateStageUpdateInfo,
              params: checkInParams,
            },
          });
        }

        setShowModal(false);
      },
      [collab, isPreCheckIn, workflowCheckIn, workflowPreCheckIn],
    );

    return (
      <div onClick={onWrapperClick}>
        <Button
          className={cx(styles.UpdateInfoButton, className, {
            [styles.prefilled]: alreadyPrefilled,
          })}
          shape="round"
          type={buttonType}
          onClick={onShowModal}
          icon={alreadyPrefilled && <CheckIcon />}
        >
          {buttonLabel}
        </Button>
        <InfoUpdatePanel
          show={showModal}
          onRequestClose={onCloseModal}
          program={collab.program}
          loading={loading}
          onSubmit={handleSubmit}
        />
      </div>
    );
  },
);
