import React from 'react';
import numeral from 'numeral';
import cx from 'classnames';
import { map } from 'lodash';

import { Select, SelectProps } from 'antd';
import { HeartOutlined, UserOutlined } from '@ant-design/icons';
import { SocialAccountIcon, UserAvatar } from '@components';

import type { SizeType } from 'antd/lib/config-provider/SizeContext';
import { AuthorizationResourceStatus, ICreatorAccountResource } from '@types';

import styles from './CreatorAccountResourceSelect.module.scss';

interface IProps extends SelectProps<any> {
  resources: ICreatorAccountResource[];

  className?: string;
}

const { useMemo } = React;
const AvatarSizeForSelectSize: Record<SizeType, number> = {
  small: 14,
  middle: 18,
  large: 20,
};

/**
 * @type {React.FC}
 */
export const CreatorAccountResourceSelect: React.FC<React.PropsWithChildren<IProps>> = React.memo(
  ({ resources, className, placeholder = 'Select account...', size = 'middle', ...rest }) => {
    const options = useMemo(
      () =>
        map(resources, ({ id, authorizationResource: r, account }) => {
          return {
            label: (
              <div className={styles.SelectOption}>
                <UserAvatar
                  className={styles.avatar}
                  size={AvatarSizeForSelectSize[size] || 18}
                  name={account.info.displayName}
                  icon={<SocialAccountIcon type={account.type} />}
                  showBoth
                />
                {r.status !== AuthorizationResourceStatus.Active && (
                  <div className={styles.status}>(inactive)</div>
                )}
                <div className={styles.stats}>
                  <div className={styles.item}>
                    <UserOutlined />
                    <span className={styles.count}>
                      {numeral(account.stats.followers).format('0.[00] a')}
                    </span>
                  </div>
                  <div className={styles.dot} />
                  <div className={styles.item}>
                    <HeartOutlined />
                    <span className={styles.count}>
                      {numeral(account.stats.engagement).format('0.[00] a')}
                    </span>
                  </div>
                </div>
              </div>
            ),
            value: id,
            name: account.info.displayName,
          };
        }),
      [resources, size],
    );

    return (
      <Select
        className={cx(styles.CreatorAccountResourceSelect, className)}
        popupClassName={styles.SelectDropdown}
        {...rest}
        size={size}
        options={options}
        placeholder={placeholder}
        optionFilterProp="name"
      />
    );
  },
);
