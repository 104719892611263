import React from 'react';
import cx from 'classnames';
import { toLower } from 'lodash';

import { Tag } from 'antd';

import { ProgramStatus } from '@types';

import styles from './ProgramStatusTag.module.scss';

interface IProps {
  status: ProgramStatus;

  className?: string;
}

const StatusColor: Record<ProgramStatus, string> = {
  [ProgramStatus.Active]: 'green',
  [ProgramStatus.INACTIVE]: 'gray',
  [ProgramStatus.PENDING]: 'cyan',
} as const;

/**
 * @type {React.FC}
 */
export const ProgramStatusTag: React.FC<React.PropsWithChildren<IProps>> = React.memo(
  ({ status, className }) => {
    return (
      <Tag color={StatusColor[status]} className={cx(styles.ProgramStatusTag, className)}>
        {toLower(status)}
      </Tag>
    );
  },
);
