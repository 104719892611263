import { find } from 'lodash';

import { IGooglePlaceComponent, IGooglePlace } from '../types';

export function parseRawAddress(place: google.maps.places.PlaceResult): IGooglePlace {
  const components = place.address_components;

  return {
    streetNumber: findAddressComponent(components, 'street_number'),
    route: findAddressComponent(components, 'route'),
    locality: findAddressComponent(components, 'locality'),
    levelTwo: findAddressComponent(components, 'administrative_area_level_2'),
    levelOne: findAddressComponent(components, 'administrative_area_level_1'),
    country: findAddressComponent(components, 'country'),
    postalCode: findAddressComponent(components, 'postal_code'),

    fullAddress: place.formatted_address,
    placeId: place.place_id,
  };
}

function findAddressComponent(
  components: google.maps.GeocoderAddressComponent[],
  type: string,
): IGooglePlaceComponent {
  const component = find(components, (component) => component.types.includes(type));

  return {
    longName: component?.long_name,
    shortName: component?.short_name,
  };
}
