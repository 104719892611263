import React from 'react';
import cx from 'classnames';

import { Upload } from 'antd';
import { CloudUploadOutlined } from '@ant-design/icons';
import { FileContent, Image, Video } from '@components';

import type { UploadProps, UploadFile } from 'antd';
import type { UploadChangeParam } from 'antd/lib/upload';

import { contentTypeFromMimeType } from '@utils';
import { ContentType } from '@types';

import styles from './SingleMediaUpload.module.scss';

interface IPreviewMedia {
  type: ContentType;
  url: string;
  name: string;
}
interface IProps extends UploadProps {
  initialMedia?: IPreviewMedia;
  className?: string;
}

const { useState, useCallback } = React;

/**
 * @type {React.FC}
 */
export const SingleMediaUpload: React.FC<IProps> = React.memo(
  ({
    initialMedia = null,
    onChange: propOnChange,
    className,
    // Upload props
    accept = 'image/*, video/*, application/*',
    ...uploadProps
  }) => {
    const [previewMedia, setPreviewMedia] = useState<IPreviewMedia>(initialMedia);

    const onBeforeUpload = useCallback(() => false, []);
    const onChange = useCallback(
      (info: UploadChangeParam<UploadFile<any>>) => {
        const type = contentTypeFromMimeType(info?.file.type);
        const url = URL.createObjectURL(info?.file as any);

        setPreviewMedia({
          type,
          url,
          name: info?.file.name,
        });
        propOnChange(info);
      },
      [propOnChange],
    );

    return (
      <Upload.Dragger
        {...uploadProps}
        className={cx(styles.SingleMediaUpload, className)}
        multiple={false}
        beforeUpload={onBeforeUpload}
        accept={accept}
        showUploadList={false}
        onChange={onChange}
      >
        {previewMedia && (
          <div className={styles.preview}>
            {previewMedia.type === ContentType.Image && (
              <Image src={previewMedia.url} className={styles.image} />
            )}
            {previewMedia.type === ContentType.Video && (
              <Video className={styles.video} src={previewMedia.url} showDuration />
            )}
            {previewMedia.type === ContentType.Application && (
              <FileContent className={styles.file} name={previewMedia.name} />
            )}
          </div>
        )}
        {!previewMedia && (
          <p className="ant-upload-drag-icon">
            <CloudUploadOutlined />
          </p>
        )}
        <p className="ant-upload-hint">Click or drag media to this area to upload</p>
      </Upload.Dragger>
    );
  },
);
