import { gql } from '@apollo/client';

export const COLLAB_TERM_ITERATION_FRAGMENT = gql`
  fragment CollabTermIteration on CollabTermIteration {
    id
    termId
    baseIterationId
    data {
      contentReview {
        requirements {
          type
          accountResourceId
          dueDateTs
          guidelines {
            conceptAndFormat
            talkingPoints
            caption
            postInstructions
            other
          }
        }
        usageRights
        usageDuration
        customUsageRights
      }
      payment {
        amount
      }
      salesTracking {
        incentive {
          type
          value
          cpsValue
        }
      }
    }
  }
`;
