import React from 'react';
import { useMutation, MutationHookOptions, MutationFunctionOptions } from '@apollo/client';

import { message } from 'antd';

import { MARK_PROGRAM_COLLAB_AS_READ_MUTATION } from '@queries';
import { MarkProgramCollabAsReadMutation, MarkProgramCollabAsReadMutationVariables } from '@types';

type IOptions = MutationHookOptions<
  MarkProgramCollabAsReadMutation,
  MarkProgramCollabAsReadMutationVariables
>;
type IFunctionOptions = MutationFunctionOptions<
  MarkProgramCollabAsReadMutation,
  MarkProgramCollabAsReadMutationVariables
>;

const { useCallback } = React;

export const useMarkProgramCollabAsRead = (options: IOptions = {}) => {
  const [_markProgramCollabAsRead, { loading, error }] = useMutation<
    MarkProgramCollabAsReadMutation,
    MarkProgramCollabAsReadMutationVariables
  >(MARK_PROGRAM_COLLAB_AS_READ_MUTATION, {
    ...options,
  });

  const markProgramCollabAsRead = useCallback(
    async (options: IFunctionOptions = {}) => {
      try {
        const result = await _markProgramCollabAsRead(options);

        return result;
      } catch (err) {
        message.error((err as Error).message);
      }
    },
    [_markProgramCollabAsRead],
  );

  return {
    markProgramCollabAsRead,
    loading,
    error,
  };
};
