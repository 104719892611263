import React from 'react';
import { useNavigate } from 'react-router-dom';
import cx from 'classnames';

import { Typography, Button, Divider, Alert } from 'antd';
import { ArrowLeftOutlined } from '@ant-design/icons';
import { SignUpForm } from './SignUpForm';

import { useAuthContext } from '@context';

import styles from './SignUp.module.scss';

interface IProps {
  className?: string;
}

const { useState, useCallback, useEffect } = React;
const { Title } = Typography;

/**
 * @type {React.FC}
 */
export const SignUp: React.FC<React.PropsWithChildren<IProps>> = React.memo(({ className }) => {
  const navigate = useNavigate();
  const { user } = useAuthContext();
  const [signedUp, setSignedUp] = useState(false);

  const onSignUpCompleted = useCallback(() => setSignedUp(true), []);
  const goToLogin = useCallback(() => navigate('/login'), [navigate]);

  useEffect(() => {
    if (user) {
      navigate('/');
    }
  }, [user, navigate]);

  return (
    <div className={cx(styles.SignUp, className)}>
      <div className={styles.logo} />
      <div className={styles.content}>
        <Title className={styles.title} level={3}>
          Welcome to Superbloom
        </Title>
        {!signedUp && <SignUpForm className={styles.form} onComplete={onSignUpCompleted} />}
        {signedUp && (
          <>
            <Alert
              className={styles.notice}
              type="info"
              showIcon
              message="An account has been created for you, please check your emails."
            />
            <Divider />
            <Button
              className={styles.button}
              type="text"
              onClick={goToLogin}
              icon={<ArrowLeftOutlined />}
            >
              Go to Login
            </Button>
          </>
        )}
      </div>
      <div className={styles.footer}>
        <Button type="link">
          <a href="https://superbloom.one/terms-of-service" target="_blank" rel="noreferrer">
            Terms of service
          </a>
        </Button>
        <Divider type="vertical" />
        <Button type="link">
          <a href="https://superbloom.one/privacy-policy" target="_blank" rel="noreferrer">
            Privacy policy
          </a>
        </Button>
      </div>
    </div>
  );
});
