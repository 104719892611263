import React from 'react';
import cx from 'classnames';
import { isEmpty, map } from 'lodash';

import { Descriptions, Tag } from 'antd';
import { CopyLinkButton, DiscountCodeTag, TrackingIncentiveTag, UserAvatar } from '@components';

import { getDiscountCodeInfo, getSalesTrackingIncentiveTitle } from '@utils';
import { DiscountCodeSelection, IProgramCollabDetail } from '@types';

import styles from './SalesTrackingPanelContent.module.scss';

interface IProps {
  collab: IProgramCollabDetail;

  className?: string;
}

const { useMemo } = React;

/**
 * @type {React.FC}
 */
export const SalesTrackingPanelContent: React.FC<React.PropsWithChildren<IProps>> = React.memo(
  ({ collab, className }) => {
    const salesTracking = useMemo(() => collab.salesTracking, [collab]);
    const incentive = useMemo(() => salesTracking?.info.incentive, [salesTracking]);
    const [discountCode, shared] = useMemo(() => {
      const uniqueDiscountCode = salesTracking?.discountCode;
      const sharedDiscountCode = salesTracking?.sharedDiscountCode?.shopifyDiscountCode;
      const discountCode = uniqueDiscountCode || sharedDiscountCode;

      return [discountCode, !!sharedDiscountCode];
    }, [salesTracking]);
    const trackingUrl = useMemo(() => salesTracking?.info.trackingUrl, [salesTracking]);
    const recruitUrl = useMemo(() => salesTracking?.info.recruitUrl, [salesTracking]);

    if (!salesTracking) {
      return null;
    }

    return (
      <div className={cx(styles.SalesTrackingPanelContent, className)}>
        <Descriptions className={styles.section} title="Collab Incentive" column={1}>
          <Descriptions.Item label="Commission Type">
            <Tag>{getSalesTrackingIncentiveTitle(incentive.type)}</Tag>
          </Descriptions.Item>
          <Descriptions.Item label="Commission Value">
            <TrackingIncentiveTag incentive={incentive} />
          </Descriptions.Item>
        </Descriptions>
        <Descriptions className={styles.section} title="Discount Code and Tracking Link" column={1}>
          {discountCode && (
            <>
              <Descriptions.Item label="Code">
                <DiscountCodeTag discountCode={discountCode} shared={shared} />
              </Descriptions.Item>
              <Descriptions.Item label="Discount Info">
                <div className={styles.info}>
                  {map(getDiscountCodeInfo(discountCode), (str, index) => (
                    <div key={index}>• {str}</div>
                  ))}
                </div>
              </Descriptions.Item>
              {!isEmpty(discountCode.info.entities) && (
                <Descriptions.Item
                  label={`Discounted ${
                    discountCode.info.selection === DiscountCodeSelection.SPECIFIC_COLLECTIONS
                      ? 'Collection'
                      : 'Product'
                  }(s)`}
                >
                  <div className={styles.products}>
                    {map(discountCode.info.entities, (entity) => (
                      <div key={entity.id} className={styles.item}>
                        <UserAvatar
                          className={styles.avatar}
                          picture={entity.image}
                          name={entity.title}
                          showBoth
                          shape="square"
                          size="small"
                        />
                      </div>
                    ))}
                  </div>
                </Descriptions.Item>
              )}
            </>
          )}
          {trackingUrl && (
            <Descriptions.Item label="Tracking Link">
              <CopyLinkButton link={trackingUrl} />
            </Descriptions.Item>
          )}
          {recruitUrl && (
            <Descriptions.Item label="Recruit Link">
              <CopyLinkButton link={recruitUrl} />
            </Descriptions.Item>
          )}
        </Descriptions>
      </div>
    );
  },
);
