import React from 'react';
import cx from 'classnames';
import { map } from 'lodash';

import { AttachmentItem } from './AttachmentItem';

import { IContent } from '@types';

import styles from './AttachmentList.module.scss';

interface IProps {
  attachments: IContent[];

  className?: string;
}

/**
 * @type {React.FC}
 */
export const AttachmentList: React.FC<React.PropsWithChildren<IProps>> = React.memo(
  ({ attachments, className }) => {
    return (
      <div className={cx(styles.AttachmentList, className)}>
        {map(attachments, (attachment) => (
          <AttachmentItem
            className={styles.attachment}
            key={attachment.id}
            attachment={attachment}
          />
        ))}
      </div>
    );
  },
);
