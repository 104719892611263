import React from 'react';
import cx from 'classnames';
import { find, map, noop, startCase, toLower } from 'lodash';

import { Button, Form, Alert, Divider, Select, Input, InputNumber } from 'antd';
import { PlusOutlined } from '@ant-design/icons';
import { ContentRequirementsFormItem, ContentUsageRights } from '@components';

import { SalesTrackingIncentiveOptions } from '@utils';
import type { DefaultOptionType } from 'antd/lib/select';
import {
  WorkflowStage,
  ICollabTermData,
  LicensedContentUsageRights,
  LicensedContentUsageDuration,
  IProgram,
  ICreatorAccountResource,
  SalesTrackingIncentiveType,
} from '@types';

import styles from './SendTermForm.module.scss';

interface IProps {
  program: IProgram;
  accountResources: ICreatorAccountResource[];
  initialValues: ICollabTermData;

  isSubmitting: boolean;
  onSubmit(params: ICollabTermData): void;

  errorMessage?: string;
  onCancel?(): void;
  submitLabel?: string;
  className?: string;
}

const { useMemo } = React;
const { TextArea } = Input;
const usageTypeOptions: DefaultOptionType[] = map(LicensedContentUsageRights, (type) => ({
  value: type,
  label: startCase(toLower(type)),
}));
const usageDurationOptions: DefaultOptionType[] = map(
  [
    LicensedContentUsageDuration.ONE_MONTH,
    LicensedContentUsageDuration.THREE_MONTHS,
    LicensedContentUsageDuration.SIX_MONTHS,
    LicensedContentUsageDuration.ONE_YEAR,
    LicensedContentUsageDuration.TWO_YEARS,
  ],
  (duration) => ({
    value: duration,
    label: startCase(toLower(duration)),
  }),
);

export const SendTermForm: React.FC<React.PropsWithChildren<IProps>> = React.memo(
  ({
    program,
    accountResources,
    initialValues,
    onSubmit,
    isSubmitting,
    onCancel = noop,
    errorMessage,
    submitLabel = 'Propose Terms',
    className,
  }) => {
    const [form] = Form.useForm();
    const usageRights = Form.useWatch<LicensedContentUsageRights>(
      ['contentReview', 'usageRights'],
      form,
    );
    const usageDuration = Form.useWatch<LicensedContentUsageDuration>(
      ['contentReview', 'usageDuration'],
      form,
    );
    const customUsageRights = Form.useWatch<string>(['contentReview', 'customUsageRights'], form);
    const incentiveType = Form.useWatch<SalesTrackingIncentiveType>(
      ['salesTracking', 'incentive', 'type'],
      form,
    );

    const hasContentReview = useMemo(() => {
      return !!find(program.workflowConfig.stages, (s) => s.type === WorkflowStage.ContentReview);
    }, [program]);
    const hasPayment = useMemo(() => {
      return !!find(program.workflowConfig.stages, (s) => s.type === WorkflowStage.Payment);
    }, [program]);
    const hasSalesTracking = useMemo(() => {
      return !!find(program.workflowConfig.stages, (s) => s.type === WorkflowStage.SalesTracking);
    }, [program]);
    const contentReviewData = useMemo(
      () => ({
        usageRights,
        usageDuration,
        customUsageRights,
      }),
      [usageRights, usageDuration, customUsageRights],
    );

    return (
      <Form
        className={cx(styles.SendTermForm, className)}
        layout="vertical"
        form={form}
        onFinish={onSubmit}
        scrollToFirstError
        initialValues={initialValues}
      >
        <div className={styles.content}>
          {hasContentReview && (
            <>
              <Divider>Content Requirements</Divider>
              <ContentRequirementsFormItem
                required
                name={['contentReview', 'requirements']}
                program={program}
                accountResources={accountResources}
              />
              <Divider>Content Usage Rights</Divider>
              <Form.Item
                label="Content Usage Rights"
                name={['contentReview', 'usageRights']}
                rules={[{ required: true, message: 'Please select the content usage rights.' }]}
                tooltip={
                  <div>
                    <div>
                      Specify if you want to use the contents created from the program, and for how
                      long.
                    </div>
                    <div>
                      <b>Broad</b>: Full usage rights to the content.
                    </div>
                    <div>
                      <b>Limited</b>: Usage rights to the content for a limited amount of time.
                    </div>
                    <div>
                      <b>Custom</b>: Specify custom usage rights to the content.
                    </div>
                    <div>
                      <b>None</b>: No usage rights to the contents.
                    </div>
                  </div>
                }
              >
                <Select options={usageTypeOptions} />
              </Form.Item>
              {usageRights === LicensedContentUsageRights.Limited && (
                <Form.Item
                  label="Content Usage Duration"
                  name={['contentReview', 'usageDuration']}
                  rules={[{ required: true, message: 'Please select the content usage duration.' }]}
                >
                  <Select options={usageDurationOptions} />
                </Form.Item>
              )}
              {usageRights === LicensedContentUsageRights.Custom && (
                <Form.Item
                  label="Custom Content Rights"
                  name={['contentReview', 'customUsageRights']}
                  rules={[{ required: true, message: 'Please enter the custom content rights.' }]}
                >
                  <TextArea
                    placeholder="Enter your custom content rights..."
                    autoSize={{ minRows: 3, maxRows: 6 }}
                  />
                </Form.Item>
              )}
              <Form.Item label="Usage Rights Preview">
                <ContentUsageRights program={program} data={contentReviewData} />
              </Form.Item>
            </>
          )}
          {hasPayment && (
            <>
              <Divider>Payment</Divider>
              <Form.Item
                label="Amount"
                name={['payment', 'amount']}
                rules={[{ required: true, message: 'Please enter the payment amount.' }]}
              >
                <InputNumber
                  className={styles.input}
                  min={0}
                  formatter={(value) => `$ ${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
                  parser={(value) => value.replace(/\$\s?|(,*)/g, '') as any}
                />
              </Form.Item>
            </>
          )}
          {hasSalesTracking && (
            <>
              <Divider>Program Commission</Divider>
              <Form.Item
                name={['salesTracking', 'incentive', 'type']}
                rules={[{ required: true, message: 'Please choose commission type.' }]}
                label="Commission Type"
              >
                <Select
                  placeholder="Select commission type..."
                  options={SalesTrackingIncentiveOptions}
                />
              </Form.Item>
              {/* force re-render when incentive type change, so formatter changes */}
              <Form.Item label="Commission Value" key={incentiveType}>
                <div className={styles.values}>
                  <Form.Item
                    name={['salesTracking', 'incentive', 'value']}
                    rules={[{ required: true, message: 'Please enter commission value.' }]}
                    noStyle
                  >
                    <InputNumber
                      min="0"
                      formatter={(value: string) =>
                        incentiveType === SalesTrackingIncentiveType.CPS
                          ? `${value}%`
                          : `$ ${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')
                      }
                      parser={(value) =>
                        (incentiveType === SalesTrackingIncentiveType.CPS
                          ? value.replace('%', '')
                          : value.replace(/\$\s?|(,*)/g, '')) as any
                      }
                    />
                  </Form.Item>
                  {incentiveType === SalesTrackingIncentiveType.CPAS && (
                    <>
                      <PlusOutlined className={styles.plus} />
                      <Form.Item
                        name={['salesTracking', 'incentive', 'cpsValue']}
                        rules={[{ required: true, message: 'Please enter CPS value.' }]}
                        noStyle
                      >
                        <InputNumber
                          min="0"
                          formatter={(value) => `${value}%`}
                          parser={(value) => value.replace('%', '') as any}
                        />
                      </Form.Item>
                    </>
                  )}
                </div>
              </Form.Item>
            </>
          )}
        </div>
        {errorMessage && (
          <Alert
            className={styles.alert}
            type="error"
            message="Failed to send terms"
            description={errorMessage}
            closable
          />
        )}
        <Divider />
        <div className={styles.submit}>
          <Button onClick={onCancel}>Cancel</Button>
          <Button loading={isSubmitting} type="primary" htmlType="submit">
            {submitLabel}
          </Button>
        </div>
      </Form>
    );
  },
);
