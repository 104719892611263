import React from 'react';
import cx from 'classnames';
import { size } from 'lodash';

import { Divider, Typography } from 'antd';
import { ContentRequirements, ContentUsageRights } from '@components';

import { ICollabTermContentReviewData, IProgramCollabDetail } from '@types';

import styles from './ContentAgreement.module.scss';

interface IProps {
  data: ICollabTermContentReviewData;
  collab: IProgramCollabDetail;

  className?: string;
}

const { Title } = Typography;

/**
 * @type {React.FC}
 */
export const ContentAgreement: React.FC<IProps> = React.memo(({ data, collab, className }) => {
  return (
    <div className={cx(styles.ContentAgreement, className)}>
      <div className={styles.section}>
        <Title className={styles.title} level={4}>
          Content Requirements
          <span className={styles.count}>({size(data.requirements)})</span>
        </Title>
        <ContentRequirements className={styles.requirements} collab={collab} />
      </div>
      <Divider />
      <div className={styles.section}>
        <Title level={4}>Content Usage Rights</Title>
        <ContentUsageRights data={data} program={collab.program} />
      </div>
      <Divider />
    </div>
  );
});
