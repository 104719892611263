import React from 'react';
import cx from 'classnames';

import { Image } from '@components';

import styles from './AddressMap.module.scss';

interface IProps {
  address: string;

  className?: string;
}

/**
 * @type {React.FC}
 */
export const AddressMap: React.FC<React.PropsWithChildren<IProps>> = React.memo((props) => {
  const { address } = props;

  return (
    <div className={cx(styles.AddressMap, props.className)}>
      <Image
        className={styles.image}
        alt="map"
        src={`https://maps.googleapis.com/maps/api/staticmap?markers=${address}&scale=2&zoom=15&size=400x400&key=AIzaSyD_0DTBeRJ1lsAC5mGtVQHWd51qouu1qCs`}
      />
    </div>
  );
});
