import React from 'react';
import numeral from 'numeral';
import cx from 'classnames';
import { isEmpty, map, sumBy } from 'lodash';

import { Avatar, Button, Divider, Empty, InputNumber, Typography } from 'antd';
import { DeleteFilled } from '@ant-design/icons';

import { usePickProductsContext } from '../context';

import styles from './SelectedItems.module.scss';

interface IProps {
  className?: string;
}

const { useMemo } = React;
const { Text } = Typography;

/**
 * @type {React.FC}
 */
export const SelectedItems: React.FC<IProps> = React.memo(({ className }) => {
  const { items, updateItemQuantity, deleteItem } = usePickProductsContext();

  const totalAmount = useMemo(() => sumBy(items, (i) => parseFloat(i.price) * i.quantity), [items]);

  return (
    <div className={cx(styles.SelectedItems, className)}>
      {isEmpty(items) && <Empty description="Your cart is empty." />}
      {!isEmpty(items) && (
        <>
          <div className={styles.items}>
            {map(items, (item) => {
              const total = parseFloat(item.price) * item.quantity;

              return (
                <div key={item.variantId} className={styles.item}>
                  <Avatar className={styles.avatar} src={item.image} shape="square" size="large" />
                  <div className={styles.info}>
                    <Text className={styles.title} ellipsis={{ tooltip: item.title }}>
                      {item.title}
                    </Text>
                    <div className={styles.price}>{numeral(item.price).format('$0,0.[00]')}</div>
                  </div>
                  <InputNumber
                    className={styles.quantity}
                    type="number"
                    value={item.quantity}
                    min={1}
                    max={9}
                    parser={parseInt}
                    onChange={(value) => updateItemQuantity(item.variantId, value)}
                  />
                  <Button
                    className={styles.delete}
                    icon={<DeleteFilled />}
                    shape="circle"
                    type="text"
                    onClick={() => deleteItem(item.variantId)}
                  />
                  <Text className={styles.total} ellipsis>
                    {numeral(total).format('$0,0.[00]')}
                  </Text>
                </div>
              );
            })}
          </div>
          <Divider />
          <div className={styles.summary}>
            <div className={styles.entry}>
              <span className={styles.label}>Items Price</span>
              <span className={styles.amount}>{numeral(totalAmount).format('$0,0.[00]')}</span>
            </div>
            <div className={styles.entry}>
              <span className={styles.label}>Discount</span>
              <span className={styles.amount}>- {numeral(totalAmount).format('$0,0.[00]')}</span>
            </div>
            <div className={styles.entry}>
              <span className={styles.label}>Subtotal</span>
              <span className={styles.amount}>$0</span>
            </div>
            <div className={styles.entry}>
              <span className={styles.label}>Shipping Price</span>
              <span className={styles.amount}>$0</span>
            </div>
            <div className={cx(styles.entry, styles.bold)}>
              <span className={styles.label}>Total</span>
              <span className={cx(styles.amount, styles.gold)}>$0</span>
            </div>
          </div>
        </>
      )}
    </div>
  );
});
