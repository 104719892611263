import React from 'react';
import cx from 'classnames';
import numeral from 'numeral';

import { CaretRightOutlined } from '@ant-design/icons';

import styles from './Video.module.scss';

interface IProps {
  src: string;

  showDuration?: boolean;
  onMouseDown?(): void;
  onDataLoaded?(): void;
  onDimensionDetected?(videoWidth: number, videoHeight: number): void;
  onError?(): void;

  // <video /> related config
  autoPlay?: boolean;
  loop?: boolean;
  poster?: string;
  controls?: boolean;
  round?: boolean;

  className?: string;
}

type TDefaultProp =
  | 'showDuration'
  | 'onMouseDown'
  | 'onDataLoaded'
  | 'onDimensionDetected'
  | 'onError'
  | 'autoPlay'
  | 'loop'
  | 'controls'
  | 'round';

interface IState {
  duration: number;
}

/**
 * @class
 * @extends {React.Component}
 */
export class Video extends React.PureComponent<IProps, IState> {
  public static defaultProps: Pick<IProps, TDefaultProp> = {
    showDuration: false,
    onMouseDown: () => undefined,
    onDataLoaded: () => undefined,
    onDimensionDetected: () => undefined,
    onError: () => undefined,

    autoPlay: false,
    loop: false,
    controls: false,
    round: false,
  };

  private videoRef: React.RefObject<HTMLVideoElement>;

  /**
   * @inheritDoc
   */
  constructor(props: IProps) {
    super(props);

    this.videoRef = React.createRef();

    this.state = {
      duration: null,
    };
  }

  /**
   * @inheritdoc
   */
  public componentWillUnmount() {
    const video = this.videoRef.current;

    if (video && !video.paused) {
      video.pause();
    }
  }

  /**
   * @inheritdoc
   */
  public render() {
    const { src, showDuration, onMouseDown, autoPlay, loop, poster, controls, round, className } =
      this.props;
    const { duration } = this.state;

    return (
      <div className={cx(styles.Video, className)} onMouseDown={onMouseDown}>
        {showDuration && duration && (
          <div className={styles.duration}>
            <CaretRightOutlined className={styles.playIcon} />
            {numeral(duration).format('00:00:00')}
          </div>
        )}
        <video
          ref={this.videoRef}
          className={cx(styles.video, {
            [styles.round]: round,
          })}
          onLoadedData={this.onVideoDataLoaded}
          onError={this.onError}
          autoPlay={autoPlay}
          loop={loop}
          poster={poster}
          controls={controls}
        >
          {['webm', 'mp4', 'ogg'].map((type) => (
            <source key={type} src={src} type={`video/${type}`} />
          ))}
        </video>
      </div>
    );
  }

  /**
   * @private
   * Called when Video's dimension is fixed, either after loaded or failed.
   */
  private onDimensionDetected = () => {
    const { onDimensionDetected } = this.props;
    const video = this.videoRef.current;

    // TODO: figure out under what circumstances video is not mounted
    if (!video) {
      return;
    }

    const { videoWidth: width, videoHeight: height } = this.videoRef.current;

    onDimensionDetected(width, height);
  };

  /**
   * @private
   * Handler for when the video data is loaded.
   */
  private onVideoDataLoaded = () => {
    const { onDataLoaded } = this.props;
    const video = this.videoRef.current;

    if (!video) {
      return;
    }

    this.onDimensionDetected();
    onDataLoaded();

    this.setState({
      duration: video.duration,
    });
  };

  /**
   * @private
   * Video load error due to invalid src or timeout.
   * Also call onDataLoaded.
   */
  private onError = () => {
    const { onDataLoaded, onError } = this.props;

    this.onDimensionDetected();
    onDataLoaded();
    onError();
  };
}
