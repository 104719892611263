import React from 'react';
import cx from 'classnames';
import { compact, find, first, isMatch, map, pick, size } from 'lodash';

import { Button, Divider, Form, InputNumber, Select } from 'antd';

import { CollabOrderItem, IShopifyProduct } from '@types';

import styles from './ProductOptionForm.module.scss';
import numeral from 'numeral';

interface IProps {
  product: IShopifyProduct;
  onSubmit(params: CollabOrderItem): void;

  className?: string;
}
interface IProductOptions {
  option1?: string;
  option2?: string;
  option3?: string;
}
interface IFormParams {
  options?: IProductOptions;
  quantity: number;
}

const { useMemo, useCallback } = React;

export const ProductOptionForm: React.FC<React.PropsWithChildren<IProps>> = React.memo(
  ({ product, onSubmit, className }) => {
    const [form] = Form.useForm();
    const selectedOptions = Form.useWatch<IProductOptions>(['options'], form);

    const formParams: IFormParams = useMemo(() => {
      return {
        quantity: 1,
        options: pick(first(product.info.variants), ['option1', 'option2', 'option3']),
      };
    }, [product]);
    const selectedVariant = useMemo(() => {
      return find(product.info.variants, (v) => isMatch(v, selectedOptions));
    }, [product, selectedOptions]);
    const onFinish = useCallback(
      async (values: IFormParams) => {
        const { options, quantity } = values;

        const variant = find(product.info.variants, (v) => isMatch(v, options));
        if (!variant) {
          // this shouldn't happen
          return;
        }

        onSubmit({
          productId: variant.productId,
          variantId: variant.id,
          title:
            size(product.info.variants) > 1
              ? [product.info.title, ...compact(Object.values(options))].join(', ')
              : product.info.title,
          quantity,
          price: variant.price,
          image: variant.image || product.info.image,
        });
      },
      [product, onSubmit],
    );

    return (
      <Form
        className={cx(styles.ProductOptionForm, className)}
        form={form}
        layout="vertical"
        onFinish={onFinish}
        scrollToFirstError
        initialValues={formParams}
      >
        <div className={styles.price}>{numeral(selectedVariant?.price).format('$0,0.[00]')}</div>
        {map(product.info.options, (option) => (
          <Form.Item
            key={option.id}
            label={option.name}
            name={['options', `option${option.position}`]}
            rules={[
              {
                required: true,
                message: `Please select ${option.name}.`,
              },
            ]}
          >
            <Select
              placeholder={`Select ${option.name}...`}
              options={map(option.values, (v) => ({
                value: v,
                label: v,
              }))}
            />
          </Form.Item>
        ))}
        <Form.Item label="Quantity" name="quantity">
          <InputNumber className={styles.input} type="number" min={1} max={9} parser={parseInt} />
        </Form.Item>
        <Divider />
        <div className={styles.submit}>
          <Button className={styles.button} type="primary" htmlType="submit">
            Add to cart
          </Button>
        </div>
      </Form>
    );
  },
);
