import React from 'react';
import numeral from 'numeral';
import cx from 'classnames';
import { isEmpty, map, startCase, sumBy, toLower } from 'lodash';

import { Descriptions, Tag, Typography } from 'antd';
import { UserAvatar } from '@components';

import { IProgramCollabDetail, OrderShipmentStatus } from '@types';

import styles from './ProductFulfillmentPanelContent.module.scss';

interface IProps {
  collab: IProgramCollabDetail;

  className?: string;
}

const { useMemo } = React;
const { Text } = Typography;

/**
 * @type {React.FC}
 */
export const ProductFulfillmentPanelContent: React.FC<React.PropsWithChildren<IProps>> = React.memo(
  ({ collab, className }) => {
    const MemberPreferredProducts = useMemo(() => {
      const products = collab.workflowItem.data.productFulfillment?.preferredProducts;
      if (isEmpty(products)) {
        return '-';
      }

      const totalCost = sumBy(products, (p) => parseFloat(p.price) * p.quantity);

      return (
        <div className={styles.products}>
          <div className={styles.list}>
            {map(products, (product, index) => (
              <div key={index} className={styles.item}>
                <UserAvatar
                  className={styles.avatar}
                  picture={product.image}
                  name={product.title}
                  shape="square"
                  showBoth
                  size="small"
                />
                <span className={styles.extra}>x</span>
                <span className={styles.extra}>{product.quantity}</span>
              </div>
            ))}
          </div>
          <div className={styles.total}>
            <span className={styles.label}>Total:</span>
            <span className={styles.amount}>{numeral(totalCost).format('$0,0.[00]')}</span>
          </div>
        </div>
      );
    }, [collab]);
    const BrandPickedProducts = useMemo(() => {
      const products = collab.workflowItem.data.productFulfillment?.products;
      if (isEmpty(products)) {
        return '-';
      }

      const totalCost = sumBy(products, (p) => parseFloat(p.price) * p.quantity);

      return (
        <div className={styles.products}>
          <div className={styles.list}>
            {map(products, (product, index) => (
              <div key={index} className={styles.item}>
                <UserAvatar
                  className={styles.avatar}
                  picture={product.image}
                  name={product.title}
                  shape="square"
                  showBoth
                  size="small"
                />
                <span className={styles.extra}>x</span>
                <span className={styles.extra}>{product.quantity}</span>
              </div>
            ))}
          </div>
          <div className={styles.total}>
            <span className={styles.label}>Total:</span>
            <span className={styles.amount}>{numeral(totalCost).format('$0,0.[00]')}</span>
          </div>
        </div>
      );
    }, [collab]);
    const tracking = useMemo(() => collab.order?.tracking, [collab]);

    return (
      <div className={cx(styles.ProductFulfillmentPanelContent, className)}>
        <Descriptions column={1}>
          <Descriptions.Item label="Member Preferred Products">
            {MemberPreferredProducts}
          </Descriptions.Item>
          <Descriptions.Item label="Brand Picked Products">{BrandPickedProducts}</Descriptions.Item>
          {!!tracking && (
            <>
              <Descriptions.Item label="Tracking Status">
                <Tag
                  color={tracking.shipmentStatus === OrderShipmentStatus.DELIVERED ? 'green' : null}
                >
                  {startCase(toLower(tracking.shipmentStatus))}
                </Tag>
              </Descriptions.Item>
              <Descriptions.Item label="Tracking Number">
                <Text copyable>{tracking.trackingNumber}</Text>
              </Descriptions.Item>
              <Descriptions.Item label="Shipment Updates">
                <a
                  className={styles.updates}
                  href={tracking.trackingUrl}
                  target="_blank"
                  rel="noreferrer"
                  onClick={(event) => event.stopPropagation()}
                >
                  View
                </a>
              </Descriptions.Item>
            </>
          )}
        </Descriptions>
      </div>
    );
  },
);
