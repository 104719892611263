import { isEmpty } from 'lodash';

import { IProgramCollabDetail, WorkflowStage } from '@types';

export function isPanelCollapsible(stage: WorkflowStage, collab: IProgramCollabDetail): boolean {
  const { workflowItem, salesTracking } = collab;
  const { data } = workflowItem;

  switch (stage) {
    case WorkflowStage.Prospect: {
      return false;
    }
    case WorkflowStage.Term: {
      const { term } = collab;
      if (!isEmpty(term?.data)) {
        return true;
      }

      return false;
    }
    case WorkflowStage.Email: {
      const { email } = data;
      if (email?.threadId) {
        return true;
      }

      return false;
    }
    case WorkflowStage.Contract: {
      const { contract } = collab;
      if (contract) {
        return true;
      }

      return false;
    }
    case WorkflowStage.InfoUpdate: {
      return false;
    }
    case WorkflowStage.ProductFulfillment: {
      const { productFulfillment } = data;
      if (productFulfillment?.preferredProducts || productFulfillment?.products) {
        return true;
      }

      return false;
    }
    case WorkflowStage.ContentReview: {
      const { contentReviews } = collab;
      if (!isEmpty(contentReviews)) {
        return true;
      }

      return false;
    }
    case WorkflowStage.SalesTracking: {
      if (!!salesTracking) {
        return true;
      }

      return false;
    }

    default: {
      return false;
    }
  }
}
