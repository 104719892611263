import React from 'react';
import cx from 'classnames';
import { isEmpty, noop } from 'lodash';

import { Button, Input } from 'antd';
import { PaperPlaneIcon } from '@customIcons';

import { useCollabThreadsContext } from '../context';

import styles from './TextReply.module.scss';

interface IProps {
  onComplete?(): void;
  className?: string;
}

const { useState, useCallback } = React;
const { TextArea } = Input;

/**
 * @type {React.FC}
 */
export const TextReply: React.FC<React.PropsWithChildren<IProps>> = React.memo(
  ({ onComplete = noop, className }) => {
    const { sendTextMessage } = useCollabThreadsContext();
    const [value, setValue] = useState('');
    const [sending, setSending] = useState(false);

    const onValueChange = useCallback(
      (e: React.ChangeEvent<HTMLTextAreaElement>) => setValue(e.target.value),
      [],
    );
    const onSendMessage = useCallback(async () => {
      if (isEmpty(value)) {
        return;
      }

      setSending(true);

      try {
        await sendTextMessage(value);

        onComplete();
        setValue('');
      } finally {
        setSending(false);
      }
    }, [value, sendTextMessage, onComplete]);

    return (
      <div className={cx(styles.TextReply, className)}>
        <TextArea
          className={styles.input}
          autoFocus
          rows={4}
          value={value}
          onChange={onValueChange}
          placeholder="Enter message..."
        />
        <div className={styles.actions}>
          <Button
            type="primary"
            onClick={onSendMessage}
            disabled={!value}
            loading={sending}
            icon={<PaperPlaneIcon />}
          >
            Send Message
          </Button>
        </div>
      </div>
    );
  },
);
