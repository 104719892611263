import {
  endOfMonth,
  endOfQuarter,
  endOfWeek,
  endOfYear,
  format,
  startOfMonth,
  startOfQuarter,
  startOfWeek,
  startOfYear,
  subDays,
  subMonths,
  subQuarters,
  subWeeks,
  subYears,
} from 'date-fns';
import { isEmpty } from 'lodash';

import { SalesTrackingLastDate } from '@types';

export function getDateFilterLabel(lastDays: SalesTrackingLastDate | ''): string {
  if (isEmpty(lastDays)) {
    return '';
  }

  const dateNow = Date.now();
  let startDate: Date = new Date();
  let endDate: Date = new Date();

  if (lastDays === SalesTrackingLastDate.ThisWeek) {
    startDate = startOfWeek(dateNow);
    endDate = new Date(dateNow);
  } else if (lastDays === SalesTrackingLastDate.LastWeek) {
    const startOfThisWeek = startOfWeek(dateNow);
    const endOfThisWeek = endOfWeek(dateNow);

    startDate = subWeeks(startOfThisWeek, 1);
    endDate = subWeeks(endOfThisWeek, 1);
  } else if (lastDays === SalesTrackingLastDate.ThisMonth) {
    startDate = startOfMonth(dateNow);
    endDate = new Date();
  } else if (lastDays === SalesTrackingLastDate.LastMonth) {
    const startOfThisMonth = startOfMonth(dateNow);
    const endOfThisMonth = endOfMonth(dateNow);

    startDate = subMonths(startOfThisMonth, 1);
    endDate = subMonths(endOfThisMonth, 1);
  } else if (lastDays === SalesTrackingLastDate.ThisQuarter) {
    startDate = startOfQuarter(dateNow);
    endDate = new Date();
  } else if (lastDays === SalesTrackingLastDate.LastQuarter) {
    const startOfThisQuarter = startOfQuarter(dateNow);
    const endOfThisQuarter = endOfQuarter(dateNow);

    startDate = subQuarters(startOfThisQuarter, 1);
    endDate = subQuarters(endOfThisQuarter, 1);
  } else if (lastDays === SalesTrackingLastDate.ThisYear) {
    startDate = startOfYear(dateNow);
    endDate = new Date();
  } else if (lastDays === SalesTrackingLastDate.LastYear) {
    const startOfThisYear = startOfYear(dateNow);
    const endOfThisYear = endOfYear(dateNow);

    startDate = subYears(startOfThisYear, 1);
    endDate = subYears(endOfThisYear, 1);
  } else if (lastDays === SalesTrackingLastDate.PastSixMonths) {
    startDate = subDays(dateNow, 180);
    endDate = new Date();
  } else if (lastDays === SalesTrackingLastDate.PastTwoYears) {
    startDate = subDays(dateNow, 730);
    endDate = new Date();
  }

  return `${format(startDate, 'MMM dd, yyyy')} - ${format(endDate, 'MMM dd, yyyy')}`;
}
