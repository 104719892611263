import { gql } from '@apollo/client';

export const CREATOR_FRAGMENT = gql`
  fragment Creator on Creator {
    id
    email
    name
    profileImage
    createdDate
    updatedDate
  }
`;
