import { gql } from '@apollo/client';

export const PROGRAM_COLLAB_USER_STATUS_FRAGMENT = gql`
  fragment ProgramCollabUserStatus on ProgramCollabUserStatus {
    id
    collabId
    userId
    read
  }
`;
