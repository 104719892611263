import React from 'react';
import { Helmet } from 'react-helmet';
import { Navigate, Route, Routes, useLocation } from 'react-router-dom';

import { Home, Login, SignUp, ForgetPassword } from '@containers';

import { useAuthContext } from '@context';

import styles from './App.module.scss';

function RequireAuth({ children }: { children: JSX.Element }) {
  const location = useLocation();
  const { user } = useAuthContext();

  if (!user) {
    return <Navigate to="/login" state={{ from: location }} replace />;
  }

  return children;
}

export const App: React.FC<React.PropsWithChildren<React.PropsWithChildren<{}>>> = React.memo(
  () => {
    return (
      <div className={styles.App}>
        <Helmet titleTemplate="%s - Superbloom" defaultTitle="Superbloom" />
        <Routes>
          <Route>
            <Route path="/signup" element={<SignUp />} />
            <Route path="/login" element={<Login />} />
            <Route path="/forget_password" element={<ForgetPassword />} />
            <Route
              path="/*"
              element={
                <RequireAuth>
                  <Home />
                </RequireAuth>
              }
            />
          </Route>
        </Routes>
      </div>
    );
  },
);
