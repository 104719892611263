import React from 'react';
import cx from 'classnames';

import { ProspectPanelContent } from './ProspectPanelContent';
import { TermPanelContent } from './TermPanelContent';
import { ContractPanelContent } from './ContractPanelContent';
import { ProductFulfillmentPanelContent } from './ProductFulfillmentPanelContent';
import { ContentReviewPanelContent } from './ContentReviewPanelContent';
import { SalesTrackingPanelContent } from './SalesTrackingPanelContent';

import { IProgramCollabDetail, WorkflowStage } from '@types';

import styles from './PanelContent.module.scss';

interface IProps {
  stage: WorkflowStage;
  collab: IProgramCollabDetail;

  className?: string;
}

/**
 * @type {React.FC}
 */
export const PanelContent: React.FC<React.PropsWithChildren<IProps>> = React.memo(
  ({ stage, collab, className }) => {
    return (
      <div className={cx(styles.PanelContent, className)}>
        {stage === WorkflowStage.Prospect && <ProspectPanelContent collab={collab} />}
        {stage === WorkflowStage.Term && <TermPanelContent collab={collab} />}
        {stage === WorkflowStage.Contract && <ContractPanelContent collab={collab} />}
        {stage === WorkflowStage.ProductFulfillment && (
          <ProductFulfillmentPanelContent collab={collab} />
        )}
        {stage === WorkflowStage.ContentReview && <ContentReviewPanelContent collab={collab} />}
        {stage === WorkflowStage.SalesTracking && <SalesTrackingPanelContent collab={collab} />}
      </div>
    );
  },
);
