import React from 'react';
import cx from 'classnames';
import numeral from 'numeral';
import { format } from 'date-fns';
import { map, isEmpty, size } from 'lodash';

import { Button, Typography, Carousel, Tooltip, Divider } from 'antd';
import {
  HeartOutlined,
  CommentOutlined,
  EyeOutlined,
  LeftOutlined,
  RightOutlined,
} from '@ant-design/icons';
import { Image, SocialAccountAvatar } from '@components';

import type { CarouselRef } from 'antd/lib/carousel';
import { ISocialPostDetail, SocialPostMediaType } from '@types';

import styles from './SocialPostDetail.module.scss';

interface IProps {
  post: ISocialPostDetail;

  className?: string;
}

const { useState, useRef, useMemo, useCallback } = React;
const { Title, Paragraph } = Typography;

/**
 * @type {React.FC}
 */
export const SocialPostDetail: React.FC<React.PropsWithChildren<IProps>> = React.memo(
  ({ post, className }) => {
    const carouselRef = useRef<CarouselRef>(null);
    const [index, setIndex] = useState(0);

    const shouldShowControls = useMemo(() => size(post?.media) > 1, [post]);
    const goToPrevImage = useCallback(() => {
      const nextIndex = Math.max(0, index - 1);

      setIndex(nextIndex);
      carouselRef.current.goTo(nextIndex);
    }, [index]);
    const goToNextImage = useCallback(() => {
      const nextIndex = Math.min(size(post.media) - 1, index + 1);

      setIndex(nextIndex);
      carouselRef.current.goTo(nextIndex);
    }, [index, post]);

    return (
      <div className={cx(styles.SocialPostDetail, className)}>
        <div className={styles.media}>
          <Carousel ref={carouselRef} dots={shouldShowControls}>
            {map(post.media, (media, index) => {
              if (media.type === SocialPostMediaType.Image) {
                return <Image key={index} className={styles.image} cover={false} src={media.url} />;
              }

              return (
                <div key={index} className={styles.video}>
                  <iframe
                    className={styles.iframe}
                    src={media.url}
                    title="video"
                    width={640}
                    height={360}
                    allowFullScreen
                  />
                </div>
              );
            })}
          </Carousel>
          {shouldShowControls && (
            <div className={styles.controls}>
              <Button
                className={styles.button}
                ghost
                shape="circle"
                icon={<LeftOutlined />}
                onClick={goToPrevImage}
                disabled={index === 0}
              />
              <Button
                className={styles.button}
                ghost
                shape="circle"
                icon={<RightOutlined />}
                onClick={goToNextImage}
                disabled={index === size(post.media) - 1}
              />
            </div>
          )}
        </div>
        <div className={styles.info}>
          <SocialAccountAvatar account={post.account} />
          <Divider />
          {post.info.title && (
            <Title className={styles.title} level={5}>
              {post.info.title}
            </Title>
          )}
          <Tooltip
            title={
              <>
                <div>Posted on</div>
                <div>{format(new Date(post.info.postDate), 'PPpp')}</div>
              </>
            }
          >
            <div className={styles.date}>
              Posted on {format(new Date(post.info.postDate), 'MMM dd, yyyy')}
            </div>
          </Tooltip>
          <Paragraph
            className={cx(styles.description, {
              [styles.empty]: isEmpty(post.info.description),
            })}
            ellipsis={{ rows: 5, expandable: true }}
          >
            {post.info.description || 'no post description.'}
          </Paragraph>
          <div className={styles.stats}>
            {post.stats.views > 0 && (
              <>
                <Tooltip title={`${numeral(post.stats.views).format('0,0')} views`}>
                  <div className={styles.item}>
                    <EyeOutlined />
                    <span className={styles.count}>{numeral(post.stats.views).format('0 a')}</span>
                  </div>
                </Tooltip>
                <div className={styles.dot} />
              </>
            )}
            <Tooltip title={`${numeral(post.stats.likes).format('0,0')} likes`}>
              <div className={styles.item}>
                <HeartOutlined />
                <span className={styles.count}>{numeral(post.stats.likes).format('0 a')}</span>
              </div>
            </Tooltip>
            <div className={styles.dot} />
            <Tooltip title={`${numeral(post.stats.comments).format('0,0')} comments`}>
              <div className={styles.item}>
                <CommentOutlined />
                <span className={styles.count}>{numeral(post.stats.comments).format('0 a')}</span>
              </div>
            </Tooltip>
          </div>
        </div>
      </div>
    );
  },
);
