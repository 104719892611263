import React from 'react';
import cx from 'classnames';
import { includes, startCase, toLower } from 'lodash';

import { Tag, Tooltip, Typography } from 'antd';

import { IProgramCollab, ProgramCollabStatus, ProgramType, WorkflowStage } from '@types';
import { CollabStatusLabel, CollabStatusTooltip, ActionRequiredStates } from './constants';

import styles from './CollabStatusTag.module.scss';

interface IProps {
  collab: IProgramCollab;

  className?: string;
}

const { useMemo } = React;
const { Text } = Typography;

/**
 * @type {React.FC}
 */
export const CollabStatusTag: React.FC<React.PropsWithChildren<IProps>> = React.memo(
  ({ collab, className }) => {
    const tooltipTitle = useMemo(() => {
      if (collab.status !== ProgramCollabStatus.Active) {
        return 'This collaboration has been terminated.';
      }

      if (collab.workflowItem.stage === WorkflowStage.Completed) {
        if (collab.program.type === ProgramType.SalesTracking) {
          return 'Sales tracking link is activated.';
        }

        return 'This collaboration has completed.';
      }

      return CollabStatusTooltip[collab.workflowItem.state] || '-';
    }, [collab]);
    const label = useMemo(() => {
      if (collab.status !== ProgramCollabStatus.Active) {
        return startCase(toLower(collab.status));
      }

      if (collab.workflowItem.stage === WorkflowStage.Completed) {
        if (collab.program.type === ProgramType.SalesTracking) {
          return 'tracking sales';
        }

        return 'completed';
      }

      return toLower(CollabStatusLabel[collab.workflowItem.state]) || '-';
    }, [collab]);
    const tagColor = useMemo(() => {
      if (collab.status !== ProgramCollabStatus.Active) {
        return 'gray';
      }

      if (collab.workflowItem.stage === WorkflowStage.Completed) {
        if (collab.program.type === ProgramType.SalesTracking) {
          return 'gold';
        }

        return 'green';
      }

      if (includes(ActionRequiredStates, collab.workflowItem.state)) {
        return 'blue';
      }

      return null;
    }, [collab]);

    return (
      <Tooltip title={tooltipTitle}>
        <Tag className={cx(styles.CollabStatusTag, className)} color={tagColor}>
          <Text className={styles.text} ellipsis>
            {label}
          </Text>
        </Tag>
      </Tooltip>
    );
  },
);
