import React from 'react';
import cx from 'classnames';
import { format } from 'date-fns';
import { useNavigate } from 'react-router-dom';

import { PageHeader, Tooltip, Typography, Descriptions } from 'antd';
import {
  ApplyToProgramButton,
  ErrorNotice,
  Image,
  LoadSpinner,
  ProgramIncentiveTag,
  RefreshButton,
  UserAvatar,
} from '@components';

import { formatDate } from '@utils';
import { useGetProgramById } from '@hooks';

import styles from './ProgramDetail.module.scss';

interface IProps {
  programId: string;

  className?: string;
}

const { Paragraph } = Typography;

/**
 * @type {React.FC}
 */
export const ProgramDetail: React.FC<React.PropsWithChildren<IProps>> = React.memo(
  ({ programId, className }) => {
    const navigate = useNavigate();

    const { program, loading, refetch } = useGetProgramById({
      variables: {
        id: programId,
      },
    });

    return (
      <div className={cx(styles.ProgramDetail, className)}>
        {loading && <LoadSpinner className={styles.loading} />}
        {!loading && !program && <ErrorNotice />}
        {!loading && program && (
          <PageHeader
            className={styles.header}
            onBack={() => navigate(-1)}
            title={program.info.name}
            subTitle={`Last updated on ${format(new Date(program.updatedDate), 'PPpp')}`}
            extra={[
              <ApplyToProgramButton key="apply" program={program} />,
              <RefreshButton key="refresh" size="small" onClick={refetch} loading={loading} />,
            ]}
          >
            <div className={styles.content}>
              <div className={styles.info}>
                <Image
                  className={styles.image}
                  src={program.cover?.url || `https://picsum.photos/320/240?id=${program.id}`}
                />
                <Paragraph className={styles.description} ellipsis={{ rows: 5, expandable: true }}>
                  {program.info.description}
                </Paragraph>
              </div>
              <Descriptions className={styles.fields} column={4}>
                <Descriptions.Item label="Brand">
                  <UserAvatar
                    className={styles.brandAvatar}
                    shape="square"
                    size="small"
                    name={program.brand.name}
                    picture={program.brand.logo?.previewUrl}
                    showBoth
                  />
                </Descriptions.Item>
                <Descriptions.Item label="Incentive">
                  <ProgramIncentiveTag showTooltip program={program} />
                </Descriptions.Item>
                <Descriptions.Item label="Posted On">
                  <Tooltip title={format(new Date(program.createdDate), 'PPpp')}>
                    {formatDate(new Date(program.createdDate), true)}
                  </Tooltip>
                </Descriptions.Item>
                <Descriptions.Item label="Applied On">
                  {program.collab ? (
                    <Tooltip title={format(new Date(program.createdDate), 'PPpp')}>
                      {formatDate(new Date(program.collab.createdDate), true)}
                    </Tooltip>
                  ) : (
                    '-'
                  )}
                </Descriptions.Item>
              </Descriptions>
            </div>
          </PageHeader>
        )}
      </div>
    );
  },
);
