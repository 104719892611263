import React from 'react';
import cx from 'classnames';

import { AddressCardIcon } from '@customIcons';

import styles from './InfoUpdateStageIcon.module.scss';

interface IProps {
  className?: string;
}

/**
 * @type {React.FC}
 */
export const InfoUpdateStageIcon: React.FC<React.PropsWithChildren<IProps>> = React.memo(
  ({ className }) => {
    return (
      <div className={cx(styles.InfoUpdateStageIcon, className)}>
        <AddressCardIcon className={styles.icon} />
      </div>
    );
  },
);
