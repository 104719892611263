import { gql } from '@apollo/client';

export const COLLAB_TERM_FRAGMENT = gql`
  fragment CollabTerm on CollabTerm {
    id
    negotiable
    latestIterationId
    data {
      contentReview {
        requirements {
          type
          accountResourceId
          dueDateTs
          guidelines {
            conceptAndFormat
            talkingPoints
            caption
            postInstructions
            other
          }
        }
        usageRights
        usageDuration
        customUsageRights
      }
      payment {
        amount
      }
      salesTracking {
        incentive {
          type
          value
          cpsValue
        }
      }
    }
  }
`;
