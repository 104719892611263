import React from 'react';
import { initializeApp } from 'firebase/app';
import {
  User,
  UserCredential,
  onAuthStateChanged,
  signInWithEmailAndPassword,
  getAuth,
  signOut,
  GoogleAuthProvider,
  signInWithCustomToken,
  signInWithPopup,
} from 'firebase/auth';

import { LoadSpinner } from '@components';

import { useRelaxedInterval } from '@utils';
import { firebaseConfig } from './utils';

import styles from './AuthContext.module.scss';

type IAuthUser = User & {
  accessToken: string;
  isQA: boolean;
};
interface IAuthContext {
  user: IAuthUser;
  token: string;

  signIn(email: string, password: string): Promise<UserCredential>;
  signInWithGoogle(): Promise<void>;
  signOut(): Promise<void>;
}

const { useState, useEffect, useMemo, useCallback, useContext } = React;
const googleProvider = new GoogleAuthProvider();
const tenantId = process.env.REACT_APP_FIREBASE_TENANT_ID;
// refresh token every 55 minutes (Firebase token expires in one hour)
const refreshTokenInterval = 55 * 60 * 1000;

export const AuthContext = React.createContext<IAuthContext>(null);
export const useAuthContext = () => useContext(AuthContext);
export const AuthContextProvider: React.FC<React.PropsWithChildren<{}>> = React.memo(
  ({ children }) => {
    const [loading, setLoading] = useState(true);
    const [user, setUser] = useState<IAuthUser>(null);
    const [token, setToken] = useState<string>(null);

    const customToken = useMemo(() => {
      const searchParams = new URLSearchParams(window.location.search);

      return searchParams.get('custom_token');
    }, []);
    const app = useMemo(() => initializeApp(firebaseConfig), []);
    const auth = useMemo(() => {
      const auth = getAuth(app);
      auth.tenantId = tenantId;

      return auth;
    }, [app]);
    const refreshAccessToken = useCallback(async () => {
      if (user) {
        try {
          // true forces token refresh
          const token = await user.getIdToken(true);
          setToken(token);
        } catch {}
      }
    }, [user]);
    const signInWithToken = useCallback(async () => {
      // need to start the loading process again
      setLoading(true);

      try {
        // log in as QA with custom user token
        await signInWithCustomToken(auth, customToken);

        // remove custom_token from url
        const url = new URL(window.location.href);
        url.searchParams.delete('custom_token');
        window.history.replaceState({}, document.title, url.toString());
      } catch {
        // and only set to false in catch
        // if succeeded, it will be set to false in onAuthStateChanged
        setLoading(false);
      }
    }, [auth, customToken]);
    const signIn = useCallback(
      async (email: string, password: string) => {
        return signInWithEmailAndPassword(auth, email, password);
      },
      [auth],
    );
    const signInWithGoogle = useCallback(async () => {
      await signInWithPopup(auth, googleProvider);
    }, [auth]);
    const onSignOut = useCallback(async () => {
      await signOut(auth);

      // reset everything, the session expired state, user, etc
      window.location.assign('/');
    }, [auth]);

    useEffect(() => {
      let isCustomTokenUsed = false;

      const unsubscribe = onAuthStateChanged(auth, async (user) => {
        if (customToken && !isCustomTokenUsed) {
          // need to set the flag first, signOut will trigger onAuthStateChanged
          isCustomTokenUsed = true;

          if (user) {
            // sign existing user out
            await signOut(auth);
          }

          await signInWithToken();
        } else if (user) {
          // regular user sign-in
          setUser({
            ...user,
            isQA: isCustomTokenUsed,
          } as IAuthUser);
          setToken((user as IAuthUser)?.accessToken);
        }

        setLoading(false);
      });

      return () => {
        unsubscribe();
      };
    }, [auth, customToken, signInWithToken]);
    // refresh token every 55 minutes (Firebase token expires in one hour)
    useRelaxedInterval(refreshAccessToken, refreshTokenInterval);

    return (
      <AuthContext.Provider
        value={{
          user,
          token,

          signIn,
          signInWithGoogle,
          signOut: onSignOut,
        }}
      >
        <div className={styles.AuthContext}>
          {loading && <LoadSpinner className={styles.loading} />}
          {!loading && children}
        </div>
      </AuthContext.Provider>
    );
  },
);
