import React from 'react';
import { useMutation, MutationHookOptions, MutationFunctionOptions } from '@apollo/client';

import { WORKFLOW_PRE_CHECK_IN_MUTATION } from '@queries';
import { WorkflowPreCheckInMutation, WorkflowPreCheckInMutationVariables } from '@types';

type IOptions = MutationHookOptions<
  WorkflowPreCheckInMutation,
  WorkflowPreCheckInMutationVariables
>;
type IFunctionOptions = MutationFunctionOptions<
  WorkflowPreCheckInMutation,
  WorkflowPreCheckInMutationVariables
>;

const { useCallback } = React;

export const useWorkflowPreCheckIn = (options: IOptions = {}) => {
  const [_workflowPreCheckIn, { loading, error }] = useMutation<
    WorkflowPreCheckInMutation,
    WorkflowPreCheckInMutationVariables
  >(WORKFLOW_PRE_CHECK_IN_MUTATION, options);

  const workflowPreCheckIn = useCallback(
    async (options: IFunctionOptions = {}) => {
      try {
        const result = await _workflowPreCheckIn(options);

        return result;
      } catch (err) {
        // todo: log this error on Rollbar/Datadog
        throw err;
      }
    },
    [_workflowPreCheckIn],
  );

  return {
    workflowPreCheckIn,
    loading,
    error,
  };
};
