import React from 'react';
import cx from 'classnames';

import { ShareAltIcon } from '@customIcons';

import styles from './SalesTrackingStageIcon.module.scss';

interface IProps {
  className?: string;
}

/**
 * @type {React.FC}
 */
export const SalesTrackingStageIcon: React.FC<React.PropsWithChildren<IProps>> = React.memo(
  ({ className }) => {
    return (
      <div className={cx(styles.SalesTrackingStageIcon, className)}>
        <ShareAltIcon className={styles.icon} />
      </div>
    );
  },
);
