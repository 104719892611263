import { gql } from '@apollo/client';

import { SOCIAL_ACCOUNT_FRAGMENT } from './fragments';
import { SOCIAL_POST_OVERVIEW_FRAGMENT } from './socialPosts';

//========================================================
//= Fragments
//========================================================
const SOCIAL_ACCOUNT_DETAIL_FRAGMENT = gql`
  fragment SocialAccountDetail on SocialAccount {
    ...SocialAccount
    posts {
      ...SocialPostOverview
    }
  }
  ${SOCIAL_ACCOUNT_FRAGMENT}
  ${SOCIAL_POST_OVERVIEW_FRAGMENT}
`;

//========================================================
//= Queries
//========================================================
export const GET_SOCIAL_ACCOUNT_QUERY = gql`
  query GetSocialAccount($id: String!) {
    account: creatorGetSocialAccountById(id: $id) {
      ...SocialAccountDetail
    }
  }
  ${SOCIAL_ACCOUNT_DETAIL_FRAGMENT}
`;
