import { useQuery, QueryHookOptions } from '@apollo/client';
import React from 'react';

import { message } from 'antd';

import { GET_ADDRESSES_QUERY } from '@queries';
import { GetAddressesQuery } from '@types';

type IOptions = QueryHookOptions<GetAddressesQuery>;

const { useEffect, useMemo } = React;

export function useGetAddresses(options: IOptions = {}) {
  const {
    loading,
    data: { addresses } = {},
    error,
    refetch,
  } = useQuery<GetAddressesQuery>(GET_ADDRESSES_QUERY, {
    ...options,
    fetchPolicy: 'cache-and-network',
    notifyOnNetworkStatusChange: true,
  });

  const errorMessage = useMemo(() => error?.message, [error]);
  useEffect(() => {
    if (!loading && errorMessage) {
      message.error(errorMessage);
    }
  }, [loading, errorMessage]);

  return {
    loading,
    addresses,
    error,
    refetch,
  };
}
