import { gql } from '@apollo/client';

import { MEMBER_FIELD_SCHEMA_FRAGMENT } from './fragments';

//========================================================
//= Mutations
//========================================================
export const GET_MEMBER_FIELD_SCHEMAS_QUERY = gql`
  query GetMemberFieldSchemas($brandId: String!) {
    schemas: creatorGetMemberFieldSchemas(brandId: $brandId) {
      ...MemberFieldSchema
    }
  }
  ${MEMBER_FIELD_SCHEMA_FRAGMENT}
`;
