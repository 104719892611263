import React from 'react';
import { useNavigate } from 'react-router-dom';
import cx from 'classnames';

import { Typography, Button, Divider, Alert } from 'antd';
import { ArrowLeftOutlined } from '@ant-design/icons';
import { ForgetPasswordForm } from './ForgetPasswordForm';

import { useAuthContext } from '@context';

import styles from './ForgetPassword.module.scss';

interface IProps {
  className?: string;
}

const { useState, useCallback, useEffect } = React;
const { Title } = Typography;

/**
 * @type {React.FC}
 */
export const ForgetPassword: React.FC<React.PropsWithChildren<IProps>> = React.memo(
  ({ className }) => {
    const navigate = useNavigate();
    const { user } = useAuthContext();
    const [requested, setRequested] = useState(false);

    const onForgetPasswordCompleted = useCallback(() => setRequested(true), []);
    const goToLogin = useCallback(() => navigate('/login'), [navigate]);

    useEffect(() => {
      if (user) {
        navigate('/');
      }
    }, [user, navigate]);

    return (
      <div className={cx(styles.ForgetPassword, className)}>
        <div className={styles.logo} />
        <div className={styles.content}>
          <Title className={styles.title} level={3}>
            Reset Password
          </Title>
          {!requested && (
            <ForgetPasswordForm className={styles.form} onComplete={onForgetPasswordCompleted} />
          )}
          {requested && (
            <>
              <Alert
                className={styles.notice}
                type="info"
                showIcon
                message="A magic link for signing in has been sent to your email. Please check your inbox."
              />
              <Divider />
              <Button
                className={styles.button}
                type="text"
                onClick={goToLogin}
                icon={<ArrowLeftOutlined />}
              >
                Go to Login
              </Button>
            </>
          )}
        </div>
        <div className={styles.footer}>
          <Button type="link">
            <a href="https://superbloom.one/terms-of-service" target="_blank" rel="noreferrer">
              Terms of service
            </a>
          </Button>
          <Divider type="vertical" />
          <Button type="link">
            <a href="https://superbloom.one/privacy-policy" target="_blank" rel="noreferrer">
              Privacy policy
            </a>
          </Button>
        </div>
      </div>
    );
  },
);
