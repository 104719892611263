import React from 'react';
import { useNavigate } from 'react-router-dom';
import { format } from 'date-fns';
import cx from 'classnames';

import { PageHeader, Tabs, Descriptions, Skeleton } from 'antd';
import { LockFilled } from '@ant-design/icons';
import {
  CollabStatusTag,
  CopyLinkButton,
  ErrorNotice,
  ProgramIncentiveTag,
  RefreshButton,
  TrackingStatsDisplay,
  UserAvatar,
} from '@components';
import {
  CollabOverview,
  CollabActivities,
  CollabSalesTracking,
  CollabMessages,
} from './components';

import { formatDate } from '@utils';
import { ProgramType } from '@types';
import { useGetProgramCollabById } from '@hooks';
import { useGlobalOverlayContext } from '@containers';

import styles from './ProgramCollabDetail.module.scss';

interface IProps {
  collabId: string;

  allowGoBack?: boolean;
  className?: string;
}
type TActiveTab = 'overview' | 'activities' | 'messages' | 'sales_tracking';

const { useState, useMemo, useCallback } = React;

/**
 * @type {React.FC}
 */
export const ProgramCollabDetail: React.FC<React.PropsWithChildren<IProps>> = React.memo(
  ({ collabId, allowGoBack = true, className }) => {
    const navigate = useNavigate();
    const { setEntity } = useGlobalOverlayContext();
    const [activeTab, setActiveTab] = useState<TActiveTab>('overview');

    const { collab, loading, error, refetch } = useGetProgramCollabById({
      variables: {
        id: collabId,
      },
    });

    const hasSalesTracking = useMemo(
      () => collab?.program.type === ProgramType.SalesTracking,
      [collab],
    );
    const trackingUrl = useMemo(() => collab?.salesTracking?.info.trackingUrl, [collab]);
    const tabItems: Array<{ key: TActiveTab; label: React.ReactNode }> = useMemo(() => {
      return [
        {
          key: 'overview',
          label: 'Overview',
        },
        {
          key: 'activities',
          label: 'Activities',
        },
        {
          key: 'messages',
          label: 'Messages',
        },
        {
          key: 'sales_tracking',
          disabled: !hasSalesTracking,
          label: (
            <>
              {hasSalesTracking && 'Sales Tracking'}
              {!hasSalesTracking && (
                <>
                  <LockFilled className={styles.lock} /> Sales Tracking
                </>
              )}
            </>
          ),
        },
      ];
    }, [hasSalesTracking]);
    const onBack = useCallback(() => navigate(-1), [navigate]);
    const openProgramDetail = useCallback(() => {
      setEntity({
        type: 'program',
        id: collab.programId,
      });
    }, [collab, setEntity]);

    return (
      <div className={cx(styles.ProgramCollabDetail, className)}>
        {loading && <Skeleton active />}
        {!loading && error && <ErrorNotice />}
        {!loading && collab && (
          <>
            <PageHeader
              className={styles.header}
              title={
                <div className={styles.title}>
                  Collaboration with
                  <UserAvatar
                    className={styles.name}
                    shape="square"
                    name={collab.program.brand.name}
                    picture={collab.program.brand.logo?.previewUrl}
                    showBoth
                  />
                </div>
              }
              subTitle={`Last updated on ${format(new Date(collab.updatedDate), 'PPpp')}`}
              onBack={allowGoBack && onBack}
              extra={[
                trackingUrl && (
                  <CopyLinkButton key="tracking" label="Copy Tracking Link" link={trackingUrl} />
                ),
                <RefreshButton key="refresh" size="small" onClick={refetch} loading={loading} />,
              ]}
              footer={
                <Tabs
                  className={styles.tabs}
                  size="small"
                  activeKey={activeTab}
                  onChange={(tab) => setActiveTab(tab as TActiveTab)}
                  items={tabItems}
                />
              }
            >
              <div className={styles.detailsAndMetrics}>
                <Descriptions className={styles.details} column={1}>
                  <Descriptions.Item label="Program">
                    <div className={styles.program}>
                      <UserAvatar
                        className={styles.programAvatar}
                        size="small"
                        shape="square"
                        name={collab.program.info.name}
                        picture={
                          collab.program.cover?.url ||
                          `https://picsum.photos/100?id=${collab.program.id}`
                        }
                        showBoth
                        onClick={openProgramDetail}
                      />
                      <ProgramIncentiveTag showTooltip program={collab.program} />
                    </div>
                  </Descriptions.Item>
                  <Descriptions.Item label="Status">
                    <CollabStatusTag collab={collab} />
                  </Descriptions.Item>
                  <Descriptions.Item label="Start Date">
                    {formatDate(new Date(collab.createdDate))}
                  </Descriptions.Item>
                </Descriptions>
                {!!collab.salesTracking && (
                  <TrackingStatsDisplay
                    className={styles.metrics}
                    stats={collab.salesTracking.stats}
                    name={collab.member.info.fullName}
                  />
                )}
              </div>
            </PageHeader>
            <div className={styles.tabContent}>
              {activeTab === 'overview' && <CollabOverview collab={collab} />}
              {activeTab === 'activities' && <CollabActivities collab={collab} />}
              {activeTab === 'messages' && <CollabMessages collab={collab} />}
              {activeTab === 'sales_tracking' && <CollabSalesTracking collab={collab} />}
            </div>
          </>
        )}
      </div>
    );
  },
);
