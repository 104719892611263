import React from 'react';
import cx from 'classnames';
import { noop } from 'lodash';

import { Alert, Button, Form, Input } from 'antd';

import { signUp } from './useSignUp';

import styles from './SignUpForm.module.scss';

interface IProps {
  onComplete?(): void;
  className?: string;
}
interface ISubmitParams {
  email: string;
  name: string;
}

const { useState, useCallback } = React;

export const SignUpForm: React.FC<React.PropsWithChildren<IProps>> = React.memo(
  ({ onComplete = noop, className }) => {
    const [form] = Form.useForm();
    const [signingUp, setSigningUp] = useState(false);
    const [errorMessage, setErrorMessage] = useState<string>(null);

    const onFinish = useCallback(
      async (params: ISubmitParams) => {
        setSigningUp(true);
        setErrorMessage(null);

        try {
          await signUp(params);

          onComplete();
        } catch (err) {
          setErrorMessage((err as Error)?.message);
        }

        setSigningUp(false);
      },
      [onComplete],
    );

    return (
      <Form
        className={cx(styles.SignUpForm, className)}
        form={form}
        layout="vertical"
        onFinish={onFinish}
        scrollToFirstError
      >
        <Form.Item
          name="email"
          label="Email"
          rules={[
            {
              type: 'email',
              message: `The entered value is not a valid email.`,
            },
            {
              required: true,
              message: `Please enter your email.`,
            },
          ]}
        >
          <Input name="email" type="email" autoComplete="email" size="large" />
        </Form.Item>
        <Form.Item
          name="name"
          label="Full Name"
          rules={[
            {
              required: true,
              message: `Please enter your name.`,
            },
          ]}
        >
          <Input autoComplete="cc-name" size="large" />
        </Form.Item>
        {errorMessage && (
          <Alert className={styles.alert} type="error" message={errorMessage} closable />
        )}
        <Button
          className={styles.submit}
          type="primary"
          size="large"
          htmlType="submit"
          loading={signingUp}
        >
          Sign Up
        </Button>
        <div className={styles.extra}>
          By signing up, you agree to the{' '}
          <a href="https://superbloom.one/terms-of-service" target="_blank" rel="noreferrer">
            Terms
          </a>{' '}
          and{' '}
          <a href="https://superbloom.one/terms-of-service" target="_blank" rel="noreferrer">
            Privacy Policies
          </a>
          .
        </div>
      </Form>
    );
  },
);
