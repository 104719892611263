import React from 'react';
import cx from 'classnames';

import { Drawer } from 'antd';
import { ErrorNotice, LoadSpinner, SocialPostDetail } from '@components';

import { useGetSocialPostById } from '@hooks';

import styles from './SocialPostPanel.module.scss';

interface IProps {
  postId: string;
  open: boolean;
  onRequestClose(): void;

  className?: string;
}

/**
 * @type {React.FC}
 */
export const SocialPostPanel: React.FC<React.PropsWithChildren<IProps>> = React.memo(
  ({ postId, open, onRequestClose, className }) => {
    const { loading, post, error } = useGetSocialPostById({
      variables: {
        id: postId,
      },
    });

    return (
      <Drawer
        className={cx(styles.SocialPostPanel, className)}
        onClose={onRequestClose}
        open={open}
        width={980}
        destroyOnClose
      >
        {loading && <LoadSpinner className={styles.loading} />}
        {!loading && error && <ErrorNotice />}
        {!loading && post && <SocialPostDetail post={post} />}
      </Drawer>
    );
  },
);
