import React from 'react';
import cx from 'classnames';
import { noop } from 'lodash';

import { Button, Tooltip } from 'antd';
import { EditFilled } from '@ant-design/icons';

import type { TooltipPlacement } from 'antd/lib/tooltip';
import type { SizeType } from 'antd/lib/config-provider/SizeContext';

import styles from './EditButton.module.scss';

interface IProps {
  name?: string;
  size?: SizeType;
  placement?: TooltipPlacement;
  loading?: boolean;
  disabled?: boolean;
  icon?: React.ReactNode;
  onClick?(): void;

  className?: string;
}

const { useCallback } = React;

/**
 * @type {React.FC}
 */
export const EditButton: React.FC<IProps> = React.memo(
  ({
    name = 'resource',
    size = 'middle',
    placement = 'top',
    loading = false,
    disabled = false,
    icon = <EditFilled className={styles.icon} />,
    onClick = noop,
    className,
  }) => {
    // if the edit button is used in a grid item, stop it from going into detail view
    const handleClick = useCallback(
      (e: React.MouseEvent<HTMLElement>) => {
        e.preventDefault();
        e.stopPropagation();

        onClick();
      },
      [onClick],
    );

    return (
      <Tooltip placement={placement} trigger={['click', 'hover']} title={`Edit ${name}`}>
        <Button
          className={cx(styles.EditButton, className)}
          icon={icon}
          size={size}
          shape="circle"
          type="text"
          disabled={disabled}
          loading={loading}
          onClick={handleClick}
        />
      </Tooltip>
    );
  },
);
