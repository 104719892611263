import numeral from 'numeral';

import { SalesTrackingIncentive, SalesTrackingIncentiveType } from '@types';

export function formatIncentiveValue(incentive: SalesTrackingIncentive) {
  switch (incentive.type) {
    case SalesTrackingIncentiveType.CPA: {
      return numeral(incentive.value).format('$0,0.[00]');
    }

    case SalesTrackingIncentiveType.CPC: {
      return numeral(incentive.value).format('$0,0.[00]');
    }

    case SalesTrackingIncentiveType.CPS: {
      return numeral(incentive.value / 100).format('0.[00]%');
    }

    case SalesTrackingIncentiveType.CPAS: {
      return `${numeral(incentive.value).format('$0,0.[00]')} + ${numeral(
        incentive.cpsValue / 100,
      ).format('0.[00]%')}`;
    }
  }
}
