import React from 'react';
import cx from 'classnames';

import { Drawer } from 'antd';
import { AffiliateTrackingDetail } from './AffiliateTrackingDetail';

import { ErrorBoundary } from '@utils';
import { useGlobalOverlayContext } from '../GlobalOverlayContext';

import styles from './AffiliateTrackingOverlay.module.scss';

interface IProps {
  className?: string;
}

const { useMemo } = React;

/**
 * @type {React.FC}
 */
export const AffiliateTrackingOverlay: React.FC<React.PropsWithChildren<IProps>> = React.memo(
  ({ className }) => {
    const { entity, closeOverlay } = useGlobalOverlayContext();

    const trackingId = useMemo(() => {
      if (entity?.type === 'affiliate_tracking') {
        return entity.id;
      }

      return null;
    }, [entity]);

    return (
      <Drawer
        className={cx(styles.AffiliateTrackingOverlay, className)}
        open={!!trackingId}
        onClose={closeOverlay}
        width={1000}
        closable={false}
      >
        {trackingId && (
          <ErrorBoundary>
            <AffiliateTrackingDetail trackingId={trackingId} />
          </ErrorBoundary>
        )}
      </Drawer>
    );
  },
);
