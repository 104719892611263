import React from 'react';

import { faAddressCard } from '@fortawesome/free-solid-svg-icons';
import { IIconProps, Icon } from './Icon';

interface IProps extends Omit<IIconProps, 'icon'> {}

/**
 * @type {React.FC}
 */
export const AddressCardIcon: React.FC<React.PropsWithChildren<IProps>> = React.memo((props) => {
  return <Icon {...props} icon={faAddressCard} />;
});
