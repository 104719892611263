import { gql } from '@apollo/client';

export const MESSAGE_FRAGMENT = gql`
  fragment Message on Message {
    id
    brandId
    threadId
    type
    senderType
    internalDate
    data {
      labels
      from
      to
      cc
      snippet
      subject
      payload {
        textHtml
        textPlain
      }
    }
    createdDate
    updatedDate
  }
`;
