import { gql } from '@apollo/client';

import {
  SOCIAL_ACCOUNT_FRAGMENT,
  CREATOR_ACCOUNT_RESOURCE_FRAGMENT,
  AUTHORIZATION_RESOURCE_FRAGMENT,
} from './fragments';

//========================================================
//= Fragments
//========================================================
export const CREATOR_ACCOUNT_RESOURCE_DETAIL_FRAGMENT = gql`
  fragment CreatorAccountResourceDetail on CreatorAccountResource {
    ...CreatorAccountResource
    authorizationResource {
      ...AuthorizationResource
    }
    account {
      ...SocialAccount
    }
  }
  ${AUTHORIZATION_RESOURCE_FRAGMENT}
  ${CREATOR_ACCOUNT_RESOURCE_FRAGMENT}
  ${SOCIAL_ACCOUNT_FRAGMENT}
`;

//========================================================
//= Queries
//========================================================
export const GET_ACCOUNT_RESOURCES_QUERY = gql`
  query GetAccountResources {
    accountResources: creatorGetAccountResources {
      ...CreatorAccountResourceDetail
    }
  }
  ${CREATOR_ACCOUNT_RESOURCE_DETAIL_FRAGMENT}
`;

//========================================================
//= Mutations
//========================================================
export const AUTHORIZE_RESOURCE_MUTATION = gql`
  mutation AuthorizeResource($type: AuthorizationResourceType!, $redirectUri: String) {
    url: creatorAuthorizeResource(type: $type, redirectUri: $redirectUri)
  }
`;

export const REVOKE_CREATOR_RESOURCE_MUTATION = gql`
  mutation RevokeCreatorResource($id: String!) {
    succeeded: creatorRevokeResource(id: $id)
  }
`;

export const SYNC_CREATOR_ACCOUNT_RESOURCE_MUTATION = gql`
  mutation SyncCreatorAccountResource($id: String!) {
    succeeded: creatorSyncCreatorAccountResource(id: $id)
  }
`;
