import React from 'react';
import cx from 'classnames';

import { Typography } from 'antd';
import { FileIcon } from '@customIcons';

import styles from './FileContent.module.scss';

interface IProps {
  name?: string;
  mimeType?: string;
  className?: string;
}

const { Text } = Typography;

/**
 * @type {React.FC}
 */
export const FileContent: React.FC<IProps> = React.memo(({ name = null, className }) => {
  return (
    <div className={cx(styles.FileContent, className)}>
      <FileIcon className={styles.icon} />
      {name && (
        <Text className={styles.name} ellipsis>
          {name}
        </Text>
      )}
    </div>
  );
});
