import { WorkflowState } from '@types';

export const CollabStatusLabel: Partial<Record<WorkflowState, string>> = {
  // prospect stage
  [WorkflowState.ProspectStateInvitationReceived]: 'Pending Invitation',
  [WorkflowState.ProspectStateApplicationReceived]: 'Applied',
  [WorkflowState.ProspectStateInvitationRejected]: 'Invitation Rejected',
  [WorkflowState.ProspectStateApplicationRejected]: 'Application Rejected',
  // term stage
  [WorkflowState.TermStateAwaitProposeTerm]: 'Send Collab Terms',
  [WorkflowState.TermStateBrandProposedTerm]: 'Brand Proposed Terms',
  [WorkflowState.TermStateCreatorProposedTerm]: 'Creator Proposed Terms',
  // email stage
  [WorkflowState.EmailStateAwaitSendEmail]: 'Awaiting Brand Action',
  // contract stage
  [WorkflowState.ContractStateAwaitSendContract]: 'Awaiting Contracts',
  [WorkflowState.ContractStateAwaitAcceptContract]: 'Awaiting Signature',
  // info update stage
  [WorkflowState.InfoUpdateStateAwaitUpdateInfo]: 'Awaiting Info Update',
  // product fulfillment stage
  [WorkflowState.ProductFulfillmentStateAwaitPickProducts]: 'Pick Preferred Products',
  [WorkflowState.ProductFulfillmentStateAwaitCreateOrder]: 'Awaiting Order Creation',
  [WorkflowState.ProductFulfillmentStateAwaitOrderFulfillment]: 'Awaiting Order Fulfillment',
  [WorkflowState.ProductFulfillmentStateAwaitOrderDelivery]: 'Awaiting Order Delivery',
  // content review stage
  [WorkflowState.ContentReviewStateReviewingContents]: 'Review Contents',
  [WorkflowState.ContentReviewStateVerifyingPosts]: 'Verify Posts',
  // payment stage
  [WorkflowState.PaymentStateAwaitCreatePayment]: 'Awaiting Payment',
  [WorkflowState.PaymentStateAwaitConfirmPayment]: 'Awaiting Payment Confirmation',
  // sales tracking stage
  [WorkflowState.SalesTrackingStateAwaitCreateLink]: 'Awaiting Tracking Link',
};

export const CollabStatusTooltip: Partial<Record<WorkflowState, string>> = {
  // prospect stage
  [WorkflowState.ProspectStateInvitationReceived]:
    'You can either accept or reject the invitation.',
  [WorkflowState.ProspectStateApplicationReceived]:
    'Please be patient while the brand is reviewing your application.',
  [WorkflowState.ProspectStateInvitationRejected]: 'You have rejected the invitation.',
  [WorkflowState.ProspectStateApplicationRejected]: 'The brand has rejected your application.',
  // term stage
  // email stage
  [WorkflowState.EmailStateAwaitSendEmail]: 'Awaiting the brand on the next steps.',
  // contract stage
  [WorkflowState.ContractStateAwaitSendContract]: 'Awaiting the brand to send over the contracts.',
  [WorkflowState.ContractStateAwaitAcceptContract]:
    'Please sign the contracts from your email inbox.',
  // info update stage
  [WorkflowState.InfoUpdateStateAwaitUpdateInfo]: 'Please update your address information.',
  // product fulfillment stage
  [WorkflowState.ProductFulfillmentStateAwaitPickProducts]:
    'Please select your preferred products.',
  [WorkflowState.ProductFulfillmentStateAwaitCreateOrder]:
    'Awaiting the brand to create the gift order.',
  [WorkflowState.ProductFulfillmentStateAwaitOrderFulfillment]:
    'Awaiting the brand to fulfill the order.',
  [WorkflowState.ProductFulfillmentStateAwaitOrderDelivery]: 'Awaiting the order to be delivered.',
  // content review stage
  [WorkflowState.ContentReviewStateReviewingContents]: 'Reviewing member uploaded contents.',
  [WorkflowState.ContentReviewStateVerifyingPosts]: 'Verifying member posts',
  // payment stage
  [WorkflowState.PaymentStateAwaitCreatePayment]: 'Awaiting the brand to create payment.',
  [WorkflowState.PaymentStateAwaitConfirmPayment]: 'Awaiting the brand to confirm the payment.',
  // sales tracking stage
  [WorkflowState.SalesTrackingStateAwaitCreateLink]:
    'Awaiting the brand to create a tracking link for you.',
};

export const ActionRequiredStates: WorkflowState[] = [
  WorkflowState.ProspectStateInvitationReceived,
  WorkflowState.TermStateBrandProposedTerm,
  WorkflowState.ContractStateAwaitAcceptContract,
  WorkflowState.InfoUpdateStateAwaitUpdateInfo,
  WorkflowState.ContentReviewStateReviewingContents,
  WorkflowState.ContentReviewStateVerifyingPosts,
];
