import { IProgramCollabDetail, WorkflowState } from '@types';

export function getNextStep(collab: IProgramCollabDetail): string {
  const { workflowItem } = collab;
  const { state } = workflowItem;

  switch (state) {
    /**
     * prospect stage
     */
    case WorkflowState.ProspectStateApplicationReceived: {
      return 'Awaiting brand to accept application...';
    }
    case WorkflowState.ProspectStateInvitationReceived: {
      return 'Awaiting member to accept invitation...';
    }
    /**
     * term stage
     */
    /**
     * email stage
     */
    case WorkflowState.EmailStateAwaitSendEmail: {
      return 'Awaiting brand to send welcome email...';
    }
    /**
     * contract stage
     */
    case WorkflowState.ContractStateAwaitSendContract: {
      return 'Awaiting brand to send the contracts...';
    }
    case WorkflowState.ContractStateAwaitAcceptContract: {
      return 'Awaiting member to sign the contracts...';
    }
    /**
     * info update stage
     */
    case WorkflowState.InfoUpdateStateAwaitUpdateInfo: {
      return 'Awaiting member to update their information...';
    }
    /**
     * product fulfillment stage
     */
    case WorkflowState.ProductFulfillmentStateAwaitPickProducts: {
      return 'Awaiting member to pick preferred products...';
    }
    case WorkflowState.ProductFulfillmentStateAwaitCreateOrder: {
      return 'Awaiting brand to create gift order...';
    }
    case WorkflowState.ProductFulfillmentStateAwaitOrderFulfillment: {
      return 'Awaiting brand to fulfill the order...';
    }
    case WorkflowState.ProductFulfillmentStateAwaitOrderDelivery: {
      return 'Awaiting member to receive the order...';
    }
    /**
     * content review stage
     */
    case WorkflowState.ContentReviewStateReviewingContents: {
      return 'Reviewing member uploaded contents...';
    }
    /**
     * payment stage
     */
    case WorkflowState.PaymentStateAwaitCreatePayment: {
      return 'Awaiting brand to create payment...';
    }
    case WorkflowState.PaymentStateAwaitConfirmPayment: {
      return 'Awaiting brand to confirm payment...';
    }
    /**
     * sales tracking stage
     */
    case WorkflowState.SalesTrackingStateAwaitCreateLink: {
      return 'Awaiting brand to generate tracking link...';
    }

    default: {
      return null;
    }
  }
}
