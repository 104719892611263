import React from 'react';
import { useQuery, QueryHookOptions } from '@apollo/client';

import { message } from 'antd';

import { GET_ACTION_REQUIRED_COLLAB_COUNT_QUERY } from '@queries';
import {
  GetActionRequiredCollabCountQuery,
  GetActionRequiredCollabCountQueryVariables,
} from '@types';

type IOptions = QueryHookOptions<
  GetActionRequiredCollabCountQuery,
  GetActionRequiredCollabCountQueryVariables
>;

const { useEffect, useMemo } = React;

export function useGetActionRequiredCollabCount(options: IOptions = {}) {
  const {
    loading,
    data: { result } = {},
    error,
    refetch,
  } = useQuery<GetActionRequiredCollabCountQuery, GetActionRequiredCollabCountQueryVariables>(
    GET_ACTION_REQUIRED_COLLAB_COUNT_QUERY,
    {
      ...options,
      fetchPolicy: 'cache-and-network',
      nextFetchPolicy: 'cache-first',
      notifyOnNetworkStatusChange: true,
    },
  );

  const errorMessage = useMemo(() => error?.message, [error]);
  useEffect(() => {
    if (!loading && errorMessage) {
      message.error(errorMessage);
    }
  }, [loading, errorMessage]);

  return {
    loading,
    result,
    error,
    refetch,
  };
}
