export const firebaseConfig = {
  apiKey: 'AIzaSyD_0DTBeRJ1lsAC5mGtVQHWd51qouu1qCs',
  authDomain: 'morax-375121.firebaseapp.com',
  projectId: 'morax-375121',
  storageBucket: 'morax-375121.appspot.com',
  messagingSenderId: '922090089025',
  appId: '1:922090089025:web:a92f3d645133f9971234c0',
};

export const firebaseMessagingVapidKey =
  'BKi2Ic3RH2MhLlXDbHErzjF_VhmdWvDYjqZnNfuJf73iZTb9naXZiZ0crPwbtBW0vYlNQPuskoE5IxCHwFh-zMQ';
