import React from 'react';
import cx from 'classnames';

import { Spin } from 'antd';
import { LoadingOutlined } from '@ant-design/icons';

import styles from './LoadSpinner.module.scss';

type TSize = 'small' | 'default' | 'large';
interface IProps {
  size?: TSize;
  className?: string;
}

/**
 * @type {React.FC}
 */
export const LoadSpinner: React.FC<React.PropsWithChildren<IProps>> = React.memo(
  ({ size = 'large', className }) => {
    return (
      <div className={cx(styles.LoadSpinner, className)}>
        <Spin size={size} indicator={<LoadingOutlined spin />} />
      </div>
    );
  },
);
