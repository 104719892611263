import React from 'react';
import firebase from 'firebase/compat/app';
import 'firebase/compat/messaging';
import 'firebase/compat/storage';
import 'firebase/compat/firestore';

import { firebaseConfig } from './config';

const { useMemo } = React;

export function useInitializeFirebase() {
  const firebaseApp = useMemo<firebase.app.App>(() => {
    const firebaseApp = firebase.initializeApp(firebaseConfig);

    return firebaseApp;
  }, []);

  return firebaseApp;
}
