import React from 'react';
import cx from 'classnames';
import { find, noop } from 'lodash';

import { Button } from 'antd';
import { PhotoFilmIcon } from '@customIcons';
import { SelectPostTrackingPanel } from './SelectPostTrackingPanel';

import { IProgramCollab, PostTrackingStatus } from '@types';

import styles from './SubmitPostsButton.module.scss';

interface IProps {
  collab: IProgramCollab;

  onComplete?(): void;
  className?: string;
}

const { useState, useMemo, useCallback } = React;

/**
 * @type {React.FC}
 */
export const SubmitPostsButton: React.FC<React.PropsWithChildren<IProps>> = React.memo(
  ({ collab, onComplete = noop, className }) => {
    const [showModal, setShowModal] = useState(false);

    const buttonType = useMemo(() => {
      const hasPendingSubmit = !!find(
        collab.postTrackings,
        (p) => p.status === PostTrackingStatus.PendingSubmit,
      );
      if (hasPendingSubmit) {
        return 'primary';
      }

      return 'default';
    }, [collab]);
    const onShowModal = useCallback(() => setShowModal(true), []);
    const onCloseModal = useCallback(() => setShowModal(false), []);
    const stopPropagation = useCallback((event: React.MouseEvent) => {
      // NOTE: do not preventDefault(), otherwise form submission wont work
      // do not open detail panel
      event.stopPropagation();
    }, []);

    return (
      <div onClick={stopPropagation}>
        <Button
          className={cx(styles.SubmitPostsButton, className)}
          icon={<PhotoFilmIcon className={styles.icon} />}
          shape="round"
          type={buttonType}
          onClick={onShowModal}
        >
          Submit Posts
        </Button>
        <SelectPostTrackingPanel
          show={showModal}
          onRequestClose={onCloseModal}
          collabId={collab.id}
          onComplete={onComplete}
        />
      </div>
    );
  },
);
