import React from 'react';
import { useMutation, MutationHookOptions, MutationFunctionOptions } from '@apollo/client';

import { message } from 'antd';

import { BULK_MARK_PROGRAM_COLLAB_AS_UNREAD_MUTATION } from '@queries';
import {
  BulkMarkProgramCollabAsUnreadMutation,
  BulkMarkProgramCollabAsUnreadMutationVariables,
} from '@types';

type IOptions = MutationHookOptions<
  BulkMarkProgramCollabAsUnreadMutation,
  BulkMarkProgramCollabAsUnreadMutationVariables
>;
type IFunctionOptions = MutationFunctionOptions<
  BulkMarkProgramCollabAsUnreadMutation,
  BulkMarkProgramCollabAsUnreadMutationVariables
>;

const { useCallback } = React;

export const useBulkMarkProgramCollabAsUnread = (options: IOptions = {}) => {
  const [_bulkMarkProgramCollabAsUnread, { loading, error }] = useMutation<
    BulkMarkProgramCollabAsUnreadMutation,
    BulkMarkProgramCollabAsUnreadMutationVariables
  >(BULK_MARK_PROGRAM_COLLAB_AS_UNREAD_MUTATION, {
    ...options,
  });

  const bulkMarkProgramCollabAsUnread = useCallback(
    async (options: IFunctionOptions = {}) => {
      try {
        const result = await _bulkMarkProgramCollabAsUnread(options);

        return result;
      } catch (err) {
        message.error((err as Error).message);
      }
    },
    [_bulkMarkProgramCollabAsUnread],
  );

  return {
    bulkMarkProgramCollabAsUnread,
    loading,
    error,
  };
};
