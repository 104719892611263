export const resourceZhCN = {
  translation: {
    ACCEPT: '同意',
    ACCOUNT: '个人信息',
    ACCOUNT_SECURITY: '账号安全',
    BILLING_PLURAL: '支付设置',
    CAMPAIGN: '合作',
    CAMPAIGN_PLURAL: '合作',
    COLLABORATION_PLURAL: '合作',
    DELETE: '删除',
    DISCOVER: '发现',
    EDIT: '编辑',
    FORECASTED_COST: '预测开销',
    INVITE: '邀请',
    LANGUAGE: '语言',
    LOG_OUT: '登出',
    MY_LISTS: '我的收藏',
    NOTIFICATION_PLURAL: '消息',
    PASSWORD: '密码',
    PAYMENT: '支付',
    PAYMENT_HISTORY: '支付记录',
    PAYMENT_METHOD: '支付方式',
    PROFILE: '个人信息',
    REJECT: '拒绝',
    SAVE: '收藏',
    SEARCH: '搜索',
    SET_AS_DEFAULT_PAYMENT_METHOD: '设为默认支付方式',
    SETTING_PLURAL: '设置',
    SUBSCRIPTION: '订阅',
    SWITCH_BRAND: '更换商家',
    TERMINATE: '终止',
  },
};
