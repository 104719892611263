import React from 'react';
import cx from 'classnames';
import { noop } from 'lodash';

import { Button, Popconfirm, Tooltip } from 'antd';
import { DeleteFilled } from '@ant-design/icons';

import type { TooltipPlacement } from 'antd/lib/tooltip';
import type { SizeType } from 'antd/lib/config-provider/SizeContext';

import styles from './DeleteButton.module.scss';

interface IProps {
  name?: string;
  size?: SizeType;
  placement?: TooltipPlacement;
  onConfirm?(): void;
  deleting?: boolean;
  disabled?: boolean;
  icon?: React.ReactNode;
  tooltipTitle?: string;

  className?: string;
}

const { useCallback } = React;

/**
 * @type {React.FC}
 */
export const DeleteButton: React.FC<IProps> = React.memo(
  ({
    name = 'resource',
    size = 'middle',
    placement = 'top',
    onConfirm = noop,
    deleting = false,
    disabled = false,
    icon = <DeleteFilled className={styles.icon} />,
    tooltipTitle = null,
    className,
  }) => {
    // if the delete button is used in a grid item, stop it from going into detail view
    const handleClick = useCallback((e: React.MouseEvent<HTMLElement>) => {
      e.preventDefault();
      e.stopPropagation();
    }, []);
    const onPopconfirmConfirm = useCallback(
      (e: React.MouseEvent<HTMLElement>) => {
        handleClick(e);
        onConfirm();
      },
      [handleClick, onConfirm],
    );

    return (
      <Tooltip
        placement={placement}
        trigger={['click', 'hover']}
        title={tooltipTitle || `Delete ${name}`}
      >
        <Popconfirm
          title={`Are you sure you want to delete this ${name}?`}
          placement={placement}
          onConfirm={onPopconfirmConfirm}
          onCancel={handleClick}
          okText="Yes, delete"
          cancelText="Cancel"
          okButtonProps={{
            danger: true,
          }}
        >
          <Button
            className={cx(styles.DeleteButton, className)}
            icon={icon}
            size={size}
            shape="circle"
            type="text"
            disabled={disabled}
            loading={deleting}
            onClick={handleClick}
          />
        </Popconfirm>
      </Tooltip>
    );
  },
);
