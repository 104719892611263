import React from 'react';
import cx from 'classnames';

import { Pagination } from 'antd';
import { RefreshButton } from '@components';
import { ProductSearch } from './ProductSearch';

import { usePickProductsContext } from '../context';

import styles from './ListHeader.module.scss';
import { ShoppingCart } from './ShoppingCart';

interface IProps {
  className?: string;
}

const { useMemo, useCallback } = React;

/**
 * @type {React.FC}
 */
export const ListHeader: React.FC<React.PropsWithChildren<IProps>> = React.memo(({ className }) => {
  const { loading, refetch, count, limit, offset, setLimit, setOffset } = usePickProductsContext();

  const page = useMemo(() => Math.floor(offset / limit) + 1, [offset, limit]);
  const onPaginationChange = useCallback(
    (page: number, pageSize: number) => {
      setLimit(pageSize);
      setOffset((page - 1) * pageSize);
    },
    [setLimit, setOffset],
  );

  return (
    <div className={cx(styles.ListHeader, className)}>
      <ProductSearch className={styles.search} />
      <Pagination
        className={styles.pagination}
        current={page}
        total={count}
        pageSize={limit}
        showSizeChanger={true}
        showTotal={(total) => `${total} product(s)`}
        onChange={onPaginationChange}
      />
      <RefreshButton className={styles.refresh} loading={loading} onClick={refetch} />
      <ShoppingCart className={styles.cart} />
    </div>
  );
});
