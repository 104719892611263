import React from 'react';
import cx from 'classnames';
import { startCase } from 'lodash';

import { Tag } from 'antd';

import { ProgramCollabContractStatus } from '@types';

import styles from './ContractStatusTag.module.scss';

interface IProps {
  status: ProgramCollabContractStatus;

  className?: string;
}

const { useMemo } = React;

/**
 * @type {React.FC}
 */
export const ContractStatusTag: React.FC<React.PropsWithChildren<IProps>> = React.memo(
  ({ status, className }) => {
    const color = useMemo(() => {
      if (status === ProgramCollabContractStatus.Sent) {
        return 'cyan';
      } else if (status === ProgramCollabContractStatus.Declined) {
        return 'red';
      } else if (status === ProgramCollabContractStatus.Voided) {
        return 'gray';
      } else if (status === ProgramCollabContractStatus.Completed) {
        return 'green';
      }

      return 'gray';
    }, [status]);

    return (
      <Tag className={cx(styles.ContractStatusTag, className)} color={color}>
        {startCase(status)}
      </Tag>
    );
  },
);
