import React from 'react';
import cx from 'classnames';

import { Button } from 'antd';
import { CheckIcon } from '@customIcons';

import { IProgramCollab, WorkflowEvent } from '@types';
import { useWorkflowCheckIn } from '@hooks';

import styles from './AcceptInviteButton.module.scss';

interface IProps {
  collab: IProgramCollab;

  className?: string;
}

const { useCallback } = React;

/**
 * @type {React.FC}
 */
export const AcceptInviteButton: React.FC<React.PropsWithChildren<IProps>> = React.memo(
  ({ collab, className }) => {
    const { workflowCheckIn, loading } = useWorkflowCheckIn();

    const onAcceptInvite = useCallback(
      async (event: React.MouseEvent<HTMLElement>) => {
        // avoid open detail panel
        event.stopPropagation();

        await workflowCheckIn({
          variables: {
            collabId: collab.id,
            event: WorkflowEvent.ProspectStageAcceptInvitation,
          },
        });
      },
      [collab, workflowCheckIn],
    );

    return (
      <Button
        className={cx(styles.AcceptInviteButton, className)}
        type="text"
        shape="round"
        icon={<CheckIcon className={styles.icon} />}
        loading={loading}
        onClick={onAcceptInvite}
      >
        Accept
      </Button>
    );
  },
);
