import React from 'react';
import cx from 'classnames';
import { filter, map } from 'lodash';

import { Collapse, Tooltip, Typography } from 'antd';
import { FieldTimeOutlined } from '@ant-design/icons';
import { CheckIcon } from '@customIcons';
import { WorkflowStageIcon } from '@components';
import { getPanelFutureActions, isPanelCollapsible, PanelContent } from './overview';

import { getWorkflowStageName } from '@utils';
import { IProgramCollabDetail, WorkflowStage } from '@types';

import { TPanelStepStatus, getPanelStatus, getPanelDescription, getPanelActions } from './overview';

import styles from './CollabOverview.module.scss';

interface IProps {
  collab: IProgramCollabDetail;

  className?: string;
}

const { useMemo, useCallback } = React;
const { Panel } = Collapse;
const { Text } = Typography;
const StatusIcon: Partial<Record<TPanelStepStatus, JSX.Element>> = {
  pending: <FieldTimeOutlined className={cx(styles.statusIcon, styles.pending)} />,
  pendingManager: <FieldTimeOutlined className={cx(styles.statusIcon, styles.pending)} />,
  completed: <CheckIcon className={cx(styles.statusIcon, styles.completed)} />,
};
const StatusTooltip: Record<TPanelStepStatus, string> = {
  disabled: 'This stage has not started yet.',
  pending: 'Pending...',
  pendingMember: 'Pending member actions...',
  pendingManager: 'Pending brand actions...',
  completed: 'This stage has been completed.',
};
const CreatorVisibleStages: WorkflowStage[] = [
  WorkflowStage.Prospect,
  WorkflowStage.Term,
  WorkflowStage.Contract,
  WorkflowStage.InfoUpdate,
  WorkflowStage.ProductFulfillment,
  WorkflowStage.ContentReview,
  WorkflowStage.SalesTracking,
  WorkflowStage.Payment,
];

/**
 * @type {React.FC}
 */
export const CollabOverview: React.FC<React.PropsWithChildren<IProps>> = React.memo(
  ({ collab, className }) => {
    const stages: WorkflowStage[] = useMemo(() => {
      return filter(
        map(collab.program.workflowConfig.stages, (s) => s.type),
        (stage) => CreatorVisibleStages.includes(stage),
      );
    }, [collab]);
    const onStopPropagation = useCallback((e: React.MouseEvent) => e.stopPropagation(), []);

    return (
      <div className={cx(styles.CollabOverview, className)}>
        <Collapse className={styles.stages} expandIconPosition="end" destroyInactivePanel>
          {map(stages, (stage) => {
            const status = getPanelStatus(stage, collab);
            const description = getPanelDescription(stage, collab);
            const actions = getPanelActions(stage, collab);
            const futureActions = getPanelFutureActions(stage, collab);
            const statusIcon = StatusIcon[status];
            const collapsible = isPanelCollapsible(stage, collab);

            return (
              <Panel
                key={stage}
                className={cx(styles.panel, {
                  [styles.completed]: status === 'completed',
                  [styles.disabled]: status === 'disabled',
                })}
                collapsible={collapsible ? undefined : 'disabled'}
                showArrow={collapsible ? true : false}
                header={
                  <div className={styles.header}>
                    <div className={styles.left}>
                      <div className={styles.main}>
                        <WorkflowStageIcon className={styles.icon} stage={stage} />
                        {getWorkflowStageName(stage)}
                      </div>
                      {description && (
                        <Text className={styles.info} type="secondary" ellipsis>
                          {description}
                        </Text>
                      )}
                    </div>
                    <div className={styles.actions} onClick={onStopPropagation}>
                      {React.Children.toArray(actions)}
                      {React.Children.toArray(futureActions)}
                    </div>
                    {statusIcon && <Tooltip title={StatusTooltip[status]}>{statusIcon}</Tooltip>}
                  </div>
                }
              >
                <PanelContent stage={stage} collab={collab} />
              </Panel>
            );
          })}
        </Collapse>
      </div>
    );
  },
);
