import React from 'react';
import cx from 'classnames';
import { Routes, Route, Navigate, NavLink, useNavigate } from 'react-router-dom';
import { ApolloProvider } from '@apollo/client';

import { Badge, Button } from 'antd';
import { ArrowLeftOutlined } from '@ant-design/icons';
import { ErrorNotice } from '@components';
import { UserMenu } from './UserMenu';
import { NotificationPopover } from './NotificationPopover';

import { ErrorBoundary, lazyLoadComponent } from '@utils';
import { useGetUnreadProgramCollabCount } from '@hooks';
import {
  NotificationContextProvider,
  ProfileContextProvider,
  useAppContext,
  useAuthContext,
} from '@context';
import { GlobalOverlayContextProvider } from './Overlays';

import styles from './Home.module.scss';

interface IProps {
  className?: string;
}

const { lazy } = React;
const OverviewPage = lazy(() => import('./Overview/OverviewPage'));
const ProgramsPage = lazy(() => import('./Program/ProgramsPage'));
const ProgramCollabs = lazy(() => import('./ProgramCollabs/CollabsPage'));
const SettingsPage = lazy(() => import('./Settings/SettingsPage'));

/**
 * @type {React.FC}
 */
const HomeInternal: React.FC<React.PropsWithChildren<IProps>> = React.memo(({ className }) => {
  const navigate = useNavigate();
  const { count } = useGetUnreadProgramCollabCount();

  return (
    <div className={cx(styles.Home, className)}>
      <div className={styles.header}>
        <div className={styles.headerContent}>
          <div className={styles.logo} onClick={() => navigate('/')} />
          <div className={styles.nav}>
            <NavLink
              className={({ isActive }) =>
                cx(styles.navItem, {
                  [styles.active]: isActive,
                })
              }
              to="/overview"
            >
              Overview
            </NavLink>
            {/* <NavLink
              className={({ isActive }) =>
                cx(styles.navItem, {
                  [styles.active]: isActive,
                })
              }
              to="/programs"
            >
              Marketplace
            </NavLink> */}
            <NavLink
              className={({ isActive }) =>
                cx(styles.navItem, {
                  [styles.active]: isActive,
                })
              }
              to="/collabs"
            >
              Collaborations
              <Badge className={styles.unreadCount} count={count} overflowCount={99} />
            </NavLink>
          </div>
          <div className={styles.extra}>
            <NotificationPopover className={styles.notification} />
            <UserMenu className={styles.userMenu} />
          </div>
        </div>
      </div>
      <div className={styles.content}>
        <ErrorBoundary>
          <Routes>
            <Route path="overview/*" element={lazyLoadComponent(OverviewPage)} />
            <Route path="programs/*" element={lazyLoadComponent(ProgramsPage)} />
            <Route path="collabs/*" element={lazyLoadComponent(ProgramCollabs)} />
            <Route path="settings/*" element={lazyLoadComponent(SettingsPage)} />
            <Route path="*" element={<Navigate to="/overview" replace />} />
          </Routes>
        </ErrorBoundary>
      </div>
    </div>
  );
});

export const Home = React.memo((props) => {
  const { sessionExpired, apolloClient } = useAppContext();
  const { signOut } = useAuthContext();

  if (sessionExpired) {
    return (
      <ErrorNotice
        className={styles.notice}
        status="403"
        title="Please log in again"
        description="For security reason, we've logged you out, please log back in again."
        extraDescription={false}
        action={
          <Button onClick={signOut} icon={<ArrowLeftOutlined />}>
            Go Back
          </Button>
        }
      />
    );
  }

  return (
    <ApolloProvider client={apolloClient}>
      <ProfileContextProvider>
        <NotificationContextProvider>
          <GlobalOverlayContextProvider>
            <HomeInternal {...props} />
          </GlobalOverlayContextProvider>
        </NotificationContextProvider>
      </ProfileContextProvider>
    </ApolloProvider>
  );
});
