import qs from 'qs';

const keywords = {
  true: true,
  false: false,
  null: null,
  undefined: undefined,
};

export function parseLocationSearch() {
  return qs.parse(window.location.search, {
    ignoreQueryPrefix: true,
    // reference: https://github.com/ljharb/qs/issues/91
    decoder(str, _, charset) {
      const strWithoutPlus = str.replace(/\+/g, ' ');
      if (charset === 'iso-8859-1') {
        return strWithoutPlus.replace(/%[0-9a-f]{2}/gi, decodeURIComponent);
      }

      if (/^(\d+|\d*\.\d+)$/.test(str)) {
        return parseFloat(str);
      }

      if (str in keywords) {
        return keywords[str];
      }

      // utf-8
      try {
        return decodeURIComponent(strWithoutPlus);
      } catch (e) {
        return strWithoutPlus;
      }
    },
  });
}
