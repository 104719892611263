import React from 'react';
import cx from 'classnames';
import { map } from 'lodash';

import { Tag, Tooltip, Typography } from 'antd';
import { UserAvatar } from '@components';

import { getDiscountCodeInfo } from '@utils';
import { DiscountCodeSelection, IShopifyDiscountCode } from '@types';

import styles from './DiscountCodeTag.module.scss';

interface IProps {
  discountCode: IShopifyDiscountCode;

  shared?: boolean;
  className?: string;
}

const { Title, Text } = Typography;

/**
 * @type {React.FC}
 */
export const DiscountCodeTag: React.FC<React.PropsWithChildren<IProps>> = React.memo(
  ({ discountCode, shared = false, className }) => {
    const { code, info } = discountCode;

    return (
      <Tooltip
        title={
          <div className={styles.DiscountCodeOverlay}>
            <Title className={styles.title} level={5}>
              {code}
            </Title>
            <div className={styles.info}>
              {map(getDiscountCodeInfo(discountCode), (str, index) => (
                <div key={index}>• {str}</div>
              ))}
            </div>
            {info.selection !== DiscountCodeSelection.ALL_PRODUCTS && (
              <div className={styles.products}>
                {map(info.entities, (entity) => (
                  <div key={entity.id} className={styles.item}>
                    <UserAvatar
                      className={styles.avatar}
                      size="small"
                      shape="square"
                      picture={entity.image}
                      name={entity.title}
                      showBoth
                    />
                  </div>
                ))}
              </div>
            )}
          </div>
        }
      >
        <Tag className={cx(styles.DiscountCodeTag, className)} color="gray">
          <Text className={styles.text} ellipsis>
            {code}
          </Text>
          {shared && <span className={styles.shared}>(shared)</span>}
        </Tag>
      </Tooltip>
    );
  },
);
