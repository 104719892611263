import React from 'react';
import numeral from 'numeral';
import cx from 'classnames';

import { Statistic, Tooltip } from 'antd';

import { SalesTrackingStats } from '@types';

import styles from './TrackingStatsDisplay.module.scss';

type TDisplayMode = 'flexbox' | 'columns';
interface IProps {
  stats: Omit<SalesTrackingStats, '__typename'>;

  displayMode?: TDisplayMode;
  name?: string;
  className?: string;
}

const { useMemo } = React;

/**
 * @type {React.FC}
 */
export const TrackingStatsDisplay: React.FC<IProps> = React.memo(
  ({ stats, displayMode = 'flexbox', name = 'member', className }) => {
    const disabled = useMemo(() => {
      return !stats.clicks && !stats.conversions && !stats.cost && !stats.sales;
    }, [stats]);

    return (
      <div
        className={cx(styles.TrackingStatsDisplay, className, {
          [styles.disabled]: disabled,
          [styles.flexbox]: displayMode === 'flexbox',
          [styles.columns]: displayMode === 'columns',
        })}
      >
        <Statistic
          className={styles.item}
          title="Total Clicks"
          value={numeral(stats.clicks).format('0.[0] a')}
        />
        <Statistic
          className={styles.item}
          title="Total Orders"
          value={numeral(stats.conversions).format('0.[0] a')}
        />
        <Statistic
          className={styles.item}
          title="Total Sales"
          formatter={() => (
            <Tooltip
              overlayClassName={styles.TooltipOverlay}
              title={
                <>
                  Tracking link(s) generated
                  <span
                    className={cx(styles.sales, {
                      [styles.active]: stats.sales > 0,
                    })}
                  >
                    {numeral(stats.sales).format('$0,0.[00]')}
                  </span>{' '}
                  in total sales.
                </>
              }
            >
              <span
                className={cx(styles.sales, {
                  [styles.active]: stats.sales > 0,
                })}
              >
                {numeral(stats.sales).format('$0.[00] a')}
              </span>
            </Tooltip>
          )}
        />
        <Statistic
          className={styles.item}
          title="Total Payout"
          formatter={() => (
            <Tooltip
              overlayClassName={styles.TooltipOverlay}
              title={
                <>
                  <span className={styles.name}>{name}</span> will receive{' '}
                  <span
                    className={cx(styles.payout, {
                      [styles.active]: stats.cost > 0,
                    })}
                  >
                    {numeral(stats.cost).format('$0,0.[00]')}
                  </span>{' '}
                  in total payout.
                </>
              }
            >
              <span
                className={cx(styles.payout, {
                  [styles.active]: stats.cost > 0,
                })}
              >
                {numeral(stats.cost).format('$0.[00] a')}
              </span>
            </Tooltip>
          )}
        />
      </div>
    );
  },
);
