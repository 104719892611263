import React from 'react';
import numeral from 'numeral';
import cx from 'classnames';
import { first, size } from 'lodash';

import { Card, Button } from 'antd';
import { Image } from '@components';

import { IShopifyProduct } from '@types';

import styles from './ProductGridItem.module.scss';
import { ProductDetailPanel } from './ProductDetailPanel';
import { usePickProductsContext } from '../context';

interface IProps {
  product: IShopifyProduct;

  className?: string;
}

const { useState, useMemo, useCallback } = React;
const { Meta } = Card;

/**
 * @type {React.FC}
 */
export const ProductGridItem: React.FC<React.PropsWithChildren<IProps>> = React.memo(
  ({ product, className }) => {
    const { addItem } = usePickProductsContext();
    const [showModal, setShowModal] = useState(false);

    const openModal = useCallback(() => setShowModal(true), []);
    const closeModal = useCallback(() => setShowModal(false), []);

    const hasMultipleOptions = useMemo(() => size(product.info.variants) > 1, [product]);
    const onAddToCart = useCallback(
      (e: React.MouseEvent<HTMLElement>) => {
        e.stopPropagation();

        const variant = first(product.info.variants);
        const item = {
          productId: variant.productId,
          variantId: variant.id,
          title:
            size(product.info.variants) > 1
              ? `${product.info.title} - ${variant.title}`
              : product.info.title,
          quantity: 1,
          price: variant.price,
          image: variant.image || product.info.image,
        };

        addItem(item);
      },
      [product, addItem],
    );
    const actions = useMemo(() => {
      if (hasMultipleOptions) {
        return [<Button type="dashed">{size(product.info.variants)} options</Button>];
      }

      return [
        <Button type="primary" onClick={onAddToCart}>
          Add to cart
        </Button>,
      ];
    }, [product, onAddToCart, hasMultipleOptions]);

    return (
      <>
        <Card
          className={cx(styles.ProductGridItem, className)}
          hoverable
          cover={<Image className={styles.image} src={product.info.image} />}
          actions={actions}
          onClick={openModal}
        >
          <Meta
            title={product.info.title}
            description={
              <div className={styles.info}>
                <span className={styles.price}>
                  {hasMultipleOptions && 'from'}
                  <span className={styles.amount}>
                    {numeral(product.info.lowestPrice).format('$0,0.[00]')}
                  </span>
                </span>
              </div>
            }
          />
        </Card>
        <ProductDetailPanel product={product} show={showModal} onRequestClose={closeModal} />
      </>
    );
  },
);
