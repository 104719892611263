import { SalesTrackingIncentiveType } from '@types';

const IncentiveTitleForType: Record<SalesTrackingIncentiveType, string> = {
  [SalesTrackingIncentiveType.CPA]: 'Cost per Conversion (CPA)',
  [SalesTrackingIncentiveType.CPC]: 'Cost per Click (CPC)',
  [SalesTrackingIncentiveType.CPS]: 'Cost per Sale (CPS)',
  [SalesTrackingIncentiveType.CPAS]: 'Cost per Conversion (CPA) + Cost per Sale (CPS)',
};

export function getSalesTrackingIncentiveTitle(type: SalesTrackingIncentiveType) {
  return IncentiveTitleForType[type];
}
