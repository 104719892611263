import React from 'react';
import { useMutation, MutationHookOptions, MutationFunctionOptions } from '@apollo/client';

import { message } from 'antd';

import { CONNECT_STRIPE_ACCOUNT_MUTATION } from '@queries';
import { ConnectStripeAccountMutation } from '@types';

type IOptions = MutationHookOptions<ConnectStripeAccountMutation>;
type IFunctionOptions = MutationFunctionOptions<ConnectStripeAccountMutation>;

const { useCallback } = React;

export const useConnectStripeAccount = (options: IOptions = {}) => {
  const [_connectStripeAccount, { loading, error }] = useMutation<ConnectStripeAccountMutation>(
    CONNECT_STRIPE_ACCOUNT_MUTATION,
    {
      ...options,
    },
  );

  const connectStripeAccount = useCallback(
    async (options: IFunctionOptions = {}) => {
      try {
        const result = await _connectStripeAccount(options);

        return result;
      } catch (err) {
        message.error((err as Error).message);
      }
    },
    [_connectStripeAccount],
  );

  return {
    connectStripeAccount,
    loading,
    error,
  };
};
