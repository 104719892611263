import React from 'react';
import cx from 'classnames';
import { format } from 'date-fns';
import { find, first, isEmpty, unescape } from 'lodash';

import { Tooltip, Typography } from 'antd';
import { IFrameComponent, UserAvatar, EmailIcon } from '@components';
import { AttachmentList } from './AttachmentList';

import { ThreadTypeToResourceType, formatDate, trimZeroWidthChars } from '@utils';
import { IMessage, IMessageThread, MessageSenderType } from '@types';
import { useProfileContext } from '@context';

import styles from './ThreadMessage.module.scss';

interface IProps {
  thread: IMessageThread;
  message: IMessage;

  showThreadType?: boolean;
  alignLeft?: boolean;
  forceShowOriginal?: boolean;

  actions?: React.ReactNode | React.ReactNode[];
  footer?: React.ReactNode;
  className?: string;
}

const { useState, useMemo, useCallback } = React;
const { Text, Paragraph } = Typography;

/**
 * @type {React.FC}
 */
export const ThreadMessage: React.FC<React.PropsWithChildren<IProps>> = React.memo(
  ({
    thread,
    message,
    showThreadType = false,
    alignLeft = false,
    forceShowOriginal = false,
    actions = [],
    footer,
    className,
  }) => {
    const { profile } = useProfileContext();
    const [showOriginal, setShowOriginal] = useState(forceShowOriginal);

    const workflowMetadata = useMemo(() => thread.metadata?.workflow, [thread]);
    const isWorkflowThread = useMemo(() => {
      return thread.source === MessageSenderType.System && !!workflowMetadata;
    }, [thread, workflowMetadata]);
    const senderAvatar = useMemo(() => {
      if (message.senderType === MessageSenderType.System) {
        // do not show system message to creator
        return null;
      } else if (message.senderType === MessageSenderType.Manager) {
        let manager = find(message.managers, (m) => m.email === message.data.from);
        if (!manager) {
          manager = first(message.managers);
        }

        return (
          <UserAvatar
            className={styles.avatar}
            size="small"
            name={manager.name}
            picture={manager.profileContent?.previewUrl}
          />
        );
      } else if (message.senderType === MessageSenderType.Member) {
        return <UserAvatar className={styles.avatar} size="small" name={profile.name} />;
      }
    }, [message, profile]);
    const onToggleShowOriginal = useCallback(() => setShowOriginal((show) => !show), []);

    // render nothing if we cannot find the sender for some reason
    if (!senderAvatar) {
      return null;
    }

    return (
      <div
        className={cx(styles.ThreadMessage, className, {
          [styles.alignLeft]: alignLeft,
          [styles.manager]: message.senderType === MessageSenderType.Manager,
          [styles.workflow]: isWorkflowThread,
        })}
      >
        {senderAvatar}
        <div className={styles.content}>
          <div className={styles.bubble}>
            {!showOriginal && (
              <Paragraph ellipsis={{ rows: 3 }}>
                {trimZeroWidthChars(
                  unescape(message.data.payload.textPlain || message.data.snippet),
                )}
              </Paragraph>
            )}
            {showOriginal && (
              <div className={styles.details}>
                <div className={styles.section}>
                  <span className={styles.label}>From:</span>
                  <Text ellipsis={{ tooltip: message.data.from }} className={styles.text}>
                    {message.data.from}
                  </Text>
                </div>
                <div className={styles.section}>
                  <span className={styles.label}>To:</span>
                  <Paragraph
                    ellipsis={{ rows: 3, tooltip: message.data.to.join(', ') }}
                    className={styles.text}
                  >
                    {message.data.to.join(', ')}
                  </Paragraph>
                </div>
                {!isEmpty(message.data.cc) && (
                  <div className={styles.section}>
                    <span className={styles.label}>Cc:</span>
                    <Paragraph
                      ellipsis={{ rows: 3, tooltip: message.data.cc.join(', ') }}
                      className={styles.text}
                    >
                      {message.data.cc.join(', ')}
                    </Paragraph>
                  </div>
                )}
                <div className={styles.section}>
                  <span className={styles.label}>Date:</span>
                  <Text
                    ellipsis={{ tooltip: format(message.internalDate, 'PPpp') }}
                    className={styles.text}
                  >
                    {format(message.internalDate, 'PPpp')}
                  </Text>
                </div>
                <div className={styles.section}>
                  <span className={styles.label}>Subject:</span>
                  <Text ellipsis={{ tooltip: message.data.subject }} className={styles.text}>
                    {message.data.subject}
                  </Text>
                </div>
                <div className={styles.emailBody}>
                  <IFrameComponent title={`Message ${message.id}`}>
                    <div
                      dangerouslySetInnerHTML={{
                        __html: message.data.payload.textHtml || message.data.payload.textPlain,
                      }}
                    />
                  </IFrameComponent>
                </div>
              </div>
            )}
            {!isEmpty(message.attachments) && (
              <AttachmentList className={styles.attachments} attachments={message.attachments} />
            )}
          </div>
          <div className={styles.info}>
            <div className={styles.actions}>{React.Children.toArray(actions)}</div>
            <div className={styles.extra}>
              {showThreadType && (
                <>
                  <EmailIcon type={ThreadTypeToResourceType[thread.type]} />
                  <span className={styles.dot} />
                </>
              )}
              {!forceShowOriginal && (
                <>
                  <span className={styles.action} onClick={onToggleShowOriginal}>
                    Show {showOriginal ? 'snippet' : 'original'}
                  </span>
                  <span className={styles.dot} />
                </>
              )}
              <Tooltip
                className={styles.timestamp}
                title={format(new Date(message.internalDate), 'PPpp')}
              >
                {formatDate(new Date(message.internalDate), true)}
              </Tooltip>
            </div>
          </div>
          {footer && <div className={styles.footer}>{footer}</div>}
        </div>
      </div>
    );
  },
);
