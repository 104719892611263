import { gql } from '@apollo/client';

import { CONTENT_FRAGMENT, MANAGER_FRAGMENT, MESSAGE_FRAGMENT } from './fragments';

//========================================================
//= Fragments
//========================================================
export const MESSAGE_OVERVIEW_FRAGMENT = gql`
  fragment MessageOverview on Message {
    ...Message
    managers {
      ...Manager
    }
    attachments {
      ...Content
    }
  }
  ${MESSAGE_FRAGMENT}
  ${MANAGER_FRAGMENT}
  ${CONTENT_FRAGMENT}
`;
