import React from 'react';
import { useQuery, QueryHookOptions } from '@apollo/client';
import { isEmpty } from 'lodash';

import { message } from 'antd';

import { GET_SHOPIFY_PRODUCTS_BY_IDS_QUERY } from '@queries';
import { GetShopifyProductsByIdsQuery, GetShopifyProductsByIdsQueryVariables } from '@types';

const { useEffect, useMemo } = React;
type IOptions = QueryHookOptions<
  GetShopifyProductsByIdsQuery,
  GetShopifyProductsByIdsQueryVariables
>;

export function useGetShopifyProductsByIds(options: IOptions = {}) {
  const {
    loading,
    data: { products = [] } = {},
    error,
    refetch,
  } = useQuery<GetShopifyProductsByIdsQuery, GetShopifyProductsByIdsQueryVariables>(
    GET_SHOPIFY_PRODUCTS_BY_IDS_QUERY,
    {
      ...options,
      fetchPolicy: 'cache-and-network',
      nextFetchPolicy: 'cache-first',
      notifyOnNetworkStatusChange: true,
      skip: isEmpty(options.variables?.ids),
    },
  );

  const errorMessage = useMemo(() => error?.message, [error]);
  useEffect(() => {
    if (!loading && errorMessage) {
      message.error(errorMessage);
    }
  }, [loading, errorMessage]);

  return {
    loading,
    products,
    error,
    refetch,
  };
}
