import { gql } from '@apollo/client';

import { SALES_TRACKING_SUMMARY_FRAGMENT } from './fragments';
import { PROGRAM_OVERVIEW_FRAGMENT } from './programs';

//========================================================
//= Queries
//========================================================
export const GET_SALES_TRACKING_SUMMARY_QUERY = gql`
  query GetSalesTrackingSummary($filters: SalesTrackingSummaryFilters) {
    summary: creatorGetSalesTrackingSummary(filters: $filters) {
      ...SalesTrackingSummary
    }
  }
  ${SALES_TRACKING_SUMMARY_FRAGMENT}
`;

export const GET_PROGRAM_SALES_TRACKING_SUMMARY_QUERY = gql`
  query GetProgramSalesTrackingSummary($filters: SalesTrackingSummaryFilters) {
    summary: creatorGetProgramSalesTrackingSummary(filters: $filters) {
      program {
        ...ProgramOverview
      }
      data {
        ...SalesTrackingSummary
      }
    }
  }
  ${PROGRAM_OVERVIEW_FRAGMENT}
  ${SALES_TRACKING_SUMMARY_FRAGMENT}
`;
