import React from 'react';
import cx from 'classnames';
import numeral from 'numeral';
import { size } from 'lodash';

import { Card, Statistic, Select, Radio, Typography } from 'antd';
import { Chart } from './Chart';

import { SalesTrackingLastDate } from '@types';
import { getDateFilterLabel } from './utils';
import {
  IContextProps,
  useSalesTrackingMetricsContext,
  SalesTrackingMetricsProvider,
} from './SalesTrackingMetricsContext';

import styles from './SalesTrackingMetrics.module.scss';

interface IProps {
  title?: string;
  height?: number;
  className?: string;
}

const { useMemo } = React;
const { Meta } = Card;
const { Text } = Typography;
const lastDateOptions: Array<{
  label: string;
  value: SalesTrackingLastDate | '';
}> = [
  {
    value: SalesTrackingLastDate.ThisWeek,
    label: 'This week',
  },
  {
    value: SalesTrackingLastDate.LastWeek,
    label: 'Last week',
  },
  {
    value: SalesTrackingLastDate.ThisMonth,
    label: 'This month',
  },
  {
    value: SalesTrackingLastDate.LastMonth,
    label: 'Last month',
  },
  {
    value: SalesTrackingLastDate.ThisQuarter,
    label: 'This quarter',
  },
  {
    value: SalesTrackingLastDate.LastQuarter,
    label: 'Last quarter',
  },
  {
    value: SalesTrackingLastDate.PastSixMonths,
    label: 'Past six months',
  },
  {
    value: SalesTrackingLastDate.ThisYear,
    label: 'This year',
  },
  {
    value: SalesTrackingLastDate.LastYear,
    label: 'Last year',
  },
  {
    value: SalesTrackingLastDate.PastTwoYears,
    label: 'Past two years',
  },
  {
    value: '',
    label: 'All time',
  },
];

/**
 * @type {React.FC}
 */
const SalesTrackingMetricsInternal: React.FC<React.PropsWithChildren<IProps>> = React.memo(
  ({ title = 'Sales Tracking', height = 300, className }) => {
    const {
      displayModeOptions,
      total,
      lastDays,
      setLastDays,
      chart,
      setChart,
      displayMode,
      setDisplayMode,
    } = useSalesTrackingMetricsContext();

    const cardContent = useMemo(
      () => (
        <div className={styles.content}>
          <div className={styles.metrics}>
            <div
              className={cx(styles.item, {
                [styles.active]: chart === 'clicks',
              })}
              onClick={() => setChart('clicks')}
            >
              <Statistic
                title={<div className={styles.title}>Clicks</div>}
                value={numeral(total.clicks).format('0,0')}
              />
            </div>
            <div
              className={cx(styles.item, {
                [styles.active]: chart === 'conversions',
              })}
              onClick={() => setChart('conversions')}
            >
              <Statistic
                title={<div className={styles.title}>Conversions</div>}
                value={numeral(total.conversions).format('0,0')}
                valueStyle={{ color: '#faad14' }}
              />
            </div>
            <div
              className={cx(styles.item, {
                [styles.active]: chart === 'sales',
              })}
              onClick={() => setChart('sales')}
            >
              <Statistic
                title={<div className={styles.title}>Sales</div>}
                value={numeral(total.sales).format('0.[00] a')}
                valueStyle={{ color: '#faad14' }}
                prefix="$"
              />
            </div>
            <div
              className={cx(styles.item, {
                [styles.active]: chart === 'costs',
              })}
              onClick={() => setChart('costs')}
            >
              <Statistic
                title={<div className={styles.title}>Payouts</div>}
                value={numeral(total.costs).format('0.[00] a')}
                valueStyle={{ color: '#52c41a' }}
                prefix="$"
              />
            </div>
          </div>
          <div className={styles.charts} style={{ height }}>
            <Chart className={styles.chart} />
          </div>
        </div>
      ),
      [chart, setChart, height, total],
    );
    const cardActions = useMemo(() => {
      if (size(displayModeOptions) <= 1) {
        return [];
      }

      return [
        <Radio.Group
          className={styles.displayMode}
          options={displayModeOptions}
          onChange={(event) => setDisplayMode(event.target.value)}
          value={displayMode}
          optionType="button"
          buttonStyle="solid"
        />,
      ];
    }, [displayModeOptions, displayMode, setDisplayMode]);

    return (
      <Card
        className={cx(styles.SalesTrackingMetrics, className)}
        title={
          <div className={styles.header}>
            <Text className={styles.title} ellipsis>
              {title}
            </Text>
            <Text className={styles.info} type="secondary" ellipsis>
              {getDateFilterLabel(lastDays)}
            </Text>
            <Select
              className={styles.lastDays}
              popupClassName={styles.LastDaysDropdown}
              size="small"
              value={lastDays}
              onChange={setLastDays}
              bordered={false}
              options={lastDateOptions}
            />
          </div>
        }
        actions={cardActions}
      >
        <Meta description={cardContent} />
      </Card>
    );
  },
);

export const SalesTrackingMetrics = React.memo<IContextProps & IProps>(({ filters, ...rest }) => {
  return (
    <SalesTrackingMetricsProvider filters={filters}>
      <SalesTrackingMetricsInternal {...rest} />
    </SalesTrackingMetricsProvider>
  );
});
