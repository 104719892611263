import React from 'react';
import cx from 'classnames';
import { map, slice, sortBy } from 'lodash';

import { ThreadMessage } from '@components';

import { IMessageThread } from '@types';

import styles from './ReplyList.module.scss';

interface IProps {
  thread: IMessageThread;

  className?: string;
}

const { useMemo } = React;

/**
 * @type {React.FC}
 */
export const ReplyList: React.FC<IProps> = React.memo(({ thread, className }) => {
  const messages = useMemo(() => slice(thread.messages, 1), [thread]);
  const sortedMessages = useMemo(() => {
    return sortBy(messages, (m) => m.internalDate);
  }, [messages]);

  return (
    <div className={cx(styles.ReplyList, className)}>
      {map(sortedMessages, (message) => {
        return (
          <ThreadMessage
            key={message.id}
            className={styles.item}
            thread={thread}
            message={message}
            alignLeft
          />
        );
      })}
    </div>
  );
});
