import React from 'react';
import cx from 'classnames';

import { Avatar, AvatarProps } from 'antd';

import microsoftSvg from '@assets/microsoft.svg';

import styles from './MicrosoftIcon.module.scss';

interface IProps extends AvatarProps {}

/**
 * @type {React.FC}
 */
export const MicrosoftIcon: React.FC<React.PropsWithChildren<IProps>> = React.memo((props) => {
  return (
    <Avatar
      {...props}
      className={cx(styles.MicrosoftIcon, props.className)}
      src={microsoftSvg}
      shape="square"
    />
  );
});

MicrosoftIcon.defaultProps = {
  size: 18,
};
