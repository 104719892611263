import React from 'react';
import { trim } from 'lodash';

import { ContentCategory, IContent } from '@types';
import { useCreateContent, useGenerateUploadUrl } from '@hooks';

interface IUploadParams {
  file: File;

  category?: ContentCategory;
  entityId?: string;
}

const { useState, useCallback } = React;

export function useUploadContent(): {
  loading: boolean;
  uploadContent(params: IUploadParams): Promise<IContent>;
} {
  const { generateUploadUrl } = useGenerateUploadUrl();
  const { createContent } = useCreateContent();
  const [uploading, setUploading] = useState(false);

  const uploadContent = useCallback(
    async (params: IUploadParams) => {
      const { category, entityId, file } = params;

      // set uploading status
      setUploading(true);

      // remove invalid chars from file name
      const validFilename = trim(file.name.replace(/[^\w\s.-]+/g, ''));
      let newFile: File;
      // try to create a new file using new filename
      // not supported in Edge and IE
      try {
        newFile = new File([file], validFilename, {
          type: file.type,
        });
      } catch (err) {
        // file constructor not supported
        newFile = file;
      }

      // first generates the upload url
      const { data: { response: { filename, uploadUrl, url } = {} } = {} } =
        await generateUploadUrl({
          variables: {
            params: {
              category,
              entityId,
              originalName: newFile.name,
            },
          },
        });

      // upload file to cloud storage
      await uploadToCloudStorage(file, uploadUrl);

      // create content
      const { data: { content } = {} } = await createContent({
        variables: {
          params: {
            category,
            mimeType: newFile.type,
            size: newFile.size,
            originalName: newFile.name,
            filename,
            url,
          },
        },
      });

      setUploading(false);

      return content;
    },
    [generateUploadUrl, createContent],
  );

  return {
    loading: uploading,
    uploadContent,
  };
}

async function uploadToCloudStorage(file: File, uploadUrl: string) {
  await fetch(uploadUrl, {
    method: 'PUT',
    headers: {
      // NOTE: important to keep this
      // https://github.com/googleapis/nodejs-storage/issues/347#issuecomment-516003478
      'Content-Type': 'application/octet-stream',
    },
    body: file,
  });
}
