import React from 'react';
import cx from 'classnames';

import { Button } from 'antd';
import { XIcon } from '@customIcons';

import { IProgramCollab, WorkflowEvent } from '@types';
import { useWorkflowCheckIn } from '@hooks';

import styles from './DeclineInviteButton.module.scss';

interface IProps {
  collab: IProgramCollab;

  className?: string;
}

const { useCallback } = React;

/**
 * @type {React.FC}
 */
export const DeclineInviteButton: React.FC<React.PropsWithChildren<IProps>> = React.memo(
  ({ collab, className }) => {
    const { workflowCheckIn, loading } = useWorkflowCheckIn();

    const onDeclineInvite = useCallback(
      async (event: React.MouseEvent<HTMLElement>) => {
        // avoid open detail panel
        event.stopPropagation();

        await workflowCheckIn({
          variables: {
            collabId: collab.id,
            event: WorkflowEvent.ProspectStageRejectInvitation,
          },
        });
      },
      [collab, workflowCheckIn],
    );

    return (
      <Button
        className={cx(styles.DeclineInviteButton, className)}
        icon={<XIcon className={styles.icon} />}
        shape="round"
        type="text"
        loading={loading}
        onClick={onDeclineInvite}
      >
        Decline
      </Button>
    );
  },
);
