import React from 'react';
import cx from 'classnames';

import { Avatar, AvatarProps } from 'antd';
import { FileImageFilled } from '@ant-design/icons';

import styles from './ImageIcon.module.scss';

interface IProps extends AvatarProps {}

/**
 * @type {React.FC}
 */
export const ImageIcon: React.FC<React.PropsWithChildren<IProps>> = React.memo((props) => {
  return (
    <Avatar
      {...props}
      className={cx(styles.ImageIcon, props.className)}
      icon={<FileImageFilled />}
    />
  );
});
