import React from 'react';
import cx from 'classnames';

import { AvatarProps } from 'antd';
import { icons } from '@components';

import { SocialPostType } from '@types';

import styles from './SocialPostIcon.module.scss';

interface IProps extends AvatarProps {
  type: SocialPostType;

  className?: string;
}

const { useMemo } = React;
const NumberSize = {
  default: 18,
  small: 14,
  large: 20,
};

export const SocialPostIcon: React.FC<React.PropsWithChildren<IProps>> = React.memo(
  ({ type, className, size = 'default', ...rest }) => {
    const IconClass = useMemo(() => {
      switch (type) {
        case SocialPostType.InstagramPost:
        case SocialPostType.InstagramStory: {
          return icons.InstagramIcon;
        }
        case SocialPostType.YoutubeVideo: {
          return icons.YoutubeIcon;
        }
        case SocialPostType.CustomImage: {
          return icons.ImageIcon;
        }
        case SocialPostType.CustomVideo: {
          return icons.VideoIcon;
        }
        default: {
          break;
        }
      }

      return icons.ImageIcon;
    }, [type]);

    return (
      <IconClass
        className={cx(styles.SocialPostIcon, className)}
        {...rest}
        size={NumberSize[size as string] || 18}
      />
    );
  },
);
