import React from 'react';
import cx from 'classnames';

import { Tag, Tooltip, Typography } from 'antd';
import { QuestionCircleOutlined } from '@ant-design/icons';

import { SalesTrackingIncentive } from '@types';
import { formatIncentiveValue, getSalesTrackingIncentiveDescription } from '@utils';

import styles from './TrackingIncentiveTag.module.scss';

interface IProps {
  incentive: SalesTrackingIncentive;

  showTooltip?: boolean;
  className?: string;
}

const { Text } = Typography;

/**
 * @type {React.FC}
 */
export const TrackingIncentiveTag: React.FC<React.PropsWithChildren<IProps>> = React.memo(
  ({ incentive, showTooltip, className }) => {
    return (
      <Tag color="gold" className={cx(styles.TrackingIncentiveTag, className)}>
        <Text className={styles.text} ellipsis>
          {formatIncentiveValue(incentive)} {incentive.type}
        </Text>
        {showTooltip && (
          <Tooltip title={getSalesTrackingIncentiveDescription(incentive)}>
            <QuestionCircleOutlined className={styles.icon} />
          </Tooltip>
        )}
      </Tag>
    );
  },
);
