import React from 'react';
import cx from 'classnames';

import { Avatar, AvatarProps } from 'antd';

// download icon from https://iconduck.com/
import instagramImage from '@assets/instagram.svg';

import styles from './InstagramIcon.module.scss';

interface IProps extends AvatarProps {}

/**
 * @type {React.FC}
 */
export const InstagramIcon: React.FC<React.PropsWithChildren<IProps>> = React.memo((props) => {
  return (
    <Avatar {...props} className={cx(styles.InstagramIcon, props.className)} src={instagramImage} />
  );
});
