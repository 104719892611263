import React from 'react';
import numeral from 'numeral';
import cx from 'classnames';
import { find, isEmpty } from 'lodash';

import { Descriptions, Divider, Tag, Typography } from 'antd';
import { ContentAgreement, ErrorNotice, LoadSpinner, TrackingIncentiveTag } from '@components';

import { getSalesTrackingIncentiveTitle } from '@utils';
import { useGetProgramCollabById } from '@hooks';

import styles from './CollabTerm.module.scss';

interface IProps {
  collabId: string;

  className?: string;
}

const { useMemo } = React;
const { Title } = Typography;

/**
 * @type {React.FC}
 */
export const CollabTerm: React.FC<IProps> = React.memo(({ collabId, className }) => {
  const { loading, error, collab } = useGetProgramCollabById({
    variables: {
      id: collabId,
    },
  });

  const iteration = useMemo(() => {
    return find(collab?.term?.iterations, (i) => i.id === collab?.term.latestIterationId);
  }, [collab]);
  const [contentReview, payment, salesTracking] = useMemo(
    () => [iteration?.data.contentReview, iteration?.data.payment, iteration?.data.salesTracking],
    [iteration],
  );

  if (!iteration) {
    return null;
  }

  return (
    <div className={cx(styles.CollabTerm, className)}>
      {loading && <LoadSpinner className={styles.loading} />}
      {!loading && error && <ErrorNotice />}
      {!loading && collab && (
        <>
          {!isEmpty(contentReview) && <ContentAgreement data={contentReview} collab={collab} />}
          {!isEmpty(payment) && (
            <>
              <Title level={4}>Payment</Title>
              <Descriptions column={1}>
                <Descriptions.Item label="Amount">
                  <div className={styles.amount}>{numeral(payment.amount).format('$0.[00] a')}</div>
                </Descriptions.Item>
              </Descriptions>
              <Divider />
            </>
          )}
          {!isEmpty(salesTracking) && (
            <>
              <Title level={4}>Commission</Title>
              <Descriptions column={1}>
                <Descriptions.Item label="Type">
                  <Tag>{getSalesTrackingIncentiveTitle(salesTracking.incentive.type)}</Tag>
                </Descriptions.Item>
                <Descriptions.Item label="Value">
                  <TrackingIncentiveTag incentive={salesTracking.incentive} showTooltip />
                </Descriptions.Item>
              </Descriptions>
              <Divider />
            </>
          )}
        </>
      )}
    </div>
  );
});
