import React from 'react';
import cx from 'classnames';
import { formatDistanceToNow, format } from 'date-fns';

import { Comment, Tooltip, Typography } from 'antd';
import { UserAvatar } from '@components';

import { IMessage } from './context';

import styles from './SystemMessage.module.scss';

interface IProps {
  message: IMessage;

  className?: string;
}

const { Text } = Typography;

/**
 * @type {React.FC}
 */
export const SystemMessage: React.FC<React.PropsWithChildren<IProps>> = React.memo((props) => {
  const { message } = props;

  return (
    <div className={cx(styles.SystemMessage, props.className)}>
      <Comment
        key={message.id}
        className={styles.comment}
        author={message.name}
        avatar={<UserAvatar picture={message.profileImage} name={message.name} />}
        content={<Text type="secondary">{message.payload.data}</Text>}
        datetime={
          <Tooltip title={format(message.timestamp.toDate(), 'PPpp')}>
            <span>{formatDistanceToNow(message.timestamp?.toDate())} ago</span>
          </Tooltip>
        }
      />
    </div>
  );
});
