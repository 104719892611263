import React from 'react';

import { AvatarProps } from 'antd';
import { icons } from '@components';

import { AuthorizationResourceType } from '@types';

interface IProps extends AvatarProps {
  type: AuthorizationResourceType;
}

const { useMemo } = React;

export const EmailIcon: React.FC<React.PropsWithChildren<IProps>> = React.memo(
  ({ type, ...rest }) => {
    const Icon = useMemo(() => {
      if (type === AuthorizationResourceType.Outlook) {
        return icons.OutlookIcon;
      }

      return icons.GmailIcon;
    }, [type]);

    return <Icon {...rest} />;
  },
);
