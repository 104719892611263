import { gql } from '@apollo/client';

export const MESSAGE_THREAD_FRAGMENT = gql`
  fragment MessageThread on MessageThread {
    id
    type
    source
    status
    internalDate
    hasAttachments
    priority
    metadata {
      workflow {
        stage
        event
      }
    }
    collabId
    createdDate
    updatedDate
    deletedDate
  }
`;
