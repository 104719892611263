import React from 'react';
import cx from 'classnames';
import { startCase, toLower } from 'lodash';

import { Typography } from 'antd';

import { ICollabTermContentReviewData, IProgram, LicensedContentUsageRights } from '@types';
import { useProfileContext } from '@context';

import styles from './ContentUsageRights.module.scss';

interface IProps {
  program: IProgram;
  data: Omit<ICollabTermContentReviewData, 'requirements'>;

  className?: string;
}

const { useMemo } = React;
const { Paragraph } = Typography;

/**
 * @type {React.FC}
 */
export const ContentUsageRights: React.FC<IProps> = React.memo(({ program, data, className }) => {
  const { profile } = useProfileContext();
  const { usageRights, usageDuration, customUsageRights } = data;

  const brandName = useMemo(() => program.brand.name, [program]);
  const memberName = useMemo(() => profile?.name, [profile]);

  return (
    <div className={cx(styles.ContentUsageRights, className)}>
      <Paragraph className={styles.paragraph}>
        <pre>
          {usageRights === LicensedContentUsageRights.Broad && (
            <>
              <b>{memberName}</b> agrees to give <b>{brandName}</b> a worldwide, irrevocable,
              royalty-free, fully paid-up, transferrable, and perpetual right and license to
              reproduce, publish, distribute, display, repost, share, create derivative works, and
              edit all content created for or on behalf of <b>{brandName}</b> by <b>{memberName}</b>{' '}
              in any media.
            </>
          )}
          {usageRights === LicensedContentUsageRights.Limited && (
            <>
              <b>{memberName}</b> agrees that, for{' '}
              <b>{usageDuration ? toLower(startCase(usageDuration)) : '<Usage Duration>'}</b>,{' '}
              <b>{brandName}</b> has a license, which is worldwide, irrevocable, royalty-free, fully
              paid-up, and transferable, to reproduce, publish, distribute, display, repost, share,
              create derivative works, and edit all Content created for or on behalf of{' '}
              <b>{brandName}</b> by <b>{memberName}</b> in any media. The term of this license
              starts from the date that the content is approved by <b>{brandName}</b>.
            </>
          )}
          {usageRights === LicensedContentUsageRights.Custom && (
            <>{customUsageRights ? `${customUsageRights}.` : '-'}</>
          )}
          {usageRights === LicensedContentUsageRights.None && (
            <>
              <b>{brandName}</b> has no rights to use the contents created by <b>{memberName}</b>{' '}
              for this program.
            </>
          )}
        </pre>
      </Paragraph>
    </div>
  );
});
