import React from 'react';
import { useQuery, QueryHookOptions } from '@apollo/client';
import { isEmpty } from 'lodash';

import { message } from 'antd';

import { GET_SHOPIFY_PRODUCT_COUNT_QUERY } from '@queries';
import { GetShopifyProductCountQuery, GetShopifyProductCountQueryVariables } from '@types';

const { useEffect, useMemo } = React;
type IOptions = QueryHookOptions<GetShopifyProductCountQuery, GetShopifyProductCountQueryVariables>;

export function useGetShopifyProductCount(options: IOptions = {}) {
  const {
    loading,
    data: { count } = {},
    error,
    refetch,
  } = useQuery<GetShopifyProductCountQuery, GetShopifyProductCountQueryVariables>(
    GET_SHOPIFY_PRODUCT_COUNT_QUERY,
    {
      ...options,
      skip: isEmpty(options.variables?.filters?.brandResourceId),
      fetchPolicy: 'cache-and-network',
      notifyOnNetworkStatusChange: true,
    },
  );

  const errorMessage = useMemo(() => error?.message, [error]);
  useEffect(() => {
    if (!loading && errorMessage) {
      message.error(errorMessage);
    }
  }, [loading, errorMessage]);

  return {
    loading,
    count,
    error,
    refetch,
  };
}
