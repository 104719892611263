import React from 'react';
import cx from 'classnames';

import { Typography } from 'antd';
import { SocialAccountIcon, UserAvatar } from '@components';

import { ISocialAccount } from '@types';

import styles from './SocialAccountAvatar.module.scss';

type TSize = 'default' | 'small';
interface IProps {
  account: Pick<ISocialAccount, 'id' | 'type' | 'info'>;

  size?: TSize;
  showDetail?: boolean;
  showUsername?: boolean;
  className?: string;
}

const { useCallback } = React;
const { Text } = Typography;

/**
 * @type {React.FC}
 */
export const SocialAccountAvatar: React.FC<React.PropsWithChildren<IProps>> = React.memo(
  ({ account, size = 'default', showDetail = false, showUsername = true, className }) => {
    const visitLink = useCallback(() => {
      window.open(account.info.link, '_blank');
    }, [account]);

    if (!account) {
      return null;
    }

    return (
      <div
        className={cx(styles.SocialAccountAvatar, className, {
          [styles.clickable]: showDetail,
          [styles.small]: size === 'small',
        })}
        onClick={visitLink}
      >
        <div className={styles.wrapper}>
          <UserAvatar
            className={styles.avatar}
            name={account.info.username || account.info.displayName}
            picture={account.info.profileImage}
            size={size}
          />
          <div className={styles.network}>
            <SocialAccountIcon size={size} type={account.type} />
          </div>
        </div>
        <div className={styles.nameAndUsername}>
          <Text ellipsis className={styles.name}>
            {account.info.displayName}
          </Text>
          {showUsername && (
            <Text ellipsis type="secondary" className={styles.username}>
              @{account.info.username}
            </Text>
          )}
        </div>
      </div>
    );
  },
);
