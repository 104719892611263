import { gql } from '@apollo/client';

import { BRAND_FRAGMENT, CONTENT_FRAGMENT } from './fragments';

//========================================================
//= Fragments
//========================================================
export const BRAND_OVERVIEW_FRAGMENT = gql`
  fragment BrandOverview on Brand {
    ...Brand
    logo {
      ...Content
    }
  }
  ${BRAND_FRAGMENT}
  ${CONTENT_FRAGMENT}
`;
