import { SalesTrackingIncentive, SalesTrackingIncentiveType } from '@types';

export function getSalesTrackingIncentiveDescription(incentive: SalesTrackingIncentive) {
  switch (incentive?.type) {
    case SalesTrackingIncentiveType.CPA: {
      return `You will receive $${incentive.value} for each conversion as commissions.`;
    }

    case SalesTrackingIncentiveType.CPC: {
      return `You will receive $${incentive.value} for each unique link click as commissions.`;
    }

    case SalesTrackingIncentiveType.CPS: {
      return `You will receive ${incentive.value}% of the total sales as commissions.`;
    }

    case SalesTrackingIncentiveType.CPAS: {
      return `You will receive $${incentive.value} + ${incentive.value}% of the total sales for each conversion as commissions.`;
    }
  }
}
