import React from 'react';
import { useQuery, QueryHookOptions } from '@apollo/client';

import { message } from 'antd';

import { GET_SOCIAL_POST_COUNT_QUERY } from '@queries';
import { GetSocialPostCountQuery, GetSocialPostCountQueryVariables } from '@types';

const { useEffect, useMemo } = React;
type IOptions = QueryHookOptions<GetSocialPostCountQuery, GetSocialPostCountQueryVariables>;

export function useGetSocialPostCount(options: IOptions = {}) {
  const {
    loading,
    data: { count } = {},
    error,
    refetch,
  } = useQuery<GetSocialPostCountQuery, GetSocialPostCountQueryVariables>(
    GET_SOCIAL_POST_COUNT_QUERY,
    {
      ...options,
      notifyOnNetworkStatusChange: true,
      fetchPolicy: 'cache-and-network',
    },
  );

  const errorMessage = useMemo(() => error?.message, [error]);
  useEffect(() => {
    if (!loading && errorMessage) {
      message.error(errorMessage);
    }
  }, [loading, errorMessage]);

  return {
    loading,
    count,
    error,
    refetch,
  };
}
