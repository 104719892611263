import React from 'react';
import { filter } from 'lodash';

import { Button } from 'antd';
import { ArrowLeftOutlined } from '@ant-design/icons';
import { ErrorNotice, LoadSpinner } from '@components';

import { IProfile, AuthorizationResourceStatus, ICreatorAccountResource } from '@types';
import { useGetProfile, useUpdateProfile } from '@hooks';
import { useAuthContext } from '@context';

import styles from './ProfileContext.module.scss';

interface IAuthContext {
  profile: IProfile;
  refetch(): void;

  activeAccounts: ICreatorAccountResource[];
}

const { useContext, useMemo, useCallback, useEffect } = React;

export const ProfileContext = React.createContext<IAuthContext>(null);
export const useProfileContext = () => useContext(ProfileContext);
export const ProfileContextProvider: React.FC<React.PropsWithChildren<{}>> = React.memo(
  ({ children }) => {
    const { user, signOut } = useAuthContext();
    const { loading, profile, error: profileError, refetch: refetchProfile } = useGetProfile();
    const { updateProfile } = useUpdateProfile();

    const hasError = useMemo(() => !!profileError, [profileError]);
    const activeAccounts = useMemo(() => {
      return filter(
        profile?.accountResources,
        ({ authorizationResource: r }) => r.status === AuthorizationResourceStatus.Active,
      );
    }, [profile]);

    const refetch = useCallback(() => {
      refetchProfile();
    }, [refetchProfile]);

    // update last login ts
    useEffect(() => {
      // only update last login ts for non-QA users
      if (!user.isQA) {
        updateProfile({
          variables: {
            params: {
              metadata: {
                lastLoginTs: Date.now(),
              },
            },
          },
        });
      }
    }, [user, updateProfile]);

    return (
      <ProfileContext.Provider
        value={{
          profile,
          refetch,

          activeAccounts,
        }}
      >
        <div className={styles.ProfileContext}>
          {loading && <LoadSpinner className={styles.loading} />}
          {!loading && hasError && (
            <ErrorNotice
              className={styles.notice}
              status="403"
              title="You do not have access to the platform"
              description="Please go back and register an account first."
              action={
                <Button onClick={signOut} icon={<ArrowLeftOutlined />}>
                  Go Back
                </Button>
              }
            />
          )}
          {!loading && !hasError && children}
        </div>
      </ProfileContext.Provider>
    );
  },
);
