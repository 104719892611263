import React from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import cx from 'classnames';

import { Dropdown, MenuProps, Typography } from 'antd';
import { DownOutlined } from '@ant-design/icons';
import { UserAvatar } from '@components';

import { useAuthContext, useProfileContext } from '@context';

import styles from './UserMenu.module.scss';

interface IProps {
  className?: string;
}

const { useMemo } = React;
const { Text } = Typography;

/**
 * @type {React.FC}
 */
export const UserMenu: React.FC<React.PropsWithChildren<IProps>> = React.memo(({ className }) => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { signOut } = useAuthContext();
  const { profile } = useProfileContext();

  const menuProps: MenuProps = useMemo(() => {
    return {
      className: styles.UserMenuDropdown,
      items: [
        {
          key: 'header',
          className: styles.header,
          label: profile && <Text type="secondary">{profile.email}</Text>,
        },
        {
          type: 'divider',
        },
        {
          key: 'settings',
          onClick: () => navigate('/settings'),
          label: t('SETTING_PLURAL'),
        },
        {
          key: 'billing',
          onClick: () => navigate('/settings/payment'),
          label: t('BILLING_PLURAL'),
        },
        {
          type: 'divider',
        },
        {
          key: 'log_out',
          onClick: () => signOut(),
          label: t('LOG_OUT'),
        },
      ],
    };
  }, [t, navigate, profile, signOut]);

  return (
    <Dropdown className={cx(styles.UserMenu, className)} menu={menuProps} trigger={['click']}>
      <div>
        <UserAvatar
          className={styles.avatar}
          size="small"
          name={profile.name}
          picture={profile.profileImage}
          showBoth
        />
        <DownOutlined />
      </div>
    </Dropdown>
  );
});
