import React from 'react';
import cx from 'classnames';
import { isEmpty } from 'lodash';

import { LoadSpinner } from '@components';
import { Empty } from 'antd';
import { PostList, PostListHeader } from './components';

import {
  ISelectPostListContextProps,
  useSelectPostListContext,
  SelectPostListContextProvider,
} from './SelectPostListContext';

import styles from './SelectPostList.module.scss';

interface IProps {
  selectedPostId?: string;
  onPostClick?(id: string): void;
  className?: string;
}

/**
 * @type {React.FC}
 */
const SelectPostListInternal: React.FC<React.PropsWithChildren<IProps>> = React.memo(
  ({ selectedPostId, onPostClick, className }) => {
    const { loading, posts } = useSelectPostListContext();

    return (
      <div className={cx(styles.SelectPostList, className)}>
        <PostListHeader className={styles.header} />
        {loading && <LoadSpinner />}
        {!loading && isEmpty(posts) && <Empty description="Posts for this account live here." />}
        {!loading && !isEmpty(posts) && (
          <PostList
            selectedPostId={selectedPostId}
            className={styles.list}
            onPostClick={onPostClick}
          />
        )}
      </div>
    );
  },
);

export const SelectPostList = React.memo<ISelectPostListContextProps & IProps>(
  ({ resource, ...rest }) => {
    return (
      <SelectPostListContextProvider resource={resource}>
        <SelectPostListInternal {...rest} />
      </SelectPostListContextProvider>
    );
  },
);
