import { gql } from '@apollo/client';

export const PROGRAM_FRAGMENT = gql`
  fragment Program on Program {
    id
    brandId
    category
    type
    status
    private
    workflowConfig {
      stages {
        type
      }
      data {
        prospect {
          enablePersonalizedMessage
          inviteEmailSubject
          inviteEmailTemplate
          prefillInfoWhenApply {
            enabled
            stages {
              term
              infoUpdate
              productFulfillment
            }
          }
        }
        term {
          negotiable
        }
        email {
          emailSubject
          emailTemplate
        }
        contract {
          contractContentIds
        }
        infoUpdate {
          defaultFields {
            type
            required
          }
          customFields {
            schemaId
            required
            alias
          }
        }
        productFulfillment {
          productBrandResourceId
          products {
            productId
            variantId
            title
            quantity
            price
            image
          }
          shippingMethod
          enableProductPreferences
          maxProductCount
          maxProductValue
          createOrderDirectly
        }
        contentReview {
          enablePostTracking
          agreementData {
            requirements {
              type
              dueDateTs
              guidelines {
                conceptAndFormat
                talkingPoints
                caption
                postInstructions
                other
              }
            }
            usageRights
            usageDuration
            customUsageRights
          }
        }
        payment {
          defaultPaymentMethodId
          paymentRange {
            low
            high
          }
        }
        salesTracking {
          brandResourceId
          landingUrl
          incentive {
            type
            value
            cpsValue
          }
          billingSchedule
          discountCodeInfo {
            type
            value
            selection
            namingStrategy
            oncePerCustomer
            usageLimit
            entities {
              type
              id
              productId
              title
              image
              price
            }
          }
        }
      }
    }
    info {
      name
      description
      coverContentId
    }
    createdDate
    updatedDate
  }
`;
