import { gql } from '@apollo/client';

export const CONTENT_FRAGMENT = gql`
  fragment Content on Content {
    id
    type
    mimeType
    size
    originalName
    url
    previewUrl
    createdDate
    updatedDate
  }
`;
