import React from 'react';
import cx from 'classnames';
import { map, startCase, toLower } from 'lodash';

import { Select, Button, Pagination, Tooltip } from 'antd';
import { RedoOutlined } from '@ant-design/icons';
import { LastDaysFilter } from './filters';

import { EntitySortBy } from '@types';
import { useTrackingConversionsContext } from './TrackingConversionsContext';

import styles from './OrderListHeader.module.scss';

interface IProps {
  className?: string;
}

const { useMemo, useCallback } = React;
const { Option } = Select;

/**
 * @type {React.FC}
 */
export const OrderListHeader: React.FC<React.PropsWithChildren<IProps>> = React.memo(
  ({ className }) => {
    const { loading, refetch, count, limit, offset, setLimit, setOffset, sortBy, setSortBy } =
      useTrackingConversionsContext();

    const page = useMemo(() => Math.floor(offset / limit) + 1, [offset, limit]);
    const onPaginationChange = useCallback(
      (page: number, pageSize: number) => {
        setLimit(pageSize);
        setOffset((page - 1) * pageSize);
      },
      [setLimit, setOffset],
    );

    return (
      <div className={cx(styles.OrderListHeader, className)}>
        <div className={styles.filters}>
          <LastDaysFilter />
        </div>
        <Pagination
          className={styles.pagination}
          current={page}
          total={count}
          pageSize={limit}
          showSizeChanger={true}
          showTotal={(total) => `${total} order(s)`}
          onChange={onPaginationChange}
        />
        <Select
          className={styles.sortBy}
          value={sortBy}
          onChange={(sortBy) => setSortBy(sortBy)}
          optionLabelProp="label"
          bordered={false}
        >
          {map(EntitySortBy, (sortBy) => (
            <Option value={sortBy} label={`Sort by ${startCase(toLower(sortBy))}`} key={sortBy}>
              {startCase(toLower(sortBy))}
            </Option>
          ))}
        </Select>
        <Tooltip trigger={['click', 'hover']} title="Refresh">
          <Button
            className={styles.refresh}
            onClick={refetch}
            shape="circle"
            icon={<RedoOutlined />}
            loading={loading}
          />
        </Tooltip>
      </div>
    );
  },
);
