import { useQuery, QueryHookOptions } from '@apollo/client';
import React from 'react';
import { isEmpty } from 'lodash';

import { message } from 'antd';

import { GET_COLLAB_TERM_QUERY } from '@queries';
import { GetCollabTermQuery, GetCollabTermQueryVariables } from '@types';

type IOptions = QueryHookOptions<GetCollabTermQuery, GetCollabTermQueryVariables>;

const { useEffect, useMemo } = React;

export function useGetCollabTermById(options: IOptions = {}) {
  const {
    loading,
    data: { term } = {},
    error,
    refetch,
  } = useQuery<GetCollabTermQuery, GetCollabTermQueryVariables>(GET_COLLAB_TERM_QUERY, {
    ...options,
    skip: isEmpty(options.variables?.id),
    fetchPolicy: 'cache-and-network',
    nextFetchPolicy: 'cache-first',
    notifyOnNetworkStatusChange: true,
  });

  const errorMessage = useMemo(() => error?.message, [error]);
  useEffect(() => {
    if (!loading && errorMessage) {
      message.error(errorMessage);
    }
  }, [loading, errorMessage]);

  return {
    loading,
    term,
    error,
    refetch,
  };
}
