import React from 'react';
import cx from 'classnames';
import { first, size } from 'lodash';

import { DownOutlined, UpOutlined } from '@ant-design/icons';
import { ThreadMessage } from '@components';
import { ReplyList } from './ReplyList';

import { IMessageThread } from '@types';

import styles from './ThreadItem.module.scss';

interface IProps {
  thread: IMessageThread;

  className?: string;
}

const { useMemo, useCallback, useState } = React;

/**
 * @type {React.FC}
 */
export const ThreadItem: React.FC<IProps> = React.memo(({ thread, className }) => {
  const [showReplies, setShowReplies] = useState(false);

  const firstMessage = useMemo(() => first(thread.messages), [thread]);
  const toggleShowReplies = useCallback(() => setShowReplies((show) => !show), []);
  const actions = useMemo(() => {
    const { messages } = thread;
    const actions = [];

    if (size(messages) > 1) {
      const count = size(messages) - 1;

      actions.push(
        <div className={styles.action} onClick={toggleShowReplies}>
          {showReplies && (
            <span>
              Collapse All <UpOutlined />
            </span>
          )}
          {!showReplies && (
            <span>
              {count} Replies <DownOutlined className={styles.down} />
            </span>
          )}
        </div>,
      );
    }

    return actions;
  }, [thread, showReplies, toggleShowReplies]);
  const footer = useMemo(() => {
    if (!showReplies) {
      return null;
    }

    return (
      <div className={styles.footer}>
        <div className={styles.line} onClick={toggleShowReplies} />
        <div className={styles.main}>
          <ReplyList className={styles.list} thread={thread} />
        </div>
      </div>
    );
  }, [thread, showReplies, toggleShowReplies]);

  return (
    <ThreadMessage
      className={cx(styles.ThreadItem, className)}
      showThreadType
      forceShowOriginal
      thread={thread}
      message={firstMessage}
      actions={actions}
      footer={footer}
    />
  );
});
