import { size } from 'lodash';

import { IShopifyDiscountCode, DiscountCodeSelection, DiscountCodeType } from '@types';

export function getDiscountCodeInfo(discountCode: IShopifyDiscountCode): string[] {
  const { selection, type, value, entities, oncePerCustomer, usageLimit } = discountCode.info;

  let usageInfo = 'No usage limits';
  if (oncePerCustomer && usageLimit > 0) {
    usageInfo = `Limit of ${usageLimit} use(s), one per customer`;
  } else if (oncePerCustomer) {
    usageInfo = 'One use per customer';
  } else if (usageLimit > 0) {
    usageInfo = `Limit of ${usageLimit} use(s)`;
  }

  const discountValue = type === DiscountCodeType.FixedAmount ? `$${value}` : `${value}%`;
  let discountInfo = `${discountValue} off all products`;
  if (selection === DiscountCodeSelection.SPECIFIC_PRODUCTS) {
    discountInfo = `${discountValue} off ${size(entities)} product(s)`;
  } else if (selection === DiscountCodeSelection.SPECIFIC_COLLECTIONS) {
    discountInfo = `${discountValue} off ${size(entities)} collection(s)`;
  }

  return [usageInfo, discountInfo];
}
