import React from 'react';
import cx from 'classnames';
import { isEmpty, map, sortBy } from 'lodash';

import { Empty } from 'antd';
import { LoadSpinner } from '@components';
import { PostTrackingItem } from './components';

import { PostTrackingStatus } from '@types';
import { useGetProgramCollabById } from '@hooks';

import styles from './PostTrackingList.module.scss';

interface IProps {
  collabId: string;

  onComplete?(): void;
  className?: string;
}

const { useMemo } = React;
const TrackingStatusOrder: PostTrackingStatus[] = [
  PostTrackingStatus.PendingSubmit,
  PostTrackingStatus.Rejected,
  PostTrackingStatus.PendingVerify,
  PostTrackingStatus.Verified,
];

/**
 * @type {React.FC}
 */
export const PostTrackingList: React.FC<React.PropsWithChildren<IProps>> = React.memo(
  ({ collabId, className }) => {
    const { loading, collab } = useGetProgramCollabById({
      variables: {
        id: collabId,
      },
    });

    const sortedTrackings = useMemo(() => {
      return sortBy(collab?.postTrackings, (t) => TrackingStatusOrder.indexOf(t.status));
    }, [collab]);

    return (
      <div className={cx(styles.PostTrackingList, className)}>
        {loading && <LoadSpinner className={styles.loading} />}
        {!loading && isEmpty(sortedTrackings) && (
          <Empty description="No post trackings has been found for this collaboration, if this is unexpected, please contact support@superbloom.one for support." />
        )}
        {!loading && !isEmpty(sortedTrackings) && (
          <div className={styles.content}>
            {map(sortedTrackings, (tracking) => (
              <PostTrackingItem key={tracking.id} collab={collab} tracking={tracking} />
            ))}
          </div>
        )}
      </div>
    );
  },
);
