import { gql } from '@apollo/client';

export const AUTHORIZATION_RESOURCE_FRAGMENT = gql`
  fragment AuthorizationResource on AuthorizationResource {
    id
    type
    status
    userId
    info
    createdDate
    updatedDate
  }
`;
