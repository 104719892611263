import { startCase, toLower } from 'lodash';

import { Tag } from 'antd';

import { ProgramCollabContractStatus } from '@types';

const StatusColor: Record<ProgramCollabContractStatus, string> = {
  [ProgramCollabContractStatus.Sent]: 'cyan',
  [ProgramCollabContractStatus.Voided]: 'gray',
  [ProgramCollabContractStatus.Declined]: 'red',
  [ProgramCollabContractStatus.Completed]: 'green',
} as const;

export function tagForContractStatus(status: ProgramCollabContractStatus) {
  return <Tag color={StatusColor[status]}>{startCase(toLower(status))}</Tag>;
}
