import { gql } from '@apollo/client';

export const POST_TRACKING_FRAGMENT = gql`
  fragment PostTracking on PostTracking {
    id
    collabId
    status
    requirement {
      type
      accountResourceId
      dueDateTs
      guidelines {
        conceptAndFormat
        talkingPoints
        caption
        postInstructions
        other
      }
    }
    info {
      brandComment
      submittedByType
      submittedBy
      submittedTs
      verifiedTs
      verifiedBy
      rejectedTs
      rejectedBy
    }
    createdDate
    updatedDate
  }
`;
