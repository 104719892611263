import React from 'react';
import cx from 'classnames';
import { map, random, toString } from 'lodash';

import { Drawer, Typography } from 'antd';
import { Carousel } from '@components';
import { ProductOptionForm } from './ProductOptionForm';

import { CollabOrderItem, ContentType, IContent, IShopifyProduct } from '@types';
import { usePickProductsContext } from '../context';

import styles from './ProductDetailPanel.module.scss';

interface IProps {
  product: IShopifyProduct;
  show: boolean;
  onRequestClose(): void;

  onComplete?(): void;
  className?: string;
}

const { useMemo, useCallback } = React;
const { Title } = Typography;

/**
 * @type {React.FC}
 */
export const ProductDetailPanel: React.FC<React.PropsWithChildren<IProps>> = React.memo(
  ({ product, show, onRequestClose, className }) => {
    const { addItem } = usePickProductsContext();

    // fake contents from product medias
    const contents: IContent[] = useMemo(() => {
      return map(
        product.info.medias,
        (media): IContent => ({
          id: toString(media.id),
          type: ContentType.Image,
          mimeType: 'image/jpg',
          size: random(1000, 100000),
          originalName: product.info.title,
          url: media.src,
          previewUrl: media.src,
          createdDate: new Date().toISOString(),
          updatedDate: new Date().toISOString(),
        }),
      );
    }, [product]);
    const onSubmit = useCallback(
      (item: CollabOrderItem) => {
        onRequestClose();
        addItem(item);
      },
      [addItem, onRequestClose],
    );

    return (
      <Drawer
        className={cx(styles.ProductDetailPanel, className)}
        open={show}
        onClose={onRequestClose}
        footer={null}
        width={1000}
        closable={false}
        destroyOnClose
      >
        <div className={styles.content}>
          <div className={styles.left}>
            <Carousel className={styles.carousel} contents={contents} />
          </div>
          <div className={styles.right}>
            <Title className={styles.title} level={3}>
              {product.info.title}
            </Title>
            <ProductOptionForm product={product} onSubmit={onSubmit} />
          </div>
        </div>
      </Drawer>
    );
  },
);
