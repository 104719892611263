import React from 'react';
import { createPortal } from 'react-dom';
import cx from 'classnames';

import styles from './IFrameComponent.module.scss';

interface IProps {
  title?: string;
  className?: string;
}

const { useState, useCallback, useMemo } = React;
const bufferHeight = 10;
const iframeStyleContent = `
body {
  margin: 0;
  font-family: Arial, Helvetica, sans-serif;
  font-size: 14px;
  line-height: 1.414;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  color: rgba(0, 0, 0, 0.85);
}
html {
  overflow: hidden;
}
`;

/**
 * @type {React.FC}
 */
export const IFrameComponent: React.FC<React.PropsWithChildren<IProps>> = React.memo((props) => {
  const { title } = props;
  const [ref, setRef] = useState<HTMLIFrameElement>(null);
  const [frameHeight, setFrameHeight] = useState(0);

  const content = useMemo(() => {
    const mountNode = ref?.contentWindow?.document?.body;
    if (!mountNode) {
      return null;
    }

    return createPortal(props.children, mountNode);
  }, [ref, props.children]);
  const onFrameLoad = useCallback(() => {
    const frameDocument = ref.contentWindow.document;
    const mountNode = frameDocument.body;

    setFrameHeight(mountNode.scrollHeight + bufferHeight);

    // this makes sure we can open links in parent window
    const baseNode = frameDocument.createElement('base');
    baseNode.target = '_parent';
    frameDocument.head.appendChild(baseNode);

    // apply some base styles
    const styleNode = frameDocument.createElement('style');
    styleNode.textContent = iframeStyleContent;
    frameDocument.head.appendChild(styleNode);
  }, [ref, setFrameHeight]);

  return (
    <iframe
      className={cx(styles.IFrameComponent, props.className)}
      ref={setRef}
      title={title}
      onLoad={onFrameLoad}
      style={{ height: `${frameHeight}px` }}
    >
      {content}
    </iframe>
  );
});
