import React from 'react';
import cx from 'classnames';
import { get, map, size } from 'lodash';

import { Button, Carousel as AntdCarousel, Tooltip, Typography } from 'antd';
import { CloudDownloadOutlined, LeftOutlined, RightOutlined } from '@ant-design/icons';
import { ContentModal, Image, Video } from '@components';

import type { CarouselProps, CarouselRef } from 'antd/lib/carousel';
import { IContent, ContentType } from '@types';

import styles from './Carousel.module.scss';

export interface ICarouselContent extends IContent {
  title?: string;
  description?: string;
}
interface IProps extends CarouselProps {
  contents: ICarouselContent[];

  showControls?: boolean;
  allowDownload?: boolean;
  showInfo?: boolean;
  textColor?: string;
  className?: string;
}

const { useRef, useState, useMemo, useCallback } = React;
const { Title, Paragraph } = Typography;

/**
 * @type {React.FC}
 */
export const Carousel: React.FC<React.PropsWithChildren<IProps>> = React.memo(
  ({
    contents,
    allowDownload = true,
    showControls = true,
    autoplay = false,
    showInfo = false,
    textColor = 'white',
    className,
    ...carouselProps
  }) => {
    const carouselRef = useRef<CarouselRef>(null);
    const [index, setIndex] = useState(0);
    const [showDetailModal, setShowDetailModal] = useState(false);

    const shouldShowControls = useMemo(
      () => showControls && size(contents) > 1,
      [contents, showControls],
    );
    const selectedContent = useMemo(() => get(contents, index), [contents, index]);
    const goToPrev = useCallback(() => carouselRef.current.prev(), []);
    const goToNext = useCallback(() => carouselRef.current.next(), []);
    const onDownloadContent = useCallback(() => {
      if (!selectedContent) {
        return;
      }

      const element = document.createElement('a');
      element.setAttribute('target', '_blank');
      element.setAttribute('href', selectedContent.url);
      element.setAttribute('download', selectedContent.originalName);
      element.style.display = 'none';
      document.body.appendChild(element);
      element.click();
      document.body.removeChild(element);
    }, [selectedContent]);
    const openDetailModal = useCallback(() => setShowDetailModal(true), []);
    const closeDetailModal = useCallback(() => setShowDetailModal(false), []);

    return (
      <div className={cx(styles.Carousel, className)}>
        <div className={styles.container}>
          <AntdCarousel
            {...carouselProps}
            className={styles.carousel}
            ref={carouselRef}
            autoplay={autoplay}
            dots
            afterChange={setIndex}
          >
            {map(contents, (content) => (
              <div key={content.id} className={styles.item} onClick={openDetailModal}>
                {content.type === ContentType.Image && (
                  <Image
                    className={styles.image}
                    cover={false}
                    src={content.previewUrl || content.url}
                  />
                )}
                {content.type === ContentType.Video && (
                  <Video className={styles.video} src={content.url} showDuration={true} />
                )}
              </div>
            ))}
          </AntdCarousel>
          <div className={styles.controls}>
            {shouldShowControls && (
              <>
                {index > 0 && (
                  <Button
                    className={cx(styles.button, styles.prev)}
                    ghost
                    shape="circle"
                    icon={<LeftOutlined />}
                    onClick={goToPrev}
                  />
                )}
                {index < size(contents) - 1 && (
                  <Button
                    className={cx(styles.button, styles.next)}
                    ghost
                    shape="circle"
                    icon={<RightOutlined />}
                    onClick={goToNext}
                  />
                )}
              </>
            )}
            {allowDownload && (
              <Tooltip title="Download content">
                <Button
                  className={styles.downloadButton}
                  ghost
                  shape="circle"
                  icon={<CloudDownloadOutlined />}
                  onClick={onDownloadContent}
                />
              </Tooltip>
            )}
          </div>
        </div>
        {showInfo && (
          <div className={styles.info}>
            {selectedContent?.title && (
              <Title
                className={styles.title}
                style={{
                  color: textColor,
                }}
                level={5}
              >
                {selectedContent.title}
              </Title>
            )}
            {selectedContent?.description && (
              <Paragraph
                className={styles.description}
                style={{
                  color: textColor,
                }}
                ellipsis={{ rows: 3 }}
              >
                {selectedContent.description}
              </Paragraph>
            )}
          </div>
        )}
        <ContentModal
          content={selectedContent}
          open={showDetailModal}
          onRequestClose={closeDetailModal}
        />
      </div>
    );
  },
);
