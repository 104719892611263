import { gql } from '@apollo/client';

export const SALES_TRACKING_SUMMARY_FRAGMENT = gql`
  fragment SalesTrackingSummary on SalesTrackingSummary {
    clicks {
      value
      ts
    }
    conversions {
      value
      ts
    }
    sales {
      value
      ts
    }
    costs {
      value
      ts
    }
  }
`;
