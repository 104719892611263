import React from 'react';
import cx from 'classnames';
import { formatDistance } from 'date-fns';
import { find, map, startCase } from 'lodash';

import { Tag } from 'antd';
import {
  CollapsiblePanel,
  ContentRequirement,
  SocialAccountIcon,
  UserAvatar,
  SocialPostIcon,
} from '@components';

import { isCustomPostType } from '@utils';
import { IProgramCollabDetail } from '@types';

import styles from './ContentRequirements.module.scss';

interface IProps {
  collab: IProgramCollabDetail;

  className?: string;
}

const { useMemo } = React;

/**
 * @type {React.FC}
 */
export const ContentRequirements: React.FC<IProps> = React.memo(({ collab, className }) => {
  const { term, member } = collab;

  const accountResources = useMemo(() => member.accountResources, [member]);
  const iteration = useMemo(() => {
    return find(term.iterations, (i) => i.id === term.latestIterationId);
  }, [term]);

  // this should not happen
  if (!iteration) {
    return null;
  }

  return (
    <div className={cx(styles.ContentRequirements, className)}>
      {map(iteration.data.contentReview.requirements, (requirement, index) => {
        const { type, dueDateTs, accountResourceId } = requirement;
        const resource = find(accountResources, (s) => s.id === accountResourceId);

        return (
          <CollapsiblePanel
            key={index}
            className={styles.panel}
            ghost={false}
            icon={<SocialPostIcon className={styles.icon} type={type} />}
            title={
              <div className={styles.header}>
                <span className={styles.type}>{startCase(type)}</span>
                {dueDateTs && (
                  <>
                    <span className={styles.text}>due in</span>
                    <Tag className={styles.dueDate} color="blue">
                      {formatDistance(Date.now(), dueDateTs)}
                    </Tag>
                  </>
                )}
                {!isCustomPostType(type) && (
                  <>
                    <span className={styles.text}>with account</span>
                    <UserAvatar
                      className={styles.avatar}
                      size="small"
                      showBoth
                      name={resource.account.info.displayName}
                      icon={<SocialAccountIcon type={resource.account.type} />}
                    />
                  </>
                )}
              </div>
            }
          >
            <ContentRequirement collab={collab} requirement={requirement} />
          </CollapsiblePanel>
        );
      })}
    </div>
  );
});
