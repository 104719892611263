import React from 'react';
import { useQuery, QueryHookOptions } from '@apollo/client';
import { isEmpty } from 'lodash';

import { message } from 'antd';

import { GET_CONTENT_REVIEW_QUERY } from '@queries';
import { GetContentReviewQuery, GetContentReviewQueryVariables } from '@types';

type IOptions = QueryHookOptions<GetContentReviewQuery, GetContentReviewQueryVariables>;

const { useEffect, useMemo } = React;

export function useGetContentReviewById(options: IOptions = {}) {
  const {
    loading,
    data: { review } = {},
    error,
    refetch,
  } = useQuery<GetContentReviewQuery, GetContentReviewQueryVariables>(GET_CONTENT_REVIEW_QUERY, {
    ...options,
    skip: isEmpty(options.variables?.id),
    fetchPolicy: 'cache-and-network',
    nextFetchPolicy: 'cache-first',
    notifyOnNetworkStatusChange: true,
  });

  const errorMessage = useMemo(() => error?.message, [error]);
  useEffect(() => {
    if (!loading && errorMessage) {
      message.error(errorMessage);
    }
  }, [loading, errorMessage]);

  return {
    loading,
    review,
    error,
    refetch,
  };
}
