import React from 'react';
import cx from 'classnames';
import { debounce } from 'lodash';

import { Input, InputProps, InputRef } from 'antd';
import { SearchOutlined } from '@ant-design/icons';

import styles from './SearchBox.module.scss';

interface IProps extends InputProps {
  onValueChange(value: string): void;

  inputRef?: React.MutableRefObject<InputRef>;
  buffer?: number;
  className?: string;
}

const { useState, useMemo, useCallback } = React;

/**
 * @type {React.FC}
 */
export const SearchBox: React.FC<React.PropsWithChildren<IProps>> = React.memo(
  ({
    onValueChange,
    placeholder = 'Search...',
    prefix = <SearchOutlined />,
    inputRef,
    buffer = 300,
    className,
    ...inputProps
  }) => {
    const [focused, setFocused] = useState(false);

    const onFocus = useCallback(() => setFocused(true), []);
    const onBlur = useCallback(() => setFocused(false), []);
    const onDebouncedValueChange = useMemo(
      () => debounce((value: string) => onValueChange(value), buffer),
      [buffer, onValueChange],
    );
    const handleValueChange = useCallback(
      (event: React.ChangeEvent<HTMLInputElement>) => onDebouncedValueChange(event.target.value),
      [onDebouncedValueChange],
    );

    return (
      <div
        className={cx(styles.SearchBox, className, {
          [styles.focused]: focused,
        })}
      >
        <Input
          {...inputProps}
          ref={inputRef}
          prefix={prefix}
          placeholder={placeholder}
          onChange={handleValueChange}
          spellCheck={false}
          onFocus={onFocus}
          onBlur={onBlur}
        />
      </div>
    );
  },
);
