import React from 'react';
import cx from 'classnames';
import { compact, isEmpty, map, mapValues } from 'lodash';

import { Button, Tag } from 'antd';
import { CheckIcon, CircleExclamationIcon } from '@customIcons';
import { SendTermPanel, WorkflowStageIcon } from '@components';

import { CollabTermData, IProgram, WorkflowStage, WorkflowTermStageCheckInParams } from '@types';
import { useGetAccountResources } from '@hooks';

import styles from './TermButton.module.scss';

interface IProps {
  program: IProgram;
  onSubmit(params: WorkflowTermStageCheckInParams): void;

  required?: boolean;
  completed?: boolean;
  className?: string;
}

const { useState, useCallback } = React;

/**
 * @type {React.FC}
 */
export const TermButton: React.FC<React.PropsWithChildren<IProps>> = React.memo(
  ({ program, onSubmit, required = false, completed = false, className }) => {
    const [showModal, setShowModal] = useState(false);

    const { accountResources, loading } = useGetAccountResources();

    const onWrapperClick = useCallback((event: React.MouseEvent) => {
      // NOTE: do not preventDefault(), otherwise form submission wont work
      // do not open detail panel
      event.stopPropagation();
    }, []);
    const onShowModal = useCallback(() => setShowModal(true), []);
    const onCloseModal = useCallback(() => setShowModal(false), []);
    const handleSubmit = useCallback(
      (values: CollabTermData) => {
        setShowModal(false);

        const { requirements } = values.contentReview || {};
        const params: WorkflowTermStageCheckInParams = {
          termData: values,
        };
        if (!isEmpty(requirements)) {
          params.termData.contentReview.requirements = map(requirements, (r) => ({
            ...r,
            guidelines: mapValues(r.guidelines, compact),
          }));
        }

        onSubmit(params);
      },
      [onSubmit],
    );

    return (
      <div onClick={onWrapperClick}>
        <Button
          className={cx(styles.TermButton, className, {
            [styles.completed]: completed,
          })}
          onClick={onShowModal}
          icon={<WorkflowStageIcon stage={WorkflowStage.Term} />}
          size="large"
          disabled={loading}
        >
          Draft collab terms
          <div className={styles.extra}>
            {completed && <Tag color="green">completed</Tag>}
            {!completed && required && <Tag color="yellow">required</Tag>}
            <div className={styles.status}>
              {completed && <CheckIcon className={styles.check} />}
              {!completed && <CircleExclamationIcon className={styles.alert} />}
            </div>
          </div>
        </Button>
        <SendTermPanel
          program={program}
          accountResources={accountResources}
          show={showModal}
          onRequestClose={onCloseModal}
          onSubmit={handleSubmit}
          submitLabel="Confirm Terms"
        />
      </div>
    );
  },
);
