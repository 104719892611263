import React from 'react';
import firebase from 'firebase/compat/app';

import { ApolloClient, NormalizedCacheObject } from '@apollo/client';
import { useAuthContext } from '@context';
import { useInitializeApolloClient, useInitializeFirebase } from './utils';

interface IAuthContext {
  firebaseApp: firebase.app.App;
  apolloClient: ApolloClient<NormalizedCacheObject>;
  sessionExpired: boolean;
}

const { useContext } = React;

export const AppContext = React.createContext<IAuthContext>(null);
export const useAppContext = () => useContext(AppContext);
export const AppContextProvider: React.FC<React.PropsWithChildren<{}>> = React.memo(
  ({ children }) => {
    const { token } = useAuthContext();

    const firebaseApp = useInitializeFirebase();
    const { sessionExpired, apolloClient } = useInitializeApolloClient(token);
    // useInitializeDatadogRum();

    return (
      <AppContext.Provider
        value={{
          firebaseApp,
          apolloClient,
          sessionExpired,
        }}
      >
        {children}
      </AppContext.Provider>
    );
  },
);
