import { startCase, toLower } from 'lodash';

import { IProgramCollabActivity, WorkflowEvent } from '@types';

export function getActivityDescription(activity: IProgramCollabActivity): JSX.Element {
  const { event, collab, params } = activity;

  switch (event) {
    /**
     * stage event
     */
    case WorkflowEvent.MoveToStage: {
      return (
        <span>
          moved <b>{collab.member.info.fullName}</b> to <b>{startCase(toLower(params.target))}</b>{' '}
          stage.
        </span>
      );
    }
    /**
     * prospect stage
     */
    case WorkflowEvent.ProspectStageDirectAdd: {
      return (
        <span>
          added <b>{collab.member.info.fullName}</b> to the program.
        </span>
      );
    }
    case WorkflowEvent.ProspectStageApply: {
      return <span>applied to the program.</span>;
    }
    case WorkflowEvent.ProspectStageInvite: {
      return (
        <span>
          invited <b>{collab.member.info.fullName}</b> to the program.
        </span>
      );
    }
    case WorkflowEvent.ProspectStageAcceptInvitation: {
      return <span>accepted the invitation.</span>;
    }
    case WorkflowEvent.ProspectStageRejectInvitation: {
      return <span>rejected the invitation.</span>;
    }
    case WorkflowEvent.ProspectStageAcceptApplication: {
      return <span>accepted the application.</span>;
    }
    case WorkflowEvent.ProspectStageRejectApplication: {
      return <span>rejected the application.</span>;
    }
    /**
     * term stage
     */
    /**
     * email stage
     */
    case WorkflowEvent.EmailStageSend: {
      return <span>sent the welcome email.</span>;
    }
    /**
     * contract stage
     */
    case WorkflowEvent.ContractStageSendContract: {
      return <span>sent the contract.</span>;
    }
    case WorkflowEvent.ContractStageAcceptContract: {
      return <span>accepted the contract.</span>;
    }
    /**
     * info update stage
     */
    case WorkflowEvent.InfoUpdateStageSendRequest: {
      return <span>sent the info update request.</span>;
    }
    case WorkflowEvent.InfoUpdateStageUpdateInfo: {
      return <span>updated their information.</span>;
    }
    /**
     * product fulfillment stage
     */
    case WorkflowEvent.ProductFulfillmentStagePickProducts: {
      return <span>picked the preferred products.</span>;
    }
    case WorkflowEvent.ProductFulfillmentStageSkipPickProducts: {
      return <span>skipped the pick products step.</span>;
    }
    case WorkflowEvent.ProductFulfillmentStageCreateOrder: {
      return <span>created the order.</span>;
    }
    case WorkflowEvent.ProductFulfillmentStageFulfillOrder: {
      return <span>fulfilled the order.</span>;
    }
    case WorkflowEvent.ProductFulfillmentStageDeliverOrder: {
      return (
        <span>
          The order for <b>{collab.member.info.fullName}</b> has been delivered.
        </span>
      );
    }
    case WorkflowEvent.ProductFulfillmentStageMarkOrderDelivered: {
      return <span>marked the order as delivered.</span>;
    }
    /**
     * content review stage
     */
    case WorkflowEvent.ContentReviewStageUpdateContents: {
      return <span>updated the contents.</span>;
    }
    case WorkflowEvent.ContentReviewStageApproveContents: {
      return <span>approved the contents.</span>;
    }
    case WorkflowEvent.ContentReviewStageRejectContents: {
      return <span>rejected the contents.</span>;
    }
    /**
     * payment stage
     */
    case WorkflowEvent.PaymentStageCreatePayment: {
      return <span>created the payment.</span>;
    }
    case WorkflowEvent.PaymentStageConfirmPayment: {
      return <span>confirmed the payment.</span>;
    }
    /**
     * sales tracking stage
     */
    case WorkflowEvent.SalesTrackingStageCreateLink: {
      return (
        <span>
          created the tracking link for <b>{collab.member.info.fullName}</b>.
        </span>
      );
    }
    default: {
      break;
    }
  }

  return null;
}
