import { includes, indexOf, map } from 'lodash';

import { WorkflowManagerActionRequiredStates, WorkflowMemberActionRequiredStates } from '@utils';
import { IProgramCollabDetail, WorkflowStage } from '@types';
import { TPanelStepStatus } from './constants';

export function getPanelStatus(
  stage: WorkflowStage,
  collab: IProgramCollabDetail,
): TPanelStepStatus {
  const { stage: collabStage, state } = collab.workflowItem;

  if (collabStage === WorkflowStage.Completed) {
    return 'completed';
  }

  const stages = map(collab.program.workflowConfig.stages, (s) => s.type);
  const currentStageIndex = indexOf(stages, collab.workflowItem.stage);
  const targetStageIndex = indexOf(stages, stage);

  const isPreviousStage = currentStageIndex > targetStageIndex;
  if (isPreviousStage) {
    return 'completed';
  }
  const isFutureStage = currentStageIndex < targetStageIndex;
  if (isFutureStage) {
    return 'disabled';
  }

  if (includes(WorkflowMemberActionRequiredStates, state)) {
    return 'pendingMember';
  } else if (includes(WorkflowManagerActionRequiredStates, state)) {
    return 'pendingManager';
  }

  return 'pending';
}
