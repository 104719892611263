import { gql } from '@apollo/client';

export const AFFILIATE_TRACKING_FRAGMENT = gql`
  fragment AffiliateTracking on AffiliateTracking {
    id
    email
    status
    level
    info {
      name
      incentive {
        type
        value
        cpsValue
      }
      trackingUrl
      recruitUrl
    }
    stats {
      clicks
      conversions
      sales
      cost
    }
    createdDate
  }
`;
