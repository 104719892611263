import React from 'react';
import cx from 'classnames';
import { isEmpty } from 'lodash';

import { SearchBox } from '@components';

import { usePickProductsContext } from '../context';

import styles from './ProductSearch.module.scss';

interface IProps {
  autoFocus?: boolean;
  className?: string;
}

const { useCallback } = React;

/**
 * @type {React.FC}
 */
export const ProductSearch: React.FC<React.PropsWithChildren<IProps>> = React.memo(
  ({ autoFocus = false, className }) => {
    const { filters, setFilters } = usePickProductsContext();

    const onValueChange = useCallback(
      (value: string) => {
        if (isEmpty(value)) {
          setFilters({
            ...filters,
            value: null,
          });
        } else {
          setFilters({
            ...filters,
            value,
          });
        }
      },
      [filters, setFilters],
    );

    return (
      <SearchBox
        className={cx(styles.ProductSearch, className)}
        autoFocus={autoFocus}
        defaultValue={filters?.value}
        placeholder="Search products..."
        onValueChange={onValueChange}
      />
    );
  },
);
