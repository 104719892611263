import { gql } from '@apollo/client';

import { SOCIAL_ACCOUNT_FRAGMENT, SOCIAL_POST_FRAGMENT } from './fragments';

//========================================================
//= Fragments
//========================================================
export const SOCIAL_POST_OVERVIEW_FRAGMENT = gql`
  fragment SocialPostOverview on SocialPost {
    ...SocialPost
    account {
      ...SocialAccount
    }
  }
  ${SOCIAL_ACCOUNT_FRAGMENT}
  ${SOCIAL_POST_FRAGMENT}
`;

export const SOCIAL_POST_DETAIL_FRAGMENT = gql`
  fragment SocialPostDetail on SocialPost {
    ...SocialPost
    account {
      ...SocialAccount
    }
  }
  ${SOCIAL_ACCOUNT_FRAGMENT}
  ${SOCIAL_POST_FRAGMENT}
`;

//========================================================
//= Queries
//========================================================
export const GET_SOCIAL_POST_QUERY = gql`
  query GetSocialPost($id: String!) {
    post: creatorGetSocialPostById(id: $id) {
      ...SocialPostDetail
    }
  }
  ${SOCIAL_POST_DETAIL_FRAGMENT}
`;

export const GET_SOCIAL_POSTS_QUERY = gql`
  query GetSocialPosts(
    $limit: Int
    $offset: Int
    $sortBy: EntitySortBy!
    $filters: SocialPostFilters
  ) {
    posts: creatorGetSocialPosts(
      limit: $limit
      offset: $offset
      sortBy: $sortBy
      filters: $filters
    ) {
      ...SocialPostOverview
    }
  }
  ${SOCIAL_POST_OVERVIEW_FRAGMENT}
`;

export const GET_SOCIAL_POST_COUNT_QUERY = gql`
  query GetSocialPostCount($filters: SocialPostFilters) {
    count: creatorGetSocialPostCount(filters: $filters)
  }
`;
