import { cloneDeep, cloneDeepWith } from 'lodash';

export function omitDeep<T>(collection: T, excludeKeys: string[]): T {
  function omitFn(value: T) {
    if (value && typeof value === 'object') {
      excludeKeys.forEach((key) => {
        delete value[key];
      });
    }
  }

  return cloneDeepWith(cloneDeep(collection), omitFn);
}
