import { gql } from '@apollo/client';

export const MANAGER_FRAGMENT = gql`
  fragment Manager on Manager {
    id
    email
    name
    profileContent {
      url
      previewUrl
    }
    createdDate
    updatedDate
  }
`;
