import React from 'react';
import cx from 'classnames';
import { map } from 'lodash';

import styles from './Grid.module.scss';

interface IProps {
  gap?: number;
  itemWidth?: number;
  itemHeight?: number;

  className?: string;
}

const { useEffect, useRef, useState } = React;

/**
 * @type {React.FC}
 */
export const Grid: React.FC<React.PropsWithChildren<IProps>> = React.memo((props) => {
  const items = React.Children.toArray(props.children);
  const containerRef = useRef<HTMLDivElement>(null);
  const [columns, setColumes] = useState(1);

  useEffect(() => {
    const container = containerRef.current;
    if (container) {
      setColumes(Math.round(container.clientWidth / props.itemWidth));
    }
  }, [containerRef, props.itemWidth, setColumes]);

  return (
    <div
      ref={containerRef}
      className={cx(styles.Grid, props.className)}
      style={{
        columnCount: columns,
        columnGap: props.gap,
      }}
    >
      {map(items, (item, index) => (
        <div
          key={index}
          className={styles.item}
          style={{
            marginBottom: props.gap,
            height: props.itemHeight,
          }}
        >
          {item}
        </div>
      ))}
    </div>
  );
});

Grid.defaultProps = {
  gap: 12,
  itemWidth: 300,
  itemHeight: 360,
};
