import React from 'react';
import { useMutation, MutationHookOptions, MutationFunctionOptions } from '@apollo/client';

import { message } from 'antd';

import { UPDATE_PROFILE_MUTATION } from '@queries';
import { UpdateProfileMutation, UpdateProfileMutationVariables } from '@types';

type IOptions = MutationHookOptions<UpdateProfileMutation, UpdateProfileMutationVariables>;
type IFunctionOptions = MutationFunctionOptions<
  UpdateProfileMutation,
  UpdateProfileMutationVariables
>;

const { useCallback } = React;

export const useUpdateProfile = (options: IOptions = {}) => {
  const [_updateProfile, { loading, error }] = useMutation<
    UpdateProfileMutation,
    UpdateProfileMutationVariables
  >(UPDATE_PROFILE_MUTATION, options);

  const updateProfile = useCallback(
    async (options: IFunctionOptions = {}) => {
      try {
        const result = await _updateProfile(options);

        return result;
      } catch (err) {
        message.error((err as Error).message);
      }
    },
    [_updateProfile],
  );

  return {
    updateProfile,
    loading,
    error,
  };
};
